import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `AcquirerTypeFilter` function is a React component that renders a dropdown filter for selecting
 * an acquirer company type.
 * @returns a JSX element.
 */
function AcquirerTypeFilter({ open, accordionClick, setAcquirerTypeOptions, AcquirerTypeOptions, customPathname, label = "Acquirer Company Type" }) {
    const { companyType, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const Ids = refineSearchFilters[customPathname]?.acquirerType?.map(value => value.id) ?? [];
        setAcquirerTypeOptions(companyType?.map(industry => ({ ...industry, isChecked: Ids.includes(industry.id) })))
    }, [companyType, customPathname, refineSearchFilters, setAcquirerTypeOptions])


  /**
   * The function `handleCompanyTypeChange` updates the `isChecked` property of each object in the
   * `AcquirerTypeOptions` array based on the selected value from the event target.
   * @param e - The parameter `e` is an event object that is passed to the `handleCompanyTypeChange`
   * function. It is typically an event object that is triggered by a user action, such as clicking a
   * button or changing the value of an input field.
   */
    const handleCompanyTypeChange = (e) => {
        const value = e.target.value
        setAcquirerTypeOptions(AcquirerTypeOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === value })))
    }

    const selectedAcquirerType = AcquirerTypeOptions?.filter(option => option.isChecked)?.at(0)


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedAcquirerType?.name ? ` ${label} - ` + selectedAcquirerType?.name : label}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selectedAcquirerType?.id ?? ""} onChange={handleCompanyTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {AcquirerTypeOptions && AcquirerTypeOptions.length > 0 ? (
                            AcquirerTypeOptions.map((companyType, index) => (
                                <option key={companyType.id} value={companyType.id}>{companyType.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default AcquirerTypeFilter