import React from 'react'
import Layout from '../../../Components/AdvisorLayout'
 import TransactionAdvisorCompanyDetail from '../../../Components/Advisor/TransactionAdvisor/TransactionAdvisorCompanyDetail'
 import RefineSearch from '../../../Components/RefineSearchComponent'
 import TransactionAdvisorDetailCard from '../../../Components/Advisor/TransactionAdvisor/TransactionAdvisorDetailCard'
//  import ChatIcon from '../../../images/Group 13/png/Group 13.png';
//  import chatPopupIcon from '../../../images/chatPopupIcon.png'
//  import ChatPopup from '../../../Components/ChatPopup'
 import { T_LEGAL_ADVISOR_CARD_QUERY } from "../../../Queries/Pe"
import { useSearchParams } from 'react-router-dom'
import Constants from '../../../Constants'
import AdvisorInvestmentRefineSearch from '../../../Components/RefineSearch/AdvisorInvestmentsRefineSearch'
import AdvisorExistsRefineSearch from '../../../Components/RefineSearch/AdvisorExistsRefineSearch'
import useModule from '../../../hooks/useModule'
import { routeNames } from '../../../routeSegments'
/**
 * The AdvisorDetail function renders a component that displays transaction advisor details and allows
 * for refining the search based on the section.
 * @returns The function `AdvisorDetail` returns a JSX element, which represents the UI of the
 * component.
 */
function AdvisorDetail() {
    // const [showModal, setShowModal] = React.useState(false);
    const {isPE} = useModule();

    const [URLSearchParams, SetURLSearchParams] = useSearchParams({section:Constants.primaryMasterSubIds.pe_vc_advisors_id})

    const section = parseInt(URLSearchParams.get('section'));

    let customPathname =isPE? routeNames.pe_transaction_advisors_pe_vc_advisors:routeNames.vc_transaction_advisors_vc_advisors;

    if(section === Constants.primaryMasterSubIds.pe_vc_advisors_exits_id){
        customPathname = isPE ? routeNames.pe_transaction_advisors_ma_advisors_exits : routeNames.vc_transaction_advisors_ma_vc_exits
    }else if(section === Constants.primaryMasterSubIds.pe_vc_advisors_ma_id){
        customPathname = isPE ? routeNames.pe_transaction_advisors_ma_pe_vc_advisors : routeNames.vc_transaction_advisors_ma_vc_advisors
    }else if(section === Constants.primaryMasterSubIds.pe_vc_advisors_pm_id){
        customPathname = isPE ? routeNames.pe_transaction_advisors_pm_pe_vc_advisors : routeNames.vc_transaction_advisors_pm_vc_advisors
    }


    return (
        <div> 
            <Layout>
            <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    <TransactionAdvisorDetailCard T_LEGAL_ADVISOR_CARD_QUERY={T_LEGAL_ADVISOR_CARD_QUERY}/>
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section    
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <TransactionAdvisorCompanyDetail section={section}/>
                        
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    {
                                        section === Constants.primaryMasterSubIds.pe_vc_advisors_id
                                        ?<AdvisorInvestmentRefineSearch customPathname={customPathname}/>
                                        :<AdvisorExistsRefineSearch customPathname={customPathname}/>
                                    }
                                </RefineSearch>
                            </div>
                        </aside>
                    </div>
                    
                </div>
            </Layout>
        </div>
    )
}

export default AdvisorDetail