import { useState } from "react";
import menuOpen from '../images/Group 12.png';

/**
 * The RefineSearch component is a collapsible RefineSearch that contains a title, a toggle button, and children
 * components.
 * @returns a JSX element.
 */
export default function Sidebar({children}) {
    const [open, setOpen] = useState(true);
    return (
        <>
            <div
                className={` ${
                    open ? "w-12" : "w-60 "
                } flex flex-col h-full shadow duration-300`}
            >
                <div className="space-y-3 h-screen">
                    <div className="flex items-center px-3 pt-3 justify-between">
                        <h2 className="text-xl font-bold text-white">
                            <div style={{display:!open?"block":"none"}} className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20]">Refine Search</div>
                        </h2>
                        <button onClick={() => setOpen(!open)}>
                            {!open?<img src={menuOpen} className="w-[25px] h-[25px]"/>:<img src={menuOpen} className="w-[25px] h-[25px] rotate-180"/>}
                        </button>
                    </div>
                    
                    
                    <div className="flex-1" style={{display:!open?"block":"none"}}>                        
                        {children}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-12">
            </div>
        </>
    );
}