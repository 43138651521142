import React, { useState, useEffect, useContext } from "react"
import { NavLink } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from 'moment';
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
// import ExcelPopup from "../../Components/ExcelPopup";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";

import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../Components/ExportPopup";
import {  investorProfileExportData, investorbackedIPOExportData, investorAngelInvestmentExportData, investorexitExportData, investorincubationExportData } from "../../Components/ExportPopup/ExportData"
import { exportInvestorExcel } from "../../Config/config";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import { useLocation } from "react-router";
import { ExportDirectoryExcel } from "../ExportPopup/DirectoryExport";

function MainMenu(props) {


    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const {
        // activeTabDeal,setActiveTabDeal,
        setCurrentPage} =useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    // const [save, setSave] = useState(false)
    const {save, setSave,showMonthPicker ,refineSearchFilters,groupId} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

  /* The above code is written in JavaScript and it is using the useState hook from React. */
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
      const { isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)

    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    const Link1 = () => { document.getElementById("link1").scrollIntoView({ behavior: 'smooth',block: 'nearest', inline: 'center' }); }
    const Link2 = () => { document.getElementById("link2").scrollIntoView({ behavior: 'smooth',block: 'nearest', inline: 'center' }); }
    const Link3 = () => { document.getElementById("link3").scrollIntoView({ behavior: 'smooth',block: 'nearest', inline: 'center' }); }
    const Link4 = () => { document.getElementById("link4").scrollIntoView({ behavior: 'smooth',block: 'nearest', inline: 'center' }); }
    const Link5 = () => { document.getElementById("link5").scrollIntoView({ behavior: 'smooth',block: 'nearest', inline: 'center' }); }

    useEffect(()=>{
        if(openTab===1){
          Link1()
        }
        else if(openTab===2){
          Link2()
        }
        else if(openTab===3){
          Link3()
        }else if(openTab===4){
          Link4()
        } 
        else if(openTab===5){
          Link5()
        }
        
      },[openTab])

   /**
    * The function `savedList` toggles the value of the `save` variable.
    */
    const savedList = () => {
        setSave(!save)
    }

    /**
     * The download function calls the handleDownloadExcel function.
     */
    const download = () => {
        handleDownloadExcel()
    }

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <div className="pl-3 pr-4 lg:pr-4 md:pr-5">
            <div className="scrollbar rounded shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between ">

                    <div className="flex flex-row px-3 lg:px-2 overflow-x-auto space-x-5 scrollbar-remove">

                        <div className="py-4" id="link1">
                            <NavLink className={"whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt]" + (openTab === 1 ? " font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                            }
                                data-toggle="tab"
                                to={props.menu == "vcinvestor" ? routeNames.vc_investors_vc_investments : routeNames.pe_investors_pe_vc_investments}
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button onClick={()=>setCurrentPage(1)} className="outline-none w-30 focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                    >
                                        <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                                            {props.menu == "vcinvestor" ? "VC Investments" : "PE-VC Investments"}
                                        </span>

                                    </button>

                                </div>
                            </NavLink>
                        </div>

                        <div className="py-4 pl-2 lg:pl-0" id="link2">
                            <NavLink className={" whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px]" + (openTab === 2 ? "text-[#333333] font-bold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]" : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                            }
                                to={props.menu == "maPeExit" ? routeNames.pe_investors_exists_ma_pe_vc_investments :
                                    props.menu == "public_pevc" ? routeNames.pe_investors_exists_pm_pe_vc_investments :
                                        props.menu == "ma_vc" ? routeNames.vc_investors_exists_ma_vc_investments :
                                            props.menu == "public_vc" ? routeNames.vc_investors_exists_pm_vc_investments :
                                                routeNames.pe_investors_exists_pe_vc_investments}
                                data-toggle="tab"
                                role="tablist"
                            >
                              {isFromGlobalSearch() ? 
                              <div className="group inline-block">
                              <button onClick={()=>setCurrentPage(1)} className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                              >
                                  <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                      {props.menu == "maPeExit" ? "via M&A (PE-VC)" :
                                          props.menu == "public_pevc" ? "via Public Market (PE-VC)" :
                                              props.menu == "ma_vc" ? "via M&A (VC)" :
                                                  props.menu == "public_vc" ? "Public Market (VC)" : "PE-VC Exits"}</span>
                                 
                              </button>


                                  

                             
                          </div>

                           :  <div className="group inline-block">
                                    <button onClick={()=>setCurrentPage(1)} className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                    >
                                        <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            {props.menu == "maPeExit" ? "via M&A (PE-VC)" :
                                                props.menu == "public_pevc" ? "via Public Market (PE-VC)" :
                                                    props.menu == "ma_vc" ? "via M&A (VC)" :
                                                        props.menu == "public_vc" ? "Public Market (VC)" : "PE-VC Exits"}</span>
                                        <span>
                                            <svg
                                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                />
                                            </svg>
                                        </span>
                                    </button>

                                    <ul
                                        className="mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10  absolute 
                                    transition duration-150 ease-in-out origin-top w-[9rem] md:min-w-[13rem]"
                                    >
                                        {
                                            props.menu == "maPeExit" ?


                                                <ul
                                                    className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                >
                                                    <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                        <NavLink to={routeNames.pe_investors_exists_pe_vc_investments}
                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                            <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                            </span>

                                                        </NavLink>
                                                    </li>

                                                    <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                        <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                        >
                                                            <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via Public Market (PE-VC)</span>
                                                            <span className="mr-auto">

                                                            </span>
                                                        </NavLink>

                                                    </li>
                                                </ul>


                                                :
                                                props.menu == "public_pevc" ?


                                                    <ul
                                                        className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem]  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                    >
                                                        <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                            <NavLink to={routeNames.pe_investors_exists_pe_vc_investments}
                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                </span>

                                                            </NavLink>
                                                        </li>
                                                        <li className="rounded-sm relative px-6 py-2 font-sans_book_body  hover:bg-gray-100 ">
                                                            <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_investments}
                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                            >
                                                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via M&A (PE-VC)</span>
                                                                <span className="mr-auto">

                                                                </span>
                                                            </NavLink>

                                                        </li>

                                                    </ul>


                                                    :
                                                    props.menu == "ma_vc" ?


                                                        <ul
                                                            className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                        >
                                                            <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                                <NavLink to={routeNames.pe_investors_exists_pe_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                    <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                    </span>

                                                                </NavLink>
                                                            </li>

                                                            <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                                <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via Public Market (PE-VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>

                                                            </li>
                                                        </ul>

                                                        :
                                                        props.menu == "public_vc" ?


                                                            <ul
                                                                className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                            >
                                                                <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                                    <NavLink to={routeNames.pe_investors_exists_pe_vc_investments}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                        <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                        </span>

                                                                    </NavLink>
                                                                </li>
                                                                <li className="rounded-sm relative px-6 py-2 font-sans_book_body hover:bg-gray-100 ">
                                                                    <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_investments}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                    >
                                                                        <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via M&A (PE-VC)</span>
                                                                        <span className="mr-auto">

                                                                        </span>
                                                                    </NavLink>

                                                                </li>
                                                                <li className="rounded-sm relative px-6 py-2 font-sans_book_body hover:bg-gray-100 ">
                                                                    <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                                        className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                    >
                                                                        <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via Public Market (PE-VC)</span>
                                                                        <span className="mr-auto">

                                                                        </span>
                                                                    </NavLink>

                                                                </li>
                                                            </ul>

                                                            :
                                                            props.menu == "maPeExit" ?


                                                                <ul
                                                                    className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                                >
                                                                    <li className="rounded-sm relative px-6 py-2 font-sans_book_body hover:bg-gray-100 ">
                                                                        <NavLink to={routeNames.pe_investors_exists_pe_vc_investments}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                            <span onClick={()=>setCurrentPage(1)} className=" flex-1 lg:text-[13px] text-[#333333] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                            </span>

                                                                        </NavLink>
                                                                    </li>

                                                                    <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                                        <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via Public Market (PE-VC)</span>
                                                                            <span className="mr-auto">

                                                                            </span>
                                                                        </NavLink>

                                                                    </li>
                                                                </ul>


                                                                :
                                                                <ul
                                                                    className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                                >

                                                                    <li className="rounded-sm relative px-6 py-2 font-sans_book_body  hover:bg-gray-100 ">
                                                                        <NavLink to={routeNames.pe_investors_exists_ma_pe_vc_investments}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via M&A (PE-VC)</span>
                                                                            <span className="mr-auto">

                                                                            </span>
                                                                        </NavLink>

                                                                    </li>
                                                                    <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                                                                        <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                        >
                                                                            <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">via Public Market (PE-VC)</span>
                                                                            <span className="mr-auto">

                                                                            </span>
                                                                        </NavLink>

                                                                    </li>
                                                                </ul>
                                        }

                                    </ul>
                                </div>
                                }
                            </NavLink>
                        </div>
                        <div className="py-4 " id="link3">
                            <NavLink className={" whitespace-nowrap  text-[12pt] leading-[15pt] lg:text-[14px] lg:leading-[17px] " + (openTab === 3 ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                            }
                                to={props.menu == "vcbackedipo" ? routeNames.vc_investors_backed_ipos_vc_investments : routeNames.pe_investors_backed_ipos_pe_vc_investments}
                                data-toggle="tab"
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button className="outline-none w-36 focus:outline-none py-1 bg-white rounded-sm flex items-center min-w-32"
                                    >
                                        <span onClick={()=>setCurrentPage(1)} className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">{props.menu == "vcbackedipo" ? "VC Backed IPOs" : "PE-VC Backed IPOs"}</span>
                                        <span>

                                        </span>
                                    </button>

                                </div>
                            </NavLink>
                        </div>

                        <div className="lg:py-4 py-4 pl-3 lg:pl-0 " id="link4">
                            <NavLink className={"whitespace-nowrap text-[12pt] leading-[15pt] lg:text-[14px] lg:leading-[15px] " + (openTab === 4 ? "text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : " whitespace-nowrap font-sans_medium_body text-[12pt] leading-[15pt] lg:text-[14px] lg:leading-[15px] text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                            }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(4)
                                // }}
                                data-toggle="tab"
                                to={routeNames.pe_investors_angel_investments}
                                role="tablist"
                            >
                              <span className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]" onClick={()=>setCurrentPage(1)}>Angel Investments</span>  
                            </NavLink>

                        </div>
                        <div className="lg:py-4 lg:pl-1 pl-2 py-4" id="link5">
                            <NavLink className={"whitespace-nowrap text-[12pt] leading-[15pt] lg:text-[14px] lg:leading-[15px]" + (openTab === 5 ? "text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] font-bold border-b-[3px] border-[#F1CA75] py-4"
                                : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt] opacity-[.8] hover:opacity-100    ")
                            }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to={routeNames.pe_investors_incubation}
                                role="tablist"
                            >
                               <span   className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]" onClick={()=>setCurrentPage(1)}>Incubation</span> 
                            </NavLink>
                        </div>
                    </div> 

                   {isFromGlobalSearch()
                   ?
                   "":
                  <div className={"md:flex justify-between items-center px-3 lg:px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? '':'flex')}>
                        <label htmlFor="" className="text-[#666666] ">
                            <div className={showMonthPicker!=0 ?"":'flex'}>
                                <MonthPickerComponent  />
                            </div>
                        </label>
                        <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}> 
                        {isTrialExport =='false' || isDirectoryUser || isStudent == 'true' || isFromGlobalSearch() || groupId?.myList?.length > 0 ? <></>  : <>                     
                                <button >
                                    <img src={xls} className="h-[24px] mt-1 max-w-[21px]" alt=""
                                        onClick={() => setShowPopup(!showPopup)}
                                    />
                                    {showPopup && (
                                        <div>
                                          {isDirectoryUser ? (
                                            <ExportDirectoryExcel
                                              modules="investor"
                                              url={exportInvestorExcel}
                                              fileName={props.exportFileName}
                                              module_type={
                                                props.primaryTypeMasterId ===
                                                Constants.primaryMasterIds.peId
                                                  ? "pe"
                                                  : "vc"
                                              }
                                              exportOptions={
                                                props.menu === "investor"
                                                  ? investorProfileExportData
                                                  : props.menu === "backedipo"
                                                  ? investorbackedIPOExportData
                                                  : props.menu === "angel"
                                                  ? investorAngelInvestmentExportData
                                                  : props.menu === "incubation"
                                                  ? investorincubationExportData
                                                  : investorexitExportData
                                              }
                                              type={
                                                props.menu === "investor"
                                                  ? "pe_vc_investment"
                                                  : props.menu === "backedipo"
                                                  ? "pe_ipo"
                                                  : props.menu === "angel"
                                                  ? "angel"
                                                  : props.menu === "incubation"
                                                  ? "incubation"
                                                  : props.menu === "maPeExit"
                                                  ? "pe_ma_exit"
                                                  : props.menu === "public_pevc"
                                                  ? "pe_pm_exit"
                                                  : "pe_vc_exit"
                                              }
                                              onClose={() => setShowPopup(false)}
                                            />
                                          ) : (
                                            <ExportPopup
                                              onClose={() => setShowPopup(false)}
                                              modules="investor"
                                              url={exportInvestorExcel}
                                              fileName={props.exportFileName}
                                              module_type={
                                                props.primaryTypeMasterId ===
                                                Constants.primaryMasterIds.peId
                                                  ? "pe"
                                                  : "vc"
                                              }
                                              exportOptions={
                                                props.menu === "investor"
                                                  ? investorProfileExportData
                                                  : props.menu === "backedipo"
                                                  ? investorbackedIPOExportData
                                                  : props.menu === "angel"
                                                  ? investorAngelInvestmentExportData
                                                  : props.menu === "incubation"
                                                  ? investorincubationExportData
                                                  : investorexitExportData
                                              }
                                              type={
                                                props.menu === "investor"
                                                  ? "pe_vc_investment"
                                                  : props.menu === "backedipo"
                                                  ? "pe_ipo"
                                                  : props.menu === "angel"
                                                  ? "angel"
                                                  : props.menu === "incubation"
                                                  ? "incubation"
                                                  : props.menu === "maPeExit"
                                                  ? "pe_ma_exit"
                                                  : props.menu === "public_pevc"
                                                  ? "pe_pm_exit"
                                                  : "pe_vc_exit"
                                              }
                                            />
                                        )}
                                        </div>
                                    )}

                                </button>  
                                {props.menu == "incubation" || 
                                groupId?.myList?.length > 0 ? <></>  :                          
                            <a>
                                <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                            </a>}
                        </>}  
                        {props.menu == "incubation" || 
                        groupId?.myList?.length > 0 ? <></>  : 
                            <SavedFilterICON
                                save={save}
                                FileIcon={FileIcon}
                                FileIconBlur={FileIconBlur}
                                savedList={savedList} />
                        }

                        </div>

                    </div> 


                   }
                    
                </div>
                {save ? <SavedFilterContent  primaryTypeMasterId={props.primaryTypeMasterId}
                      subTypeMasterId={props.subTypeMasterId}
                      typeId={props.typeId}
                      show={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu;
