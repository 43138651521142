import { React, useState, useMemo, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import ExcelPopup from "../../../Components/ExcelPopup";
import { ADVISOR_COMPANY_INVESTMENTS } from "../../../Queries/Pe"
import moment from 'moment'
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel, advisorExitTable } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import axios from 'axios'
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import { UserContext } from '../../../Contexts/UserContext';

/**
 * The `AdvisorCompaniesInvestments` function is a React component that displays a table of company
 * investments for a specific advisor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `AdvisorCompaniesInvestments` component. These properties can be accessed using dot notation, such
 * as `props.companyInvestment` or `props.advisorName`.
 * @returns The function `AdvisorCompaniesInvestments` returns a JSX element.
 */
const AdvisorCompaniesInvestments = (props) => {


    const [portfolio, setPortfolioData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(20)
    const [openTab, setOpenTab] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [searchParams] = useSearchParams();
    let advisorType = "L";
    const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)   
    const location = useLocation();
    const [showXlDownload, setshowXlDownload] = useState(false);      
    const {primaryMasterId} = useModule();
    const {isTrialExport,isStudent} = useContext(UserContext)


    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(portfolio?.length / dataPerPage); i++) {
        pageNumbers.push(i);
    }
    console.log(pageNumbers, "pageNumbers")
    /**
     * The function `setPage` sets the current page to the specified page number.
     * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
     * current page.
     */
    const setPage = (pageNum) => {
        setCurrentPage(pageNum)

    }
    /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a sorting function.
    This function is used to sort the data in a specific column of a table. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function. This
    function is used to sort the data in a specific column of a table. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    /* The `investorSort` constant is a memoized sorting function that is used to sort the data in a
    specific column of a table. */
    const investorSort = useMemo(() => {
        return (rowA,rowB, columnId,desc)=>{
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data=>data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data=>data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);

    /**
     * The function "addCommasToNumber" takes a number as input and returns a string representation of
     * the number with commas added for thousands separators.
     * @param number - The `number` parameter is the number that you want to add commas to.
     * @returns The function `addCommasToNumber` returns a string with commas added to the number.
     */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };
    
    const columns = useMemo(() => [
        {
            Header: "Company Name",
            accessor: "companyName",
            className:"w-[30%]",
            Cell: ({ value }) => {
                return  <div className='leading-[2rem] font-sans_book_body items-center justify-center'>
                     {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                        <RandomLogo name={value.name} page="company page"/>
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
                    }
                
                    <OverflowToolTip component={
                    <Link className='hover:text-[#BC8B20]'
                    state={{ cameFrom: location.state?.prevPath }} 
                    to={reMatch!=null?routeNames.reCompanyById(value?.id)
                     :""}>
                    {value.name ?? "--"}
                </Link>
                   } /> 
                </div> 
              
            },
            sortType: customStringSortMemo,
        },
        {
            id:"dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            className:"w-[10%]",
           
            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
                to={reMatch!=null?routeNames.reCompanyInvestmentDealById(cell.value.id,cell?.value?.dealId)
                    :""}>
                    {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType:dealDateSort
        },
        {
            Header: "Deal Amount (US$ M)",
            accessor: "dealAmountInUS",
            className:"pr-10 text-right w-[10%]",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
                        // headerspanClassName:"ml-auto"
        },
        { 
            Header: "Investors",
            accessor: "investors",
            className:"w-[50%]",
            Cell: (cell) => {
                 return <OverflowToolTip component={
                 cell.value.map((inv, index) => (<Link className='hover:text-[#BC8B20]' 
                 state={{cameFrom:location.pathname}} to={reMatch!=null?routeNames.reInvestorById(inv.investor_id)
                :""}>
                    {`${inv.investor_name}${index === cell.value.length - 1 ? "" : ", "}`}
                </Link>))
                 } />
            },
            sortType:investorSort
        },
       
    ], []);

    const rowData = useMemo(() => {
        const rows = [];
        {props.companyInvestment?.length>0 &&
            props.companyInvestment?.forEach((company, i) => {

            rows.push({
                companyName: { name: company?.company_name, dealId: company?.deal_id, id: company?.company_id ,logo_path:company?.logo_path},
                dealDate: {date:company?.deal_date, dealId: company?.deal_id, id: company?.company_id},
                dealAmountInUS: company?.deal_amount,
                investors:company?.investors,
          
            })
        });
    }
        setshowXlDownload(rows.length!==0)
        return rows;
    }, [props.companyInvestment])
    const initialSort = useMemo(() => [
        {
            id:"dealDate",
            desc: true
        }
    ], []);
    const { pathname, state } = useLocation();

    
    return (
        <>
            <div className="px-4 bg-[#EDEDED]">
                <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                        <div className="flex justify-between mb-2">
                            <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">To Companies - Investments</p>
                            {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload)&&<ExcelDownloader
                            url={reExportExcel}
                            fileName={Constants.exportNames.company_investments(props.advisorName)}
                            data={{
                                // "type":primaryMasterId,
                                "advisor_type":"T",
                                "section": "company_investments",
                                "advisor_id": advisorProfileId
                             }} />}
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div className="bg-white pb-2 rounded-[6px]">
                            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
                        </div>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default AdvisorCompaniesInvestments