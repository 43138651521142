import React, { useState, useContext } from "react"
import { NavLink } from 'react-router-dom';
import MonthPickerComponent from '../../../Utils/MonthPickerComponent'
import moment from 'moment';
import line from "../../../images/line.png"
import xls from "../../../images/xls/png/Group 47.png"
import FileIcon from "../../../images/file.png"
import FileIconBlur from "../../../images/fileblur.png"
import '../../../Components/MainMenu.css'
import { routeNames } from "../../../routeSegments";

import SavedFilterContent from "../../../Components/SavedFilter";
import SavedFilterICON from "../../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../../Components/ExportPopup";
import { investorProfileExportData, investorbackedIPOExportData, investorAngelInvestmentExportData, investorexitExportData, investorincubationExportData } from "../../../Components/ExportPopup/ExportData"
import { exportInvestorExcel, newInvestorExportUrl } from "../../../Config/config";
import Constants from "../../../Constants";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import PdfDownloader from "../../../Components/shared/PdfDownloader";
import NewInvestorDownloader from "../../../Components/shared/NewInvestorDownloader";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import useModule from "../../../hooks/useModule";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { UserContext } from "../../../Contexts/UserContext";


/**
 * The TabItem component is a functional component that renders a navigation link with a title and a
 * destination, and handles the active tab state and current page state.
 * @returns The TabItem component is returning a JSX element.
 */
const TabItem = ({ title, to }) => {
    const { activeTabDeal, setActiveTabDeal, setCurrentPage } = useContext(TableDataContext);

    return <div className="py-4">
        <NavLink
            className={({ isActive }) => {
                return `whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt] ${isActive ? "font-sans_semi_bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]" : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100"}`
            }}
            to={to}>
            <div className="group inline-block">
                <button className="outline-none w-30 focus:outline-none py-1 bg-white rounded-sm flex items-center "
                >
                    <span onClick={() => setCurrentPage(1)} className=" flex-1 lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[14pt]">
                        {title}
                    </span>

                </button>

            </div>
        </NavLink>
    </div>
}

/**
 * The above function is a React component that renders a navigation link with sub-tabs based on
 * certain conditions and user input.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `ExistsSubTabs` component. These properties can be accessed using dot notation, such as `props.menu`
 * or `props.MainMenu`.
 * @returns The component is returning a JSX element.
 */
const ExistsSubTabs =(props)=>{
    const {isPE, isVC} = useModule()
    const { setCurrentPage } = useContext(TableDataContext);

    return (
        <div className="py-4 pl-2 lg:pl-0" id="link2">
            <NavLink className={" whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[17px]" + (props.menu == "exits" || props.menu == "exits_ma" || props.menu == "exits_pm" ? "text-[#333333] font-bold  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]" : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")}
                to={props?.MainMenu == "active-investor" && isPE ?(
                        props.menu == "exits_ma" ? routeNames.pe_active_investors_exits_ma :
                        props.menu == "exits" ? routeNames.pe_active_investors_exits :
                        props.menu == "exits_pm" ? routeNames.pe_active_investors_exits_pm:routeNames.pe_active_investors_exits )
                    :
                    props?.MainMenu == "active-investor" && isVC ?(
                        props.menu == "exits_ma" ? routeNames.vc_active_investors_exits_ma :
                        props.menu == "exits" ? routeNames.vc_active_investors_exits :
                        props.menu == "exits_pm" ? routeNames.vc_active_investors_exits_pm:routeNames.vc_active_investors_exits )
                    :
                    props?.MainMenu == "new-investor" && isPE ?(
                        props.menu == "exits_ma" ? routeNames.pe_new_investors_exits_ma :
                        props.menu == "exits" ? routeNames.pe_new_investors_exits :
                        props.menu == "exits_pm" ? routeNames.pe_new_investors_exits_pm:routeNames.pe_new_investors_exits )
                    :
                    props?.MainMenu == "new-investor" && isVC ?(
                        props.menu == "exits_ma" ? routeNames.vc_new_investors_exits_ma :
                        props.menu == "exits" ? routeNames.vc_new_investors_exits :
                        props.menu == "exits_pm" ? routeNames.vc_new_investors_exits_pm:routeNames.vc_new_investors_exits )
                    :''
                    }
                    data-toggle="tab"
                    role="tablist">
                <div className="group inline-block">
                    <button onClick={()=>setCurrentPage(1)} className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center ">
                        <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                        {isPE ?
                            (props.menu == "exits_ma" ? "via M&A (PE-VC)" 
                                : props.menu == "exits_pm" ? "via Public Market (PE-VC)" 
                                    : props.menu == "exits" ? "PE-VC Exits" :"PE-VC Exits"
                            )
                            :
                            (props.menu == "exits_ma" ? "M&A (VC)" 
                                :props.menu == "exits_pm" ? "Public Market (VC)" 
                                    :props.menu == "exits" ? "VC Exits" :"VC Exits"
                            )
                        }

                        </span>
                        <span>
                            <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                />
                            </svg>
                        </span>
                    </button>
                    <ul className="mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10  absolute transition duration-150 ease-in-out origin-top w-[9rem] md:min-w-[13rem]">
                    {
                    props.menu == "exits_ma" ?
                    <ul className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32">
                        <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits:routeNames.vc_active_investors_exits )
                                : (isPE  ? 
                                routeNames.pe_new_investors_exits:routeNames.vc_new_investors_exits )
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px]
                                    text-[12pt] leading-[15pt]">{isPE ? 'PE-VC Exits':'VC Exits'}
                                </span>
                            </NavLink>
                        </li>

                        <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits_pm:routeNames.vc_active_investors_exits_pm )
                                : (isPE  ? 
                                routeNames.pe_new_investors_exits_pm:routeNames.vc_new_investors_exits_pm )
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px]
                                    lg:leading-[15px] text-[12pt] leading-[15pt]">{isPE ? 'via Public Market (PE-VC)' :'via Public Market (VC)'}</span>
                                <span className="mr-auto">
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                    :
                    props.menu == "exits_pm" ?
                    <ul className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem]  transition duration-150 ease-in-out origin-top-left min-w-32">
                        <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits:routeNames.vc_active_investors_exits )
                                : (isPE  ? 
                                routeNames.pe_new_investors_exits:routeNames.vc_new_investors_exits )
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px]
                                    text-[12pt] leading-[15pt]">{isPE ? 'PE-VC Exits':'VC Exits'}
                                </span>
                            </NavLink>
                        </li>
                        <li className="rounded-sm relative px-6 py-2 font-sans_book_body  hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits_ma:routeNames.vc_active_investors_exits_ma )
                                : (isPE ?
                                routeNames.pe_new_investors_exits_ma:routeNames.vc_new_investors_exits_ma )
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px]
                                    lg:leading-[15px] text-[12pt] leading-[15pt]">{isPE ? 'via M&A (PE-VC)':'via M&A (VC)'}</span>
                                <span className="mr-auto">
                                </span>
                            </NavLink>

                        </li>
                    </ul>
                    :                                                       
                    <ul className=" bg-white  py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0  right-[3%] md:right-[2rem] transition duration-150 ease-in-out origin-top-left min-w-32">
                        <li className="rounded-sm relative px-6 py-2 font-sans_book_body  hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits_ma:routeNames.vc_active_investors_exits_ma)
                                : (isPE  ? 
                                routeNames.pe_new_investors_exits_ma:routeNames.vc_new_investors_exits_ma)
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px]
                                    lg:leading-[15px] text-[12pt] leading-[15pt]">{isPE ? 'via M&A (PE-VC)':'via M&A (VC)'}</span>
                                <span className="mr-auto">

                                </span>
                            </NavLink>

                        </li>
                        <li className="rounded-sm relative font-sans_book_body px-6 py-2 hover:bg-gray-100 ">
                            <NavLink to={props?.MainMenu == "active-investor" ? (isPE ?
                                routeNames.pe_active_investors_exits_pm:routeNames.vc_active_investors_exits_pm)
                                : (isPE ? 
                                routeNames.pe_new_investors_exits_pm:routeNames.vc_new_investors_exits_pm )
                                }
                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px]
                                    lg:leading-[15px] text-[12pt] leading-[15pt]">{isPE ? 'via Public Market (PE-VC)':'via Public Market (VC)'}</span>
                                <span className="mr-auto">

                                </span>
                            </NavLink>

                        </li>
                    </ul>
                    }
                    </ul>
                </div>
            </NavLink>
        </div>
    )
}

const initialDate = moment().subtract(1,"month")

/**
 * The MainMenu function is a React component that renders a menu with different tabs based on the
 * props passed to it.
 * @param props - The `props` parameter is an object that contains the following properties:
 * @returns The function `MainMenu` returns a JSX element, which represents the main menu component of
 * a web application.
 */
function MainMenu(props) {

    const { primaryTypeMasterId, menu, submenu } = props;
    const { isPE } = useModule();


    const [showPopup, setShowPopup] = useState(false);
    // const [save, setSave] = useState(false)
    const {save, setSave} = useContext(PeFilterContext);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
    ]);

    const savedList = () => {
        setSave(!save)
    }
    const { isStudent} = useContext(UserContext)

    let investorTo = routeNames.pe_new_investors_investments;
    let exitTo = routeNames.pe_new_investors_exits;
    let angelTo = routeNames.pe_new_investors_angel;
    let incubationTo = routeNames.pe_new_investors_incubation;

    if (primaryTypeMasterId == Constants.primaryMasterIds.vcId) {
        if (menu == "active-investor") {
            investorTo = routeNames.vc_active_investors_investments;
            exitTo = routeNames.vc_active_investors_exits;
            angelTo = routeNames.vc_active_investors_angel;
            incubationTo = routeNames.vc_active_investors_incubation;
        } else {
            investorTo = routeNames.vc_new_investors_investments;
            exitTo = routeNames.vc_new_investors_exits;
            angelTo = routeNames.vc_new_investors_angel;
            incubationTo = routeNames.vc_new_investors_incubation;
        }
    } else {
        if (menu == "active-investor") {
            investorTo = routeNames.pe_active_investors_investments;
            exitTo = routeNames.pe_active_investors_exits;
            angelTo = routeNames.pe_active_investors_angel;
            incubationTo = routeNames.pe_active_investors_incubation;
        }
    }




    return (
        <div className="pl-3 pr-4 ">
            <div className="scrollbar rounded shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between ">

                    <div className="flex flex-row px-2 overflow-x-auto space-x-5 scrollbar-remove">

                        <TabItem
                            title={primaryTypeMasterId == Constants.primaryMasterIds.peId
                                ? "PE-VC Investments"
                                : "VC Investments"}
                            to={investorTo}
                        />
                        {/* <TabItem
                            title={primaryTypeMasterId == Constants.primaryMasterIds.peId
                                ? "PE-VC Exits"
                                : "VC Exits"}
                            to={exitTo}
                        /> */}
                        <ExistsSubTabs menu={submenu} MainMenu={menu}/>
                        <TabItem
                            title="Angel Investments"
                            to={angelTo}
                        />
                        <TabItem
                            title="Incubation"
                            to={incubationTo}
                        />
                    </div>

                    <div className="flex justify-between py-2 lg:py-0 px-5 lg:px-2 items-center">
                        <label htmlFor="" className="text-[#666666]  mr-4">
                            <div className="flex">
                                <MonthPickerComponent  date={menu == "active-investor" ? null : [initialDate.startOf("month").format("YYYY-MM-DD"),initialDate.endOf("month").format("YYYY-MM-DD")]}/>
                            </div>
                        </label>
                        <div className="flex">{isStudent == 'true' ? <></> :
                            <span className="text-center">
                                {/* <button onClick={download}> */}

                                <NewInvestorDownloader
                                    category={menu == "new-investor" ? "new" : "most_active"}
                                    section={submenu} type={props.primaryTypeMasterId}
                                    menuItems={[
                                        {
                                            key: 'profile_only',
                                            label: "Profile only",
                                            fileName: isPE ? "PE-Investors" : "VC-Investors",
                                        },
                                        {
                                            key: 'profile_with_inv',
                                            label: "Profile with inv.",
                                            fileName: isPE ? "PE-Investors" : "VC-Investors",
                                        },
                                        {
                                            key: 'table_only',
                                            label: 'Table only',
                                            fileName: isPE ? "PE-Investors" : "VC-Investors",
                                        },
                                    ]} />
                            </span>}
                            {/* <span>
                                <img alt="" className="h-[24px] w-[2px] mt-1 " src={line} />
                            </span>
                            <SavedFilterICON
                                save={save}
                                FileIcon={FileIcon}
                                FileIconBlur={FileIconBlur}
                                savedList={savedList} /> */}

                        </div>

                    </div>
                </div>
                {save ? <SavedFilterContent primaryTypeMasterId={props.primaryTypeMasterId}
                    subTypeMasterId={props.subTypeMasterId}
                    typeId={props.typeId}
                    show={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu
