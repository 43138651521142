import React, { useState, useContext, useEffect } from "react";
import MA_MainMenu from './MA_MainMenu'
import Card from '../../Components/MA/ListCard/Card'
import FilterTag from "../../Components/Tag";
import Table from '../../Components/MA/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import MALayout from '../../Components/M&ALayout'
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import moment from "moment";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import Constants from '../../Constants'
import ExcelPopup from "../../Components/ExcelPopup";
import SavedFilterContent from "../../Components/SavedFilter";
import { TableDataContext } from '../../Contexts/TableDataContext'
import { maCompanyListExcelExport } from "../../Config/config";
import PdfDownloader from "../../Components/shared/PdfDownloader";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import MaCompaniesRefineSearch from "../../Components/RefineSearch/MaCompaniesRefineSearch";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";

/**
 * The MAHome function is a React component that renders a page with a top navigation bar, a filter
 * section, and a table section.
 * @param props - The `props` parameter is an object that contains any properties passed to the
 * `MAHome` component from its parent component. These properties can be accessed using dot notation,
 * such as `props.active` or `props.someProperty`.
 * @returns The MAHome component is being returned.
 */
const MAHome = (props) => {
    const [showExcelPopup, setshowExcelPopup] = useState()
    const location = useLocation();
    const [openTab, setOpenTab] = React.useState(1);
    const { monthPickerValue ,save, setSave,showMonthPicker, refineSearchFilters ,groupId} = useContext(PeFilterContext);
    
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
    
    // let initialMonthRange = null;
    // if (loadPreviousStateRef.current === true) {
    //     initialMonthRange = monthPickerValue;
    //     loadPreviousStateRef.current = false; 
    // } else {
    //     initialMonthRange = Constants.initialMonthRange;
    // }
    // const [date, setDate] = useState(initialMonthRange); 
    // const [save, setSave] = useState(false);
    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    const { totalNoOfCompanies, setTotalNoOfCompanies, tableCompanyId, allDealIds } = useContext(TableDataContext);
    const { pathname } = useLocation(); 
    console.log(tableCompanyId, 'tableCompanyId');
    const {isStudent} = useContext(UserContext)

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

 
    return (
        <div>
            <MALayout menu="ma">
                <div className="flex min-h-screen h-screen flex-col">
                    {/* Top nav*/}
                    {isFromGlobalSearch() ? <></> :
                    <Card />
    }
                    {/* Bottom section */}
                    <div className={isFromGlobalSearch() ? "flex min-h-0 mt-[4rem] flex-1 overflow-hidden": "flex min-h-0 flex-1 overflow-hidden"}>
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
                            {/* Primary column */}
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">

                                {/* Your content */}
                                <FilterTag />
                                <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">                                   
                                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-end  ">
                                        {/* <MA_MainMenu active={1} menu="ma" primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                                            typeId={Constants.moduleIds.companiesId}/> */}
                                        
                                        <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                            {isFromGlobalSearch() ? <></> : 
                                            <label htmlFor="" className="text-[#666666] ">
                                                <div className={showMonthPicker!=0 ?"":'flex'}>
                                                    <MonthPickerComponent  />
                                                </div>
                                            </label>
}
                                            <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                                            {isStudent == 'true' || isFromGlobalSearch() || groupId?.myList?.length >0? <></> : <>                                                                          
                                                    <PdfDownloader
                                                        url={maCompanyListExcelExport}
                                                        type="xlsx"                                                            
                                                        fileName={Constants.exportNames.ma_list}

                                                        data={{
                                                            "date_range": {
                                                                "from_date": monthPickerValue[0],
                                                                "to_date": monthPickerValue[1]
                                                            },
                                                            "company_id": tableCompanyId,
                                                            "deal_ids":allDealIds

                                                        }}
                                                    >
                                                        <img src={xls} className="h-[24px]  w-[21px]" alt="" />
                                                    </PdfDownloader>
                                                
                                                    {groupId?.myList?.length >0 ?<></>:<a>
                                                    <img className="h-[24px] w-[2px] mt-1 " src={line} />
                                                </a>}
                                            </>}
                                            {isFromGlobalSearch() || groupId?.myList?.length >0 ? <></> : 
                                                <a className="group cursor-pointer">
                                                    <div className="cursor-pointer" onClick={savedList}>
                                                        <img src={save ? FileIcon : FileIconBlur}
                                                            className="h-[24px]  w-[21px]"
                                                            alt="" />
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                      {save ? <SavedFilterContent
                                        primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.ma_companies_id}
                                        typeId={Constants.moduleIds.companiesId}
                                        show={save} /> : ''}
                                        
                                        
                                </div>
                                <div className='overflow-y-auto'>
                                    <Table menu="ma" sub_type_master_id={Constants.subCategoryIds.ma_companies_id}/>
                                </div>
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                        <MaCompaniesRefineSearch customPathname={location.pathname} />
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>

                </div>
            </MALayout>
        </div>
    )
}

export default MAHome
