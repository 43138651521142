import { React, useState, useMemo, useContext, useEffect } from 'react'
import {  useParams  } from 'react-router-dom';
import CapTableDataTable from '../DataTables/CapTableDataTable';
import {  getCaptableUrl } from '../../Config/config';
import axios from 'axios';
import Constants from '../../Constants';
import { UserContext } from '../../Contexts/UserContext';
import LockIcon from 'mdi-react/LockIcon';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import useModule from '../../hooks/useModule';
import moment from "moment";
import { DataFoundContext } from "../../Contexts/DataFoundContext";


const CapTable = () => {
  const [captable, setCapTable] = useState();
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { companyId } = useParams();
  const { getToken, isDirectoryUser } = useContext(UserContext);
  let companyFundingId = parseInt(companyId)
  const [alert, setalert] = useState({ message: "", show: false });
  const { primaryMasterId } = useModule();
  const [capTableDate, setCapTableDate] = useState()
  const [capTableNote, setCapTableNote] = useState()
  const { capTableDataFound, setCapTableDataFound } = useContext(DataFoundContext);
  // const handleFetchCapTableRequestCallback = (response, requestSuccess) => {
  //   try {
  //     setCapTable(response);
  //     console.log("captable1", response);
  //   } catch (e) {
  //     console.log('Error', e)
  //   }
  // };

  // const { data, loading, error } = useQuery(CAP_TABLE, {
  //   variables: { PECompanyId: companyFundingId },
  //   onCompleted: (response) => handleFetchCapTableRequestCallback(response, true),
  //   onError: (response) => handleFetchCapTableRequestCallback(response),
  //   refetch: CAP_TABLE

  // })
  // console.log("captable12", CAP_TABLE)

  // const CapTableData = useMemo(() => captable, [captable]);
  // console.log("captablecaptable", captable);
  // alert(FundingData)

  let blurColumns = false;

  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

  if(isDirectoryUser){
    blurColumns = true;
  }
  else if (allowedModulesList.includes("pe") || allowedModulesList.includes("vc")) {
    blurColumns = false;
  } else {
    blurColumns = true;
  }

  console.log((!isDirectoryUser), 'ccd');

 /**
  * The function `getCaptable` makes a POST request to a specified URL with certain data and headers,
  * and then sets the cap table data and related variables based on the response.
  */
  const getCaptable = () => {

    axios(`${getCaptableUrl}`, {
      method: "POST",
      data: {
        company_id: companyFundingId,
        type: primaryMasterId
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.success == true && response?.data?.data) {
          setCapTable(response.data?.data);
          setCapTableDate(response.data?.add_on);
		      setCapTableNote(response.data?.notes);
          setCapTableDataFound(true);
        }else if (response.data.message === 'No Data Found'){
          setCapTableDataFound(false)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
        setCapTableDataFound(false);
      });

  };

  useEffect(() => {
    getCaptable();
    return () => {
      setCapTableDataFound(false);
    }
  }, [companyFundingId])



  // const indexOfLastPost = currentPage * dataPerPage;
  // // alert(indexOfLastPost)
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // // alert(indexOfFirstPost)
  // const currentData = CapTableData?.company?.investments?.slice(indexOfFirstPost, indexOfLastPost);
  // // alert(currentData );
  // const pageNumbers = []

  // for (let i = 1; i <= Math.ceil(CapTableData?.company?.investments?.length / dataPerPage); i++) {
  //   pageNumbers.push(i);
  // }
  // const setPage = (pageNum) => {
  //   setCurrentPage(pageNum)

  // }
  // const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage, setDataPerPage] = useState(20);


  // const indexOfLastPost = currentPage * dataPerPage;
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // const currentData = NewsLetterData?.newsletter?.slice(indexOfFirstPost, indexOfLastPost);
  // // alert(currentData)
  // const pageNumbers = []

  // for (let i = 1; i <= Math.ceil(NewsLetterData?.newsletter?.length / dataPerPage); i++) {
  //   pageNumbers.push(i);
  // } 
  // const setPage = (pageNum) => {
  //   setCurrentPage(pageNum)

  // }
  // const customStringSortMemo = useMemo(() => {
  //   return (rowA, rowB, columnId, desc) => {
  //     const defaultVal = desc ? '--' : 'A';
  //     return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
  //   }
  // }, []);

 /* The above code is defining a function that generates an array of columns for a table. It uses the
 `useMemo` hook to memoize the columns array and only regenerate it when the `captable` variable
 changes. */
  const columns = useMemo(() => {
    let columns = [];

    // columns.push({
    //     id: 'expander',
    //     Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    //       <span {...getToggleAllRowsExpandedProps()}>
    //         {isAllRowsExpanded ? ' - ' : ' + '}
    //       </span>
    //     ),
    //     enableSorting: false,
    //     Cell: ({ row }) =>
    //         <span
    //           {...row.getToggleRowExpandedProps({
    //             style: {
    //               paddingLeft: `${row.depth * 2}rem`,
    //             },
    //           })}
    //         >
    //           {row.isExpanded ? ' - ' : ' + '}
    //         </span>
    //   },)
    
    // columns.push({
    //   id: 'shareHolder',
    //   Header: captable?.headers?.at(0) ?? "Shareholders",
    //   accessor: captable?.headers?.at(0) ?? "Shareholders",
    //   headerClasName: "w-[15%]",
    //   Cell: ({ value }) => {
    //     return value.name ? <span className="flex">
    //       {value.name}
    //       <span className="mx-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
    //         {value?.flag}
    //       </span>
    //     </span> : <span className="flex font-bold">{value}</span>
    //   },
    // })

    captable?.headers?.forEach((header, index) => {
      // investment?.CaptableModuleDetail?.map(ctm => {
      //   ctm?.captableModuleRoundDetail?.map(ctmd => {
      //     const isColumnExist = Array.from(columns).findIndex((col, i) => col.accessor == ctmd?.roundMap?.new_round) != -1;
      //     if (isColumnExist == false) {
      //       columns.push({
      //         Header: ctmd?.roundMap?.new_round,
      //         accessor: ctmd?.roundMap?.new_round,
      //         className:"pr-[2.5rem] w-[7%] text-right"
      //       })
      //     }
      //   })
      // })
      if (index != 0 && (captable?.headers?.length - 1) != index && (captable?.headers?.length - 2) != index && (captable?.headers?.length - 3) != index) {
        columns.push({
          Header: header,
          accessor: header,
          className: "pr-[2.5rem] w-[7%] text-right"
        })
      }
    });
    columns.push({
      Header: captable?.headers?.at(captable?.headers?.length - 3) ?? "Total No. of Shares Held",
      accessor: (data) => {
        return data[captable?.headers?.at(captable?.headers?.length - 3) ?? "Total No. of Shares Held"]
      },
      className: 'text-right pr-[2.5rem] w-[10%]'
    });
    columns.push({
      Header: captable?.headers?.at(captable?.headers?.length - 2) ?? "Stake Held",
      accessor: (data) => {
        return data[captable?.headers?.at(captable?.headers?.length - 2) ?? "Stake Held"]
      },
      className: 'text-right pr-[2.5rem] w-[10%]'
    });
    columns.push({
      Header: captable?.headers?.at(captable?.headers?.length - 1) ?? "Valuation as per the last transaction",
      accessor: captable?.headers?.at(captable?.headers?.length - 1) ?? "Valuation as per the last transaction",
      className: 'text-right pr-[2.5rem] w-[10%]'
    });
    // captable?.headers?.forEach(header => {
    //   columns.push({
    //     Header: header,
    //     accessor: header,
    //     className: `${header == "Total No. of Shares Held" || header == "Valuation as per the last transaction" ? "text-right" : ""} pr-[2.5rem] w-[12%]`,
    //     headerClasName: "w-[10%]",
    //   });
    // });

    return columns;
  },
    [captable]);


 /* The above code is a JavaScript function that uses the `useMemo` hook to memoize the result of the
 function. It takes an input `captable` and returns an array of `rows` based on the data in
 `captable`. */
  const rowData = useMemo(() => {
    let rows = [];
    // rows = captable?.captable ?? [];

    captable?.captable?.map(row => {
      row?.subRows?.map(subRow => {
        rows.push(subRow);
      })
    })
    // captable?.length > 0 &&( 
    // captable?.company?.investments?.forEach((investment) => {
    //   investment.CaptableModuleDetail?.forEach(cmd => {
    //     let row = {};
    //     row.shareHolder = {name:`${cmd.first_name} ${cmd?.last_name ?? ""}`,id:''};
    //     row.totalShares = cmd?.total_shares;
    //     row.valueationPerLastTransaction = cmd?.valuation_last_round;
    //     cmd.captableModuleRoundDetail?.forEach(cmdR => {
    //       row[`${cmdR.roundMap.new_round}`] = cmdR.value;
    //     })
    //     rowsData.push(row);
    //     console.log(rowsData,"rowsData12")
    //   })
    // }))

    // captable?.captable?.forEach(cTable=>{
    //   let row = {};
    //   Object.keys(cTable).forEach(key=>{
    //     row[key] = cTab
    //   })
    // })

    setshowXlDownload(rows.length != 0)

    return rows;
  }, [captable])

  // const initialSort = useMemo(() => [
  //   {
  //     id: "shareHolder",
  //     desc: false
  //   }
  // ], []);

 /**
  * The function `onConfirmation` sets the `show` property of an `alert` object to `false` and clears
  * the `message` property.
  * @param flag - The `flag` parameter is a boolean value that indicates whether a confirmation action
  * has been confirmed or not.
  */
  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })
  }

  /**
   * The function `onLockClick` displays a warning message to the user.
   * @param cell - The "cell" parameter is a variable that represents a specific cell or element that
   * is being clicked or interacted with.
   */
  const onLockClick = (cell) => {
    // console.log("cellcell", cell);
    // toast.warning("Please buy PE")
    setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

  return (
    <>{capTableDataFound ? 
    <div className="mx-4">
      <div className="bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="pt-4 px-5">
          <div className="flex justify-between mb-3">
            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] ">
              Cap Table{capTableDate && <span> (as on {moment(capTableDate).format('MMM YYYY')})</span>}
            </h3>

            {/* {isStudent == 'false'  && showXlDownload && <ExcelDownloader
              url={exportExcel}
              fileName={Constants.exportNames.captable(companyName)}
              data={{
                "type": primaryMasterId,
                "export_from": "detail",
                "module_type": "investments",
                "section": "cap_table",
                "company_id": [companyId]
              }} />} */}
          </div>
        </div>
        {
          blurColumns
            ? <div className='w-full h-40 relative'>
              <div className='absolute w-full h-full blur-sm bg-[#DCDCDC]'></div>
              <div className='absolute w-full h-full flex items-center justify-center'>
                <LockIcon className='cursor-pointer' size={26} onClick={onLockClick} color='#696969'/>
              </div>
            </div>
            : <CapTableDataTable
              columns={columns}
              data={rowData}
              // sortBy={initialSort}
              />
            }
        {capTableNote &&
            <div class="px-5 py-4 text-left pl-[2rem] text-xs font-medium"><span>Note: {capTableNote}</span></div>
        }

        {/* {currentData?.length > 20 ?
          <div className="w-full flex items-center py-3 justify-center space-x-6  ">
          {pageNumbers.map((pageNum, index) => (
            <span key={index} className={pageNum == currentPage ?
              "cursor-pointer flex items-center justify-center py-1  px-3 border-1  rounded-full bg-[#BC8B20] text-white"
              : "cursor-pointer flex items-center justify-center  rounded-full"}
              onClick={() => { setPage(pageNum) }}>
                {pageNum}
              </span>
            ))
            }
          </div>
          : ''
          
        } */}
      </div>
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
        :''}</>
  );
};

export default CapTable;
