import React from 'react'
import { useLocation } from 'react-router-dom'
// import Card from '../../Components/Investor/InvestorPageCard/Card'
import Layout from '../../Components/InvestorLayout'
import Table from '../../Components/Investor/InvestorFunds/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import MainMenu from '../../Components/Investor/InvestorFunds/FundsMainmenu' 
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
// import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
import FundsBreadCrum from './FundsBreadCrum'
import FilterTag from '../../Components/Investor/Tag'
import Constants from '../../Constants'
import InvestorFundRefineSearch from '../../Components/RefineSearch/InvestorFundRefineSearch';
/**
 * The `InvestorFunds` function renders a page that displays a table of investor funds, with a sidebar
 * for refining the search and a main area for displaying the funds.
 * @returns The `InvestorFunds` component is returning a JSX structure that represents the layout and
 * content of a page. It includes a header, a main section with a primary column and a secondary
 * column, and a footer. The primary column contains various components such as a filter tag,
 * breadcrumbs, a main menu, and a table. The secondary column contains a refine search component.
 */
const InvestorFunds = () => {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    return (
        <div>
            <>
                <Layout>
                    <div className="flex h-screen flex-col">

                        {/* <TopSection /> */}

                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            {/* Narrow sidebar*/}
                            {/* Main area */}
                            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex  sm:full mt-[4.25rem] md:mt-[3rem]">
                                {/* Primary column */}
                                <section
                                    className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                                >
                                    {/* Your content */}
                                    <FilterTag  page="pefund"/>
                                    <FundsBreadCrum subMenu="vc" />
                                    <MainMenu menu="investor" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_funds_id}
                                        typeId={Constants.moduleIds.investorsId} />
                                    <div className="overflow-y-auto">
                                        <Table menu="inv_l_partner" subMenu="fund" sub_type_master_id={Constants.subCategoryIds.pe_funds_id}/>
                                    </div>
                                </section>
                                {/* Secondary column (hidden on smaller screens) */}
                                <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                    <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                        {/* Your content */}

                                        <RefineSearch>
                                            <InvestorFundRefineSearch customPathname={location.pathname}/>
                                        </RefineSearch>
                                    </div>
                                </aside>
                            </main>
                        </div>                        
                    </div>
                </Layout>
            </>
        </div>
    )
}

export default InvestorFunds