import React from 'react'
import Card from '../../Components/CFS/DetailsPage/Card'
import CAGRcalculationCard from '../../Components/CFS/DetailsPage/CAGRcalculationCard'
import CompanyDetail from '../../Components/CFS/DetailsPage/CompanyDetails'
import RefineSearch from '../../Components/RefineSearchComponent'
import Layout from '../../Components/CfsLayout'
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import { COMPANY_PROFILE, DEATAILPAGE_CARD } from '../../Queries/Pe'
import CurrencyContextProvider from '../../Contexts/CurrencyContext';
import CFSRefineSearch from '../../Components/RefineSearch/CFSRefineSearch'
import { routeNames } from '../../routeSegments'

/**
 * The DetailPage function returns a JSX component that renders a layout with a primary and secondary
 * column, along with some additional components.
 * @returns The DetailPage component is returning a JSX structure that includes a
 * CurrencyContextProvider component, a Layout component, and several nested divs and sections. The
 * main content is divided into two columns, with the primary column containing a CompanyDetail
 * component and the secondary column containing a RefineSearch component.
 */
function DetailPage() {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <CurrencyContextProvider>
            <div>
                <Layout>
                    <div className="flex min-h-screen h-screen flex-col">
                        {/* <Card /> */}
                        <CAGRcalculationCard />
                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            {/* Primary column */}
                            <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">

                                <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">

                                    <CompanyDetail menu="cfs" COMPANY_PROFILE={COMPANY_PROFILE} />

                                    {/* <Outlet /> */}
                                    {/* Your content */}
                                </section>
                                {/* Secondary column (hidden on smaller screens) */}
                                <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                    <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                        {/* Your content */}
                                        <RefineSearch>
                                            <CFSRefineSearch customPathname={routeNames.cfs} />
                                        </RefineSearch>
                                    </div>
                                </aside>
                            </main>
                        </div>
                        <br />

                    </div>
                </Layout>
            </div>
        </CurrencyContextProvider>
    )
}

export default DetailPage
