import React, { useContext, useState } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import FirmTypeMultiSelect from '../RefineSearchComponents/FirmTypeMultiSelect';
import SelectIndustryFilter from '../RefineSearchComponents/SelectIndustryFilter';
import StageFilter from '../RefineSearchComponents/StageFilter';

/**
 * The `ActiveInvestorRefineSearch` function is a React component that renders a refine search form
 * with options for industries, stages, and firm types, and allows the user to apply or reset the
 * selected filters.
 * @returns The function `ActiveInvestorRefineSearch` returns a JSX element, which represents the UI of
 * the refine search component.
 */
function ActiveInvestorRefineSearch({ customPathname }) {
    const { setrefineSearchFilters } = useContext(PeFilterContext);
    const { setSelectedPage } = useContext(TableHeaderContext);
    const [select, setSelected] = useState(0);

    const [IndustryOptions, setIndustryOptions] = useState([])
    const [StageOptions, setStageOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])


  /**
   * The function `applyFilter` updates the refine search filters by selecting the checked options for
   * industries, stages, and firm types.
   */
    const applyFilter = () => {
        const selectedIndustries = IndustryOptions.filter(industry => industry.isChecked);
        const selectedStages = StageOptions.filter(option => option.isChecked);
        const selectedFirms = FirmTypeOptions?.filter(option => option.isChecked);

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                stage: selectedStages,
                firmType: selectedFirms,
            },
        }))
    }

  /**
   * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by setting
   * its value to an empty object.
   */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
   /**
    * The function `refineSearchFilter` is used to apply a filter and reset the selected page to 1.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. It contains information about the event, such as the type of event and any data
    * associated with it. In this case, the function is using the event.preventDefault() method to
    * prevent the default behavior of the event, which is typically
    */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setSelectedPage(1);
        applyFilter();
        setSelected(false)

    }

 /**
  * The `onResetClick` function resets the selected page to 1 and calls the `resetFilter` function.
  * @param event - The event parameter is an object that represents the event that triggered the
  * function. In this case, it is the click event that occurred when the user clicked on a reset
  * button.
  */
    const onResetClick = (event) => {
        event.preventDefault();
        setSelectedPage(1);
        resetFilter()
    }

  /**
   * The function `accordionClick` sets the selected value to 0 if it is equal to `select`, otherwise
   * it sets it to the provided value.
   * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It is
   * used to determine whether the accordion should be selected or deselected.
   */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };
    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                {/* <div className="flex justify-between px-3 items-center pt-0">
                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>
                    <Switch />
                </div>
                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                    <div className="sticky top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                        <label className=" flex  rounded ">
                            <Tags />
                        </label>
                    </div>
                </div> */}
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <SelectIndustryFilter
                                IndustryOptions={IndustryOptions}
                                setIndustryOptions={setIndustryOptions}
                                accordionClick={() => accordionClick(1)}
                                customPathname={customPathname}
                                open={select === 1} />
                            <StageFilter
                                StageOptions={StageOptions}
                                customPathname={customPathname}
                                setStageOptions={setStageOptions}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3} />
                            <FirmTypeMultiSelect
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                dataPath="investments"
                                accordionClick={() => accordionClick(16)}
                                open={select === 16} />

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default ActiveInvestorRefineSearch