import React,{useState} from 'react'
import { logoUrl } from '../Config/config'
import RandomLogo from './RandomLogo'

/**
 * The CompanyLogo component displays a company logo based on the given props, and handles errors if
 * the logo image fails to load.
 */
function CompanyLogo(props) {

    const [IsError, setIsError] = useState(false)

    if(IsError){
        return <RandomLogo name={props.name} page={props.page}/>
    }
     
    return (
        <>

        {
        props.page == "detail page" ?
                <div className="h-[4rem] w-[4rem] p-[0.2rem] rounded-md bg-white flex items-center justify-center">  
                <img alt='' onError={(e)=>setIsError(true)} className="max-w-full max-h-full"
                 src={props?.logo=="default.png"?logoUrl+"/"+props?.logo:logoUrl+props?.logo}  
                 />
                </div>
        :
        // props.page == "compare page" ?
            
        //     <div className="h-[2rem] w-[2rem] rounded-md bg-white border-[1px] border-slate-400">  
        //     <img className="mr-2 w-[1.5rem] h-[18px] mx-[0.2rem] mt-2 cursor-pointer" src={logoUrl+props?.logo} />
        //     </div>
        // :
        props.page == "company page" ?
        <div  style={{ display:"-webkit-inline-box", float:"left"}}>  
            <img alt='' onError={(e)=>setIsError(true)} title={props?.name} className="border-solid border-2 rounded-full mr-2 w-[32px] h-[32px] cursor-pointer" src={props?.logo=="default.png"?logoUrl+"/"+props?.logo:logoUrl+props?.logo}  />
        </div>
        :
        props.page == "coInvestor" ?
        <div  style={{ display:"-webkit-inline-box", float:"left"}}>  
            <img alt='' onError={(e)=>setIsError(true)} title={props?.name} className="border-solid border-2 rounded-full mr-2 w-[32px] h-[32px] cursor-pointer" src={props?.logo}  />
        </div>
        :
        <div  style={{ display:"-webkit-inline-box", float:"left"}}>  
            <img alt='' onError={(e)=>setIsError(true)} className="border-solid border-2 p-[1px] rounded-full mr-2 w-[32px] h-[32px] cursor-pointer" src={require('.././company-logo/companylogo.png')}  />
        </div>
        }
        </> 
    )
}

export default CompanyLogo