import React, { useEffect, useContext } from 'react'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Constants from '../../Constants'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { BiRightArrowAlt } from 'react-icons/bi'
import { Popover, Tooltip } from 'antd'
import { PeFilterContext } from '../../Contexts/PeFilterContext'


const getYears = (startDate, endDate) => {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, 'year')
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'year'))
    }
    return range
}

const monthList = moment.monthsShort().map(month => ({ id: month, name: month, isChecked: false }))
const yearsList = getYears(moment().subtract(25, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }));

const isFutureDate = (date) => {
    return date?.isAfter(moment(), 'month') ?? true
}

const MonthRangePlaceholder = ({ fromDate, toDate }) => {
    const formattedFromDate = moment(fromDate).format('MMM YY')
    const formattedToDate = moment(toDate).format('MMM YY')
    return <>
        <div className='text-[13px] whitespace-nowrap'>{`${formattedFromDate} - ${formattedToDate}`}</div>
        <div className='ml-[2px]'><FaRegCalendarAlt size={20} color='#d2d2d2' /></div>
    </>
}


/**
 * The `MonthRangePicker` function is a React component that allows users to select a range of months
 * and years.
 * @returns a JSX element.
 */
function MonthRangePicker({ value = Constants.initialMonthRange, onChange }) {

    const [open, setOpen] = useState(false)

    const [FromDate, setFromDate] = useState(moment())
    const [ToDate, setToDate] = useState(moment())
    const {setShowMontnPicker } = useContext(PeFilterContext);

    useEffect(() => {
        setFromDate(moment(value.at(0)))
        setToDate(moment(value.at(1)))
    }, [value, open])

  /**
   * The `handleMonth` function updates the `FromDate` or `ToDate` based on the selected month, and
   * performs validation checks.
   * @param e - The parameter `e` is an event object that is passed to the `handleMonth` function. It
   * represents the event that triggered the function, such as a change event on an input element.
   * @returns The function does not have a return statement, so it does not explicitly return anything.
   */
    const handleMonth = (e) => {
        const { name, value } = e.target;

        const selectedMonth = moment().month(value).format('M')

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('month', parseInt(selectedMonth) - 1)

            if (isFutureDate(temp)) {
                toast.warning('From date cannot be greater than current date')
                return
            }

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }
            setFromDate(temp)
        } else {
            const temp = moment(ToDate);
            temp.set('month', parseInt(selectedMonth) - 1)
            if (isFutureDate(temp)) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }
            setToDate(temp)
        }
    }

   /**
    * The `handleYear` function updates the year value of either the `FromDate` or `ToDate` based on
    * the input name and value, and performs some validation checks.
    * @param e - The parameter `e` is an event object that is passed to the `handleYear` function. It
    * is typically triggered by an event, such as a change in an input field.
    * @returns The function does not have a return statement, so it does not explicitly return
    * anything.
    */
    const handleYear = (e) => {
        const { name, value } = e.target;

        if (name === "from") {
            const temp = moment(FromDate);
            temp.set('year', parseInt(value))

            if (isFutureDate(temp)) {
                toast.warning('From date cannot be greater than current date')
                return
            }

            if (temp.isAfter(ToDate)) {
                toast.warning('From date cannot be greater than to date')
                return;
            }

            setFromDate(temp)
        } else {
            const temp = moment(ToDate);
            temp.set('year', parseInt(value))
            if (isFutureDate(temp)) {
                toast.warning('To date cannot be greater than current date')
                return
            }
            if (temp.isBefore(FromDate)) {
                toast.warning('To date cannot be less than from date')
                return;
            }
            setToDate(temp)
        }
    }

    /**
     * The onSubmit function checks if the onChange function is not null, and if so, it calls the
     * onChange function with an array of formatted dates, closes a modal, and resets a month picker.
     */
    const onSubmit = () => {
        if (onChange != null) {
            onChange([FromDate.format('YYYY-MM-DD'), ToDate.format('YYYY-MM-DD')])
            setOpen(false)
            setShowMontnPicker(0)
        }
    }

useEffect(()=>{
    setShowMontnPicker(0)
},[])
    return (
        <>
            {open
                ? null
                : <div onClick={(e) =>{setOpen(true);setShowMontnPicker(1)}} className='cursor-pointer py-2 px-1 select-none rounded-[5px] flex items-center justify-evenly border border-[#d2d2d2] w-[130px]'>
                    <MonthRangePlaceholder fromDate={value.at(0)} toDate={value.at(1)} />
                </div>}
            {!open
                ? null
                : <div className='flex items-center border border-[#d2d2d2] shadow-md rounded-md'>
                    <div className='flex flex-grow items-center py-2 pl-2 pr-1 justify-evenly'>
                        <select value={FromDate?.format('MMM')} name="from" id="" onChange={handleMonth}
                            className=" border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]">
                            {
                                monthList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                        <select value={FromDate?.year()} name="from" id="" onChange={handleYear}
                            className=" border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]">
                            {
                                yearsList?.slice(0).reverse().map(year => (
                                    <option key={year.id} value={year.id}>{year.name}</option>
                                ))
                            }
                        </select>
                        <p className="text-[12px] mx-2">To</p>
                        <select value={ToDate?.format('MMM')} name="to" id="" onChange={handleMonth}
                            className=" border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]">
                            {
                                monthList?.map(month => (
                                    <option key={month.id} value={month.id}>{month.name}</option>
                                ))
                            }
                        </select>
                        <select value={ToDate?.year()} name="to" id="to" onChange={handleYear}
                            className=" border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]">
                            {
                                yearsList?.slice(0).reverse().map(year => (
                                    <option key={year.id} value={year.id}>{year.name}</option>
                                ))
                            }
                        </select>
                        <Tooltip title="Search">
                            <BiRightArrowAlt onClick={onSubmit} className='cursor-pointer' size={24} color='#BC8B20' />
                        </Tooltip>
                    </div>
                </div>}
        </>
        // <Popover
        //     open={open}
        //     onOpenChange={setOpen}
        //     placement='left'
        //     trigger="click"
        //     destroyTooltipOnHide
        //     showArrow={false}
        //     overlayInnerStyle={{
        //         padding: "0px",
        //         borderColor: "#d2d2d2",
        //     }}
        //     content={

        //     }>

        // </Popover>

    )
}




export default MonthRangePicker