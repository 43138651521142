import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from "react-router-dom";
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import companyLogo1 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo2 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo3 from "../../../images/logo2/png/logo2.png";
import ExcelPopup from "../../../Components/ExcelPopup";
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from "../../RandomLogo";
import DataTable from "../../DataTables/DataTable";
import { routeNames } from '../../../routeSegments';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import moment from "moment";
import OverflowToolTip from "../../shared/OverflowToolTip";
import Paginate from '../../Paginate';
import Constants from '../../../Constants';
import { usePagination, useSortBy, useTable } from 'react-table';
import ConfirmPopup from '../../../Utils/ConfirmPopup';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import Loader from '../../Loader';
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";


/**
 * The function `InvestorPortfolioIPO` is a React component that displays a table of IPO data for a
 * given investor's portfolio.
 * @returns The function `InvestorPortfolioIPO` returns a JSX element, which represents the UI
 * component to display the investor's portfolio companies that have gone public (IPOs).
 */
function InvestorPortfolioIPO({ isLoading, portfolio, investorName }) {
    const { investorId } = useParams();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const location = useLocation();
    const { primaryMasterId } = useModule();
    const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)
    const { investorsIPODataFound } = useContext(DataFoundContext);
    const [alert, setalert] = useState({ message: "", show: false });

   /* The above code is defining a custom sorting function called `customStringSortMemo` using the
   `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
   boolean value `desc` indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            const r1 = rowA?.values[columnId]?.name
            const r2 = rowB?.values[columnId]?.name
            return (r1 ?? defaultVal).localeCompare(r2 ?? defaultVal);
        }
    }, []);
/* The above code is using the `matchPath` function to check if the `location.pathname` matches a
specific route pattern. The route pattern is constructed using the `routePathSegments.pe` variable
and a wildcard (`*`) to match any additional path segments. */
    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

   /* The above code is defining a function called `dealDateSort` using the `useMemo` hook in
   JavaScript. This function takes in two parameters `rowA` and `rowB`, which represent two rows of
   data, `columnId`, which represents the column to sort by, and `desc`, which indicates whether the
   sorting should be in descending order. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

   /**
    * The function "addCommasToNumber" takes a number as input and returns a string representation of
    * the number with commas added for thousands separators.
    * @param number - The `number` parameter is the number that you want to add commas to.
    * @returns a string representation of the number with commas added for every three digits.
    */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

  /* The above code is defining an array of columns for a table. Each column has properties such as id,
  Header (column header text), accessor (data accessor), className (CSS classes for the column),
  headerClassName (CSS classes for the column header), Cell (rendering function for the cell
  content), and sortType (custom sort function for the column). These columns are used to configure
  the appearance and behavior of the table. */
    const columns = useMemo(() => [
        {
            id: 'companyName',
            Header: "Company Name",
            accessor: "companyName",
            className: "clickable text-left lg:relative sticky left-0  bg-white",
            headerspanClassName: "",
            headerClassName: "text-left sticky lg:relative left-0   w-[3rem] bg-[#EDEDED] ",
            // className: "w-[10%]",
            // headerClassName: "w-[0%]",
            // className:"w-[0%]",
            Cell: ({ value }) => {
                return <div className='leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center' style={{whiteSpace: "initial"}}>

                    {value?.icon_path == "default.png" || value?.icon_path == "/default.png" || !value?.icon_path ?
                        <RandomLogo name={value.name} page="company page" />
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.icon_path} />
                    }
                    <OverflowToolTip className="w-[10rem] lg:w-auto" component={
                        <Link className=" text-start flex lg:inline hover:text-[#BC8B20]"
                        state={{ cameFrom: location.state?.prevPath }}
                        to={peMatch != null ? routeNames.peCompanyFundById(value?.id)
                          : routeNames.vcCompanyFundById(value?.id)}>
                        {value.name ?? "--"}
                      </Link>} />
                </div>
            },
            sortType: customStringSortMemo,
        },
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            // className: " text-right pr-[1.5rem]  w-[0%]",
            // headerClassName: "flex justify-center ",
            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peCompanyBackedIPOSDealById(cell.value?.id, cell.value?.dealId) : routeNames.vcCompanyBackedIPOSDealById(cell.value?.id, cell.value?.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {  
            Header: "IPO Size (US$ M)",
            accessor: "ipoSize",
            // headerClassName: "pl-[4.5rem] ",
            className: " text-right pr-[2.6rem]  w-[0%]",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Investor Sale in IPO?",
            accessor: "investorSaleInIpo",
            headerClassName: "pl-[8rem] ",
            className:"pl-[8rem]"
           
        },
        {
            Header: "IPO Valuation (US$ M)",
            accessor: "ipoValuation",
            className: " text-right pr-[2.5rem]  w-[0%]",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
            
        },

    ], [])

    /* The above code is a JavaScript function that is using the `useMemo` hook to memoize the
    `rowData` variable. */
    const rowData = useMemo(() => {
        let rows = [];
        portfolio?.forEach(company => {
            rows.push({
                companyName: { name: company?.company_name, id: company?.company_id, dealId: company?.ipo_id, icon_path: company?.icon_path },
                dealDate: { date: company?.ipo_date, dealId: company?.ipo_id, id: company?.company_id },
                ipoSize: company?.ipo_size == 0 ? "" : company?.ipo_size,
                investorSaleInIpo: company?.investor_sale=='NO' ? 'No' :company?.investor_sale,
                ipoValuation: company?.ipo_valuation == 0 ? "" : company?.ipo_valuation,
            })
        });
        setshowXlDownload(rows.length != 0)
        return rows;

    }, [portfolio])

   /* The above code is defining an initial sort configuration for a data table. It is using the
   `useMemo` hook to create a memoized value that will only be recalculated if the dependencies
   change. The initial sort configuration is an array with a single object containing two
   properties: `id` and `desc`. The `id` property represents the column to sort by, and the `desc`
   property indicates whether the sorting should be in descending order (`true`) or ascending order
   (`false`). In this case, the initial sort configuration is sorting by the 'dealDate' column in
   descending order. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

   /* The above code is using the `useTable` hook from a library (possibly React-Table) to create a
   table instance. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is destructuring properties from the `tableInstance` object. It is extracting the
    following properties: */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;

    console.log(portfolio, "portfolio-123");

    const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })
    }

    const onBlurColumnClick = (cell) => {
    setalert({ show: true, message: Constants.maSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    return (
        <>
      {investorsIPODataFound ? (
        <div className="px-4  bg-[#EDEDED] w-[100%]">
            <div className=" mt-4 pb-2 bg-white shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
                <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                    <div className="flex justify-between mb-3">
                        <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold">
                            Portfolio Companies - IPOs
                        </h3>
                        {!isDirectoryUser ? (isStudent == 'false' && isTrialExport !='false' && showXlDownload) && <ExcelDownloader
                            url={exportExcel}
                            fileName={Constants.exportNames.portfolio_companies_ipo(investorName)}
                            data={{
                                "type":primaryMasterId,
                                "export_from": "detail",
                                "module_type":"investors",
                                "category": "ipos",
                                "section":"company_ipos",
                               "investor_id": [investorId]
                              }} /> : ''}
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white pb-2 rounded-[6px] overflow-x-auto scrollbar">
                    { rows?.length > 0 ? 
                        <table className=" datatable w-full" {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span className='mr-1'>{column.render('Header')}</span>
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {page.length > 0 && !isLoading
                                    ? page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {row.cells.map(cell => {
                                                if (
                                                    cell.column.blurColumns ||
                                                    cell.value == Constants.restrictedPlaceholder) {
                                                    return (
                                                        <td
                                                        onClick={onBlurColumnClick}
                                                        className=" relative whitespace-nowrap cursor-pointer"
                                                        {...cell.getCellProps()}
                                                        >
                                                        <div className="blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4">
                                                            {
                                                            Constants.restrictedPlaceholder
                                                            }
                                                        </div>
                                                        <div className="absolute h-full w-full flex items-center justify-center top-0 bottom-0">
                                                            <LockIcon size={16} />
                                                        </div>
                                                        </td>
                                                    );
                                                }       
                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })
                                    : <></>}
                            </tbody>
                        </table>
                        :
                        <div className='w-full text-[#333333] h-16 bg-white flex items-center justify-center font-sans_book_body  mx-auto lg:text-[15px] text-[15px] relative text-center mt-2'>
                            {isLoading ? <Loader /> : "No data found"}
                        </div>
                        }
                    </div>

                </div>

                {/* {isLoading || rows?.length <= 0
                    ? <div className='w-full text-[#333333] h-16 bg-white flex items-center justify-center font-sans_book_body  mx-auto text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center'>
                        {isLoading ? <Loader /> : "No data found"}
                    </div>
                    : <></>} */}
                {rows?.length <= Constants.noOfRowPerPage || isLoading
                    ? <></>
                    : <Paginate
                        currentPage={pageIndex + 1}
                        totalCount={rows?.length}
                        pageSize={pageSize}
                        onPageChange={(page) => {
                            gotoPage(page - 1);
                        }}
                    />}
            </div>
            {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </div>
        ) : (
        ""
      )}
    </>
    )
}

export default InvestorPortfolioIPO