import React, {useEffect ,useContext, useMemo} from "react"
import { NavLink} from 'react-router-dom';
import PEactive from '../../images/reportsProducts/pe.png'
import MAactive from '../../images/reportsProducts/ma.png'
import REactive from '../../images/reportsProducts/pe-re.png'
import reShape from '../../images/RE.png';
import maShape from '../../images/M & A.png';
import cfsShape from '../../images/CFS.png';
import  { routeNames } from "../../routeSegments";
import peBlurIcon from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import cfsBlurIcon from '../../images/CFS Active.png';
import Constants from '../../Constants';
import axios from "axios";
import { faqApi } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import { FaqContext } from "../../Contexts/FaqContext";


/**
 * The Menu function is a React component that renders a menu with tabs for different modules, and when
 * a tab is clicked, it makes an API call to fetch FAQs related to that module.
 * @param props - The `props` parameter is an object that contains the properties passed to the `Menu`
 * component. These properties can be accessed using dot notation, such as `props.active`,
 * `props.menu`, etc.
 * there are four NavLink components representing different tabs: PE, RE, M&A,
 * and CFS. Each NavLink component has an image and text representing the tab name. The active tab is
 * determined by the value of the "openTab" variable.
 */
function Menu(props) {

    const openTab = useMemo(() => props.active, [props.active])

    useEffect(() => {
        onTabClick(props.menu)
    }, [])
    

    const { getToken } = useContext(UserContext);

    const {setIsLoading,setFaqs,setSelectedSearchResult} = useContext(FaqContext)

    const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
    // console.log("allowedModulesList", allowedModulesList);

    const onTabClick = (tab) => {
        setIsLoading(true)
        setSelectedSearchResult(null)
        setFaqs([])
        axios(`${faqApi}`, {
            method: "POST",
            data: {
            "type"      :   tab == "PE" ? 1 : tab == "CFS" ? 5 : tab == "RE" ? 2 : tab == "M&A" ? 3: "",
            "db_type"   :   tab
        },
        headers: {
            "Content-Type": "application/json",
            "Authorization": getToken()
        },
        })
            .then((response) => {
                console.log(response,"faq")
                if (response.status == 200) {
                    setFaqs(response.data.data)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.log("API not working - faq ", error);
            });
        
    }

    
    return (

        <section>
            <div className=" flex flex-col items-stretch  w-full  border-b-[1px] border-[#D3D3D3]" >
                <div className=" flex flex-row   mt-5 justify-around md:justify-center ">
                    <NavLink className={"pl-2 leading-[17pt] text-[14pt] group flex items-center py-2 space-x-8 md:px-7 lg:text-[16px] lg:leading-[19px]"
                        + (openTab === 1
                            ? " border-b-2 border-[#F1CA75] font-sans_medium_body "
                            : " ")
                        +(allowedModulesList.includes("pe") ? " text-[#333333]" : " text-[#cccccc] cursor-not-allowed")
                    }
                        to={allowedModulesList.includes("pe") ? routeNames.pe_faq : ""}
                        data-toggle="tab"
                        role="tablist"
                        // onClick={() => onTabClick("PE")}
                    >
                        <img className="  h-[24px] w-[27px] mr-2" src={openTab === 1 ? PEactive : peBlurIcon}  alt="PE"/>
                        PE
                    </NavLink>

                    <NavLink className={"pl-2 leading-[17pt] text-[14pt] group flex items-center py-2 space-x-8 md:px-7 lg:text-[16px] lg:leading-[19px] "
                        + (openTab === 2
                            ? " border-b-2 border-[#F1CA75] font-sans_medium_body"
                            : " ")
                        +(allowedModulesList.includes("re") ? " text-[#333333]" : " text-[#cccccc] cursor-not-allowed")
                    }
                        to={allowedModulesList.includes("re") ? routeNames.re_faq : ""}
                        data-toggle="tab"
                        role="tablist"
                        // onClick={() => onTabClick("RE")}
                    >
                        <img className="  h-[24px] w-[27px] mr-2" src={openTab === 2 ?  REactive : reShape }  alt="RE"/>
                        RE
                    </NavLink>

                    <NavLink className={"pl-2 leading-[17pt] text-[14pt] group flex items-center py-2 space-x-8 md:px-7 lg:text-[16px] lg:leading-[19px]"
                        + (openTab === 3
                            ? " border-b-2 border-[#F1CA75] font-sans_medium_body"
                            : " ")
                        +(allowedModulesList.includes("ma") ? " text-[#333333]" : " text-[#cccccc] cursor-not-allowed")
                    }
                        to={allowedModulesList.includes("ma") ? routeNames.ma_faq : ""}
                        data-toggle="tab"
                        role="tablist"
                        // onClick={() => onTabClick("MA")}
                    >
                        <img className="  h-[24px] w-[27px] mr-2" src={openTab === 3 ? MAactive: maShape}  alt="M&A"/>
                        M & A
                    </NavLink>

                    <NavLink className={"pl-2 leading-[17pt] text-[14pt] group flex items-center py-2 space-x-8 md:px-7 lg:text-[16px] lg:leading-[19px]"
                        + (openTab == 5
                            ? " border-b-2 border-[#F1CA75] font-sans_medium_body"
                            : "")
                            +(allowedModulesList.includes("cfs") ? " text-[#333333]" : " text-[#cccccc] cursor-not-allowed")
                    }
                        to={allowedModulesList.includes("cfs") ? routeNames.cfs_faq : ""}
                        data-toggle="tab"
                        role="tablist"
                        // onClick={() => onTabClick("CFS")}
                    >
                        <img className="  h-[25px] w-[24px] mr-2" src={openTab == 5 ?  cfsBlurIcon : cfsShape } alt="CFS"/>
                        CFS
                    </NavLink>
                </div>
            </div>

        </section>
    )
}

export default Menu
