import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import { toast } from 'react-toastify';

/**
 * The `InvestorDealRangeFilter` function is a React component that renders a dropdown menu for
 * selecting a deal range in US dollars.
 * @returns a JSX element.
 */
function InvestorDealRangeFilter({ MinDealOptions, MaxDealOptions, setMinDealOptions, setMaxDealOptions, customPathname }) {

    const { InvestoDealRange, InvestoMaxDealRange, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const min = refineSearchFilters[customPathname]?.dealRange?.at(0)
        const max = refineSearchFilters[customPathname]?.dealRange?.at(1)
        setMinDealOptions(InvestoDealRange?.map(option => ({ ...option, isChecked: min?.name == option.name })))
        setMaxDealOptions(InvestoMaxDealRange?.map(option => ({ ...option, isChecked: max?.name == option.name })))
    }, [refineSearchFilters, customPathname, setMinDealOptions, InvestoDealRange, setMaxDealOptions, InvestoMaxDealRange])

    const selectedMin = MinDealOptions?.filter(option => option.isChecked)?.at(0)
    const selectedMax = MaxDealOptions?.filter(option => option.isChecked)?.at(0)

    const handleMinRange = (e) => {
        const { value } = e.target;
        if (selectedMax?.name !== "" && selectedMax?.name != null && (parseInt(selectedMax?.name) < parseInt(value))) {
            toast.warning('Minimum deal amount must be less than maximum amount')
            return;
        }
        setMinDealOptions(MinDealOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
    }

    const handleMaxRange = (e) => {
        const { value } = e.target;
        if (selectedMin?.name !== "" && selectedMin?.name != null && (parseInt(selectedMin?.name) > parseInt(value))) {
            toast.warning('Maximum deal amount must be greater than minimum amount')
            return;
        }
        setMaxDealOptions(MaxDealOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.name === value })))
    }



    console.log('MinDealOptions', MinDealOptions);

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className="relative px-3 py-2"
            >
                <p className="text-[14px] tracking-[-.3px]">Deal Range (US$ M)</p>
                <div className="flex mt-2">
                    <select value={selectedMin?.name ?? ""} name="" id="" onChange={handleMinRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MinDealOptions && MinDealOptions.length > 0 ? (
                            MinDealOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                    <p className="text-sm mr-3">To</p>
                    <select value={selectedMax?.name ?? ""} name="" id="" onChange={handleMaxRange}
                        className=" border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFF9EC] lg:w-[5rem] w-[9.5rem] md:w-[16rem] lg:h-[1.6rem]">
                        <option value="" selected>All</option>
                        {MaxDealOptions && MaxDealOptions.length > 0 ? (
                            MaxDealOptions.map((date, index) => (
                                <option value={date.name ? date.name : ''}>{date.name ? date.name : ''}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>
        </div>
    )
}

export default InvestorDealRangeFilter