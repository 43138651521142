import React, { useState,useContext } from 'react'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/VC/VcInvestor'
import MainMenu from '../../../Components/VC/InvestorVcMainmenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';
import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorExistRefineSearch from '../../../Components/RefineSearch/InvestorExistRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
import chatPopupIcon from '../../../images/chatPopupIcon.png'
import ChatPopup from '../../../Components/ChatPopup'
import Constants from '../../../Constants'

/**
 * The MA_VC function is a React component that renders a layout with a primary column and a secondary
 * column, and includes a filter tag, a main menu, a table, and a refine search component.
 * @returns The MA_VC function returns a JSX element that represents the layout and components of a
 * page.
 */
function MA_VC() {
    const [showModal, setShowModal] = React.useState(false);
    const location = useLocation(); 

    const {refineSearchFilters} = useContext(PeFilterContext);

    const { pathname } = useLocation(); 
      const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
        <div className="flex min-h-screen h-screen flex-col">
        {isFromGlobalSearch() ? "" :   <div className="hidden lg:block">  <Card  card1={window.location.pathname ?(window.location.pathname =='/vc/investors/exits/ma/vc-investments'? 'VC Investors':''):''} card2={window.location.pathname ?(window.location.pathname =='/vc/investors/exists/ma/vc-investments'? 'VC Exists':''):''}/>
        </div>}<div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
                        <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">

                                <FilterTag menu ="investor-exit-ma-vc" />
                                <MainMenu active={2} menu="ma_vc" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_ma_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.vc_investor_exit_ma}/>
                                <div className='overflow-y-auto'>
                                <Table menu="ma_vc" sub_type_master_id={Constants.subCategoryIds.vc_investor_exitsma_id}/>
                                </div>
                                


                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>                       

                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch><InvestorExistRefineSearch customPathname={location.pathname}/> </RefineSearch>
                        </div>
                    </aside>
                    </main>
                    </div>
                </div>           
        </Layout>
    )
}

export default MA_VC
