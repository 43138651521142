import { React, useState, useMemo, useEffect, useContext} from 'react'
import { useLocation, useParams, NavLink } from 'react-router-dom';
import {  getCfsProfileApi} from "../../../Config/config"
import axios from 'axios';
import { UserContext } from '../../../Contexts/UserContext';
import Constants from '../../../Constants';
import ProfileHighlight from '../Detailpage/ProfileHighlights'
import Funding from '../Detailpage/Funding'
import NewsActivity from '../Detailpage/NewsActivity'
import FundingDataProvider from '../../../Contexts/FundingDataProvider';
import DetailInformation from '../../../Components/RE/Detailpage/RECompanyDetailInformation';
import { routeNames } from '../../../routeSegments';
import Tab from '../../shared/Tab';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from "../../../Contexts/TableDataContext";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Tooltip } from 'antd';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';
 
/**
 * The `CompanyDetail` function is a React component that displays detailed information about a
 * company, including its overview, funding details, and recent news and activity.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `CompanyDetail` component. These properties can be accessed using dot notation, such as
 * `props.propertyName`.
 * @param menu - The `menu` parameter is used to determine the menu type. It can have values like "re"
 * or "other" to specify different menu types.
 * @param section - The `section` parameter is a variable that represents the section of the company
 * detail page that is currently being displayed. It is passed as a prop to the `CompanyDetail`
 * component and is used to determine which section to display within the component.
 * @returns The `CompanyDetail` component is returning a JSX element.
 */
const CompanyDetail = (props, menu ,section) => {

    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' }); }
    const funding = () => { document.getElementById("fund").scrollIntoView({ behavior: 'smooth' }); }    
    const newsActivity = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }
    const [isActive, setIsActive] = useState(1);
    const { getToken, logout } = useContext(UserContext);
    const [profileData, setProfileData] = useState({});
    // const [topProfile, setTopProfile] = useState([])
    const { companyId } = useParams();
    const location = useLocation()
    let companyProfileId = parseInt(companyId) 

    const [skipScroll, setSkipScroll] = useState(false)
    const { newsAndActivityDataFound } = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundingEl, isfundingVisible, fundingEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })


  /**
   * The function `getProfile` makes a POST request to an API endpoint to retrieve profile data and
   * sets the response data to the `profileData` state variable.
   */
    const getProfile = () => {

        axios(`${getCfsProfileApi}`, {
            method: "POST",
            data: {
                company_id: [companyProfileId],
                type: Constants.primaryMasterIds.reId
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response?.data?.success == true) {
                    // setTopProfile(response?.data?.data?.topProfile)

                    // console.log("setProfileData", response.data);
                    setProfileData(response.data?.data);

                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });

    };

    useEffect(() => {
        getProfile();
    }, [companyProfileId])


   /**
    * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
    * selected tab index.
    * @param tabIndex - The `tabIndex` parameter is the index of the tab that was clicked. It is used
    * to determine which tab was clicked and perform the corresponding action.
    */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            funding();

        } else if (tabIndex === 3) {
            newsActivity();
        }

        setIsActive(tabIndex);
    }

    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])


    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isfundingVisible) {
            setIsActiveOnScroll(2);
        
        } else if (isnewsVisible) {
            setIsActiveOnScroll(3);
        }
    }, [isOverviewVisible, , isfundingVisible, isnewsVisible, setIsActiveOnScroll])

    const { tableCompanyId: cmpId } = useContext(TableDataContext);

    const currentIndex = cmpId?.indexOf(Number(companyId));
    
    return (
        <>
            <div className='overflow-y-auto'>
                <div className="bg-[#FFFFFF] relative">
                    <nav className="flex p-1 px-4 lg:px-1 border-b overflow-x-auto" aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center space-x-1">
                            <li className='flex items-center'>
                                <div className='flex items-center'>
                                    <NavLink
                                        to={
                                            location.state?.prevPath != null
                                                ? location.state?.prevPath
                                                : menu == "re" ? routeNames.re_companies_re_investments :
                                                routeNames.re_companies_re_investments
                                        }
                                        className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px]  text-[#666666]">
                                        Companies
                                    </NavLink>
                                </div>
                            </li>
                            <li className='flex items-center'>
                                <div className="flex items-center">
                                    <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                    <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold whitespace-nowrap"
                                    >{profileData?.topProfile?.company_name}</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    {cmpId && 
                        <>        
                            <Tooltip title="Prev">
                                <NavLink
                                    className="absolute top-2 right-8"
                                    to={currentIndex === 0 ? null : routeNames.reCompanyById(cmpId[currentIndex - 1])}
                                    >
                                    {cmpId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Next">
                                <NavLink
                                    className="absolute top-2 right-4"
                                    to={
                                        cmpId.length - 1 === currentIndex
                                        ? null
                                    : routeNames.reCompanyById(cmpId[currentIndex + 1])}
                                    >
                                    {cmpId.length - 1 === currentIndex ? null : (
                                        <FaChevronRight size={15} color='grey'/>
                                    )}
                                </NavLink>
                            </Tooltip>
                        </>
                    }
                    <DetailInformation data={profileData?.topProfile} id={companyId} page="reCompanyPage" cname={profileData?.topProfile?.company_name}  section={props?.section}/>

                    
                    <div className='bg-[#EDEDED]'>
                        <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
                            <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                            <Tab active={isActive === 2} label={"Funding"} onClick={() => onTabClick(2)} />
                            {newsAndActivityDataFound ? <Tab active={isActive === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} /> : ""}
                        </div>


                        <div className="h-full space-y-5">
                            <section ref={overviewEl}  className="scroll-mt-14" id="profile">
                                <ProfileHighlight
                                    profile={profileData?.profile}
                                    highlights={profileData?.highlights}
                                    shareholdingPattern={profileData?.shareholdingPattern} />
                            </section>
                            <section ref={fundingEl} className="scroll-mt-14" id="fund"><FundingDataProvider><Funding menu={menu} companyName={profileData?.topProfile?.company_name} /></FundingDataProvider></section>
                            <section ref={newsEl}  className="scroll-mt-14" id="news"><NewsActivity menu="companydetail" /></section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CompanyDetail