import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';

/**
 * The `DealTypeFilter` function is a component in JavaScript that renders a filter for selecting deal
 * types, either as a single select or a multi-select option.
 * @returns a JSX element.
 */
function DealTypeFilter({ open,
    DealTypeOption,
    setDealTypeOption,
    customPathname,
    accordionClick,
    dataPath,
    isMultiSelect = false,
    showAll = true,
    label = "Deal Type"
}) {

    const { dealTypeFilterResponse, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.dealType?.map(value => value.id) ?? [];
        setDealTypeOption(dealTypeFilterResponse[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [dealTypeFilterResponse, customPathname, refineSearchFilters, setDealTypeOption, dataPath])


  /**
   * The function `handleDealTypeChange` is used to handle changes in the deal type selection, either
   * for a single select or a multi-select option.
   * @param e - The parameter `e` is an event object that is passed to the `handleDealTypeChange`
   * function. It represents the event that triggered the function, such as a change event on an input
   * element.
   */
    const handleDealTypeChange = (e) => {
        if (isMultiSelect) {
            const { name, checked, id } = e.target;
            let currentDeals = [];
            if (name === "allSelect") {
                currentDeals = DealTypeOption.map((industry) =>
                    ({ ...industry, isChecked: checked })
                );
                setDealTypeOption(currentDeals);
            } else {
                currentDeals = DealTypeOption.map((industry) =>
                    industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
                );
                setDealTypeOption(currentDeals);
            }
        } else {
            const value = e.target.value
            setDealTypeOption(DealTypeOption?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === parseInt(value) })))
        }
    };

    const selected = DealTypeOption?.filter(option => option.isChecked)?.length ?? 0
    const selectedOption = DealTypeOption?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={isMultiSelect ? false : true}
                    label={isMultiSelect ? label : selectedOption?.name ? `${label} - ` + selectedOption?.name : label}
                    onClick={accordionClick}
                    open={open}
                    count={isMultiSelect ? selected : null}

                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {
                        isMultiSelect
                            ? open === true
                                ? <MultiSelectEntity
                                    handleEntityChange={handleDealTypeChange}
                                    primaryEntity={DealTypeOption}
                                    showAll={showAll}
                                />
                                : <></>
                            : <select name="" id="" value={selectedOption?.id ?? ""} onChange={handleDealTypeChange}
                                className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                                <option value="">All</option>
                                {DealTypeOption && DealTypeOption.length > 0 ? (
                                    DealTypeOption.map((dealType, index) => (
                                        <option key={dealType.id} value={dealType.id}>{dealType.name}</option>
                                    )))
                                    :
                                    (
                                        <h1>No results found!</h1>
                                    )}
                            </select>
                    }
                </div>

            </li>

        </div>
    )
}

export default DealTypeFilter