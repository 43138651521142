import Layout from '../Components/Layout'
import { NavLink, useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import RandomLogo from '../Components/RandomLogo';
import CompanyLogo from '../Components/CompanyLogo';
import OverflowToolTip from '../Components/shared/OverflowToolTip';
import Loader from '../Components/Loader';
import { Pagination } from 'antd';
import {TableDataContext} from '../Contexts/TableDataContext';
import { cubejsApi } from '..';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import { routeNames } from '../routeSegments';
import FilterTags from '../Components/QueryBuilder/Components/FilterTags';


/**
 * The `LegalAdvisorsQueryBuilder` function is a React component that renders a table of legal advisors
 * based on search parameters and allows for sorting and pagination.
 * @returns The function `LegalAdvisorsQueryBuilder` returns a JSX element, which represents the UI
 * component to be rendered on the screen.
 */
function LegalAdvisorsQueryBuilder() {
    const { advancedSearchCurrentPage,setAdvancedSearchCurrentPage} = useContext(TableDataContext)
    // const [isLoading, setIsLoading] = useState(false)
    const [companyData, setCompanyData] = useState();
    const [totalCompany, setTotalCompany] = useState(1)
    const [sort, setSort] = useState({ order: "asc", orderBy: "advisor_cias.cianame" })
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get('id')

    
    const [PEVCTableHeader, setPEVCTableHeader] = useState(
        [
            { accessor: 'advisor_cias.cianame', label: 'Advisor' },
            { accessor: 'advisor_cias.country', label: 'Country' },
            { accessor: 'advisor_cias.city', label: 'City' },
            { accessor: 'advisor_cias.designation', label: 'Designation' },
            { accessor: 'advisor_cias.email', label: 'Email' }
        ]
    );
  
    /**
     * The function `currentPageTable` sets the current page value and performs a search with the
     * updated value.
     * @param value - The value parameter is the current page number that is being set.
     */
    const currentPageTable = (value) => {
      setAdvancedSearchCurrentPage(value)
      serachResult(value, sort.orderBy, sort.order)
    }
  
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to fetch the total count of legal advisors and perform a search for
    the first page of legal advisors. */
    useEffect (() => {
      findTotalCount()
      serachResult("1", sort.orderBy, sort.order)
    },[])
  
    /**
     * The function `findTotalCount` retrieves the total count of companies from a data source using
     * Cube.js and sets the value in the state variable `totalCompany`.
     */
    const findTotalCount = () => {
        const query0 = totalCount();
  
        // setIsLoading(true);
        cubejsApi.load(query0
        )
          .then((result) => {
            // console.log('result', result.loadResponse.results[0].data[0]["Pecompanies.count_of_companyname"]);
            setTotalCompany(result.loadResponse.results[0].data[0]["Pecompanies.count_of_companyname"]);
          }) 
    }

    /**
     * The function `searchResult` takes in three parameters (page, accessor, order) and generates a
     * cube query using these parameters to fetch data from an API.
     * @param page - The "page" parameter is used to specify the page number for pagination purposes.
     * It determines which page of search results to retrieve.
     * @param accessor - The `accessor` parameter is used to specify the dimension or measure that you
     * want to search for in your data. It could be the name of a column in your database table or a
     * cube.js dimension or measure.
     * @param order - The "order" parameter is used to specify the order in which the search results
     * should be sorted. It can be either "asc" for ascending order or "desc" for descending order.
     */
    const serachResult = (page, accessor, order) => {
      // setrefineSearchFilters(1)
      setCompanyData()
      // setIsLoading(true);
      const query0 = generateCubeQuery(page, accessor, order);
  
      // setIsLoading(true);
      cubejsApi.load(query0
      )
        .then((result) => {
          // console.log('result', result.loadResponse.results[0].data);
          setCompanyData(result.loadResponse.results[0].data);
          // setIsLoading(false)
        })  
  
    }

    /**
     * The function `totalCount` returns a cube query object with measures, order, filters, and
     * segments based on data stored in local storage.
     * @returns The function `totalCount` returns the `cubeQuery` object.
     */
    function totalCount() {
        const cubeQuery = {
          timeDimensions: [],
            measures: [
              "Pecompanies.count_of_companyname"
            ],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
        cubeQuery.segments = JSON.parse(localStorage.getItem("LASegmentsList"))
        return cubeQuery;
      }
  
    /**
     * The function generates a cube query object with specified page, orderBy, and order parameters.
     * @param page - The "page" parameter is used to determine which page of data to retrieve. It is
     * typically used in pagination to fetch data in chunks or pages.
     * @param orderBy - The `orderBy` parameter is used to specify the field by which the data should
     * be ordered. It can be any of the dimensions mentioned in the `dimensions` array.
     * @param order - The "order" parameter specifies the order in which the data should be sorted. It
     * can have two possible values: "asc" for ascending order and "desc" for descending order.
     * @returns a cubeQuery object.
     */
    function generateCubeQuery(page, orderBy, order) {
      const cubeQuery = {
        timeDimensions: [],
        order: {
            [orderBy]: order,
        },
        segments:[],
        dimensions: [
          "advisor_cias.cianame",
          "advisor_cias.country",
          "advisor_cias.city",
          "advisor_cias.designation",
          "advisor_cias.email",
          "advisor_cias.CIAId"
        ],
        filters: JSON.parse(localStorage.getItem(id)),
        // limit: 20
        limit: 10,
        offset: page=="1"?0:10*(page-1)
      };
      cubeQuery.segments = JSON.parse(localStorage.getItem("LASegmentsList"))
  
      return cubeQuery;
    }
    
    const filtersFromLocalStorage = JSON.parse(localStorage.getItem(id));
    
      /**
       * The handleSort function updates the sorting order and column to be sorted based on the given
       * accessor and order.
       * @param accessor - The `accessor` parameter is a value that represents the property or key of
       * the object that you want to sort by. It is used to identify the specific property that you
       * want to sort the search results by.
       * @param order - The "order" parameter is used to determine the sorting order. It can have two
       * possible values: 'asc' for ascending order and 'desc' for descending order.
       */
      const handleSort = (accessor, order) => {
        setSort((prevSort) => ({
            order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
            orderBy: accessor,
          }))
        serachResult(advancedSearchCurrentPage, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc')
      }

    const onClose = (value) => {
      console.log(value);
      const updatedData = filtersFromLocalStorage
        .map((item) => {
        if (item.values && Array.isArray(item.values)) {
          item.values = item.values.filter((val) => val !== value);

          if (item.values.length === 0) {
            return null;
          }
        }

        return item;
      })
      .filter((item) => item !== null);

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));
    serachResult("1", sort.orderBy, sort.order);
    findTotalCount();
  };
  
  const deleteFilterByMember = (member) => {
    const updatedData = filtersFromLocalStorage.filter((item) => {
      return item.member !== member || (item.values && item.values.length <= 3);
    });

    console.log(updatedData, filtersFromLocalStorage, 'comparefilter');

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));

    serachResult("1", sort.orderBy, sort.order);
    findTotalCount();
  };
  
    return (
        <div>
            <Layout>
                <div className="mt-[3.5rem]">
                    {/* <QueryBuilderTabs /> */}
                    {/* <TableTabTable filter={location.state.filter}/> */}
                    <FilterTags
                      filtersFromLocalStorage={filtersFromLocalStorage}
                      onClose={onClose}
                      deleteFilterByMember={deleteFilterByMember}
                    />
      {/* <h2> Table Rendering </h2> */}
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] m-3 " + (companyData?.length > 5 ? '' : 'pr-2')}>

              <table className="md:pl-5 md:pr-5 pl-5 pr-[16rem]  listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left"
                >
                    {PEVCTableHeader.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={"md:pl-5 md:pr-5 px-[1rem] py-2 pr-[11rem] "} key={i}>
                          <span className='flex'><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.label ? column.label : "--"}</a>} />
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                         </span>
                        </th>
                      )
                    })}
                </thead>
                <tbody className='text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body'>
                  {companyData ?
                 companyData.length > 0 ? (
                  companyData?.map((row, i) => {
                    return (
                      <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white " >
                         <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                         {/* {row['CfsFlat.logopath'] == '' ? 'empty' : 'No empty'} */}
                         {row["peadvisor_logo.icon_path"] == "default.png" || row["peadvisor_logo.icon_path"] == "/default.png" || !row["peadvisor_logo.icon_path"] ?
                                    <RandomLogo name={row['advisor_cias.cianame']} />
                                    :
                                    <CompanyLogo name={row['advisor_cias.cianame']} page="company page" logo={row["peadvisor_logo.icon_path"]} />
                                  }
                                 <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<NavLink className='hover:text-[#BC8B20] text-[#333333]' to={routeNames.peAdvisorById(row['advisor_cias.CIAId'])}>{row['advisor_cias.cianame'] ? row['advisor_cias.cianame'] : "--"}</NavLink>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['advisor_cias.country'] ? row['advisor_cias.country'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <p className='cursor-text'>{row['advisor_cias.city']?row['advisor_cias.city']:"--"}</p></td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {/* {row['profile_flat.sector']?row['profile_flat.sector']:"--"} */}
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['advisor_cias.designation'] ? row['advisor_cias.designation'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <p className='cursor-text'>{row['advisor_cias.email']?row['advisor_cias.email']:"--"}</p></td>
                          {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center"
                          >{row['Pecompanies.region']?row['Pecompanies.region']:"--"}</td> */}
                           {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.amountM']?row['DealFlat.amountM']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['PecompanyTagCrosstabVw.tags']?row['PecompanyTagCrosstabVw.tags']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.SPV']?row['DealFlat.SPV']:"--"}</td> */}

                          
                      </tr>
                    )
                  }))
                  
                   : (
                    <tr>
                      <td colSpan="5" className="py-3 text-center">No Records Found</td>
                    </tr>
                  )
                  :
                  <div className='sticky left-[50%] py-[2rem]'><Loader /></div>}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
      <center className='my-[1rem]'><Pagination defaultCurrent={1} total={totalCompany} onChange={currentPageTable}/></center>

                </div>
            </Layout>
        </div>
    )
}

export default LegalAdvisorsQueryBuilder
