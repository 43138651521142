import{React, useState, createContext} from 'react'
import { useLocation} from 'react-router-dom';

export const RaiseRequestContext = createContext('');

/**
 * The code defines a React context provider component called RaiseRequestContextProvider that manages
 * state related to raising requests, including the open status of a request popup, the default
 * messages for different types of requests, the email ID to send the request to, and the author ID.
 * @returns The `RaiseRequestContextProvider` component is being returned.
 */
const RaiseRequestContextProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [openTable, setOpenTable] = useState(false);
    const [DefaultMessage, setDefaultMessage] = useState({
      financial : "Request for financials linking",
      moreInfoInvestment  : "Request for more deal data-PE Investment",
      moreInfoAngel : "Request for more deal data-Ang Inv",
      moreInfoIncubation  : "Request for more deal data",
      MandA : "Check for M&A Activity",
      valuationInfo: "Request for more deal data-PE Investment",
      coInvestor: "Investor - Investment",
      moreDealVc  : "Request for more deal data-VC Investment",
      mandaDealAdvisorInfo :"Request for more deal data-MandA",
      mandaDealValuationInfo:"Request for more deal data",
      faq :"Query from FAQ section",
      subscription : "Upgrade Subscription - Enquiry"
    })
    const [subjectData, setSubjectData] = useState("")
    const location = useLocation()

    const [requestMailID, setRequestMailId] = useState({
      commonEmailId : location.pathname=='/setting' ? "sales@ventureintelligence.com" :"research@ventureintelligence.com"
    })

    const [authorId, setAuthorId] = useState("vijayakumar.k@praniontech.com")

    const openRequestPopup = (from)=>{

      console.log(from,'jbidvcjufyusfyv');      
        if(from===""){
          setSubjectData("");
        }else{
          setSubjectData(DefaultMessage[from]??"");
        }            
      setOpen(true);
    }

    const Detail = { open, setOpen,openTable, setOpenTable, DefaultMessage, requestMailID, authorId,subjectData, setSubjectData,openRequestPopup}

    return (
      <RaiseRequestContext.Provider value={Detail}>
        {children}
      </RaiseRequestContext.Provider>
    )
}

export default RaiseRequestContextProvider
