import { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import axios from "axios";
import { Link, NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import { sortRows, filterRows, paginateRows } from '../../../Components/helpers'
import Pagination from '../../../Components/Pagination'
import { rowsPerPageData, getLimitedPartners, dateRangeFilter, newInvestorUrl, activeInvestorsUrl } from '../../../Config/config'
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../../Components/RandomLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../../Pages/addNote";
import '../../../Components/TableView.css'
import { getRoutePathById, routeNames } from '../../../routeSegments';
import { PeFilterContext } from '../../../Contexts/PeFilterContext'
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { TableHeaderContext } from '../../../Contexts/TableHeaderContext';
import CustomNavigation from '../../../Utils/CustomNavigation';
import NoDataPlaceholder from '../../../Components/shared/NoDataPlaceholder';
import Loader from '../../../Components/Loader';
import OverflowToolTip from '../../../Components/shared/OverflowToolTip';
import { UserContext } from '../../../Contexts/UserContext';
import AddNotePopover from '../../../Components/shared/AddNotePopover';
import Constants from '../../../Constants';
import { useRef } from 'react';


/**
 * The Table component is a reusable component in JavaScript that renders a table with dynamic data and
 * allows sorting and pagination.
 * @returns The Table component is returning a JSX element.
 */
const Table = ({ columns, menu, primaryTypeMasterId, primaryMasterSubId, submenu }) => {
    const { monthPickerValue, setIsTagClosed, refineSearchFilters } = useContext(PeFilterContext);
    // const { isLoad, setIsLoad } = useContext(TableDataContext);
    // const [isLoad, setIsLoad] = useState(true);
    const { isLoad, setIsLoad, currentPage, setCurrentPage, currentActiveInvestorId, setCurrentActiveInvestorId, setCurrentFilter, tableActiveInvestorId, setTableActiveInvestorId, tableNewInvestorId, setTableNewInvestorId, setTableInvestorId } = useContext(TableDataContext);

    const [activePage, setActivePage] = useState(currentPage)
    const [sort, setSort] = useState({ order: 'asc', orderBy: 'investor' })
    const { getToken, logout } = useContext(UserContext);
    const [investorsData, setInvestorsData] = useState();
    const abortRef = useRef(null)
    console.log("primaryTypeMasterId", { columns, menu, primaryTypeMasterId, primaryMasterSubId, submenu });

    /**
     * The function `findActivePage` sets the current page, renders a table with specified parameters, and
     * updates the load status.
     * @param page - The `page` parameter represents the current page number.
     */
    const findActivePage = (page) => {
        setCurrentPage(page)
        tableRender(page, monthPickerValue, sort.orderBy, sort.order)
        setIsLoad(true)
        // setIsDataLoad(false)
    }

    /* The above code is a JavaScript function that makes an API call using axios. It takes in
    parameters such as page, month, column, and order to customize the API request. It sets the
    necessary data and headers for the request and sends it to the server. If the response status is
    200, it updates the state variables with the received data. If there is an error or the response
    status is a session termination status, it handles the error accordingly. */
    const tableRender = useCallback((page, month, column, order) => {
        setIsTagClosed(0)
        axios(menu == "new-investor"
            ? newInvestorUrl
            : activeInvestorsUrl, {
            method: "POST",
            signal: abortRef.current.signal,
            data: {
                "type": primaryTypeMasterId,
                "module_type": primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_investments_id
                    ? "investments"
                    : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
                        ? "angels"
                        : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_incubation_id
                            ? "incubation"
                            : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_exits_id
                                ? "exits"
                                : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_ma_id
                                    ? 'exits_ma'
                                    : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_pm_id
                                        ? 'exits_pm'
                                        : "ipos",
                "date_range": { from_date: month[0], to_date: month[1] },
                "order_by": {
                    "column": column,
                    "direction": order
                },
                "industry": refineSearchFilters[location.pathname]?.industry?.at(0)?.id,
                "stage": refineSearchFilters[location.pathname]?.stage?.map(value => value.id),
                "firmType": refineSearchFilters[location.pathname]?.firmType?.map(firm => firm.id),
                "page": page,
                "investor_ids": menu == "new-investor" ? tableNewInvestorId : tableActiveInvestorId
            },
            headers: {

                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsLoad(false)
                console.log("API response working", response);
                if (response.status === 200) {
                    setTableInvestorId([])
                    setTableActiveInvestorId([])
                    setTableNewInvestorId([])
                    setCurrentFilter(response.config.data)
                    setInvestorsData(response.data)
                    setCurrentActiveInvestorId(response.data?.all_investor_id)
                    setTableInvestorId(response?.data?.all_investor_id)
                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });
    }, [refineSearchFilters, monthPickerValue]);

    // console.log("sortsortsortsort122",refineSearchFilters[location.pathname]?);


    useEffect(() => {
        const abortController = new AbortController()
        abortRef.current = abortController;
        setIsLoad(true)
        tableRender(1, monthPickerValue, sort.orderBy, sort.order)
        return () => {
            abortController.abort();
        }
    }, [tableRender, monthPickerValue, primaryMasterSubId, refineSearchFilters]);



    /**
     * The function `handleSort` is used to handle sorting of a table based on a given accessor and order.
     * @param accessor - The `accessor` parameter is a value that represents the property or key of the
     * object that you want to sort by. It is used to identify the specific property or key that you want
     * to sort the data by.
     * @param order - The `order` parameter is used to specify the sorting order. It can have two possible
     * values: 'asc' for ascending order and 'desc' for descending order.
     */
    const handleSort = (accessor, order) => {
        setCurrentPage(currentPage)
        setIsLoad(true);
        setSort((prevSort) => ({
            order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
            orderBy: accessor,
        }))
        tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc')
    }

    console.log('isLoad-67', isLoad);

    var location = useLocation();

    /**
     * The function `addCommasToNumber` takes a number as input and returns a string representation of the
     * number with commas added for thousands separators.
     * @param number - The `number` parameter is the number that you want to add commas to.
     * @returns The function `addCommasToNumber` returns a string with commas added to the number.
     */
    const addCommasToNumber = (number) => {
        return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };



    /**
     * The function `getSearch` returns an object with a property `companyIds` that contains an array of
     * objects with `value` and `name` properties.
     * @param row - The `row` parameter is an object that contains information about a particular investor.
     * It likely has properties such as `cos_ids` and `investor`.
     * @returns The function `getSearch` returns an object with a property `companyIds` which is an array
     * containing an object. The object has two properties: `value` and `name`. The value of `value` is
     * `row.cos_ids` and the value of `name` depends on the value of the `menu` variable. If `menu` is
     * equal to "new-investor",
     */
    const getSearch = (ids, investor,investorId) => {
        return {
            companyIds: [
                {
                    value: ids,
                    investorId,
                    name: menu == "new-investor" ? `New Investor - ${investor}` : `Most Active Investor - ${investor}`,
                }
            ]
        }
    }

    const SubPrimaryId = useMemo(() => {

        //for PE
        if (primaryTypeMasterId == Constants.primaryMasterIds.peId) return primaryMasterSubId

        //for VC
        return primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_investments_id
            ? Constants.primaryMasterSubIds.vc_investments_id
            : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
                ? Constants.primaryMasterSubIds.vc_angel_invesments_id
                : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_incubation_id
                    ? Constants.primaryMasterSubIds.vc_incubation_id
                    : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_exits_id
                        ? Constants.primaryMasterSubIds.vc_exits_id
                        : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_ma_id
                            ? Constants.primaryMasterSubIds.vc_ma_id
                            : primaryMasterSubId == Constants.primaryMasterSubIds.pe_vc_pm_id
                                ? Constants.primaryMasterSubIds.vc_pm_id
                                : Constants.primaryMasterSubIds.vc_backed_ipos_id
    }, [primaryMasterSubId, primaryTypeMasterId])


    const redirectPath = getRoutePathById({ primaryId: primaryTypeMasterId, subPrimaryId: SubPrimaryId, moduleId: Constants.moduleIds.companiesId })

    const onNumClick = (initialSearch, dateRange) => {
        const data = {
            initialSearch,
            dateRange,
        }
        localStorage.setItem(Constants.localStorageKeys.referer, JSON.stringify(data))
    }

    return (
        <>
            <div className="md:col-span-4 col-span-5">
                <div className="lg:col-span-4">
                    <div className=" ">
                        <div className="overflow-x-auto  scrollbar rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                            <table className=" table w-full p-1 bg-grey-50 divide-y" width="50%">
                                <thead className="bg-[#EBEBEB]  text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] font-sans_semi_bold  text-[#666666] text-left">
                                    <tr>
                                        {columns.map((column, index) => {
                                            const sortIcon = () => {
                                                if (column.accessor === sort.orderBy) {
                                                    if (sort.order === 'asc') {
                                                        return <SortDescendingIcon className="pl-2 block h-[15px]" />
                                                    }
                                                    return <SortAscendingIcon className="pl-2 block h-[15px]" />
                                                } else {
                                                    return <SortIcon className="pl-2 block h-[15px] " />
                                                }
                                            }
                                            return (
                                                <th className={`whitespace-nowrap   py-2`} key={column.accessor} width={`${index == 0 ? '30%' : '10%'}`}>
                                                    <span className={`flex px-5 ${index == 0 ? "justify-start" : ""}`}>
                                                        <span className="t" title={column.label ? column.label : "--"}>{column.label ? column.label : "--"}</span>
                                                        {column.accessor == "add" ? "" :
                                                            <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                                                        }</span>
                                                </th>
                                            )
                                        })}
                                        <th></th>
                                    </tr>
                                </thead>
                                {!isLoad ?
                                    investorsData?.num_of_investors > 0
                                        ? menu == "new-investor"
                                            ? <tbody className="w-full text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                                                {investorsData?.investors?.map((row, i) => {
                                                    return (
                                                        <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                                                            <td className="leading-[2rem] w-[20%] 2xl:w-[15%] px-5 py-3 font-sans_book_body card-animate " >
                                                                <div className='flex items-center'>
                                                                    {row?.logoPath == "default.png" || row?.logoPath == "/default.png" || !row?.logoPath ?
                                                                        <RandomLogo name={row?.investor} />
                                                                        :
                                                                        <CompanyLogo name={row?.investor} id={row?.LPId} page="company page" logo={row?.logoPath} />
                                                                    }
                                                                    <OverflowToolTip component={

                                                                        <NavLink state={{ prevPath: location.pathname }} to={primaryTypeMasterId == Constants.primaryMasterIds.peId
                                                                            ? routeNames.peInvestorById(row.id)
                                                                            : routeNames.vcInvestorById(row.id)} >
                                                                            {row.investor ? row.investor : "--"}
                                                                        </NavLink>

                                                                    }
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="pr-11 2xl:pr-[3.5rem] text-right py-3 font-sans_book_body">{row.deals != 0
                                                                ? <NavLink
                                                                    onClick={() => onNumClick(getSearch(row.cos_ids, row.investor,row.id), monthPickerValue, redirectPath)}
                                                                    target='_blank'
                                                                    to={redirectPath}>
                                                                    {addCommasToNumber(row.deals)}
                                                                </NavLink>
                                                                : "--"}</td>
                                                            <td className="pr-11 2xl:pr-[3.7rem] text-right py-3 font-sans_book_body">{row.cos != 0
                                                                ? <NavLink
                                                                    onClick={() => onNumClick(getSearch(row.cos_ids, row.investor,row.id), monthPickerValue, redirectPath)}
                                                                    target='_blank'
                                                                    to={redirectPath}>
                                                                    {addCommasToNumber(row.cos)}
                                                                </NavLink>
                                                                : "--"}</td>
                                                            {/* <td width="5%" className=" px-[10px] py-3 font-sans_book_body " >
                                                                <AddNotePopover companyId={row.id} companyName={row.investor} type_id={Constants.moduleIds.investorsId} />
                                                            </td> */}
                                                            <td width="10"></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            : <tbody className="text-[11pt] w-full leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                                                {investorsData?.investors?.map((row, i) => {
                                                    return (
                                                        <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                                                            <td width="20%" className="leading-[2rem] px-5 py-3 font-sans_book_body card-animate " >
                                                                <div className='flex items-center'>
                                                                    {row?.logoPath == "default.png" || row?.logoPath == "/default.png" || !row?.logoPath ?
                                                                        <RandomLogo name={row?.investor} />
                                                                        :
                                                                        <CompanyLogo name={row?.investor} id={row?.LPId} page="company page" logo={row?.logoPath} />
                                                                    }
                                                                    <OverflowToolTip component={

                                                                        <NavLink state={{ prevPath: location.pathname }} to={primaryTypeMasterId == Constants.primaryMasterIds.peId
                                                                            ? routeNames.peInvestorById(row.id)
                                                                            : routeNames.vcInvestorById(row.id)} >
                                                                            {row.investor ? row.investor : "--"}
                                                                        </NavLink>

                                                                    }
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="pr-11 2xl:pr-[3.3rem] py-3 font-sans_book_body text-right">{row.deals != 0
                                                                ? <NavLink
                                                                    onClick={() => onNumClick(getSearch(row.cos_ids, row.investor,row.id), monthPickerValue, redirectPath)}
                                                                    target='_blank'
                                                                    to={redirectPath}>
                                                                    {addCommasToNumber(row.deals)}
                                                                </NavLink> : "--"}</td>
                                                            <td className="pr-11 2xl:pr-16 py-3 font-sans_book_body text-right">{row.cos != 0 ?
                                                                <NavLink
                                                                    onClick={() => onNumClick(getSearch(row.cos_ids, row.investor,row.id), monthPickerValue, redirectPath)}
                                                                    target='_blank'
                                                                    to={redirectPath}>
                                                                    {addCommasToNumber(row.cos)}
                                                                </NavLink> : "--"}</td>
                                                            {submenu === "angels" || submenu === "incubation" ? null : <td className="pr-12 py-3 font-sans_book_body text-right">{row.newPCos != 0
                                                                ? <NavLink
                                                                    onClick={() => onNumClick(getSearch(row.newPCos_ids, row.investor,row.id), monthPickerValue, redirectPath)}
                                                                    target='_blank'
                                                                    to={redirectPath}>
                                                                    {addCommasToNumber(row.newPCos)}
                                                                </NavLink> : "--"}</td>}
                                                            <td width="10"></td>
                                                            {/* <td width="5%" className=" px-[10px] py-3 font-sans_book_body " >
                                                                <AddNotePopover companyId={row.id} companyName={row.investor} type_id={Constants.moduleIds.investorsId} />
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        : <></>
                                    : <></>
                                }
                            </table>
                            {!isLoad ?
                                investorsData?.num_of_investors <= 0 ?
                                    <div className='w-full h-[17rem] bg-white flex items-center justify-center '>
                                        <NoDataPlaceholder />
                                    </div>
                                    : <></>
                                :
                                <div className='w-full h-[17rem] bg-white flex items-center justify-center '>
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {investorsData?.num_of_investors > 0 ? (
                <div className='mb-5'>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={investorsData?.num_of_investors}
                        pageSize={20}
                        onPageChange={(page) => findActivePage(page)}
                    />
                </div>

            ) : (
                ""
            )}

            {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
        </>
    )
}

export default Table