import Layout from '../Components/Layout'
import { NavLink, useSearchParams} from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import RandomLogo from '../Components/RandomLogo';
import CompanyLogo from '../Components/CompanyLogo';
import OverflowToolTip from '../Components/shared/OverflowToolTip';
import Loader from '../Components/Loader';
import { Pagination } from 'antd';
import {TableDataContext} from '../Contexts/TableDataContext';
import { cubejsApi } from '..';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import { routeNames } from '../routeSegments';
import FilterTags from '../Components/QueryBuilder/Components/FilterTags';


function InvestorsQueryBuilder() {
    // console.log(location.state.filter,"jkjnkjkij");
    const { advancedSearchCurrentPage,setAdvancedSearchCurrentPage} = useContext(TableDataContext)
    // const [isLoading, setIsLoading] = useState(false)
    const [companyData, setCompanyData] = useState();
    const [totalCompany, setTotalCompany] = useState(1)
    const [sort, setSort] = useState({ order: "asc", orderBy: "peinvestors.investor" })
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get('id')

    
    const [PEVCTableHeader, setPEVCTableHeader] = useState(
        [
            { accessor: 'peinvestors.investor', label: 'Investor' },
            { accessor: 'peinvestors.email', label: 'Email' },
            { accessor: 'peinvestors.city', label: 'City' },
            { accessor: 'peinvestors.yearfounded', label: 'Year Founded' },
            { accessor: 'peinvestors.description', label: 'Description' }
        ]
    );
  
    /**
     * The function `currentPageTable` sets the current page value and performs a search with the
     * updated value.
     * @param value - The value parameter is the current page number that is being set.
     */
    const currentPageTable = (value) => {
      setAdvancedSearchCurrentPage(value)
      serachResult(value, sort.orderBy, sort.order)
    }
  
    /* The above code is using the useEffect hook in React to perform some actions when the component
    mounts. It calls the functions findTotalCount() and searchResult() with some parameters. The
    findTotalCount() function is likely used to calculate the total count of some data, while the
    searchResult() function is used to perform a search with pagination and sorting. The useEffect
    hook is also passed an empty dependency array, [], which means it will only run once when the
    component mounts. */
    useEffect (() => {
      findTotalCount()
      serachResult("1", sort.orderBy, sort.order)
    },[])
  
    /**
     * The function `findTotalCount` retrieves the total count of investors from a data source using
     * Cube.js and sets the value to `totalCompany`.
     */
    const findTotalCount = () => {
        const query0 = totalCount();
  
        // setIsLoading(true);
        cubejsApi.load(query0
        )
          .then((result) => {
            // console.log('result', result.loadResponse.results[0].data[0]["peinvestors.count_of_investor"]);
            setTotalCompany(result.loadResponse.results[0].data[0]["peinvestors.count_of_investor"]);
          }) 
    }
    

    /**
     * The function `searchResult` takes in three parameters (page, accessor, order) and generates a
     * cube query using these parameters to fetch data from an API.
     * @param page - The "page" parameter is used to specify the page number for pagination purposes.
     * It determines which page of search results to retrieve.
     * @param accessor - The `accessor` parameter is used to specify the dimension or measure that you
     * want to search for in your data. It could be the name of a column in your database or a cube.js
     * dimension or measure.
     * @param order - The "order" parameter is used to specify the order in which the search results
     * should be sorted. It can be either "asc" for ascending order or "desc" for descending order.
     */
    const serachResult = (page, accessor, order) => {
      // setrefineSearchFilters(1)
      setCompanyData()
      // setIsLoading(true);
      const query0 = generateCubeQuery(page, accessor, order);
  
      // setIsLoading(true);
      cubejsApi.load(query0
      )
        .then((result) => {
          // console.log('result', result.loadResponse.results[0].data);
          setCompanyData(result.loadResponse.results[0].data);
          // setIsLoading(false)
        })  
  
    }

      /**
       * The function `totalCount()` returns a cube query object based on the filters provided.
       * @returns The function `totalCount()` returns the `cubeQuery` object.
       */
      function totalCount() {
        const cubeQuery = {
          timeDimensions: [],
            measures: [
              "peinvestors.count_of_investor"
            ],
            segments:["peinvestors.investors_seg"],
          filters: JSON.parse(localStorage.getItem(id))
        };
        if(cubeQuery.filters.find(o => o.member === 'DealFlat.SPV') || cubeQuery.filters.find(o => o.member === 'DealFlat.investors' ) || cubeQuery.filters.find(o => o.member === 'DealFlat.Amountm') || cubeQuery.filters.find(o => o.member === 'DealFlat.Amount_inr') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountM') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.date') || cubeQuery.filters.find(o => o.member === 'DealFlat.exitStatus') || cubeQuery.filters.find(o => o.member === 'DealFlat.stage') || cubeQuery.filters.find(o => o.member === 'DealFlat.round') || cubeQuery.filters.find(o => o.member === 'DealFlat.priceToBook') || cubeQuery.filters.find(o => o.member === 'DealFlat.preMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.postMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.enterpriseValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultipleEv')){
          cubeQuery['segments'].push(
            "Pecompanies.dealtype"
          )
        }
        else if(cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_amount') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_date') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.exit_status') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_sub_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.company_valuation') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.revenue_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.ebitda_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.pat_multiple')){
          cubeQuery['segments'].push(
            "Pecompanies.exitsOnly"
          )
        }
        else if(cubeQuery.filters.find(o => o.member === 'peinvestments_investors.count_of_leadinvestor')){
          cubeQuery['segments'].push(
            "peinvestments_investors.lead_seg"
          )
        }
    
        return cubeQuery;
      }
  
    /**
     * The function `generateCubeQuery` generates a cube query object with specified parameters for
     * pagination, ordering, dimensions, filters, and segments.
     * @param page - The `page` parameter is used to determine which page of results to retrieve. It is
     * used to calculate the `limit` and `offset` values in the cube query.
     * @param orderBy - The `orderBy` parameter is used to specify the dimension or measure by which
     * the data should be ordered in the query result.
     * @param order - The "order" parameter determines the order in which the results should be sorted.
     * It can have two possible values: "asc" for ascending order and "desc" for descending order.
     * @returns The function `generateCubeQuery` returns an object representing a cube query.
     */
    function generateCubeQuery(page, orderBy, order) {
      const cubeQuery = {
        timeDimensions: [],
        order: {
            [orderBy]: order,
        },
        dimensions: [
          "peinvestors.investor",
          "peinvestors.description",
          "peinvestor_logo.icon_path",
          "peinvestors.email",
          "peinvestors.city",
          "peinvestors.yearfounded",
          "peinvestors.InvestorId"
          // "DealFlat.amountM",
          // "PecompanyTagCrosstabVw.tags",
          // "DealFlat.SPV"
        ],
        filters: JSON.parse(localStorage.getItem(id)),
        segments:["peinvestors.investors_seg"],
        limit: 10,
        offset: page=="1"?0:10*(page-1)
      };
      if(cubeQuery.filters.find(o => o.member === 'DealFlat.SPV') || cubeQuery.filters.find(o => o.member === 'DealFlat.investors' ) || cubeQuery.filters.find(o => o.member === 'DealFlat.Amountm') || cubeQuery.filters.find(o => o.member === 'DealFlat.Amount_inr') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountM') || cubeQuery.filters.find(o => o.member === 'DealFlat.amountInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.date') || cubeQuery.filters.find(o => o.member === 'DealFlat.exitStatus') || cubeQuery.filters.find(o => o.member === 'DealFlat.stage') || cubeQuery.filters.find(o => o.member === 'DealFlat.round') || cubeQuery.filters.find(o => o.member === 'DealFlat.priceToBook') || cubeQuery.filters.find(o => o.member === 'DealFlat.preMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePre') || cubeQuery.filters.find(o => o.member === 'DealFlat.postMoneyValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultiplePost') || cubeQuery.filters.find(o => o.member === 'DealFlat.enterpriseValuationInr') || cubeQuery.filters.find(o => o.member === 'DealFlat.revenueMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.ebitdaMultipleEv') || cubeQuery.filters.find(o => o.member === 'DealFlat.patMultipleEv')){
        cubeQuery['segments'].push(
          "Pecompanies.dealtype"
        )
      }
      else if(cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_amount') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_date') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.exit_status') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.deal_sub_type') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.company_valuation') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.revenue_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.ebitda_multiple') || cubeQuery.filters.find(o => o.member === 'exit_investment_flat.pat_multiple')){
        cubeQuery['segments'].push(
          "Pecompanies.exitsOnly"
        )
      }
      else if(cubeQuery.filters.find(o => o.member === 'peinvestments_investors.count_of_leadinvestor')){
        cubeQuery['segments'].push(
          "peinvestments_investors.lead_seg"
        )
      }
  
      return cubeQuery;
    }

    const filtersFromLocalStorage = JSON.parse(localStorage.getItem(id));

      /**
       * The handleSort function updates the sorting order and column to be sorted based on the given
       * accessor and order.
       * @param accessor - The `accessor` parameter is a value that represents the property or key of
       * the object that you want to sort by. It is used to identify the specific property that you
       * want to sort the search results by.
       * @param order - The "order" parameter is used to determine the sorting order. It can have two
       * possible values: 'asc' for ascending order and 'desc' for descending order.
       */
      const handleSort = (accessor, order) => {
        setSort((prevSort) => ({
            order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
            orderBy: accessor,
          }))
        serachResult(advancedSearchCurrentPage, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc')
      }

      const onClose = (value) => {
      console.log(value);
      const updatedData = filtersFromLocalStorage
        .map((item) => {
        if (item.values && Array.isArray(item.values)) {
          item.values = item.values.filter((val) => val !== value);

          if (item.values.length === 0) {
            return null;
          }
        }

        return item;
      })
      .filter((item) => item !== null);
    console.log(updatedData);

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));
    serachResult("1", sort.orderBy, sort.order);
    findTotalCount();
  };
  
  const deleteFilterByMember = (member) => {
    const updatedData = filtersFromLocalStorage.filter((item) => {
      return item.member !== member || (item.values && item.values.length <= 3);
    });

    console.log(updatedData, member);

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));

    serachResult("1", sort.orderBy, sort.order);
    findTotalCount();
  };
  
    return (
        <div>
            <Layout>
                <div className="mt-[3.5rem]">
                    {/* <InvestorsQueryBuilderTabs /> */}
                    {/* <TableTabTable filter={location.state.filter}/> */}
                    <FilterTags
                      filtersFromLocalStorage={filtersFromLocalStorage}
                      onClose={onClose}
                      deleteFilterByMember={deleteFilterByMember}
                    />
      {/* <h2> Table Rendering </h2> */}
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] m-3 " + (companyData?.length > 5 ? '' : 'pr-2')}>

              <table className="md:pl-5 md:pr-5 pl-5 pr-[16rem]  listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left"
                >
                    {PEVCTableHeader.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={"md:pl-5 md:pr-5 px-[1rem] py-2 pr-[11rem] "} key={i}>
                          <span className='flex'><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.label ? column.label : "--"}</a>} />
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                         </span>
                        </th>
                      )
                    })}
                  {/* <th className=' font-sans_book_body p-[1rem] text-[15px]'>Company <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button></th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'>Description</th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'>Industry</th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'>Sector</th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'>Year Founded</th> */}
                  {/* <th className=' font-sans_book_body p-[1rem] text-[15px]'>Range</th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'>Tags</th>
                  <th className=' font-sans_book_body p-[1rem] text-[15px]'> Deal Type </th>                    */}
                  {/* <th className=' font-sans_book_body p-[1rem] text-[15px]'>Region</th> */}
                </thead>
                <tbody className='text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body'>
                  {companyData ?
                 companyData.length > 0 ? (
                  companyData?.map((row, i) => {
                    return (
                      <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white " >
                         <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                         {/* {row['CfsFlat.logopath'] == '' ? 'empty' : 'No empty'} */}
                         {row["peinvestor_logo.icon_path"] == "default.png" || row["peinvestor_logo.icon_path"] == "/default.png" || !row["peinvestor_logo.icon_path"] ?
                                    <RandomLogo name={row['peinvestors.investor']} />
                                    :
                                    <CompanyLogo name={row['peinvestors.investor']} page="company page" logo={row["peinvestor_logo.icon_path"]} />
                                  }
                                 <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<NavLink className='hover:text-[#BC8B20] text-[#333333]' to={routeNames.peInvestorById(row["peinvestors.InvestorId"])}>{row['peinvestors.investor'] ? row['peinvestors.investor'] : "--"}</NavLink>}
                                   />
                                    {/* {row['']} */}
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['peinvestors.email'] ? row['peinvestors.email'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['peinvestors.city'] ? row['peinvestors.city'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <p className='cursor-text'>{row['peinvestors.yearfounded']?row['peinvestors.yearfounded']:"--"}</p></td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['peinvestors.description'] ? row['peinvestors.description'] : "--"}</a>}
                                   />
                          </td>
                          {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center"
                          >{row['Pecompanies.region']?row['Pecompanies.region']:"--"}</td> */}
                           {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.amountM']?row['DealFlat.amountM']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['PecompanyTagCrosstabVw.tags']?row['PecompanyTagCrosstabVw.tags']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.SPV']?row['DealFlat.SPV']:"--"}</td> */}

                          
                      </tr>
                    )
                  }))
                  
                   : (
                    <tr>
                      <td colSpan="5" className="py-3 text-center">No Records Found</td>
                    </tr>
                  )
                  :
                  <div className='sticky left-[50%] py-[2rem]'><Loader /></div>}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
      <center className='my-[1rem]'><Pagination defaultCurrent={1} total={totalCompany} onChange={currentPageTable}/></center>

                </div>
            </Layout>
        </div>
    )
}

export default InvestorsQueryBuilder
