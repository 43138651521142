import React from "react";
import Loader from "../../Loader";
import OverflowToolTip from "../../shared/OverflowToolTip";
import { Popover } from "antd";

/**
 * The function `InvestorProfileHighlights` renders a component that displays profile highlights for an
 * investor, including information such as news, LinkedIn profile, and website.
 * @returns The code is returning a JSX element that represents a div containing various nested divs
 * and components. The content of the returned JSX element depends on the values of the
 * `profileHighlights` and `isLoading` variables.
 */
const InvestorProfileHighlights = ({ profileHighlights, isLoading }) => {
  return (
    <div>
      <div className="lg:mt-4 px-4 w-[100%]">
        <div className="">
          <div
            className="lg:bg-white rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                <div className="px-5 py-4 ">
                  <p className=" text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Profile
                  </p>
                  <div className="  w-full bg-[#FFFFFF] ">
                    {!isLoading
                      ?
                      <div className=" w-full bg-[#FFFFFF] ">
                        <div className="grid grid-cols-2 md:grid-cols-5 border-b lg:pr-[1rem]">
                          {profileHighlights?.map((highlight, i) => {

                            return (
                              i <= 11 &&
                              <div className={i < 10 ? 'border-b' : ''} >

                                <div className=" leading-[21px] font-sans_book_body ">
                                  <OverflowToolTip component={
                                    <p className=" py-1 text-[11pt]   leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                      readOnly >
                                      {highlight.label}
                                    </p>
                                  } />
                                  {
                                    highlight.key === "news"
                                      ? highlight.value
                                        ? <Popover
                                          placement="topLeft"
                                          className=' overflow text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline '
                                          content={
                                            <a
                                              className=" overflow text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                              href={highlight.value} target="_blank" rel='noreferrer'>
                                              {highlight.value}
                                            </a>
                                          }>
                                          <a
                                            className=" overflow text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                                            href={highlight.value} target="_blank" rel='noreferrer'>
                                            Link 1
                                          </a>
                                        </Popover>
                                        : <p className=" mb-2 w-[88%] text-[11pt] break-word leading-[18pt] lg:text-[13px] text-[#333333] font-semibold ">
                                          --
                                        </p>
                                      : highlight.key === "linked_in"
                                        ? highlight.value
                                          ? <OverflowToolTip className="max-w-[12rem] 2xl:max-w-[90%] hover:text-[#BC8B20]" component={
                                            <a className="mb-2 w-[88%] text-[11pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] hover:text-[#BC8B20] font-semibold" target="_blank" href={highlight.value} rel="noreferrer" >
                                              {highlight.value}
                                            </a>
                                          } />
                                          : <p className=" mb-2 w-[88%] text-[11pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] font-semibold ">
                                            --
                                          </p>
                                        : <p className=" mb-2 w-[88%] text-[11pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] font-semibold "
                                          readOnly >
                                          {highlight.value ? highlight.label === "Website" ? <OverflowToolTip className="hover:text-[#BC8B20]" component={<a target="_blank" className="hover:text-[#BC8B20] text-[#333333]" href={highlight.value} rel="noreferrer" >{highlight.value}</a> }/> : highlight.value == 0 ? "--" : highlight.value : "--"}
                                        </p>
                                  }

                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div className="grid grid-cols-1">
                          {profileHighlights?.map((highlight, i) => {

                            return (
                              i > 11 &&
                              <div className="" >

                                <div className=" leading-[21px] font-sans_book_body ">
                                  <OverflowToolTip component={
                                    <p className=" py-1 text-[11pt]   leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                      readOnly >
                                      {highlight.label}
                                    </p>
                                  } />
                                  
                                  <p className=" mb-2 w-[92%] text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold "
                                    readOnly >
                                    {highlight.value ? highlight.label === "Website" || highlight.label === "LinkedIn" ? <a target="_blank" href={highlight.value} >{highlight.value}</a> : highlight.value == 0 ? "--" : highlight.value : "--"}
                                  </p>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                      </div>

                      : <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center ">
                        <Loader />
                      </div>}
                  </div>
                </div>
              </div>
              {/* <!--first half end--> */}

              {/* <!--end--> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default InvestorProfileHighlights;