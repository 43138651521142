import React from 'react'
import M_ALayout from '../../Components/Layout'
import RefineSearch from '../../Components/RefineSearchComponent'
import M_ADealDetail from '../../Components/MA/DealPage/M_ADealDetail'
import { COMPANY_PROFILE} from '../../Queries/Pe'
import ChatPopup from '../../Components/ChatPopup'
import chatPopupIcon from '../../images/chatPopupIcon.png'
import DealCard from '../../Components/MA/DealCard/Card'

/**
 * The `M_ADealPage` function is a React component that renders a layout with a primary and secondary
 * column, and includes a deal card and a refine search component.
 * @returns The function `M_ADealPage` is returning JSX code, which represents the structure and
 * content of a React component.
 */
const M_ADealPage = () => {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div>
        <M_ALayout>
        <div className="flex h-screen flex-col">
                <DealCard />
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */} 
                    <section
                        aria-labelledby="primary-heading"
                        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto  lg:order-last">
                        <M_ADealDetail COMPANY_PROFILE={COMPANY_PROFILE}  />
                        
                    {/* <Outlet /> */}
                    {/* Your content */}
                    </section>
                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch />
                        </div>
                    </aside>
                </div>
                <br/>
                
            </div>
        </M_ALayout>
    </div>
    )
}

export default M_ADealPage