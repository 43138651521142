import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'

/**
 * The `CompanyTypeFilter` function is a React component that renders a filter for selecting company
 * types, with options for single or multiple selection.
 * @returns a JSX element.
 */
function CompanyTypeFilter({ open, accordionClick, setCompanyTypeOptions, CompanyTypeOptions, customPathname,isMulti, label="Company Type" }) {

    const { companyType, refineSearchFilters } = useContext(PeFilterContext);
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.companyType?.map(value => value.id) ?? [];
        console.log('industryIds', refineSearchFilters[customPathname]?.companyType);
        setCompanyTypeOptions(companyType?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [companyType, customPathname, refineSearchFilters, setCompanyTypeOptions])


    const handleCompanyTypeChange = (e) => {
        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setCompanyTypeOptions(CompanyTypeOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id == value })))
    }

    const handleMultiCompanyTypeChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentCompanyType = CompanyTypeOptions.map((cType) =>
                ({ ...cType, isChecked: checked })
            );
            setCompanyTypeOptions(currentCompanyType);
        } else {
            let currentCompanyType = CompanyTypeOptions.map((cType) =>
                cType.id === parseInt(id) ? { ...cType, isChecked: checked } : cType
            );
            setCompanyTypeOptions(currentCompanyType);
        }
    });
    const len = CompanyTypeOptions?.filter(option => option.isChecked)?.length ?? 0
    const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={isMulti ? label : selectedCompanyType?.name ? ` ${label} - ` + selectedCompanyType?.name : label}
                    count={len}
                    onClick={accordionClick}
                    open={open}                    
                />
            {!isMulti ?
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selectedCompanyType?.id ?? ""} onChange={handleCompanyTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFFFFF] w-full">
                        {CompanyTypeOptions && CompanyTypeOptions.length > 0 ? (
                            CompanyTypeOptions.map((companyType, index) => (
                                <option key={companyType.id} value={companyType.id}>{companyType.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>
                :
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleMultiCompanyTypeChange}
                        primaryEntity={CompanyTypeOptions}
                    /> : <></>}

                </div>
            }

            </li>

        </div>
    )
}

export default CompanyTypeFilter