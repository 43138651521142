import { React, useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import ReactReadMoreReadLess from "react-read-more-read-less";
import ProfileHighlight from '../DetailsPage/ProfileHighlights'
import Funding from '../DetailsPage/Funding'
import MergerAcquisition from '../../../Components/DetailPage/MergerAcquisition'
import Financials from '../../../Components/DetailPage/Financials'
import CapTable from '../../../Components/DetailPage/CapTable'
import SimilarCompanies from '../../../Components/DetailPage/SimilarCompanies'
import NewsActivity from '../../../Components/DetailPage/NewsActivity'
import { descriptionCharLimit, exportPdf, exportExcel, getCfsDetailsApi, getCfsProfileApi, getProfileUrl } from "../../../Config/config";
import barRaiser from '../../../images/barraiser-logo.png'
import ComparePopUp from '../../../Pages/ComparePopUp'
import compare from '../../../images/compare.png'
import pdf from '../../../images/pdf.png'
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext'
import AddNote from "../../../Pages/addNote";
import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from "../../../Components/SendDealPopUp";
import PdfDownloader from "../../shared/PdfDownloader";
import XLSPopup from "../../DetailPage/DownloadXLS"
import Constants from "../../../Constants";
import { CurrencyContext } from '../../../Contexts/CurrencyContext'
import { Tooltip } from "antd";
import FundingDataProvider from '../../../Contexts/FundingDataProvider';
import axios from "axios";
import CompanyLogo from "../../CompanyLogo";
import RandomLogo from "../../RandomLogo";
import routePathSegments, { routeNames } from '../../../routeSegments';
import { UserContext } from '../../../Contexts/UserContext';
import AddNotePopover from '../../shared/AddNotePopover';
import Tab from '../../shared/Tab';
import ContentLoader, { Facebook } from 'react-content-loader'
import './Profile.css'
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TableDataContext } from '../../../Contexts/TableDataContext';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';

/**
 * The CompanyDetail function is  component that displays detailed information about a company,
 * including its profile, financials, funding, mergers and acquisitions, cap table, similar companies,
 * and news activity.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `CompanyDetail` component. These properties can be accessed using dot notation, such as
 * `props.companyId` or `props.setCurrentPage`.
 */
const CompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' }); }
    const financial = () => { document.getElementById("financial").scrollIntoView({ behavior: 'smooth' }); }
    const funding = () => { document.getElementById("fund").scrollIntoView({ behavior: 'smooth' }); }
    const merger = () => { document.getElementById("merge").scrollIntoView({ behavior: 'smooth' }); }
    const capTable = () => { document.getElementById("cap").scrollIntoView({ behavior: 'smooth' }); }
    const similarCompanies = () => { document.getElementById("similar").scrollIntoView({ behavior: 'smooth' }); }
    const newsActivity = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const { companyId } = useParams();
    let companyProfileId = parseInt(companyId)
    const { companyDetail, setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);
    const { currencyType, setCurrencyType, currency, setCurrency, isDetailed, setIsDetailed } = useContext(CurrencyContext);
    const [note, setNote] = useState(false)
    const [showNote, setShowNote] = useState(false)
    const [profileData, setProfileData] = useState([])
    const [highlightsData, setHighlightsData] = useState([])
    const [shareHoldingPatternData, setShareHoldingPatternData] = useState([])
    // const [mergerAcquisitionData, setMergerAcquisitionData] = useState({})
    const [newsLetterData, setNewsLetterData] = useState({})
    // const [news, setNews] = useState({})
    // const [publishedAt, setPublishedAt] = useState({})
    // const [similarCompaniesData, setSimilarCompaniesData] = useState({})
    // const [fudingData, setFundingData] = useState({})
    const [topProfile, setTopProfile] = useState([])
    const location = useLocation();
    const { getToken, isStudent } = useContext(UserContext);
    const [skipScroll, setSkipScroll] = useState(false)
    const { capTableDataFound, similarCompaniesDataFound, newsAndActivityDataFound} = useContext(DataFoundContext);

    /* Below code is using the `useInView` hook to track the visibility of an element on the page. */
    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [financialsEl, isfinancialVisible, financialEntry] = useInView({ threshold: [0.2,0.5], rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundingEl, isfundingVisible, fundingEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [mergerEl, ismergerVisible, mergerEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [captableEl, iscaptableVisible, captableEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [similarCompaniesEl, issimilarCompaniesVisible, similarCompaniesEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    
    // console.log(isOverviewVisible, iscaptableVisible, isfinancialVisible, "ismergerVisible")


    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);
    const vcMatch = matchPath(`${routePathSegments.vc}/*`, location.pathname);
    const cfsMatch = matchPath(`${routePathSegments.cfs}/*`, location.pathname);
    const maMatch = matchPath(`${routePathSegments.cfs}/*`, location.pathname);

    // const getDetails = () => {
    //     axios(`${getCfsDetailsApi}`, {
    //       method: "POST",
    //       data: {
    //       company_id : [companyProfileId],
    //       type       : ["recent_news","similar_companies","merge_acquisitions","funding"]
    //         },      
    //       headers: {"Content-Type": "application/json"},
    //     })
    //       .then((response) => {          
    //         if (response?.data?.status == 200) {
    //             setNewsLetterData(response?.data?.recent_news)
    //             setSimilarCompaniesData(response?.data?.similar_companies)                               
    //         }
    //       })
    //       .catch((error) => {        
    //         console.log("API not working", error);
    //       });

    //   };
    const getProfile = () => {
        axios(`${getProfileUrl}`, {
            method: "POST",
            data: {
                company_id: companyProfileId,
                type: peMatch != null
                    ? Constants.primaryMasterIds.peId
                    : vcMatch != null
                        ? Constants.primaryMasterIds.vcId
                        : cfsMatch != null
                            ? Constants.primaryMasterIds.cfsId
                            : maMatch != null
                                ? Constants.primaryMasterIds.mANDaId
                                : Constants.primaryMasterIds.reId
            },

            headers: { "Content-Type": "application/json", "Authorization": getToken() },
        })
            .then((response) => {
                if (response?.data?.success == true) {
                    setTopProfile(response?.data?.data?.topProfile)
                    console.log(response?.data?.data?.topProfile, "top-topProfile")
                    setProfileData(response?.data?.data?.profile)
                    //   console.log(response?.data?.data?.profile[0],"profilesdata")

                    setHighlightsData(response?.data?.data?.highlights)

                    setShareHoldingPatternData(response?.data?.data?.shareholdingPattern)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });

    };
    //   console.log(profileData, "profiledata")
    //   console.log(highlightsData, "highlights")
    //   console.log(shareHoldingPatternData, "shareHoldingPatternData")
    //   console.log(newsLetterData, "cfsnewsletter")
    //   console.log(similarCompaniesData, "cfssimilarcompanies")

    useEffect(() => {
        getProfile()
        // getDetails()               
    }, [companyProfileId]);
    //  let CompanysLogo ='';
    //  let longText ='';
    //  let companyName='';
    //  let Industry='';
    //  let Sector='';
    //  console.log(topProfile,"topProfile")
    //   topProfile?.map(long => {

    //     if (long.label=="Logo"){

    //         CompanysLogo= (long.value == "" ? "default.png" :long.value)
    //     }
    //     if(long.label=="Busniess Description")
    //     {
    //         longText=long?.value
    //     }
    //     else if(long.label=="Company Name"){
    //         companyName=long?.value
    //     }
    //     else if(long.label=="Industry"){
    //         Industry=long?.value!= null ? long?.value :''
    //     }
    //     else if(long.label=="Sector"){
    //         Sector=long?.value!= null ? long?.value :''
    //     }
    //  })
    const charLength = descriptionCharLimit;

    let showSimilarCompany = false;

    const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

    if (allowedModulesList.includes('pe') || allowedModulesList.includes('vc')) {
        showSimilarCompany = true;
    } else {
        showSimilarCompany = false;
    }

    /**
     * The `onTabClick` function is used to handle tab clicks and perform different actions based on
     * the selected tab index.
     * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
     * is used to determine which tab was clicked and perform the corresponding action.
     */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            financial();
        } else if (tabIndex === 3) {
            funding();
        } else if (tabIndex === 4) {
            merger()
        } else if (tabIndex === 5) {
            capTable()
        } else if (tabIndex === 6) {
            similarCompanies()
        } else if (tabIndex === 7) {
            newsActivity();
        }

        setIsActive(tabIndex);
    }
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } 
        else if (isfinancialVisible)  {    
            setIsActiveOnScroll(2);
        }
         else if (isfundingVisible) {
            setIsActiveOnScroll(3);
        } 
        else if (ismergerVisible) {
            setIsActiveOnScroll(4);
        } 
        else if (iscaptableVisible) {
            setIsActiveOnScroll(5);
        } 
        else if (issimilarCompaniesVisible) {
            setIsActiveOnScroll(6);
        }
         else if (isnewsVisible) {
            setIsActiveOnScroll(7);
        }
       

        // console.log(isfinancialVisible,isfundingVisible,"isfinancialVisible");
    }, [isOverviewVisible,isfinancialVisible, iscaptableVisible, isfundingVisible, ismergerVisible, isnewsVisible, issimilarCompaniesVisible, setIsActiveOnScroll])

//  useEffect(() => {

//         let sections = document.querySelectorAll('.details-page section')
//         let tabs = document.querySelectorAll(".detail-tabs span")
//         let page = document.querySelector(".details-screen")
//         let profile = document.querySelector(".profile-detail")
//         console.log("sections", sections);

//         const onScroll = (e) => {
            
//             sections?.forEach(section => {
//                 let top = e.target.scrollTop;
//                 let offset = section.offsetTop - profile?.offsetTop;
//                 offset = isNaN(offset - 28.0) ? offset : offset - 28.0
//                 let height = section.offsetHeight;
//                 let id = section.getAttribute("id");
//                 // console.log("sections-scroll",id, top,offset ,  height,top >= offset && top < offset + height);
//                 if (top >= offset && top < offset + height) {
//                     if (id === "profile") {
//                         setIsActive(1);
//                     } else if (id === "financial") {
//                         setIsActive(2);
//                     } else if (id === "fund") {
//                         setIsActive(3);
//                     } else if (id === "merge") {
//                         setIsActive(4);
//                     } else if (id === "cap") {
//                         setIsActive(5);
//                     } else if (id === "similar") {
//                         setIsActive(6);
//                     } else if (id === "news") {
//                         setIsActive(7);
//                     }
//                 }else{
//                     if(top === 0){
//                         setIsActive(1);
//                     }
//                 }

//             })
//         }

//         page.addEventListener("scroll",onScroll)


//         return ()=>{
//             page.removeEventListener("scroll",onScroll)
//         }

//     }, [])

    const MyLoader = () => (
        <ContentLoader viewBox="0 0 350 40">
            <rect x="0" y="0" rx="5" ry="5" width="30" height="30" />
            <rect x="40" y="1" rx="4" ry="0" width="150" height="5" />
            <rect x="40" y="10" rx="3" ry="1" width="100" height="5" />
            <rect x="40" y="20" rx="2" ry="2" width="100" height="5" />
        </ContentLoader>
      )
    
    const { tableCompanyId: cmpId } = useContext(TableDataContext);

    console.log("ddl", cmpId);

    const currentIndex = cmpId?.indexOf(Number(companyId));

    console.log("curr", currentIndex);

    return (
        <><div className='overflow-y-auto details-screen'>
            <div className="bg-[#FFFFFF] profile-detail relative">
                <nav className=" flex p-1 px-4 lg:px-1 border-b " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={"/cfs"} className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]">
                                    CFS
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] ">/</span>
                                <span href="#" className="px-1 text-[11pt] lg:text-[12px]  text-[#666666] leading-[14px] font-sans_book_body font-semibold"
                                > {topProfile?.company_name}</span>
                            </div>
                        </li>
                    </ol>
                </nav>
                {cmpId && 
                    <>
                            <Tooltip title="Prev">
                            <NavLink
                                className="absolute top-2 right-8"
                                to={routeNames.cfsCompanyPageById(cmpId[currentIndex - 1])}
                                >
                                {cmpId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                                className="absolute top-2 right-4"
                                to={routeNames.cfsCompanyPageById(cmpId[currentIndex + 1])}
                                >
                                {cmpId.length - 1 === currentIndex ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                    )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                {/* <!-- BarRaiser--> */}
                <div className="px-5 ">
                    <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                        <div className="md:col-span-6 col-span-5" title="Compare Company">
                            <button
                                className={
                                    showModal
                                        ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                        : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                }
                                type="button"
                                onClick={() => {
                                    setShowModal(!showModal)
                                    setCompanyDetail([])
                                    setCurrentPage("cfs")
                                }
                                }
                            >
                                <img
                                    className={
                                        showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                    }
                                    src={compare}
                                    alt=""
                                />
                                <span>Compare</span>
                            </button>
                        </div>
                        <div className="flex md:col-span-2 col-span-5 space-x-3">
                            <div >
                                <AlertPopUp cid={props.id} type_id={1} />{" "}
                            </div>
                            <div >
                                <span title="Save List">
                                    <img className=" cursor-pointer w-[24px] h-[22px]"
                                        src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                        alt=""
                                    />
                                    {showNote ? <AddNote module="cfs" page="detailpage" type_id={1} cname={topProfile?.company_name} cid={profileData?.data?.company_id} /> : ""}
                                </span>
                            </div>
                            <div className="col-span-1">
                                <PENotesPopup note={note} cid={props.id} type_id={1} />
                            </div>
                            <div className="col-span-1">
                                <SendDealPopUp data={topProfile?.company_name} />
                            </div>
                            {isStudent=='true' ? <></> :
                            <div className="col-span-1">
                                                {
                                                    props?.page == "dealPage" ? (
                                                        <XLSPopup activeTab={props?.activeTab} />
                                                    ) : (
                                                        <PdfDownloader
                                                            url={exportPdf}
                                                            fileName={topProfile?.company_name?.replaceAll(
                                                                /\s/g,
                                                                ""
                                                            )}
                                                            // data={{
                                                            //     entity_id: companyProfileId,
                                                            //     type_id: Constants.moduleIds.companiesId,
                                                            //     is_detailed: isDetailed,
                                                            //     currency_cd: currencyType,
                                                            //     display_in: currency

                                                            // }}
                                                            data={{
                                                                "module_type": "CFS",
                                                                "entity_id": companyProfileId,
                                                                "type_id": 1,
                                                                "type": 5,
                                                                "is_detailed": true,
                                                                "currency_cd": "INR",
                                                                "display_in": "C",
                                                                "sub_section": [
                                                                    "plstandard",
                                                                    "balancesheet",
                                                                    "cashflow",
                                                                    "ratio"
                                                                ]
                                                            }}
                                                        />
                                                    )
                                                }

                            </div>
                            }
                        </div>
                    </div>
                    <div className="pt-6 bg-[#FFFFFF]">
                        <ul role="list" >
                            <li className="w-full flex bg-[#FFFFFF]">
                            { topProfile?.fcompanyName && topProfile?.company_name ? <>
                                <div className=" rounded-md">
                                    {
                                        topProfile?.logo == "default.png" || topProfile?.logo == "" || topProfile?.logo == null || !topProfile?.logo?
                                            <RandomLogo name={topProfile?.company_name} page="detail page" />
                                            : <CompanyLogo name={topProfile?.company_name} id={companyProfileId} page="detail page" logo={topProfile?.logo} />
                                    }
 
                                    {/* <img src={CompanyLogo}></img> */}

                                </div>
                               
                                <div className="ml-3 flex flex-1 flex-col">
                                    <p className="text-[#333333] text-[22px] font-semibold leading-[24px]" >
                                        {topProfile?.company_name} {" "}<span className='text-[18px] leading-[20px]'>{'('+topProfile?.fcompanyName+')'}</span>
                                    </p>
                                    <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">
                                        {topProfile?.industry}
                                    </span>
                                    <span className="text-[11pt] leading-[15pt]  text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">
                                        {topProfile?.sector}
                                    </span>
                                </div>
                                </>
                                :<div className='w-[75%]'><MyLoader /></div>
                                }
                                <div className="hidden lg:grid grid-cols-8 gap-2">
                                    <div className="col-span-3 mt-2">
                                        <Tooltip title="Compare Company" >
                                            <button
                                                className={
                                                    showModal
                                                        ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"
                                                        : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                                }
                                                type="button"
                                                onClick={() => {
                                                    setShowModal(!showModal)
                                                    setCompanyDetail([])
                                                    setCurrentPage("cfs")
                                                }
                                                }
                                            >
                                                <img
                                                    className={
                                                        showModal
                                                            ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                            : "w-[20px] h-[18px] pr-1 blur-btn"
                                                    }
                                                    src={compare}
                                                    alt=""
                                                />
                                                <span>Compare</span>
                                            </button>
                                        </Tooltip>
                                    </div>

                                    <div className="col-span-5 mt-[5px]">
                                        <div className="grid grid-cols-5 gap-1 flex items-center">
                                            <div className="col-span-1 ">
                                                <AlertPopUp cid={companyProfileId} type_id={1} />{" "}
                                            </div>
                                            <div className="col-span-1">
                                                <AddNotePopover
                                                    title="Save List"
                                                    companyId={companyProfileId}
                                                    companyName={topProfile?.company_name ?? ""}
                                                    type_id={Constants.moduleIds.companiesId} 
                                                    IsDetailed={true}
                                                    sub_type_master_id={Constants.subCategoryIds.cfs_id}
                                                    />
                                                {/* <span>
                                                    <Tooltip title="Save List">
                                                        <img className="w-[22px] h-[22px] cursor-pointer"
                                                            src={showNote ? addNotedActive : addNoted}
                                                            onClick={() => setShowNote((showNote) => !showNote)}
                                                            alt=""
                                                        />
                                                    </Tooltip>
                                                    {showNote ? <AddNote module="cfs" page="cfsdetail" type_id={1} cname={topProfile?.company_name} cid={companyProfileId} /> : ""}
                                                </span> */}
                                            </div>
                                            <div className="col-span-1 mt-1">
                                                <PENotesPopup note={note} cid={companyProfileId} type_id={1} />
                                            </div>
                                            <div className="col-span-1">
                                                <SendDealPopUp data={topProfile?.company_name} />
                                            </div>
                                            {isStudent=='true' ? <></> :
                                            <div className="col-span-1">
                                                {
                                                    props?.page == "dealPage" ? (
                                                        <XLSPopup activeTab={props?.activeTab} />
                                                    ) : (
                                                        <PdfDownloader
                                                            url={exportPdf}
                                                            fileName={topProfile?.company_name?.replaceAll(
                                                                /\s/g,
                                                                ""
                                                            )}
                                                            // data={{
                                                            //     entity_id: companyProfileId,
                                                            //     type_id: Constants.moduleIds.companiesId,
                                                            //     is_detailed: isDetailed,
                                                            //     currency_cd: currencyType,
                                                            //     display_in: currency

                                                            // }}
                                                            data={{
                                                                "module_type": "CFS",
                                                                "entity_id": companyProfileId,
                                                                "type_id": 1,
                                                                "type": 5,
                                                                "is_detailed": true,
                                                                "currency_cd": "INR",
                                                                "display_in": "C",
                                                                "sub_section": [
                                                                    "plstandard",
                                                                    "balancesheet",
                                                                    "cashflow",
                                                                    "ratio"
                                                                ]
                                                            }}
                                                        />
                                                    )
                                                }

                                            </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- tags--> */}
                    <div className="bg-[#FFFFFF]">
                        <div >
                            <p className="text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                {topProfile?.business_desc ?
                                    <ReactReadMoreReadLess
                                        charLimit={charLength}
                                        readMoreText={"View More "}
                                        readLessText={"View Less "}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                    >
                                        {topProfile?.business_desc}

                                    </ReactReadMoreReadLess>
                                    : ""}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="bg-[#EDEDED]">
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={isActive === 2} label={"Financials"} onClick={() => onTabClick(2)} />
                    <Tab active={isActive === 3} label={"Funding"} onClick={() => onTabClick(3)} />
                    <Tab active={isActive === 4} label={"Mergers & Acquisitions"} onClick={() => onTabClick(4)} />                    
                    {capTableDataFound ? <Tab active={isActive === 5} label={"Cap Table"} onClick={() => onTabClick(5)} />: ''}
                    {similarCompaniesDataFound ? showSimilarCompany && <Tab active={isActive === 6} label={"Similar Companies"} onClick={() => onTabClick(6)} /> : ""}
                    {newsAndActivityDataFound ? <Tab active={isActive === 7} label={"Recent News & Activity"} onClick={() => onTabClick(7)} /> : ""}
                </div>
                <div className="h-full space-y-5 details-page">
                    <section ref={overviewEl}    id="profile"   className="scroll-mt-14" ><ProfileHighlight Profile={profileData} Highlights={highlightsData} ShareHolding={shareHoldingPatternData} /></section>
                    <section ref={financialsEl}  id="financial" className="scroll-mt-14" ><Financials fcompanyName={topProfile?.fcompanyName} companyName={topProfile?.fcompanyName} profileData={topProfile}/></section>
                    <section ref={fundingEl}     id="fund"       className="scroll-mt-14" ><FundingDataProvider><Funding menu="cfs" companyName={topProfile?.company_name} /></FundingDataProvider></section>
                    <section ref={mergerEl}      id="merge"      className="scroll-mt-14" ><MergerAcquisition companyName={topProfile?.company_name} /></section>
                    <section ref={captableEl}    id="cap"      className="scroll-mt-14" ><CapTable companyName={topProfile?.company_name} /></section>
                    {showSimilarCompany && <section ref={similarCompaniesEl} id="similar" className="scroll-mt-14" ><SimilarCompanies companyName={topProfile?.company_name} /></section>}
                    <section ref={newsEl} id="news" className="scroll-mt-14" ><NewsActivity NewsActivity={newsLetterData} /></section>
                </div>
            </div>
            {showModal ? (<ComparePopUp id={companyProfileId} onClose={(e) => setShowModal(false)} />) : null}
        </div>
        </>
    )

}

export default CompanyDetail;
