import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import { useParams, matchPath, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Tooltip } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import AdvisorOverview from './AdvisorOverview'
import AdvisorCompaniesInvestments from './AdvisorCompanies_Investments'
import AdvisorCompaniesExits from './AdvisorCompanies_Exits'
import AdvisorTo_Investors from './AdvisorTo_Investors'
import AdvisorTo_Acquirer from './AdvisorTo_Acquirer'
import AdvisorNewsActivity from './AdvisorNewsActivity'
import { getReAdvisorDetail, exportPdf } from '../../../Config/config'
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import AddNotePopover from "../../shared/AddNotePopover";
import RandomLogo from "../../RandomLogo";
import CompanyLogo from "../../CompanyLogo";
import Constants from "../../../Constants";
import routePathSegments, { routeNames } from "../../../routeSegments";
import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from "../../SendDealPopUp";
import AddNote from "../../../Pages/addNote"
import PdfDownloader from "../../shared/PdfDownloader";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import Tab from '../../shared/Tab';
import useModule from '../../../hooks/useModule';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";

const AdvisorCompanyDetail = (props ,section) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' });; }
    const exits = () => { document.getElementById("exits").scrollIntoView({ behavior: 'smooth' });; }
    const funds = () => { document.getElementById("funds").scrollIntoView({ behavior: 'smooth' });; }
    const toAcquirer = () => { document.getElementById("acquirer").scrollIntoView({ behavior: 'smooth' });; }
    const similarCompanies = () => { document.getElementById("news").scrollIntoView(); }    
    const [isActive, setIsActive] = useState(1);
    const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
    const location = useLocation();    
    const [showNote, setShowNote] = useState(false)
    // const [open, setOpen] = useState()
    const { getToken, isStudent } = useContext(UserContext);
    const {primaryMasterId} = useModule();
    const [profileHighlights, setProfileHighlights] = useState([]);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [isCompanyExit, setIsCompanyExit] = useState([])
    const [companyInvestment, setCompanyInvestment] = useState([])
    const [advisorAcquirer, setAdvisorAcquirer] = useState([]);
    const [toInvestor, setToInvestor] = useState([])
    const [advisorNews, setAdvisorNews] = useState([])
    const [skipScroll, setSkipScroll] = useState(false)
    const { newsAndActivityDataFound, setNewsAndActivityDataFound } = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toCompanyInvestmentEl, isToCompanyInvestmentVisible, tocompanyInvestmentEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toCompanyExitEl, istoCompanyExitVisible, toCompanyExitEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toInvestorEl, istoInvestorVisible, toInvestorEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toAcquirerEl, istoAcquirerVisible, toAcquirerEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    


   /**
    * The function `sendDetails` sends a POST request to a specified endpoint with certain parameters
    * and headers, and then sets various state variables based on the response data.
    */
    const sendDetails = () => {
        axios.post(getReAdvisorDetail, {
            "advisor_id": advisorProfileId,
            "advisor_type": "L",
            "type":primaryMasterId

        }, {
            headers: { "Authorization": getToken() }
        }).then(response => {
            if (response.status === 200) {
                setProfileHighlights(response.data?.advisor_detail?.advisor_profile)
                setIsCompanyExit(response.data?.advisor_detail?.to_exits_investment)
                setAdvisorAcquirer(response.data?.advisor_detail?.to_acquirer_investment)
                setToInvestor(response.data?.advisor_detail?.to_investors_investment)
                setCompanyInvestment(response.data?.advisor_detail?.to_companies_investment)
                setIsProfileLoading(false);
                if(!response.data?.advisor_detail?.news_letter || response.data?.advisor_detail?.news_letter.length === 0){
                    setNewsAndActivityDataFound(false)
                } else {
                    setNewsAndActivityDataFound(true)
                    setAdvisorNews(response.data?.advisor_detail?.news_letter)
                }
                // setOpen(false)
            }
        }).catch(error => {
            setNewsAndActivityDataFound(false)
        })
    }


    useEffect(() => {
        sendDetails();
        return () => {
            setNewsAndActivityDataFound(false)
        }

    }, [advisorProfileId])

    const menuRef = useRef();

    const reMatch = matchPath(`${routePathSegments.re}/*`, location.pathname);
   /**
    * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
    * selected tab index.
    * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
    * is used to determine which action to perform based on the clicked tab.
    */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            investments();
        } else if (tabIndex === 3) {
            exits()

        } else if (tabIndex === 4) {
            funds();
        
        } else if (tabIndex === 5){
            toAcquirer()
        }
        else if (tabIndex === 6) {
            similarCompanies()
        } 

        setIsActive(tabIndex);
    }

   /* The above code is using the `useMemo` hook in React to create a memoized version of the
   `setIsActiveOnScroll` function. This function is using the `debounce` function to delay the
   execution of the `setIsActive` function by 100 milliseconds. The `setIsActive` function is a
   callback function that takes an index as an argument and updates the state to set the active
   index. */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isToCompanyInvestmentVisible) {
            setIsActiveOnScroll(2);
        } else if (istoCompanyExitVisible) {
            setIsActiveOnScroll(3);
        } else if (istoInvestorVisible) {
            setIsActiveOnScroll(4);
        } else if (istoAcquirerVisible) {
            setIsActiveOnScroll(5);
        
        } else if (isnewsVisible) {
            setIsActiveOnScroll(6);
        }
    }, [isOverviewVisible, isToCompanyInvestmentVisible, istoCompanyExitVisible, istoInvestorVisible, istoAcquirerVisible, isnewsVisible, setIsActiveOnScroll])


   /**
    * The function `subTypeMasterId` returns a sub category ID based on the value of the `section`
    * property in the `props` object.
    * @returns The function `subTypeMasterId` returns the subcategory ID based on the value of
    * `props?.section`.
    */
    const subTypeMasterId =() =>{
       
        if(props?.section == Constants.primaryMasterSubIds.re_advisor_investment_id){
          return Constants.subCategoryIds.re_advisor_investment_id
        }
        else if(props?.section == Constants.primaryMasterSubIds.re_advisor_exits_id){
          return Constants.subCategoryIds.re_advisor_exits_id
        }
        
        else if(props?.section == Constants.primaryMasterSubIds.re_advisor_otherma_id){
          return Constants.subCategoryIds.re_advisor_otherma_id
        }
       
        
      } 
       
    const {tableAdvisorId : advId} = useContext(TableDataContext);
      
    const currentIndex = advId?.indexOf(Number(advisorProfileId));

    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 border-b " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={location.state?.prevPath != null
                                    ? location.state?.prevPath
                                    : reMatch != null ? routeNames.RE_advisor :
                                        ""} className="ml-1 text-[12pt] lg:text-[12px] text-[#666666]">
                                    Advisors</NavLink>
                            </div>
                        </li>
                        <li className='flex items-center'>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <span className="text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profileHighlights?.advisor_name ? profileHighlights?.advisor_name : "--"}</span>
                            </div>
                        </li>
                    </ol>
                </nav>
                {advId && 
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                                className="absolute top-2 right-8"
                                to={routeNames.reAdvisorById(advId[currentIndex - 1])}
                                >
                                {advId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                                className="absolute top-2 right-4"
                                to={routeNames.reAdvisorById(advId[currentIndex + 1])}
                                >
                                {advId.length - 1 === currentIndex ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                {/* <!-- BarRaiser--> */}
                {Object.keys(profileHighlights).length <= 0 ?
                    <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                        <center><div role="status" className="bg-white  h-[16rem]">
                            <svg className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                        </div></center>
                    </table>
                    :
                    <div className="pl-5 pr-3 ">
                        <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4">
                         <div className="col-span-5 mt-2"></div>
                            <div className="flex md:col-span-2 col-span-5 space-x-3">
                                <div >
                                    <AlertPopUp cid={companyId} type_id={Constants.moduleIds.l_advisor_id} page="advisor"/>
                                </div>
                                <div >
                                    {/* <OutsideClickHandler onOutsideClick={() => { setShowNote(false); }} >
                                        <span > */}
                                    <img className=" cursor-pointer w-[24px] h-[22px]"
                                        src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                        alt=""
                                    />
                                    {showNote ? <AddNote module="Advisors" page="detailpage" type_id={Constants.moduleIds.l_advisor_id} 
                                    sub_type_master_id={subTypeMasterId()}
                                    cname={profileHighlights?.advisor_name ?? ""} cid={companyId} /> : ""}
                                    {/* </span>
                                    </OutsideClickHandler> */}
                                </div>


                                <div className="col-span-1">
                                    <PENotesPopup cid={companyId} type_id={Constants.moduleIds.l_advisor_id} />
                                </div>
                                <div className="col-span-1">
                                    <SendDealPopUp data={profileHighlights?.advisor_name ?? ""} />
                                </div>
                                {isStudent == 'true' ? <></> : 
                                <PdfDownloader
                                    url={exportPdf}
                                    fileName={profileHighlights?.advisor_name?.replaceAll(/\s/g, '')}
                                    data={{
                                        entity_id: companyId,
                                        type_id: Constants.moduleIds.l_advisor_id,
                                        "module_type": "RE",
                                    }} />
                                }
                            </div>
                        </div>
                        <div className=" pb-4 pt-6 bg-[#FFFFFF]">
                            <ul role="list" >
                                <li className="w-full flex ">
                                    <div className=" rounded-md">
                                        {/* <CompanyLogo id={props.id} page="detail page"/> */}
                                        {profileHighlights?.icon_path == "default.png" || profileHighlights?.icon_path == "/default.png" || !profileHighlights?.icon_path ?
                                            <RandomLogo name={profileHighlights?.advisor_name} page="detail page" />
                                            :
                                            <CompanyLogo name={profileHighlights?.advisor_name} page="detail page" logo={profileHighlights?.icon_path} />

                                        }
                                    </div>

                                    <div className="ml-3 flex flex-1 flex-col">
                                        <span className="text-[#333333] text-[22px] font-semibold leading-[24px]" href="#"> {profileHighlights?.advisor_name ? profileHighlights?.advisor_name : "--"} </span>
                                        <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">{profileHighlights?.advisor_type ? (profileHighlights?.advisor_type == 'L' ? 'Legal Advisor' : 'Legal Advisor') : "--"}</span>
                                        {/* <span className="text-[11pt] leading-[17pt] text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">Enterprise Software (SaaS - HR Services - Recuirement)</span> */}
                                    </div>
                                    <div className="hidden lg:grid grid-cols-5 mr-4">


                                        <div className="col-span-5 mt-[5px]">
                                            <div className="grid grid-cols-5 gap-2 flex items-center">
                                                <div className="col-span-1 ">
                                                    <AlertPopUp cid={companyId} type_id={Constants.moduleIds.l_advisor_id} page="advisor"/>{" "}
                                                </div>

                                                <div className="col-span-1" ref={menuRef}>
                                                    <AddNotePopover
                                                        title="Save List"
                                                        companyId={companyId}
                                                        companyName={profileHighlights?.advisor_name ?? ""}
                                                        type_id={Constants.moduleIds.l_advisor_id} IsDetailed={true} 
                                                        sub_type_master_id={subTypeMasterId()}/>

                                                </div>
                                                <div className="col-span-1 mt-1">
                                                    <PENotesPopup cid={companyId} type_id={Constants.moduleIds.l_advisor_id} />
                                                </div>
                                                <div className="col-span-1">
                                                    <SendDealPopUp data={profileHighlights?.advisor_name ?? ""} />
                                                </div>
                                                {isStudent == 'true' ? <></> :
                                                <div className="col-span-1">

                                                    <PdfDownloader
                                                        url={exportPdf}
                                                        fileName={profileHighlights?.advisor_name?.replaceAll(/\s/g, '')}
                                                        data={{
                                                            entity_id: companyId,
                                                            type_id: Constants.moduleIds.l_advisor_id,
                                                            module_type: "RE",
                                                            type: 3
                                                        }} />
                                                </div>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- tags--> */}
                        <div className="bg-[#FFFFFF]">



                        </div>

                    </div>
                }


            </div>
            <div className="bg-[#EDEDED] ">
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={isActive === 2} label={"To Companies (Investments)"} onClick={() => onTabClick(2)} />
                    <Tab active={isActive === 3} label={"To Companies (Exits)"} onClick={() => onTabClick(3)} />
                    <Tab active={isActive === 4} label={"To Investors"} onClick={() => onTabClick(4)} />
                    <Tab active={isActive === 5} label={"To Acquirers"} onClick={() => onTabClick(5)} />
                    {newsAndActivityDataFound ? <Tab active={isActive === 6} label={"Recent News & Activity"} onClick={() => onTabClick(6)} /> : ""}
                </div>
                <div className="space-y-5">
                    <section  ref={overviewEl} className="scroll-mt-14" id="profile"><AdvisorOverview isLoading={isProfileLoading} profileHighlights={profileHighlights} /></section>
                    <section ref={toCompanyInvestmentEl} className="scroll-mt-14" id="investments"><AdvisorCompaniesInvestments companyInvestment={companyInvestment} advisorName={profileHighlights?.advisor_name} /></section>
                    <section ref={toCompanyExitEl} className="scroll-mt-14" id="exits"><AdvisorCompaniesExits companyExits={isCompanyExit} advisorName={profileHighlights?.advisor_name} /></section>
                    <section ref={toInvestorEl} className="scroll-mt-14" id="funds"><AdvisorTo_Investors toInvestor={toInvestor} advisorName={profileHighlights?.advisor_name} /></section>
                    <section ref={toAcquirerEl} className="scroll-mt-14" id="acquirer"><AdvisorTo_Acquirer toAquirer={advisorAcquirer} advisorName={profileHighlights?.advisor_name} /></section>
                    <section ref={newsEl} className="scroll-mt-14" id="news"><AdvisorNewsActivity newsActivity={advisorNews} /></section>
                </div>
            </div>
        </>
    )

}

export default AdvisorCompanyDetail