import React, { useContext } from 'react'
import { matchPath, useLocation } from 'react-router-dom';
import { descriptionCharLimit } from "../../Config/config";
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext';
import routePathSegments from '../../routeSegments';
import OverflowToolTip from '../shared/OverflowToolTip';

/** The above code is a React component called ValuationInfo. It renders a section that displays
valuation information and financial information. It receives data as a prop and uses that data to
populate the table with valuation information and financial information. If there is no data
available, it displays a message and a button to request the data. */
const ValuationInfo = (props) => {
    const { openRequestPopup } = useContext(RaiseRequestContext)
    console.log(props?.data?.length, "vvv")
    const location = useLocation();
    const isPeMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname)
  



    return (
        <div className=" bg-white rounded-[4px] px-5">
            <div className="">
                <p
                    className="text-[15pt] leading-[19pt] lg:text-[17px] pb-3 pl-1 text-[#333333] font-semibold lg:leading-[22px]">
                    Valuation Info</p>
            </div>
            <div
                className="overflow-x-auto scrollbar">
                {props.data?
                 props.data?.valuationInfo ==0 &&
                 props?.data?.financialInfo == 0 ?

                 <center>
                 <div className="bg-[#F3F3F3] p-3 h-[2.5rem] text-[14px] leading-[16px] font-sans_book_body text-[#333333] relative mt-[1rem] ">No data available.
                  <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isPeMatch != null ? 'valuationInfo' : 'moreDealVc')}>Click Here</button> to request.
                  </div>
              </center>  
              :

                        <div>
                            <div>

                                <div
                                    className="overflow-x-auto scrollbar">
                                    {
                                       props.data?.valuationInfo != 0?
                                        <table
                                            className="w-full mx-auto">
                                            <thead
                                                className="text-[12pt] leading-[15pt] bg-[#F3F3F3] lg:leading-[15px] lg:text-[12px] text-[#666666] font-semibold text-center whitespace-nowrap">
                                                {/* <th
                                                    className="py-2 pr-3 text-left sm:pl-6 whitespace-nowrap">
                                                </th> */}
                                                <th colSpan={2}
                                                    className="whitespace-nowrap py-2 pr-8 text-right">
                                                    Post-Money
                                                </th>

                                            </thead>
                                            {props?.data ?.valuationInfo?
                                            <tbody
                                                className="text-[12pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333]  text-center">
                                                <tr
                                                    className="border-b">
                                                    <td
                                                        className="whitespace-nowrap  font-sans_book_body py-4 px-4 text-left">
                                                        Valuation
                                                        (₹Cr)
                                                    </td>
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body  py-4 pr-8 text-right">
                                                        {props.data?.valuationInfo[0]?.post_company_valuation !=0 ?props.data?.valuationInfo[0]?.post_company_valuation : " "}
                                                    </td>
                                                </tr>
                                                <tr
                                                    className="border-b">
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body py-4 px-4 text-left">
                                                        Revenue
                                                        Multiple
                                                    </td>
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body  py-4 pr-8 text-right ">
                                                        {props.data?.valuationInfo[0]?.post_revenue_multiple !=0 ? props.data?.valuationInfo[0]?.post_revenue_multiple : " "}
                                                    </td>

                                                </tr>
                                                <tr
                                                    className="border-b">
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body py-4 px-4 text-left">
                                                        EBITDA
                                                        Multiple
                                                    </td>
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body  py-4 pr-8 text-right">
                                                        {props.data?.valuationInfo[0]?.post_ebitda_multiple !=0 ? props.data?.valuationInfo[0]?.post_ebitda_multiple : " "}
                                                    </td>

                                                </tr>
                                                <tr
                                                    className="border-b">
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body py-4 px-4   text-left">
                                                        PAT
                                                        Multiple
                                                    </td>
                                                    <td
                                                        className="whitespace-nowrap font-sans_book_body  py-4 pr-8 text-right">
                                                        {props.data?.valuationInfo[0]?.post_pat_multiple !=0 ? props.data?.valuationInfo[0]?.post_pat_multiple : " "}
                                                    </td>

                                                </tr>

                                            </tbody>
                                              :
                                              <div className="w-full bg-[#FFFFFF]">
                                                  <center><div role="status" className=" h-[8rem]">
                                                      <svg className="relative left-[17rem] top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                      </svg>
                                                  </div></center>
                                              </div>
                                                }
                                         </table>

                                        : <center>
                                            <div className="bg-[#F3F3F3] p-3 h-[2.5rem] text-[14px] leading-[16px] font-sans_book_body text-[#333333] relative mt-[1rem] ">No data available. <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isPeMatch != null ? 'valuationInfo' : 'moreDealVc')}>Click Here</button> to request.</div></center>

                                    }
                                    <div className="  font-sans_book_body py-4 px-4 w-full">
                                        { props?.data?.valuationInfo !="" ?
                                        <div className='grid grid-cols-12 items-center'>
                                            <div className='col-span-4'>
                                                <p className='text-[12pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333]  text-left'>
                                                    More Info
                                                </p>
                                            </div>
                                            <div className='col-span-8'>
                                                <p className='  valutionMore text-[12pt]  leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333]  text-center'
                                                >
                                                    <OverflowToolTip className="max-w-[15rem] xl:max-w-[17rem] 2xl:max-[20rem]" component={props?.data?.valuationInfo ?
                                                            props?.data?.valuationInfo[0]?.valuation_more_info ? props?.data?.valuationInfo[0]?.valuation_more_info : " "
                                                            : ""} />
                                                </p>
                                            </div>
                                        </div>
                                        :""}
                                    </div>
                                </div>

                            </div>

                            <div>
                                <div className="row-span-1 border-t px-5 pt-1 mb-2 overflow-x-auto"> <p className="text-[15pt]  leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mt-3 lg:leading-[22px]">
                                    Financials - FY21 (₹Cr)</p></div>
                                {props?.data?.financialInfo != 0
                                    ? <div
                                        className="w-full px-5">
                                        {/* {props?.data ? */}
                                        <div
                                            className="grid grid-cols-3 text-[12pt] leading-[16pt] text-[#333333] lg:text-[14px] lg:leading-[16px] font-sans_book_body ">

                                            <div
                                                className=" py-2 pr-3.5">
                                                <div className="grid grid-rows-2 grid-flow-col gap-2">
                                                    <div>
                                                        <p
                                                            className="whitespace-nowrap ">
                                                            Revenue
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p
                                                            className="mt-1 whitespace-nowrap font-semibold">
                                                            {props?.data?.financialInfo[0]?.revenue ? props?.data?.financialInfo[0]?.revenue : ""}

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className=" py-2 pr-3.5">
                                                <div className="grid grid-rows-2 grid-flow-col gap-2">
                                                    <div>
                                                        <p
                                                            className="whitespace-nowrap ">
                                                            EBITDA
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p
                                                            className="mt-1 whitespace-nowrap font-semibold">

                                                            {props?.data?.financialInfo[0]?.ebitda ? props?.data?.financialInfo[0]?.ebida : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className=" py-2 pr-3.5">
                                                <div className="grid grid-rows-2 grid-flow-col gap-2">
                                                    <div>
                                                        <p
                                                            className="whitespace-nowrap ">
                                                            PAT
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p
                                                            className="mt-1 whitespace-nowrap font-semibold">
                                                            {props?.data?.financialInfo[0]?.pat ? props?.data?.financialInfo[0]?.pat : ""}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                          </div>

                                    :
                                    <center>
                                    <div className="bg-[#F3F3F3] p-3 h-[2.5rem] text-[14px] leading-[16px] font-sans_book_body text-[#333333] relative mt-[1rem] ">No data available.
                                     <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup(isPeMatch != null ? 'valuationInfo' : 'moreDealVc')}>Click Here</button> to request.
                                     </div>
                                 </center>  
                                }
                             </div>
                        </div>
                         
                      
                         
                 :
                 <div className="w-full bg-[#FFFFFF]">
                     <center><div role="status" className=" h-[7rem]">
                         <svg className="relative left-[0rem] top-[3rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                             <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                         </svg>
                     </div></center>
                 </div>
                 }  
            </div>
        </div >
    )
}

export default ValuationInfo