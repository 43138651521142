import React from 'react'
import useModule from '../../hooks/useModule'
import { Navigate } from 'react-router-dom'
import routePathSegments, { routeNames } from '../../routeSegments'
import SubscribeProductPage from '../../Components/shared/SubscribeProductPage'

/**
 * The FAQLandingPage component checks the allowedModulesList and navigates to different routes based
 * on the included modules.
 * @returns The code is returning a JSX element.
 */
const FAQLandingPage = () => {

    const {allowedModulesList} = useModule()

  return (
    <div>
       { allowedModulesList.includes("pe") ?
         <Navigate to={routePathSegments.pe_faq }/> 
         :   
         allowedModulesList.includes("re") ?
         <Navigate to={routePathSegments.re_faq }/> 
         :
         allowedModulesList.includes("ma") ?
         <Navigate to={routePathSegments.ma_faq }/>
         :
         allowedModulesList.includes("cfs") ?
         <Navigate to={routePathSegments.cfs_faq }/> 
         : 
        allowedModulesList.includes("vc") ?
        <SubscribeProductPage to={routeNames.vc_companies_vc_investments} />
        :''
        }      
    </div>
  )
}

export default FAQLandingPage
