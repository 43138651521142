import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `SyndicationFilter` function is a React component that renders a dropdown menu for selecting
 * syndication options.
 * @returns a JSX element.
 */
function SyndicationFilter({ open, accordionClick, SyndicationOptions, setSyndicationOptions, customPathname }) {

    const { Syndications, refineSearchFilters } = useContext(PeFilterContext)

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.syndication?.map(value => value.value) ?? [];
        setSyndicationOptions(Syndications?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.value) })))
    }, [Syndications, customPathname, refineSearchFilters, setSyndicationOptions])

    const handleSyndicationChange = (e) => {
        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setSyndicationOptions(SyndicationOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.value === value })))
    };

    const selected = SyndicationOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Syndication - " + selected?.name : "Syndication"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selected?.value ?? ""} onChange={handleSyndicationChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {SyndicationOptions && SyndicationOptions.length > 0 ? (
                            SyndicationOptions.map((syndication, index) => (
                                <option value={syndication.value} key={index}>{syndication.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                    {/* {Syndications && Syndications.length > 0 ? (
                  Syndications.map((syndication, index) => (
                    <div className="form-check" key={index}>
                      <input type="radio" id={syndication.id} name={syndication.name} className="hidden" value="" />
                      <label className="form-check-label my-2  cursor-pointer" onClick={() => handleSyndicationChange(syndication.name, true)} >{syndication.name}</label>
                    </div>
                  ))
                ) :
                  (
                    <h1>No results found!</h1>
                  )} */}
                </div>

            </li>

        </div>
    )
}

export default SyndicationFilter