import React, { useContext, useState, Suspense } from "react";
import { PeFilterContext, ReCompaniesMaxDealOptions, ReCompaniesMinDealOptions } from "../../Contexts/PeFilterContext";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import SectorFilter from "../../Components/RefineSearchComponents/FundSector";
import TypeFilter from "../../Components/RefineSearchComponents/ReTypeFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import CityFilter from "../../Components/RefineSearchComponents/CityFilter";
import DealRangeFilter from "../../Components/RefineSearchComponents/DealRangeFilter";
import { useLocation, useMatch, useNavigate } from "react-router";
import routePathSegments, { routeNames } from "../../routeSegments";
import { useEffect } from "react";
import axios from "axios";
import { filterLocationUrl, filterSectorByIndutry } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import AcquirerDealRangeFilter from "../RefineSearchComponents/AcquirerDealRangeFilter";
import Constants from "../../Constants";
const InvestorDealRangeFilter = React.lazy(() => import("../RefineSearchComponents/InvestorDealRangeFilter"));

/**
 * The function `ReOthermaRefineSearch` is a React component that renders a form for refining search
 * filters in a specific context.
 * @returns a JSX element.
 */

export default function ReOthermaRefineSearch({ customPathname }) {
    const {
        setSectors,
        onErrorHandler,
        refineSearchFilters,
        setMonthPickerValue,
        setrefineSearchFilters } = useContext(PeFilterContext);
    const { setSelectedPage } = useContext(TableHeaderContext);
    const [select, setSelected] = useState(0);
    const navigate = useNavigate();
    // const [savedFilters, setSavedFilters] = useState(state?.filters)
    const { getToken } = useContext(UserContext);
    const isMatch = useMatch({ path: routeNames.re_companies_re_investments })
    const isRematch = useMatch({ path: `${routePathSegments.re}/*` })
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false


    //local updated options
    const [SectorOptions, setSectorOptions] = useState([])
    const [TypeOption, setTypeOption] = useState([]);
    const [RegionOptions, setRegionOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    // const [DealRange, setDealRange]                     = useState({ from: "", to: "" })
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])



    const applyFilter = () => {
        const selectedFundSector = SectorOptions.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedType = TypeOption?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],

                fundSector: selectedFundSector,
                region: selectedRegion,
                city: selectedCity,
                type: selectedType,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],

                // dealRange   : DealRange?.from !== "" && DealRange?.to !== "" ? [{ id: 0, name: DealRange?.from }, { id: 1, name: DealRange?.to }] : [],

            },
        }))
    }

    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    console.log('refineSearchFilters[refineSearchFilters]', refineSearchFilters[customPathname]);


    // Submitting Refine Search Form 

    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        setSelectedPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }


    const onResetClick = (event) => {
        event.preventDefault();
        setSelectedPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.re_companies_re_investments);
        // }
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                // setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    useEffect(() => {
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])



    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter" >


                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className="text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]">

                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                            <TypeFilter
                                TypeOption={TypeOption}
                                setTypeOption={setTypeOption}
                                customPathname={customPathname}
                                dataPath="re_exits"
                                accordionClick={() => accordionClick(31)}
                                open={select === 31} />

                            <AcquirerDealRangeFilter
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealrange={ReCompaniesMaxDealOptions}
                                MinDealRange={ReCompaniesMinDealOptions}
                                label="Deal Range (US $M)"
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                open={select === 15}
                            />

                        </ul>
                    </main>
                    <div className="px-3  py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
        </div>
    );
}

