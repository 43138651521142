import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext'
import Logo from '../images/vi-logo.png';
import Laptop from '../images/laptop.png';
import RingTop from '../images/ring1.png';
import RingBottom from '../images/ring3.png';
import Mail from '../images/mailicon.png';
import Lock from '../images/lock-icon.png';
import { routeNames } from '../routeSegments';
import LogoutPopup from '../Components/Login/LogoutPopup';
import { createDeviceAuthUrl,getPECompaniesApi } from '../Config/config';
import {ToastContainer, toast } from 'react-toastify';

const Login = () => {
    let redirectTo = useNavigate();
    const { login, isLogging, forgotPassword } = useContext(UserContext);
    const [loginErrorMsg, setLoginErrorMsg] = useState();
    const [forgotErrorMsg, setForgotErrorMsg] = useState();
    const [selectedProduct, setSelectedProduct] = useState("1");
    const [logoutPopupData, setLogoutPopupData] = useState({ show: false, browserList: [], onClick: () => { } })
    const [isforgotPasswordClick,setIsForPasswordClick] = useState(false)

    //useForm
    const { register, handleSubmit, handleForgotSubmit,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        defaultValues: {
            email: "",
            password: "",
            forgotEmail: ""
        }
    });


    // useEffect(() => {
    //     // POST request using axios inside useEffect React hook
    //     const companyRequst = {
    //         "date_range": {
    //             "from_date": "1998-01-01",
    //             "to_date": "1998-12-31"
    //         },
    //         "order_by": {
    //             "column": "PECompanyId",
    //             "direction": "asc",
    //             "level": "parent"
    //         },
    //         "industry": {
    //             "id": [9]
    //         },
    //         "per_page": 3,
    //         "page": 1
    //     };
    //     // axios.post('https://reqres.in/api/articles', article)
    //     axios.post(getPECompaniesApi, companyRequst)
    //         // axios.post('http://localhost:8000/api/getCompanies', companyRequst)
    //         .then(response => { console.log('response', response) });

    //     // empty dependency array means this effect will only run once (like componentDidMount in classes)
    // }, []);

  /**
   * The `onSubmit` function handles the login process, including checking if the user needs to
   * authorize a device, redirecting to the appropriate page, and displaying error messages if
   * necessary.
   * @param formData - An object containing the form data, including the email and password fields.
   * @returns The function `onSubmit` returns nothing (undefined).
   */
    const onSubmit = (formData) => {
        if (isLogging) {
            return;
        }
        login(formData.email, formData.password, selectedProduct).then((data) => {
            if (data.success === true) {
                if (data?.data?.show === true) {
                    setLogoutPopupData({
                        show: true,
                        browserList: data?.data?.deviceDetails ?? [],
                        onClick: (selected) => {
                            console.log(selected);
                            if (selected == null) {
                                setLogoutPopupData({ show: false })
                            } else {
                                createDeviceAuth(formData, selected)
                            }
                        }
                    });
                }else if(data?.data?.showAuthorize == true){
                    redirectTo(routeNames.device_authorization, {
                        state: {
                            // deviceId: id,
                            // password: data.password,
                            loginFrom: selectedProduct,
                            authCode: data?.data?.authCode,
                            email: data?.data?.emailId,
                        }
                    })
                } else {
                    redirectTo(data?.data?.redirectUrl)
                }
            } else {

                setLoginErrorMsg(data.message)

            }
        })

    }

    const onSubmitForForgotPW = (formData) =>{
        forgotPassword(formData.forgotEmail).then((data) =>{
            if (data.success == true) {
                toast.success(data?.message)
                // console.log(data,'forgot password responsedata');
            }
            else {
                setForgotErrorMsg(data?.message)
            }
        })
    }

    const createDeviceAuth = (data, id) => {
        console.log(data,"datadata-");
        axios.post(createDeviceAuthUrl, {
            "email": data.email,
            "password": data.password,
            "loginFrom": selectedProduct,
            "auth_device_id": id
        }).then(response => {
            if (response.status === 200) {
                redirectTo(routeNames.device_authorization, {
                    state: {
                        deviceId: id,
                        password: data.password,
                        loginFrom: selectedProduct,
                        authCode: response.data?.data?.authCode,
                        email:data.email,
                    }
                })
            }
        }).catch(error => {

        })
    }

  /**
   * The function `onProductChange` sets the selected product based on the value of the event target.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is likely an event object related to a change event, such as a user
   * selecting a different option in a radio group.
   */
    const onProductChange = (event) => {
        setLoginErrorMsg(null);
        setSelectedProduct(event.target.value)
    }
    // console.log("selectedProduct12", selectedProduct);
    return (
        <div>
            <div className="inset-0 h-full w-full object-cover">
                <div className="h-max ">
                    {/* <!-- left side--> */}
                    <div className="grid grid-cols-2">
                        <div className=" lg:col-span-1 hidden lg:block h-screen  lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%] " alt="" />
                            </div>
                        </div>
                        {/* <!--right side--> */}
                        {isforgotPasswordClick ? 

                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24">
                            <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="2xl:mt-8">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    <h2 className="mt-6 2xl:mt-[3rem] text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">Reset Password</h2>
                                </div>
                                {forgotErrorMsg  ?
                                <div className='bg-[#F2EDE1] mx-auto h-[3rem] w-fit mt-8'>
                                <p className='py-3 px-4 whitespace-nowrap font-sans_light_body text-[13px] lg:text-[12px] text-[#333333] leading-[24px] '> {forgotErrorMsg}</p>
                                </div>
                                :
                                <div className='mt-8'>
                                <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmitForForgotPW)}>
                                <div className="space-y-1">
                                    <div className="mb-2 ">
                                        <label htmlFor="email" className="block font-sans_book_body text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Email address </label>
                                    </div>
                                    <div  >
                                        <label className="relative block" >
                                            <input
                                                {...register("forgotEmail", {
                                                    required: "Please enter your email address",
                                                    onChange: (e) => {
                                                        setLoginErrorMsg(null);
                                                    },
                                                    pattern: {
                                                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: "Invalid email address"
                                                    }
                                                })}
                                                id="forgotEmail"
                                                name="forgotEmail"
                                                type="email"
                                                autoComplete="off"
                                                className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block " />
                                            <img src={Mail} className="  absolute top-1/2 transform -translate-y-1/2 right-3" />

                                        </label>
                                        <p className="text-[red] text-[12px]">{errors.forgotEmail?.message}</p>
                                    </div>
                                    <div>
                                        <button type="submit"
                                            disabled={isLogging}
                                            className="w-full flex justify-center text-left py-2.5 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] tracking-[-0.3px] leading-[17px] bg-[#A5711A] hover:bg-[#BC8B20]  focus:outline-none focus:ring-2 focus:ring-offset-2 my-4">
                                            {isLogging ? "Submitting..." : "Submit"}</button>

                                    </div>
                                    <p className="mt-3 font-sans_light_body text-[13px] lg:text-[12px] text-[#2B2A29] leading-[24px] lg:text-start text-center">Please enter your registered email to receive a password reset mail.</p>
                                </div>
                                </form>                                 
                                </div> } 
                            </div>
                        </div>                            
                        :

                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24">
                            <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    <p className="text-center text-[#333333] mt-6 text-[18px] lg:text-[12px] tracking-[-0.24pt] lg:text-[#969696] font-sans_book_body ">Welcome to</p>
                                    <h2 className=" text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">Single Sign-on.</h2>
                                </div>
                                <div className='flex mt-8 items-center hidden' onChange={onProductChange}>
                                    <input
                                        className='mx-1'
                                        type="radio"
                                        name="products"
                                        checked={selectedProduct == "1"}
                                        value="1" 
                                        id="1"/>
                                        <label for="1">PE</label> 
                                    <input
                                        className='ml-4 mr-1'
                                        type="radio"
                                        name="products"
                                        checked={selectedProduct == "3"}
                                        value="3" 
                                        id="3"/> 
                                        <label for="3">RE</label> 
                                    <input
                                        className='ml-4 mr-1'
                                        type="radio"
                                        name="products"
                                        checked={selectedProduct == "4"}
                                        value="4" 
                                        id="4"/> 
                                        <label for="4">MA</label> 

                                    <input
                                        className='ml-4 mr-1'
                                        type="radio"
                                        name="products"
                                        checked={selectedProduct == "5"}
                                        value="5" 
                                        id="5"/> 
                                        <label for="5">CFS</label> 
                                </div>
                                {/* <!--form start--> */}
                                <div className="mt-8">
                                    <div className="mt-6">
                                        <form action="#" method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="space-y-1">
                                                <div className="mb-2 ">
                                                    <label htmlFor="email" className="block font-sans_book_body text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Email address </label>

                                                </div>
                                                <div  >
                                                    <label className="relative block" >
                                                        <input
                                                            {...register("email", {
                                                                required: "Please enter your email address",
                                                                onChange: (e) => {
                                                                    setLoginErrorMsg(null);
                                                                },
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                    message: "Invalid email address"
                                                                }
                                                            })}
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoComplete="off"
                                                            className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block " />
                                                        <img src={Mail} className="  absolute top-1/2 transform -translate-y-1/2 right-3" />

                                                    </label>
                                                    <p className="text-[red] text-[12px]">{errors.email?.message}</p>
                                                </div>
                                            </div>

                                            <div className="space-y-1">
                                                <div className="flex items-center mb-2 justify-between">
                                                    <label htmlFor="password" className="block font-sans_book_body  text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Password </label>
                                                    <p onClick={(e)=>setIsForPasswordClick(true)} className=" text-[12px] font-sans_book_body text-[#D7A945] hover:text-[#333333] tracking-[-0.26px] leading-[14px] cursor-pointer"> Forgot Password? </p>
                                                </div>
                                                <div  >
                                                    <label className="relative block">
                                                        <input  {...register("password", {
                                                            required: "Please enter your password",
                                                            onChange: (e) => {
                                                                setLoginErrorMsg(null);
                                                            },
                                                            minLength: {
                                                                value: 5,
                                                                message: "Min length should be greater than 5"
                                                            }
                                                        })}
                                                            id="password" name="password" type="password" autoComplete="off"
                                                            className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block  " />
                                                        <img src={Lock} className=" absolute top-1/2 transform -translate-y-1/2 right-3" />
                                                    </label>
                                                    <p className="text-[red] text-[12px]">{errors.password?.message}</p>
                                                </div>
                                            </div>
                                            {loginErrorMsg && <p className="text-[red] text-[16px]">{loginErrorMsg}</p>}
                                            <div>

                                                <button type="submit"
                                                    disabled={isLogging}
                                                    className="w-full flex justify-center text-left py-2.5 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] tracking-[-0.3px] leading-[17px] bg-[#A5711A] hover:bg-[#BC8B20]  focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                                                    {isLogging ? "Signing In..." : "Sign in"}</button>

                                            </div>
                                        </form>
                                        {/* <!--form end--> */}
                                    </div>
                                </div>
                                <p className="mt-3 font-sans_light_body text-[13px] lg:text-[12px] text-[#2B2A29] leading-[24px] lg:text-start text-center">Having trouble to logging in,
                                    <a className="mt-3 pl-1 font-sans_light_body text-[13px] lg:text-[12px] text-[#A5711A] hover:text-[#333333]" href="">click here</a>
                                </p>
                            </div>
                        </div>}

                    </div>


                </div>
            </div>
            {logoutPopupData.show ? <LogoutPopup {...logoutPopupData} /> : <></>}
            <ToastContainer
            position={"top-center"}
            autoClose={3000}
            toastClassName="bg-white shadow-lg rounded-lg flex items-center"
            bodyClassName="text-sm font-medium text-gray-900"
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        // className='bg-success text-white'
        />
        </div>
    )
}

export default Login
