import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'
import image1 from '../../../src/images/Sixdot/Group15.png'
import './dashboarcd.css'
import axios from 'axios';
import { savedFilterListApi } from '../../Config/config';
import { moduleValues, primaryMasterSubValues, primaryMasterValues } from '../../Constants/values';
import { useFilters, usePagination, useTable } from 'react-table';
import DashboardPagination from '../shared/DashboardPagination';
import OverflowToolTip from '../shared/OverflowToolTip';
import { GrView } from 'react-icons/gr'
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getRoutePathById} from '../../routeSegments';
// import { PeFilterContext } from '../../Contexts/PeFilterContext';
import Loader from '../Loader';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { UserContext } from '../../Contexts/UserContext';
import moment from "moment";
import Constants from '../../Constants';
import DashBoardTableTwo from './DashBoardTableTwo'
import useModule from '../../hooks/useModule';

/**
 * The function `getSectionLabelById` takes an ID as input and returns the corresponding label for that
 * ID, based on predefined values and constants.
 * @returns The function `getSectionLabelById` returns the label of a section based on its ID.
 */
const getSectionLabelById = (id) => {
    if(id == null){
        return null
    }
    const primaryMasterSub = primaryMasterSubValues.find(option=>parseInt(option.value) == parseInt(id))
    if(primaryMasterSub){
        return primaryMasterSub.title;
    }

    const key = Object.keys(Constants.primaryMasterSubIds)
        .find(subIdKey => Constants.primaryMasterSubIds[subIdKey] == id) ?? ""

    const label = key.split("_").filter(value => value != "id")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return label;
}

/* The above code is a React component called `FilterListDataTable`. It is responsible for rendering a
table that displays a list of saved filters. The component uses various hooks such as `useState`,
`useEffect`, and `useContext` to manage state and perform side effects. */
function FilterListDataTable() {
    const [savedFilterList, setSavedFilterList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const { applyFilterFrom } = useContext(PeFilterContext);
    const [selectedPrimaryModule, setselectedPrimaryModule] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState("My Filters")
    const {getToken, getUser} = useContext(UserContext);
    const navigate = useNavigate();
    const [savedGroupList,setSavedGroupList] =useState([])
    const {allowedModulesList} = useModule()
    const [moduleOptions,setModuleOptions] = useState([])

    // const getSavedList = () => {
    //     setIsLoading(true);
    //     axios.post(savedFilterListApi, {
    //         "user_id": getUser().id,
    //         // "module_id": 1,
    //         "primary_type_master_id": selectedPrimaryModule,
    //         "filter_type": selectedCategory,
    //         // "sub_type_master_id": []
    //     },{
    //         headers:{
    //             "Authorization": getToken()
    //         }
    //     }).then(response => {
    //         setIsLoading(false);
    //         if (response.status === 200) {
    //             setSavedFilterList(response.data?.listviewfilter ?? [])
    //             setSavedGroupList(response?.data?.groupList ?? [])
    //         }
    //     })
    // }
    // console.log(savedGroupList,'savedGroupList');

    useEffect(() => {
        // getSavedList();
        setIsLoading(true);
        axios.post(savedFilterListApi, {
            "user_id": getUser().id,
            // "module_id": 1,
            "primary_type_master_id": selectedPrimaryModule,
            "filter_type": selectedCategory,
            // "sub_type_master_id": []
        },{
            headers:{
                "Authorization": getToken()
            }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setSavedFilterList(response.data?.listviewfilter ?? [])
                setSavedGroupList(response?.data?.groupList ?? [])
            }
        })
    }, [selectedCategory,selectedPrimaryModule])

   /* The above code is defining a function called `onViewClick` in JavaScript React. This function
   takes an `id` parameter and is defined using the `useCallback` hook. */
    const onViewClick = useCallback(async (id) => {
        const clickedFilter = savedFilterList.find(filter => filter.id === id);
        const moduleId = parseInt(clickedFilter.module_id);
        const primaryId = parseInt(clickedFilter.primary_type_master_id);
        const subPrimaryId = parseInt(clickedFilter.sub_type_master_id);
        
        const filter = JSON.parse(clickedFilter?.request_json)??{}
        // console.log("clickedFilterclickedFilter", filter);

        navigate(getRoutePathById({ moduleId, primaryId, subPrimaryId }),{state:{filterData:filter?.filterData,monthRange:[filter?.date_range?.from_date,filter?.date_range?.to_date]}})

    }, [savedFilterList, navigate])

    const columns = useMemo(() => [
        {
            Header: "Module",
            accessor: "module",
            className: "text-center",
            Cell: ({ value }) => {
                return primaryMasterValues.find(data => data.value == value)?.title
            },
        },
        {
            Header: "Type",
            accessor: "type",
            className: "text-center "
        },
        {
            Header: "Sub Type",
            accessor: "subType",
            className: "text-center ",
            Cell:({value})=>{
                if(value) return value
                else return "N/A"
            }
        },
        {
            Header: "Refine Search Name",
            accessor: "searchName",
            // className:"flex justify-center",
            Cell: ({ value }) => {
                return <OverflowToolTip title="Refine Search Name" className="max-w-[18rem]"
                    component={value} />
            }
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
        },
        
        {
            Header: "Action",
            accessor: "actions",
            className: "flex justify-center pt-[0.7rem]",
            Cell: ({ value }) => {
                return <Tooltip className='text-center' title="Apply Filter">
                    <GrView onClick={(e) => {
                        onViewClick(value)
                    }} className='cursor-pointer ' />
                </Tooltip>
            }
        },
    ], [onViewClick])

    const data = useMemo(() => {
        let rows = [];

        savedFilterList?.forEach(savedFilter => {
            const filters = JSON.parse(savedFilter.request_json)??{};
            let keys = new Set();

            Object.keys(filters)?.forEach(key => {

                if (Array.isArray(filters[key])) {
                    if (filters[key].length > 0) {
                        keys.add(key.toUpperCase().replace('_', " "))
                    }
                } else if (typeof filters[key] === "object") {
                    keys.add(key.toUpperCase().replace('_', " "))
                }
            })



            rows.push({
                module: savedFilter.primary_type_master_id,
                type: moduleValues.find(data => data.value == savedFilter.module_id)?.title,
                subType: getSectionLabelById(savedFilter.sub_type_master_id),
                searchName: savedFilter.name,
                appliedFilter: Array.from(keys),
                createdDate: savedFilter.created_at ?? moment(new Date()).format('DD MMM YYYY'),
                actions: savedFilter.id
            })
        })
        return rows;
    }, [savedFilterList])

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    },
        useFilters,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        // pageCount,
        gotoPage,
        // nextPage,
        // previousPage,
        setFilter,
        state: { pageIndex, pageSize },
    } = tableInstance

   /**
    * The function `onPaginationChange` is used to handle pagination changes in a React component.
    */
    const onPaginationChange = (page, pageSize) => {
        gotoPage(page - 1)
    }

    /**
     * The function `onModuleChange` updates the value of `selectedPrimaryModule` based on the value of
     * the target element in an event.
     */
    const onModuleChange = (e) => {
        setselectedPrimaryModule(e.target.value);
    }

    /**
     * The function `onCategoryChange` updates the selected category based on the value passed as an
     * argument.
     */
    const onCategoryChange = (value)=>{
        // alert(value)
        setSelectedCategory(value)
    }

    useEffect(() => {
        setFilter('module', selectedPrimaryModule === "All" ? "" : selectedPrimaryModule)
    }, [selectedPrimaryModule])

    // const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    var allowedModulesData =[] 

    useEffect (()=>{
        
        /* The above code is checking if certain modules are included in the `allowedModulesList`
        array. If a module is included, it adds an object with a `title` and `value` property to the
        `allowedModulesData` array. After checking all the modules, if there is more than one module
        in the `allowedModulesData` array, it adds an additional object with a `title` of "All" and
        a `value` of "All" at the beginning of the array. Finally, it sets the state of
        `moduleOptions` to the `allowedModulesData` array. */
        if(allowedModulesList.includes('pe')){
            allowedModulesData.push({title: "PE", value: "1"},)
        }
        if(allowedModulesList.includes('vc')){
            allowedModulesData.push({title: "VC", value: "2"},)
        }
        if(allowedModulesList.includes('re')){
            allowedModulesData.push({title: "RE", value: "3"},)
        }
        if(allowedModulesList.includes('ma')){
            allowedModulesData.push({title: "M & A", value: "4"},)
        }
        if(allowedModulesList.includes('cfs')){
            allowedModulesData.push({title: "CFS", value: "5"},)
        }
        if(allowedModulesData.length > 1 ){
            allowedModulesData.splice(0,0,{title: "All", value: "All"},)
        }
        setModuleOptions(allowedModulesData)

    },[]) 
    


    return (        
            <div className="border border-[#CECECE] rounded-[4px] m-2 lg:p-2 bg-[#EBEBEB] lg:w-[70%]  lg:h-[35rem]"> 
                    <div className="sm:flex flex justify-between items-center px-2 lg:pb-2 border-b-[#CCCCCC] border-b border-x-1 h-[4rem] lg:h-[2rem]">
                        <div className="flex space-x-3 items-center">
                            <span className="items-center">
                                <img src={image1} alt="" className="w-[12px] h-[18px]" />
                            </span>
                            <div className='flex space-x-5 lg:space-x-12 items-center'>
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='VI Filters' ? 'font-bold text-[16px] leading-[19px]':'text-[14px] leading-[17px]'}`} onClick={()=>onCategoryChange('VI Filters')}>Vi Filters</p>                        
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='My List' ? 'font-bold text-[16px] leading-[19px]':'text-[14px] leading-[17px]'}`} onClick={()=>onCategoryChange('My List')}>My List</p>                        
                                <p className={`text-[#333333] font-sans_medium_body cursor-pointer ${selectedCategory==='My Filters' ? 'font-bold  text-[15px] pb-1 lg:pb-0 lg:text-[16px] leading-[19px]':'text-[14px] leading-[17px]'}`} onClick={()=>onCategoryChange('My Filters')}>Saved Search</p>   
                            </div>                     
                        </div> 
                        {moduleOptions.length > 0 && 
                        <select onChange={onModuleChange}
                                value={selectedPrimaryModule}
                                className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                {
                                    moduleOptions.map((primaryValue,i) => {
                                    return(
                                    <option value={primaryValue.value} key={i}>
                                        {primaryValue.title}
                                    </option>)})
                                }
                            </select>}
                            {/* <select onChange={onCategoryChange}
                                value={selectedCategory}
                                className=" py-0.5 text-[13px] font-sans_book_body text-[#645326] leading-[16px] rounded-[10.5px] block px-2.5 bg-[#FFFFFF] border border-[#BC8B20]  focus:outline-none ">
                                {
                                    filterCategoryValues.map(filterCategory => (<option value={filterCategory.value}>
                                        {filterCategory.title}
                                    </option>))
                                }
                            </select> */}                 
                    </div>
                    {/* <!-- table --> */}              
                    <div className=' overflow-x-auto lg:thin-scrollbar scrollbar-remove'>
                    {selectedCategory==='My List' ? 
                     
                            <DashBoardTableTwo savedWishList={savedGroupList} isLoading={isLoading}/>
                          
                        :        <div className=" overflow-x-auto lg:thin-scrollbar scrollbar-remove">                  
                            <table className="w-full h-full p-1 bg-[#EBEBEB] rounded-[4px] border-separate text-center border-spacing-y-1 " {...getTableProps()}>
                                <thead className="bg-[#EBEBEB] font-sans_medium_body text-[13px] leading-[16px]  text-[#666666] h-[2.5rem]">
                                    {
                                        headerGroups.map(headerGroup => (

                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map(column => (

                                                        <th {...column.getHeaderProps([
                                                            { className: `whitespace-nowrap px-5  ${column.headerClassName}` }
                                                        ])}>
                                                            {
                                                                column.render('Header')}
                                                        </th>
                                                    ))}
                                            </tr>
                                        ))}
                                </thead>
                                {!isLoading && savedFilterList.length > 0 && page.length > 0 ? <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body text-center " {...getTableBodyProps()}>
                                    {
                                        page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr className="text rounded-[4px] bg-[#FFFFFF] px-2 " {...row.getRowProps()} style={{height:'2.3rem'}}>
                                                    {
                                                        row.cells.map(cell => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps([
                                                                        {
                                                                            className: `lg:whitespace-nowrap px-5 py-2 rounded ${cell.column.className}`,
                                                                        }])}
                                                                >
                                                                    {
                                                                        cell.render('Cell')
                                                                    }
                                                                </td>
                                                            )
                                                        })}
                                                </tr>
                                            )
                                        })}
                                </tbody> : 
                                <tbody className='h-[27.5rem]'>
                                <td colSpan={headerGroups.map(headerGroup => { return headerGroup?.headers?.length })} className=''>
                                {isLoading
                                    ? <div className='bg-white w-full flex items-center justify-center h-full'>
                                        <Loader />
                                    </div>
                                    : savedFilterList.length < 1 || page.length < 1
                                        ? <div className='bg-white w-full flex items-center justify-center h-full'>
                                            <NoDataPlaceholder />
                                        </div>
                                        : <></>}
                                        </td>
                                </tbody>
                                }                           
                            </table> 
                            </div>                        
                        }                    
                    </div>                             
                    {rows.length > 0 && !isLoading &&
                        <div className='text-center mt-2 mb-2'>
                            <DashboardPagination
                                current={pageIndex + 1}
                                total={rows.length}
                                pageSize={pageSize}
                                onChange={onPaginationChange}
                            />
                        </div>
                    }  
            </div>                     
    )
}

export default FilterListDataTable