import React, { useContext, useEffect, useState, useRef } from 'react'
import search from '../../images/Shape_13/png/Shape.png';
import axios from "axios";
import { faqTrendTopics, faqSearchApi } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext'
import menuOpen from '../../images/Group 12.png';
import Loader from '../../Components/Loader';
import { FaqContext } from '../../Contexts/FaqContext';
import { faqLogApi } from '../../Config/config';

function Intro(props) {

  const { getToken } = useContext(UserContext);
  const [searchInput, setSearchInput] = useState('');
  const { openRequestPopup } = useContext(RaiseRequestContext)
  const { searchResult, setSearchResult, setSelectedSearchResult } = useContext(FaqContext)
  const { isLoading, setIsLoading, trends, setTrends } = useContext(FaqContext)

/**
 * The function `accordionClick` logs the value of a scrollable ID, sets a selected search result,
 * clears the search result and input, scrolls to the element with the given ID, and creates a log.
 * @param value - The `value` parameter in the `accordionClick` function represents the ID of the
 * scrollable element that needs to be scrolled into view.
 */
  const accordionClick = (value) => {
    console.log(value,'scrollable id');
    setSelectedSearchResult(value)
    setSearchResult([]);
    setSearchInput('');
    document.getElementById(value).scrollIntoView({ behavior: 'smooth' });
    createLog(value)

  }

 /**
  * The handleChange function updates the value of the search input based on the user's input.
  * @param e - The parameter "e" is an event object that is passed to the handleChange function. It
  * represents the event that triggered the function, such as a user input event like typing in an
  * input field.
  */
  const handleChange = (e) => {
    setSearchInput(e.target.value);
  }


/**
 * The `createLog` function sends a POST request to an API endpoint with the `faq_id` and `db_type`
 * data, along with the necessary headers, and logs the response data to the console before calling the
 * `searchTrend` function.
 * @param faq_id - The `faq_id` parameter is the ID of the frequently asked question (FAQ) that you
 * want to create a log for.
 */
  const createLog = (faq_id) => {
    axios(`${faqLogApi}`, {
      method: "POST",
      data: {
        "faq_id": faq_id,
        "db_type": props.menu
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        console.log(response.data, "faqlog")
        searchTrend()
      })
      .catch((error) => {
        console.log("API not working - faq log", error);
      });
  }

 /**
  * The function `searchTrend` makes a POST request to a specified endpoint using axios, passing in
  * some data and headers, and then logs the response data to the console if the request is successful.
  */
  const searchTrend = () => {
    axios(`${faqTrendTopics}`, {
      method: "POST",
      data: {
        "db_type": props.menu
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        console.log(response.data, "faq trend")
        if (response.data.success == true) {
          setTrends(response.data.data ?? [])
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log("API not working - faq trend ", error);
      });
  }

  useEffect(() => {
    setIsLoading(true)
    searchTrend()
  }, [])

  useEffect(() => {
    // setIsLoading(true)
    if (searchInput?.length !== 0) {
      axios.post(faqSearchApi, {
        "search_text": searchInput,
        "type": props.menu == "PE" ? 1 : props.menu == "CFS" ? 5 : props.menu == "RE" ? 2 : props.menu == "M&A" ? 3 : 1,
      }, {
        headers: {
          "Authorization": getToken()
        }
      })
        .then(response => {
          console.log(response.data.data, "SearchResult")
          if (response?.status === 200) {
            setSearchResult(response.data.data);
          } else {
            setSearchResult([]);
          }
        })
        .catch((error) => {
          setSearchResult([]);
          console.log("API not working", error);
        });
    } else {
      setSearchResult([]);
    }
  }, [searchInput])

  const searchRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target) && event.target?.id !== "globalSearchInput" && !event.target.classList?.contains("searchItem")) {
        setSearchInput("")
        // onClose()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchInput?.length, searchRef, setSearchInput]);

  return (

    <div className='mt-[3.5rem] bg-gray-100 h-full '>
      <div>
        <p className="relative top-[30px] text-center text-[20pt]  font-sans_medium_body text-[#2B2A29] leading-[24pt] tracking-[-0.43pt] 
            lg:text-[32px]  lg:text-[#2B2A29] lg:leading-[38px]  lg:tracking-[-0.69px]  ">
          Hello, how may we help you?</p>
      </div>

      {/* -------searchbar----- */}
      <div>
        <form className="flex justify-center px-[0.5rem] md:px-0 mt-9">
          <label htmlFor="simple-search" className="sr-only text-[18px] leading-[22px] tracking-[-0.39] font-sans_book_body">Search</label>
          <div className="relative mt-3 shadow-sm  md:w-[35rem] w-[275pt] lg:w-[42rem] ">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <img className="text-[#989898] h-[22px] w-[22px]" src={search} />
            </div>
            <input type="text" id="simple-search" autoComplete='off'
              className=" leading-[18pt] text-[#2B2A29] tracking-[-0.32pt] w-full pl-10 p-3 rounded-md lg:text-[#2B2A29] text-[15px] sm:text-[18px] font-SequelSans tracking-[-0.39px] leading-[22px]"
              placeholder=" Search or ask your any question here..." required
              onChange={handleChange}
              value={searchInput}
              ref={searchRef}
            />
          </div>
        </form>
        <div className={" mx-auto w-[90%] h-full  overflow-y-auto absolute " + (searchInput?.length == 0 ? "hidden" : "")}>
          <div className="pl-12">
            <div className="mx-auto grid  overflow-y-auto mb-40 w-full gap-y-6 py-6 px-4 sm:gap-6 sm:px-6 sm:py-6 lg:px-4 lg:pt-6 lg:pb-2 xl:py-6 bg-[#FFFFFF] md:w-[35rem] lg:w-[42rem]">
              {
                searchResult.length > 0
                  ? searchResult?.map(value => {
                    if (searchResult.length == 0)
                      return <div className=''><span>No data found</span><span><img src={menuOpen} className="w-[25px] h-[25px] rotate-180 float-right" alt='Right Arrow' onClick={() => openRequestPopup("faq")} /></span></div>

                    return <div className="flex cursor-pointer searchItem" onClick={() => accordionClick(value.id)}>

                      {value.question}

                    </div>

                  })
                  : <div><span className='font-sans_medium_bodylg:text-[15px] text-[15px] text-[#333333]'>No data found</span><span><img src={menuOpen} className="w-[25px] h-[25px] rotate-180 float-right" alt='Right Arrow' onClick={() => openRequestPopup("faq")} /></span></div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className='mt-3'>
        <div>
          <h1 className=" font-sans_medium_body text-center mt-9  text-[16pt] text-[#333333] leading-[19pt] tracking-[-0.34pt]
                    lg:text-[20px] lg:leading-[24px] lg:tracking-[-0.43px]">
            Trending Topics</h1>
        </div>
        <div className='mt-3'>
          {isLoading ?
            <center className="mt-4" ><Loader /></center>
            :
            !isLoading && trends.length == 0 ?
              <center className="mt-4 font-sans_medium_bodylg:text-[15px] text-[15px] text-[#333333]" >No Data Found</center>
              :
              <ul role="list"
                className=" hidden xl:px-16 md:px-7 lg:grid grid-cols-1 flex w-full gap-5 lg:grid-cols-4  xl:overflow-hidden ">

                {trends.map((key, value) => (

                  <li className="shadow bg-[#FFFFFF] rounded-[6px]" key={key.faq_id}>
                    <div className="px-4 py-2  lg:mt-1 cursor-pointer" onClick={() => accordionClick(key.faq_id)}>
                      <p
                        className=" font-SequelSans text-[14pt] font-sans_book_body text-[#333333] leading-[18pt] tracking-[-0.3pt]  lg:text-[14px]  lg:leading-[19px] lg:tracking-[-0.34px] " >
                        {key.faq_question}
                      </p>
                    </div>
                  </li>
                ))}

              </ul>
          }
        </div>

        {/* -------------Mobile Menu--------------------- */}


        <div className="lg:hidden  px-4 overflow-x-auto lg:grid grid-cols-4 gap-2 mt-5 flex w-full xl:overflow-hidden ">
          {isLoading ?
            <center className="mt-4" ><Loader /></center>
            :
            !isLoading && trends.length == 0 ?
              <center className="mt-4 font-sans_medium_bodylg:text-[15px] text-[15px] text-[#333333]" >No Data Found</center>
              :
              <div className='lg:hidden  px-4 overflow-x-auto lg:grid grid-cols-4 gap-2 mt-5 flex w-full xl:overflow-hidden '>
                {trends.map((key, value) => (
                  <section className="flex-shrink-0 mb-3 " key={key.faq_id} >
                    <div className="bg-white p-3 rounded-[4pt]  overflow-hidden" onClick={() => accordionClick(key.faq_id)} >
                      <div className="grid grid-cols-4 ">

                        <div className="col-span-4 ">

                          <div className="flex space-x-1 ">
                            <div className=" px-4 py-2 ">
                              <p
                                className=" font-SequelSans text-[14pt] text-[#333333] leading-[18pt] tracking-[-0.3pt]  lg:text-[14px]  lg:leading-[19px] lg:tracking-[-0.34px] ">
                                {key.faq_question}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ))}

              </div>
          }
        </div>
      </div>
    </div>

  )
}

export default Intro
