import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import PrimaryButton from "../../../Components/shared/PrimaryButton";
import { commentListUrl, addCommentUrl } from "../../../Config/config";
import { IoPersonCircleSharp } from 'react-icons/io5';
import Loader from "../../../Components/Loader";
import moment from 'moment';
import { UserContext } from "../../../Contexts/UserContext";

const ProfileHighlights = ({ ticketId, author }) => {

  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const { getToken } = useContext(UserContext);


 /**
  * The function `getComments` makes a POST request to retrieve comments for a ticket using the
  * provided ticket ID and author, and updates the state with the retrieved comments.
  */
  const getComments = () => {
    setIsLoading(true);
    axios.post(commentListUrl, {
      "ticket_id": ticketId,
      "author": author ?? "loke2@prano.com",
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      setIsLoading(false);
      if (response.status === 200) {
        setComments(response.data?.comments ?? [])
      }
    })
  }

  useEffect(() => {
    if (ticketId != null && author != null) {
      getComments();
    }
  }, [ticketId, author])

 /**
  * The function `onCommentChange` updates the state variable `commentText` with the value of the input
  * field.
  */
  const onCommentChange = (e) => {
    setCommentText(e.target.value)
  }

  /**
   * The `onCommentPost` function is used to post a comment on a ticket and update the comments list.
   */
  const onCommentPost = (comment) => {
    setIsCommenting(true);
    setCommentText("");
    axios.post(addCommentUrl, {
      "ticket_id": ticketId,
      "author": author,
      "comment": comment,
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      setIsCommenting(false);
      if (response.status === 200) {
        getComments();
        toast.success(response.data?.message)
      }
    })
  }

  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED] w-[100%]">
        <div className="lg:border ">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-5 lg:rounded-[6px] lg:shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] ">
              <div className=" mt-3 lg:mt-0 rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] lg:shadow-none lg:rounded lg:col-span-3  bg-white overflow-x-auto">
                <div className="mx-5 py-4 lg:overflow-hidden overflow-x-auto">
                  <p className=" text-[13px] leading-[17px] lg:text-[14px] text-[#333333] font-sans_semi_bold lg:leading-[17px]">
                    Leave a Comments
                  </p>
                  <div className="mt-2">
                    {/* <textarea
                      name="text"
                      id="link"
                      className="border w-full py-1 px-2 mt-2 text-[11px] text-[#777777] bg-[#efefef]"
                      placeholder="Enter comments"
                      type="text"
                      value=""
                      onChange={handleMessageChange}
                    ></textarea> */}
                    <textarea
                      className="border outline-none w-full py-1 px-2 mt-2 text-[11px] text-[#777777] bg-[#efefef]"
                      id=""
                      rows="3"
                      value={commentText}
                      onChange={onCommentChange}
                      placeholder="Enter comments"
                    ></textarea>
                    <div className=" flex justify-end">
                      <PrimaryButton
                        disable={isCommenting || commentText === ""}
                        isLoading={isCommenting}
                        onClick={(e) => onCommentPost(commentText)}>
                        Post Comments
                      </PrimaryButton>
                      {/* <button disabled={isLoading} onClick={onCommentPost} className="flex  font-sans_book_body bg-[#BC8B20]  text-[#FFFFFF] text-[14px] rounded-[3px] leading-[17px] items-center">
                        <span className="whitespace-nowrap  px-2 py-2">
                          
                        </span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              isLoading
                ? <div className="h-32 flex items-center justify-center">
                  <Loader />
                </div>
                : comments?.map(cmt => {
                  return <div className="px-5 bg-white ">
                    <div className="flex  border-t">

                      {/* <div className="mt-2 w-12">
                      <RandomLogo name={cmt?.author} />
                    </div> */}
                      <IoPersonCircleSharp className="mt-2 max-w-[3rem] min-w-[3rem]" size={32} />

                      <div className="mt-2">
                        <span className="text-[13px] leading-[17px] mt-3  text-[#333333] font-sans_semi_bold lg:leading-[17px]">
                          {cmt?.author ?? "--"}
                        </span>
                        <span className="text-[11pt] leading-[15pt] text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px] ml-2">
                          {/* {moment(cmt?.created_at, "DD-MM-YYYY").format("DD MMM yyyy hh:mm A")} */}
                          {`${moment(cmt?.created_at).format("DD MMM yyyy hh:mm A")}`}
                        </span>
                        <p className="text-[11pt] leading-[18pt] py-1 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                          {cmt.comment}
                        </p>
                      </div>
                    </div>
                  </div>;
                })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHighlights;
