import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { getPEDealsApi, getPEGlobalSearchCompaniesApi } from "../Config/config";
import { filterRows } from "./helpers";
import Pagination from "./Pagination";
import { getPECompaniesApi } from "../Config/config";
import SortIcon from "mdi-react/SortIcon";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import CompanyLogo from "../Components/CompanyLogo";
import RandomLogo from "../Components/RandomLogo";
import "./TableView.css";
import "../Utils/Common.css";
import { PeFilterContext } from "../Contexts/PeFilterContext";
import { TableDataContext } from "../Contexts/TableDataContext";
import routePathSegments, { routeNames } from "../routeSegments";
import OverflowToolTip from "./shared/OverflowToolTip";
import NoDataPlaceholder from "./shared/NoDataPlaceholder";
import Loader from "./Loader";
import { UserContext } from "../Contexts/UserContext";
import Constants from "../Constants";
import CustomNavigation from "../Utils/CustomNavigation";
import AddNotePopover from "../Components/shared/AddNotePopover";
import { getSection } from "../Utils/utils";
import DeleteItemsFromGroup from "./shared/DeleteItemsFromGroup";
import { Tooltip } from "antd";

/**
 * The Table component renders a table based on the provided columns, menu, order, and
 * orderBy props, and handles sorting and filtering of the table data.
 */
const Table = ({ columns, menu, order, orderBy, sub_type_master_id }) => {
  const {
    monthPickerValue,
    setIsMonthPickerChanged,
    setIsTagClosed,
    refineSearchFilters,
    groupId,
    // dealsView,
  } = useContext(PeFilterContext);

  // console.log(dealsView, "vv");

  const {
    rows,
    setRows,
    tableCompanyId,
    setTableCompanyId,
    data,
    setData,
    isLoad,
    setIsLoad,
    setIsDataLoad,
    setCurrentFilter,
    currentPage,
    setCurrentPage,
    dealIds,
    setDealIds
  } = useContext(TableDataContext);

  console.log(dealIds, 'dealIds');

  // const { selectedPage, setSelectedPage, orderingColumn, setOrderingColumn } = useContext(TableHeaderContext);

  const [
    filters,
    //  setFilters
  ] = useState({});
  const [sort, setSort] = useState({ order: order, orderBy: orderBy });
  const location = useLocation();
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null);
  const [reload, setReload] = useState(false);

  const findActivePage = (page) => {
    setCurrentPage(page);
    tableRender(page, monthPickerValue, sort.orderBy, sort.order, groupId);
    setIsLoad(true);
    // if(menu == "pe" || menu == "vc"){
    //   setActiveTabDeal('investments')
    // }
  };

  const isFromGlobalSearch = () =>
    refineSearchFilters[location.pathname]?.globalSearch?.length > 0
      ? true
      : false;

  const tableRender = useCallback(
    (page, month, column, order, groupId) => {
      setIsTagClosed(0);
      setIsMonthPickerChanged(0);

      // if(dealsView === 'Company'){ setApiUrl(getPECompaniesApi) } else setApiUrl(getPEDealsApi);

      const category =
        menu == "pe" || menu == "vc"
          ? "investments"
          : menu == "exit-pe-vc" ||
            menu == "exit-ma-pe-vc" ||
            menu == "exit-ma-vc" ||
            menu == "exit-pm-pe-vc" ||
            menu == "exit-pm-vc" ||
            menu == "vc_exit"
          ? "exits"
          : menu == "backedIpos-pe-vc" || menu == "backedIpos-vc"
          ? "ipos"
          : menu == "angel" || menu == "vc-angel"
          ? "angel"
          : menu == "incubation" || menu == "vc-incubation"
          ? "incubation"
          : "";

      const dealCategory =
        category == "investments"
          ? "PEVC"
          : category == "exits"
          ? "EXIT"
          : category == "ipos"
          ? "IPO"
          : category == "angel"
          ? "ANGEL"
          : "INCUBATOR";

      const sortBy =
        dealCategory == "PEVC"
          ? "pevc_latest_inv_date_num"
          : dealCategory == "EXIT"
          ? "exit_deal_date_num"
          : dealCategory == "IPO"
          ? "ipo_date_num"
          : dealCategory == "ANGEL"
          ? "angel_latest_deal_date_num"
          : "incubator_deal_date_num";

      // console.log(dealsView, "vvv");
      axios(
        isFromGlobalSearch() ? getPEGlobalSearchCompaniesApi : getPEDealsApi,
        {
          method: "POST",
          signal: abortRef.current.signal,
          data: isFromGlobalSearch()
            ? {
                searchTerm:
                  refineSearchFilters[location.pathname]?.globalSearch[0]
                    ?.searchTerm,
                type: refineSearchFilters[location.pathname]?.globalSearch[0]
                  ?.type,
                dealCategory: dealCategory,
                type_id: 1,
                page: page,
                sortBy: sortBy,
                sortDirection: order,
              }
            : {
                category: category,
                type:
                  menu == "pe"
                    ? "pe_vc_investment"
                    : menu == "vc"
                    ? "vc_investment"
                    : menu == "exit-pe-vc"
                    ? "pe_vc_exit"
                    : menu == "exit-ma-pe-vc"
                    ? "pe_ma_exit"
                    : menu == "vc_exit"
                    ? "vc_exit"
                    : menu == "exit-pm-pe-vc"
                    ? "pe_pm_exit"
                    : menu == "exit-pm-vc"
                    ? "vc_pm_exit"
                    : menu == "exit-ma-vc"
                    ? "vc_ma_exit"
                    : menu == "backedIpos-pe-vc"
                    ? "pe_vc_ipo"
                    : menu == "backedIpos-vc"
                    ? "vc_ipo"
                    : "",
                date_range: {
                  from_date: moment(month[0]).format("YYYY-MM-DD"),
                  to_date: moment(month[1]).format("YYYY-MM-DD"),
                },
                order_by: {
                  column: column,
                  direction: order,
                },
                tab_category: "company",
                page: page,
                // "industry" : refineFilterValues.industry, //Industries //[1],
                industry: refineSearchFilters[location.pathname]?.industry?.map(
                  (value) => value.id
                ), //Industries //[1],
                sector: refineSearchFilters[location.pathname]?.sector?.map(
                  (value) => value.id
                ), //Industries //[1],
                companyIds:
                  refineSearchFilters[location.pathname]?.globalSearch?.at(0)
                    ?.value,
                stage: refineSearchFilters[location.pathname]?.stage?.map(
                  (value) => value.id
                ),
                round: refineSearchFilters[location.pathname]?.round?.map(
                  (value) => value.name
                ),
                valuation: refineSearchFilters[
                  location.pathname
                ]?.valuations?.map((valuation) => valuation.value),
                investor_headquaters: refineSearchFilters[
                  location.pathname
                ]?.investorHeadquarters?.map((inv) => inv.id),
                deal_type: refineSearchFilters[
                  location.pathname
                ]?.dealType?.map((type) => type.id),
                company_type: refineSearchFilters[
                  location.pathname
                ]?.companyType?.map((type) => type.id),
                year_founded: refineSearchFilters[
                  location.pathname
                ]?.yearFounded?.at(0) && {
                  from: refineSearchFilters[location.pathname]?.yearFounded[0]
                    ?.name,
                  to: refineSearchFilters[location.pathname]?.yearFounded[1]
                    ?.name,
                },
                syndication: refineSearchFilters[
                  location.pathname
                ]?.syndication?.at(0)?.value
                  ? [
                      refineSearchFilters[location.pathname]?.syndication?.at(0)
                        ?.value,
                    ]
                  : [],
                deals_involving: refineSearchFilters[
                  location.pathname
                ]?.dealsInvolving?.map((deal) => deal.value),
                country: refineSearchFilters[location.pathname]?.countries?.map(
                  (country) => country.id
                ),
                investor_type: refineSearchFilters[
                  location.pathname
                ]?.investorType?.map((inv) => inv.id),
                investor_sale: refineSearchFilters[
                  location.pathname
                ]?.investorSaleInipo?.map((inv) => inv.id),
                region: refineSearchFilters[location.pathname]?.region?.map(
                  (region) => region.id
                ),
                state: refineSearchFilters[location.pathname]?.state?.map(
                  (state) => state.id
                ),
                city: refineSearchFilters[location.pathname]?.city?.map(
                  (city) => city.id
                ),
                deal_range: refineSearchFilters[
                  location.pathname
                ]?.dealRange?.at(0) && {
                  from: refineSearchFilters[location.pathname]?.dealRange[0]
                    .name,
                  to: refineSearchFilters[location.pathname]?.dealRange[1].name,
                },
                exit_status: refineSearchFilters[
                  location.pathname
                ]?.exitStatus?.map((status) => status.id),
                exit_type: refineSearchFilters[
                  location.pathname
                ]?.exitType?.map((type) => type.id),
                exited: refineSearchFilters[location.pathname]?.exited?.map(
                  (exit) => exit.id
                ),
                vc_fund: refineSearchFilters[location.pathname]?.vcFunded?.map(
                  (vc) => vc.id
                ),
                incubation_status: refineSearchFilters[
                  location.pathname
                ]?.incubationStatus?.map((vc) => vc.id),
                defunct: refineSearchFilters[
                  location.pathname
                ]?.excludeDefunctCos?.at(0)?.value
                  ? 1
                  : 0,
                firm_type: refineSearchFilters[
                  location.pathname
                ]?.firmType?.map((firm) => firm.id),
                return_multiple: refineSearchFilters[
                  location.pathname
                ]?.returnMultiple?.at(0) && {
                  from: refineSearchFilters[
                    location.pathname
                  ]?.returnMultiple?.at(0)?.name,
                  to: refineSearchFilters[
                    location.pathname
                  ]?.returnMultiple?.at(1)?.name,
                },
                tag:
                  (refineSearchFilters[location.pathname]?.tags?.length ?? 0) !=
                  0
                    ? {
                        operator:
                          refineSearchFilters[location.pathname]?.operator,
                        data: refineSearchFilters[location.pathname]?.tags?.map(
                          (tag) => tag.name
                        ),
                      }
                    : null,
                group: {
                  group_id:
                    groupId?.myList?.map((id) => id.group_id)?.toString() ??
                    null,
                  sub_type_master_id:
                    groupId?.myList?.length > 0 ? sub_type_master_id : null,
                },
              },
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          }, // console.log('Incremental Issue', i+1);
        }
      )
        .then((response) => {
          console.log(response, "tableresponse");
          if (response?.data?.message == "Success") {
            setCurrentFilter(response.config.data);
            setIsLoad(false);
            setIsDataLoad(false);
            setIsMonthPickerChanged(0);
            setRows([]);
            setRows(response?.data?.deals || response?.data?.companies);
            setTableCompanyId([]);
            setTableCompanyId(
              response?.data?.all_companies_ids?.map(JSON.parse)
            );
            setDealIds([]);
            setDealIds(response?.data?.all_deal_ids)
            setData(response?.data);
          }
        })
        .catch((error) => {
          if (error?.response?.status == Constants.sessionTerminatedStatus) {
            logout();
          }
        });
    },
    [
      monthPickerValue,
      refineSearchFilters[location.pathname],
      // dealsView
    ]
  );

  console.log(dealIds, tableCompanyId)

  useEffect(() => {
    const abortController = new AbortController();
    abortRef.current = abortController;
    setIsLoad(true);
    setIsDataLoad(true);
    setCurrentPage(currentPage);
    tableRender(
      currentPage,
      monthPickerValue,
      sort.orderBy,
      sort.order,
      groupId
    );
    return () => {
      abortController.abort();
    };
  }, [
    tableRender,
    monthPickerValue,
    refineSearchFilters,
    groupId,
    reload,
    // dealsView,
  ]);

  const filteredRows = useMemo(
    () => filterRows(rows, filters),
    [rows, filters]
  );
  const count = filteredRows.length;

  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor.
   * @param accessor - The accessor parameter is a value that represents the property or key of the data
   * object that you want to sort by. It is used to determine which property to sort the data by.
   */
  const handleSort = (accessor) => {
    setCurrentPage(currentPage);
    setRows([]);
    setIsLoad(true);
    setIsDataLoad(true);
    setSort((prevSort) => ({
      order:
        prevSort.order == "asc" && prevSort.orderBy == accessor
          ? "desc"
          : "asc",
      orderBy: accessor,
    }));
    tableRender(
      currentPage,
      monthPickerValue,
      accessor,
      sort.order == "asc" && sort.orderBy == accessor ? "desc" : "asc",
      groupId
    );
  };

  useEffect(() => {}, [location?.state?.isFromGlobalSearch]);

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div
              className={
                "overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] " +
                (rows.length > 5 ? "" : "pr-2")
              }
            >
              <table
                className="listPage  table  w-full p-1 bg-grey-50 divide-y"
                width="50%"
              >
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor == sort.orderBy) {
                          if (sort.order == "asc") {
                            return (
                              <SortDescendingIcon className="pl-2 block h-[15px]" />
                            );
                          }
                          return (
                            <SortAscendingIcon className="pl-2 block h-[15px]" />
                          );
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />;
                        }
                      };
                      return (
                        <th
                          className={
                            i == 0
                              ? menu == "backedIpos-pe-vc" ||
                                menu == "backedIpos-vc"
                                ? "md:pl-5 md:pr-5 pl-5 pr-[10rem] lg:pr-[9rem] text-left lg:relative bg-[#EBEBEB] sticky left-0 "
                                : i == 0
                                ? " text-left bg-[#EBEBEB] lg:relative  sticky left-0 pl-5"
                                : "md:pl-5 md:pr-5 pl-5  pr-[10rem] lg:pr-[6rem] "
                              : "px-5 " + " py-2 font-sans_book_body"
                          }
                          key={i}
                        >
                          <span
                            className={`flex whitespace-nowrap ${
                              column.accessor == "add" &&
                              groupId?.myList?.length > 0 &&
                              (menu == "angel" ||
                                menu == "vc-angel" ||
                                menu == "incubation" ||
                                menu == "vc-incubation")
                                ? "pl-3"
                                : ""
                            }`}
                          >
                            <OverflowToolTip
                              component={
                                <a className="cursor-text hover:text-[#000000]">
                                  {column.accessor == "add" &&
                                  groupId?.myList?.length > 0
                                    ? "Delete"
                                    : column.label
                                    ? column.label
                                    : "--"}
                                </a>
                              }
                            />
                            {column.accessor == "add" ||
                            column.label == "Investor" ? (
                              ""
                            ) : (
                              <button
                                onClick={() =>
                                  handleSort(column.accessor, sort.order)
                                }
                              >
                                {sortIcon()}
                              </button>
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {!isLoad ? (
                  count > 0 ? (
                    <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rows.length > 0 ? (
                        menu == "pe" || menu == "vc" ? (
                          rows?.map((row, i) => {
                            return (
                              <tr
                                key={i}
                                className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white "
                              >
                                <td
                                  className="md:w-[20%] px-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333]  bg-white lg:relative  py-3 font-sans_book_body items-center  justify-center"
                                  style={{ whiteSpace: "initial" }}
                                >
                                  {row?.logo_path == "default.png" ||
                                  row?.logo_path == "/default.png" ||
                                  !row?.logo_path ? (
                                    <RandomLogo name={row?.company_name} />
                                  ) : (
                                    <CompanyLogo
                                      id={row?.company_id}
                                      name={row.company_name}
                                      page="company page"
                                      logo={row?.logo_path}
                                    />
                                  )}
                                  <OverflowToolTip
                                    className="w-[11rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] "
                                    style={{ whiteSpace: "initial" }}
                                    component={
                                      <NavLink
                                        className="flex text-start leading-[1.8rem] lg:inline lg:whitespace-nowrap hover:text-[#BC8B20]"
                                        state={{
                                          prevPath: location.pathname,
                                          sub_type_master_id:
                                            sub_type_master_id,
                                        }}
                                        to={{
                                          pathname:
                                            menu == "vc"
                                              ? routeNames.vcCompanyInvestmentDealById(
                                                  row.company_id,
                                                  row.deal_id
                                                )
                                              : routeNames.peCompanyInvestmentDealById(
                                                  row.company_id,
                                                  row.deal_id
                                                ),
                                          search: getSection(
                                            menu == "vc"
                                              ? Constants.primaryMasterSubIds
                                                  .vc_investments_id
                                              : Constants.primaryMasterSubIds
                                                  .pe_vc_investments_id
                                          ),
                                        }}
                                      >
                                        {row.company_name
                                          ? row.company_name
                                          : "--"}
                                      </NavLink>
                                    }
                                  />
                                </td>

                                <td
                                  className=" md:w-[20%] pl-5  py-3 font-sans_book_body"
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className="w-[12rem] lg:w-auto lg:max-w-[20rem] 2xl:max-w-[30rem]"
                                    component={
                                      <a className="text-start hover:text-[#000000] cursor-text ">
                                        {row.sector ? row.sector : "--"}
                                      </a>
                                    }
                                  />
                                </td>

                                <td className="md:w-[15%] md:pr-[4.5%] xl:pr-[4%] 2xl:pr-[4.8%] py-3 md:px-3 pr-10 font-sans_book_body text-right">
                                  <OverflowToolTip
                                    className=" w-[10rem] lg:w-auto sm:max-w-[15rem] 2xl:max-w-[30rem] flex"
                                    component={
                                      <Link
                                        rel="noopener noreferrer"
                                        state={{
                                          prevPath:
                                            location.state?.prevPath ?? "",
                                        }}
                                        to={
                                          menu == "exit-pe-vc" ||
                                          menu == "exit-ma-pe-vc" ||
                                          menu == "exit-pm-pe-vc"
                                            ? routeNames.peInvestorById(
                                                row.investor_id
                                              )
                                            : routeNames.vcInvestorById(
                                                row.investor_id
                                              )
                                        }
                                      >
                                        {row?.investor_name?.length > 0
                                          ? row?.investor_name?.map(
                                              (invest, i) => {
                                                return (
                                                  <>
                                                    {row?.investor_name
                                                      ?.length -
                                                      1 ==
                                                    i ? (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                            "exit-pe-vc" ||
                                                          menu ==
                                                            "exit-ma-pe-vc" ||
                                                          menu ==
                                                            "exit-pm-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          invest.investor_name
                                                        }
                                                        entityId={
                                                          invest?.investor_id
                                                        }
                                                      />
                                                    ) : (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                            "exit-pe-vc" ||
                                                          menu ==
                                                            "exit-ma-pe-vc" ||
                                                          menu ==
                                                            "exit-pm-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          invest.investor_name +
                                                          ", "
                                                        }
                                                        entityId={
                                                          invest?.investor_id
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          : "--"}
                                      </Link>
                                    }
                                  />
                                </td>
                                <td className="md:w-[10%] px-5 py-3 font-sans_book_body ">
                                  <Link
                                    className="hover:text-[#BC8B20]"
                                    state={{
                                      prevPath: location.pathname,
                                      // dealType: row.latest_deal_type,
                                    }}
                                    to={{
                                      pathname:
                                        menu == "vc"
                                          ? routeNames.vcCompanyInvestmentDealById(
                                              row.company_id,
                                              row.deal_id || row.id
                                            )
                                          : routeNames.peCompanyInvestmentDealById(
                                              row.company_id,
                                              row.deal_id || row.id
                                            ),
                                    }}
                                  >
                                    {row.date ? row.date : "--"}
                                  </Link>
                                </td>
                                {/* <td className="md:w-[10%] px-5 py-3 font-sans_book_body" ><OverflowToolTip component={row.latest_deal_type ? row.latest_deal_type : "--"} /></td> */}
                                <td className="md:w-[10%] px-5 py-3 font-sans_book_body ">
                                  <OverflowToolTip
                                    component={
                                      <a className="hover:text-[#000000] cursor-text">
                                        {row.exit_status
                                          ? row.exit_status
                                          : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td className="md:w-[10%] pr-[3.1rem] md:pr-[2.8rem] lg:pr-[3rem] px-3 py-3 font-sans_book_body text-right">
                                  <a>
                                    {row.amount == 0 || row.amount == 0.0
                                      ? "--"
                                      : row.amount}
                                  </a>
                                </td>
                                <td className="md:w-[5%] px-[10px] py-3 font-sans_book_body ">
                                  {groupId?.myList?.length > 0 ? (
                                    <DeleteItemsFromGroup
                                      id={row.company_id}
                                      group_id={row?.grp_lt_item_id}
                                      type_id={1}
                                      setReload={setReload}
                                    />
                                  ) : (
                                    <AddNotePopover
                                      className="mx-3"
                                      key={row.company_id}
                                      companyId={row.company_id}
                                      companyName={row.company_name}
                                      type_id={1}
                                      sub_type_master_id={sub_type_master_id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : menu == "exit-pe-vc" ||
                          menu == "exit-ma-pe-vc" ||
                          menu == "exit-ma-vc" ||
                          menu == "exit-pm-pe-vc" ||
                          menu == "exit-pm-vc" ||
                          menu == "vc_exit" ? (
                          rows?.map((row, i) => {
                            return (
                              <tr
                                key={i}
                                className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text"
                              >
                                <td
                                  className="md:w-[20%] leading-[1.8rem] lg:relative lg:leading-[2rem] text-left sticky left-0 lg:relative  text-[#333333] w-[3rem] bg-white  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  {row?.logo_path == "default.png" ||
                                  row?.logo_path == "/default.png" ||
                                  !row?.logo_path ? (
                                    <RandomLogo name={row?.company_name} />
                                  ) : (
                                    <CompanyLogo
                                      name={row.company_name}
                                      id={row?.company_id}
                                      page="company page"
                                      logo={row?.logo_path}
                                    />
                                  )}
                                  <OverflowToolTip
                                    className=" w-[12rem] md:w-[10rem] lg:w-auto 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis"
                                    component={
                                      <NavLink
                                        className="flex lg:inline text-start  hover:text-[#BC8B20]"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "exit-pe-vc" ||
                                            menu == "exit-ma-pe-vc" ||
                                            menu == "exit-pm-pe-vc"
                                              ? routeNames.peCompanyFundById(
                                                  row.company_id
                                                )
                                              : routeNames.vcCompanyFundById(
                                                  row.company_id
                                                ),
                                          search: getSection(
                                            menu == "exit-pe-vc" ||
                                              menu == "vc_exit"
                                              ? Constants.primaryMasterSubIds
                                                  .pe_vc_exits_id
                                              : menu == "exit-ma-pe-vc" ||
                                                menu == "exit-ma-vc"
                                              ? Constants.primaryMasterSubIds
                                                  .pe_vc_ma_id
                                              : Constants.primaryMasterSubIds
                                                  .pe_vc_pm_id
                                          ),
                                        }}
                                      >
                                        {/* <span style={{display:"flex"}} className="lg:grid grid-cols-4 gap-1"> */}
                                        <a className="hover:text-[#BC8B20] ">
                                          {row.company_name
                                            ? row.company_name
                                            : "--"}
                                        </a>
                                        {/* </span> */}
                                      </NavLink>
                                    }
                                  />
                                </td>
                                <td
                                  className="md:w-20% px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className=" w-[10rem] lg;w-auto 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis"
                                    component={
                                      <a className="hover:text-[#000000] cursor-text">
                                        {row.sector ? row.sector : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td
                                  className="md:w-20% px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className=" w-[10rem] lg:w-auto sm:max-w-[15rem] 2xl:max-w-[30rem]"
                                    component={
                                      <Link
                                        rel="noopener noreferrer"
                                        state={{
                                          prevPath:
                                            location.state?.prevPath ?? "",
                                        }}
                                        to={
                                          menu == "exit-pe-vc" ||
                                          menu == "exit-ma-pe-vc" ||
                                          menu == "exit-pm-pe-vc"
                                            ? routeNames.peInvestorById(
                                                row.investor_id
                                              )
                                            : routeNames.vcInvestorById(
                                                row.investor_id
                                              )
                                        }
                                      >
                                        {row?.investor_name?.length > 0
                                          ? row?.investor_name?.map(
                                              (invest, i) => {
                                                return (
                                                  <>
                                                    {row?.investor?.length -
                                                      1 ==
                                                    i ? (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                            "exit-pe-vc" ||
                                                          menu ==
                                                            "exit-ma-pe-vc" ||
                                                          menu ==
                                                            "exit-pm-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          invest.investor_name
                                                        }
                                                        entityId={
                                                          invest?.investor_id
                                                        }
                                                      />
                                                    ) : (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                            "exit-pe-vc" ||
                                                          menu ==
                                                            "exit-ma-pe-vc" ||
                                                          menu ==
                                                            "exit-pm-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          invest.investor_name +
                                                          ", "
                                                        }
                                                        entityId={
                                                          invest?.investor_id
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          : "--"}
                                      </Link>
                                    }
                                  />
                                </td>
                                <td className="md:w-[10%] px-5 py-3 font-sans_book_body ">
                                  <OverflowToolTip
                                    component={
                                      <a className="hover:text-[#000000]">
                                        {" "}
                                        {row.exit_status
                                          ? row.exit_status
                                          : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td className="md:w-[10%] px-4 py-3 font-sans_book_body ">
                                  <Link
                                    className="hover:text-[#BC8B20] whitespace-nowrap"
                                    state={{ prevPath: location.pathname }}
                                    to={{
                                      pathname:
                                        menu == "exit-pe-vc" ||
                                        menu == "exit-ma-pe-vc" ||
                                        menu == "exit-pm-pe-vc"
                                          ? routeNames.peCompanyExitsDealById(
                                              row.company_id,
                                              row.latest_deal_id || row.deal_id
                                            )
                                          : routeNames.vcCompanyExitsDealById(
                                              row.company_id,
                                              row.latest_deal_id || row.deal_id
                                            ),
                                    }}
                                  >
                                    {row.date ? row.date : "--"}
                                  </Link>
                                </td>
                                <td className="md:w-[15%] pr-[3.1rem] md:pr-[2.7rem] py-3 px-3 font-sans_book_body text-right">
                                  <a>
                                    {row.amount_realized == 0 ||
                                    row.amount_realized == 0.0
                                      ? "--"
                                      : row.amount}
                                  </a>
                                </td>
                                <td className="md:w-[5%] px-[10px] py-3 font-sans_book_body ">
                                  {groupId?.myList?.length > 0 ? (
                                    <DeleteItemsFromGroup
                                      id={row.company_id}
                                      group_id={row?.grp_lt_item_id}
                                      type_id={1}
                                      setReload={setReload}
                                    />
                                  ) : (
                                    <AddNotePopover
                                      className="mx-3"
                                      key={row.company_id}
                                      companyId={row.company_id}
                                      companyName={row.company_name}
                                      type_id={1}
                                      sub_type_master_id={sub_type_master_id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : menu == "backedIpos-pe-vc" ||
                          menu == "backedIpos-vc" ? (
                          rows?.map((row, i) => {
                            return (
                              <tr
                                key={i}
                                className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text"
                              >
                                <td
                                  className=" leading-[1.8rem] text-left sticky lg:relative left-0  text-[#333333] lg:relative w-[3rem] bg-white lg:leading-[2rem] w-[20%]  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  {row?.logo_path == "default.png" ||
                                  row?.logo_path == "/default.png" ||
                                  !row?.logo_path ? (
                                    <RandomLogo name={row?.company_name} />
                                  ) : (
                                    <CompanyLogo
                                      name={row.company_name}
                                      id={row?.company_id}
                                      page="company page"
                                      logo={row?.logo_path}
                                    />
                                  )}
                                  <OverflowToolTip
                                    className="w-[13rem] md:w-[12rem] lg:w-auto"
                                    component={
                                      <NavLink
                                        className="hover:text-[#BC8B20]"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "backedIpos-pe-vc"
                                              ? routeNames.peCompanyFundById(
                                                  row.company_id,
                                                  routePathSegments.ipos
                                                )
                                              : routeNames.vcCompanyFundById(
                                                  row.company_id,
                                                  routePathSegments.ipos
                                                ),
                                          search: getSection(
                                            Constants.primaryMasterSubIds
                                              .pe_vc_backed_ipos_id
                                          ),
                                        }}
                                      >
                                        <a className="flex lg:inline text-start  hover:text-[#BC8B20]">
                                          {row.company_name
                                            ? row.company_name
                                            : "--"}
                                        </a>
                                      </NavLink>
                                    }
                                  />
                                </td>
                                <td
                                  className=" w-[25%] px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className="w-[10rem] lg:w-auto max-w-[15rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis"
                                    component={
                                      <a className="hover:text-[#000000] cursor-text">
                                        {row.sector ? row.sector : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td
                                  className=" w-[24%] px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className="w-[10rem] lg:w-auto sm:max-w-[17rem] 2xl:max-w-[30rem]"
                                    component={
                                      <Link
                                        rel="noopener noreferrer"
                                        state={{
                                          prevPath:
                                            location.state?.prevPath ?? "",
                                        }}
                                        to={routeNames.peInvestorById(
                                          row.investor_id
                                        )}
                                      >
                                        {row?.investor_name?.length > 0
                                          ? row?.investor_name?.map(
                                              (inve, i) => {
                                                return (
                                                  <>
                                                    {row?.investor_name
                                                      ?.length -
                                                      1 ==
                                                    i ? (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                          "backedIpos-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          inve.investor_name
                                                        }
                                                        entityId={
                                                          inve?.investor_id
                                                        }
                                                      />
                                                    ) : (
                                                      <CustomNavigation
                                                        mainModule={
                                                          menu ==
                                                          "backedIpos-pe-vc"
                                                            ? "pe"
                                                            : "vc"
                                                        }
                                                        module="investor"
                                                        name={
                                                          inve.investor_name +
                                                          ", "
                                                        }
                                                        entityId={
                                                          inve?.investor_id
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          : "--"}
                                      </Link>
                                    }
                                  />
                                  {/* <OverflowToolTip component={<a className='hover:text-[#BC8B20]'>{row.investor ? row.investor : "--"}</a>} /> */}
                                </td>
                                <td className=" w-[10%]  px-5 py-3 font-sans_book_body ">
                                  <OverflowToolTip
                                    component={
                                      <Link
                                        state={{ prevPath: location.pathname }}
                                        className="hover:text-[#BC8B20] whitespace-nowrap"
                                        to={{
                                          pathname:
                                            menu == "backedIpos-pe-vc"
                                              ? routeNames.peCompanyBackedIPOSDealById(
                                                  row.company_id,
                                                  row.latest_deal_id ||
                                                    row.deal_id
                                                )
                                              : routeNames.vcCompanyBackedIPOSDealById(
                                                  row.company_id,
                                                  row.latest_deal_id ||
                                                    row.deal_id
                                                ),
                                        }}
                                      >
                                        {row.date ? row.date : "--"}
                                      </Link>
                                    }
                                  />
                                </td>
                                <td className=" w-[10%] pr-[2.9rem] py-3 px-3 font-sans_book_body text-right">
                                  <a>
                                    {row.ipo_size == 0 || row.ipo_size == 0.0
                                      ? "--"
                                      : row.ipo_size}
                                  </a>
                                </td>
                                <td className="w-[5%] px-[10px] py-3 font-sans_book_body ">
                                  {groupId?.myList?.length > 0 ? (
                                    <DeleteItemsFromGroup
                                      id={row.company_id}
                                      group_id={row?.grp_lt_item_id}
                                      type_id={1}
                                      setReload={setReload}
                                    />
                                  ) : (
                                    <AddNotePopover
                                      className="mx-3"
                                      key={row.company_id}
                                      companyId={row.company_id}
                                      companyName={row.company_name}
                                      type_id={1}
                                      sub_type_master_id={sub_type_master_id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : menu == "angel" || menu == "vc-angel" ? (
                          rows?.map((row, i) => {
                            return (
                              <tr
                                key={i}
                                className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text"
                              >
                                <td
                                  className="leading-[1.8rem] text-left lg:relative sticky left-0 text-[#333333] w-[3rem] bg-white lg:leading-[2rem] w-[15%]  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  {row?.logo_path == "default.png" ||
                                  row?.logo_path == "/default.png" ||
                                  !row?.logo_path ? (
                                    <RandomLogo name={row?.company_name} />
                                  ) : (
                                    <CompanyLogo
                                      name={row?.company_name}
                                      id={row?.company_id}
                                      page="company page"
                                      logo={row?.logo_path}
                                    />
                                  )}
                                  <OverflowToolTip
                                    className="w-[13rem] lg:w-auto sm:max-w-[13rem] 2xl:max-w-[25rem]"
                                    component={
                                      <NavLink
                                        className="hover:text-[#BC8B20]"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "vc-angel"
                                              ? routeNames.vcCompanyFundById(
                                                  row.company_id
                                                )
                                              : routeNames.peCompanyFundById(
                                                  row.company_id
                                                ),
                                          search: getSection(
                                            Constants.primaryMasterSubIds
                                              .pe_vc_angel_invesments_id
                                          ),
                                        }}
                                      >
                                        <a className=" flex lg:inline text-start  hover:text-[#BC8B20]">
                                          {row.company_name
                                            ? row.company_name
                                            : "--"}
                                        </a>
                                      </NavLink>
                                    }
                                  />
                                </td>
                                <td
                                  className="w-[25%]  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className="w-[10rem] lg:w-auto max-w-[22rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis"
                                    component={
                                      <a className="hover:text-[#000000] cursor-text">
                                        {row.sector ? row.sector : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td
                                  className="w-[22%]  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className="w-[10rem] lg:w-auto sm:max-w-[17rem] 2xl:max-w-[30rem]"
                                    component={
                                      <Link
                                        rel="noopener noreferrer"
                                        className="w-[3rem]"
                                        state={{
                                          prevPath:
                                            location.state?.prevPath ?? "",
                                        }}
                                        to={routeNames.peInvestorById(
                                          row.investor_id
                                        )}
                                      >
                                        {row?.investor_name?.length > 0
                                          ? row?.investor_name?.map(
                                              (inves, i) => {
                                                return (
                                                  <>
                                                    {row?.investor_name
                                                      ?.length -
                                                      1 ==
                                                    i ? (
                                                      <CustomNavigation
                                                        menu={menu}
                                                        mainModule={
                                                          menu == "vc-angel"
                                                            ? "vc"
                                                            : "pe"
                                                        }
                                                        module="investor"
                                                        className="hover:text-[#BC8B20]"
                                                        name={
                                                          inves.investor_name
                                                        }
                                                        entityId={
                                                          inves?.investor_id
                                                        }
                                                      />
                                                    ) : (
                                                      <CustomNavigation
                                                        menu={menu}
                                                        mainModule={
                                                          menu == "vc-angel"
                                                            ? "vc"
                                                            : "pe"
                                                        }
                                                        module="investor"
                                                        className="hover:text-[#BC8B20]"
                                                        name={
                                                          inves.investor_name +
                                                          ", "
                                                        }
                                                        entityId={
                                                          inves?.investor_id
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }
                                            )
                                          : "--"}
                                      </Link>
                                    }
                                  />
                                  {/* <OverflowToolTip component={<a className='hover:text-[#BC8B20]' >{row.investor ? row.investor : "--"}</a>} /> */}
                                </td>
                                <td className="w-[10%]  px-5 py-3 font-sans_book_body ">
                                  <OverflowToolTip
                                    component={
                                      <Link
                                        className="hover:text-[#BC8B20] whitespace-nowrap"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "vc-angel"
                                              ? routeNames.vcCompanyAngelDealById(
                                                  row.company_id,
                                                  row.latest_deal_id
                                                )
                                              : routeNames.peCompanyAngelDealById(
                                                  row.company_id,
                                                  row.latest_deal_id
                                                ),
                                        }}
                                      >
                                        {row.date ? row.date : "--"}
                                      </Link>
                                    }
                                  />
                                </td>
                                <td className="w-[5%] px-[10px] py-3 font-sans_book_body ">
                                  {groupId?.myList?.length > 0 ? (
                                    <DeleteItemsFromGroup
                                      id={row.company_id}
                                      group_id={row?.grp_lt_item_id}
                                      type_id={1}
                                      setReload={setReload}
                                    />
                                  ) : (
                                    <AddNotePopover
                                      className="mx-3"
                                      key={row.company_id}
                                      companyId={row.company_id}
                                      companyName={row.investee}
                                      type_id={1}
                                      sub_type_master_id={sub_type_master_id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : menu == "incubation" || menu == "vc-incubation" ? (
                          rows?.map((row, i) => {
                            console.log(menu, "manuvke");
                            return (
                              <tr
                                key={i}
                                className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text"
                              >
                                <td
                                  className="leading-[1.8rem] text-left lg:relative sticky left-0 lg:relative text-[#333333] w-[3rem] bg-white lg:leading-[2rem] w-[15%]  px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  {row?.logo_path == "" ||
                                  row?.logo_path == "/default.png" ||
                                  !row?.logo_path ? (
                                    <RandomLogo name={row?.incubatee_name} />
                                  ) : (
                                    <CompanyLogo
                                      name={row.incubatee_name}
                                      id={row?.company_id}
                                      page="company page"
                                      logo={row?.logo_path}
                                    />
                                  )}
                                  <OverflowToolTip
                                    className="w-[10rem] lg:w-auto"
                                    component={
                                      <Link
                                        rel="noopener noreferrer"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "vc-incubation"
                                              ? routeNames.vcCompanyFundById(
                                                  row.company_id
                                                )
                                              : routeNames.peCompanyFundById(
                                                  row.company_id
                                                ),
                                          search: getSection(
                                            Constants.primaryMasterSubIds
                                              .pe_vc_incubation_id
                                          ),
                                        }}
                                      >
                                        <a className=" flex lg:inline text-start  hover:text-[#BC8B20]">
                                          {row.incubatee_name
                                            ? row.incubatee_name
                                            : "--"}
                                        </a>
                                      </Link>
                                    }
                                  />
                                </td>
                                <td
                                  className=" w-[26%] px-5 py-3 font-sans_book_body "
                                  style={{ whiteSpace: "initial" }}
                                >
                                  <OverflowToolTip
                                    className=" w-[10rem] lg:w-auto max-w-[30rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis"
                                    component={
                                      <a className="hover:text-[#000000] cursor-text">
                                        {row.sector ? row.sector : "--"}
                                      </a>
                                    }
                                  />
                                </td>
                                <td className="w-[15%]  px-5 py-3 font-sans_book_body ">
                                  <Link
                                    rel="noopener noreferrer"
                                    className="hover:text-[#BC8B20] lg:whitespace-nowrap"
                                    state={{ prevPath: location.pathname }}
                                    to={
                                      menu == "vc-incubation"
                                        ? routeNames.vcIncubationInvestorById(
                                            row.incubator_id
                                          )
                                        : routeNames.peIncubationInvestorById(
                                            row.incubator_id
                                          )
                                    }
                                  >
                                    {console.log(row?.incubator_name, "incub")}
                                    {row.incubator_name ? (
                                      <CustomNavigation
                                        mainModule={
                                          menu === "vc-incubation" ? "vc" : "pe"
                                        }
                                        module="investor/incubation"
                                        name={row.incubator_name.incubator}
                                        entityId={
                                          row.incubator_name.incubator_id
                                        }
                                      />
                                    ) : (
                                      "--"
                                    )}
                                  </Link>

                                  {/* <a className='hover:text-[#BC8B20]' >{row.incubator ? row.incubator : "--"}</a> */}
                                </td>
                                <td className=" w-[10%] px-5 py-3 font-sans_book_body ">
                                  <OverflowToolTip
                                    component={
                                      <Link
                                        className="hover:text-[#BC8B20] whitespace-nowrap"
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname:
                                            menu == "vc-incubation"
                                              ? routeNames.vcCompanyIncubationDealById(
                                                  row.company_id,
                                                  row.deal_id
                                                )
                                              : routeNames.peCompanyIncubationDealById(
                                                  row.company_id,
                                                  row.deal_id
                                                ),
                                        }}
                                      >
                                        {row.date ? row.date : "--"}
                                      </Link>
                                    }
                                  />
                                </td>
                                <td className="w-[5%] px-[10px] py-3 font-sans_book_body ">
                                  {groupId?.myList?.length > 0 ? (
                                    <DeleteItemsFromGroup
                                      id={row.company_id}
                                      group_id={row?.grp_lt_item_id}
                                      type_id={1}
                                      setReload={setReload}
                                    />
                                  ) : (
                                    <AddNotePopover
                                      className="mx-3"
                                      key={row.company_id}
                                      companyId={row.company_id}
                                      companyName={row.company_name}
                                      type_id={1}
                                      sub_type_master_id={sub_type_master_id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          ""
                        )
                      ) : (
                        <center>
                          <div className="h-[4rem] text-[12pt] lg:text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">
                            No data found
                          </div>
                        </center>
                      )}
                    </tbody>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </table>
              {!isLoad ? (
                count > 0 ? (
                  <></>
                ) : (
                  <div className="pr-2">
                    <div className="w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative">
                      <NoDataPlaceholder />
                    </div>
                  </div>
                )
              ) : (
                <div className="w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isFromGlobalSearch() ? (
        data?.total_num_companies && rows.length > 0 ? (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data?.total_num_companies}
            pageSize={20}
            onPageChange={(page) => findActivePage(page)}
          />
        ) : (
          ""
        )
      ) : data?.tot_num_deals && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.tot_num_deals}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Table;
