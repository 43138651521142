import { Fragment, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { createRequestUrl } from '../Config/config'
import closeIcon from '../images/search_close_button.png'
import { toast } from "react-toastify";
import { RaiseRequestContext } from '../Contexts/RaiseRequestContext'
import OutlineButton from './shared/OutlineButton'
import PrimaryButton from './shared/PrimaryButton'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../Contexts/UserContext'
import { useLocation } from 'react-router-dom'

/**
 * The `ChatPopup` component is a form that allows users to raise a request by entering a subject,
 * description, and other details.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `ChatPopup` component. These properties can be accessed using dot notation, such as `props.product`
 * or `props.data`.
 * @returns The ChatPopup component is returning a JSX element.
 */
const ChatPopup = (props) => {
  const {setOpen, setOpenTable, requestMailID,subjectData } = useContext(RaiseRequestContext)
  const [subject, setSubject] = useState(subjectData)
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const {getToken, getUser} = useContext(UserContext);
  const location = useLocation()


  const subjecthandleChange = (e) => {
    setSubject(e.target.value);
  }
  const messagehandleChange = (e) => {
    setMessage(e.target.value);
  }
  const resetRaiseRequest = () => {
    if(location.pathname!='/setting'){
    setSubject("");
    }
    setMessage('');

   
  }
  const submitRaiseRequest = () => {
    if (subject  === "") {
      toast.warning('Subject must not be empty')
      return;
    
    }
    setIsLoading(true);
    createRequest();      
  }

  const createRequest = () => {
    axios.post(createRequestUrl, {
      "subject":  subject,
      "description": window.location.href + " - " + message,
      "product": props.product,
      "url": window.location.href,
      "author": getUser().email,
      "to":  requestMailID.commonEmailId,
      "userId": getUser().id
    },{
      headers:{
        "Authorization": getToken()
      }
    }).then(response => {
      setIsLoading(false);
      setOpen(false);
      setOpenTable(false);
      if (response.status === 200) {
        toast.success(response.data?.message)
      } else {
        toast.error(response.data?.message)
      }
    }).catch(error => {
      setIsLoading(false);
      toast.error("Something went wrong! Please try again later")
    })
  }

  const handleSubmit = (e) => {  
    e.preventDefault();
    submitRaiseRequest();
  }
  const isClose = () => {
    setOpen(false)
    setOpenTable(false)
  }
  return (
    <>
      <div className='fixed right-1 bottom-0'>
        <div>
          <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0  bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-10 ">
                <div className="flex max-h-screen justify-end items-end mx-3 md:mx-0 md:mr-4 w-[94%] md:w-full text-center sm:items-center absolute bottom-[1rem] xl:bottom-[4rem] right-[0]">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4  sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#EAEAEA] text-left  transition-all  max-w-sm  h-full
                ">
                      <form onSubmit={(e) => { handleSubmit(e) }}>
                        <div className='bg-[#BC8B20] text-[#FFFFFF]  font-sans_book_body text-[18px] leading-[21px] w-full h-12 text-center  mb-5'>
                          <p className='py-3'> Raise Your Request</p>
                          {/* <img src={closeIcon} className="h-8 w-8 " /> */}
                          {/* close button */}
                          <div className="absolute top-[8px] right-0  sm:block">
                            <span className="sr-only">Close</span>
                            <img src={closeIcon} className="w-6 mt-1 h-6 mr-2 cursor-pointer"
                              onClick={isClose}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="sm:flex sm:items-start">
                            <div className="my-3 mx-3 text-left">
                              <Dialog.Title as="h3" className="text-sm mt-1 px-4 leading-6 text-[#333333]">
                                <label  className="block text-[#333333] leading-[17px]  font-sans_book_body text-[14px] mb-2">
                                  Subject
                                </label>
                                {
                                // !props?.data ?
                                //   <input className="appearance-none border font-sans_book_body rounded-md rounded w-[20rem] p-3 text-black focus:outline-[#f3f3f3]" placeholder='Please enter subject'
                                //     type='text' value={subjectData} readOnly
                                //   />
                                //   :
                                  <input className="appearance-none border font-sans_book_body rounded-md rounded md:w-[20rem] w-full p-3 text-black focus:outline-[#f3f3f3]" placeholder='Please enter subject'
                                    type='text' value={subject} required onChange={(e) => { subjecthandleChange(e) }} readOnly={location.pathname=='/setting'}
                                  />
                                }{
                                  location.pathname=='/setting' ?
                                  <>
                                  <label className="block mt-3 text-[#333333] leading-[17px]  font-sans_book_body text-[14px] mb-2">
                                 To
                                </label>
                                <input className="disabled appearance-none border font-sans_book_body rounded-md rounded md:w-[20rem] w-full p-3 text-black focus:outline-[#f3f3f3]" placeholder='Please enter subject'
                                  type='text' value={'sales@ventureintelligence.com'} readOnly
                                />
                                  </>
                                  :
                                  <>
                                  <label className="block mt-3 text-[#333333] leading-[17px]  font-sans_book_body text-[14px] mb-2">
                                  Url
                                </label>
                                <input className="disabled appearance-none border font-sans_book_body rounded-md rounded md:w-[20rem] w-full p-3 text-black focus:outline-[#f3f3f3]" placeholder='Please enter subject'
                                  type='text' value={window.location.href} readOnly
                                />
                                  </>

                                }
                                
                                <label className="block mt-3 mb-2 text-[#333333] leading-[17px]  font-sans_book_body text-[14px]  mb-1">
                                  Description
                                </label>
                                <textarea className='md:w-[20rem] w-full lg:max-h-[10rem] font-sans_book_body rounded-md p-3 focus:outline-[#f3f3f3]' id="w3review" name="w3review" rows="8" cols="50" placeholder='Please enter description'
                                  type='text' value={message}  onChange={(e) => { messagehandleChange(e) }}
                                />
                              </Dialog.Title>
                            </div>
                          </div>
                        </div>
                        {/* <div className=' py-3 px-5 justify-between sm:flex bg-[#DFDFDF]'>
                        <button
                            type='submit' value="submit" items-right 
                            className=" inline-flex w-full font-sans_book_body rounded-md border border-gray-300 px-5 py-1 text-base font-medium  bg-white shadow-sm  focus:outline-none focus:ring-2 sm:ml-3 sm:w-auto sm:text-sm  "
                            onClick={resetRaiseRequest}
                          >
                            Reset
                          </button>
                          <button
                            type='submit' value="submit"
                            className=" inline-flex w-full font-sans_book_body rounded-md border border-transparent px-4 py-1 text-base font-medium text-white bg-[#BC8B20] shadow-sm  focus:outline-none focus:ring-2 sm:ml-3 sm:w-auto sm:text-sm  "
                            onClick={submitRaiseRequest}
                          >
                            Submit
                          </button>
                        </div> */}
                        <div className="bg-[#DFDFDF] px-5 py-3 flex flex-row-reverse px-6 ">
                          {isLoading === true
                            ? <PrimaryButton><Spin indicator={<LoadingOutlined style={{ fontSize: 16,color:"white",marginRight:"4px" }} spin />} /> Submitting</PrimaryButton> :
                            <PrimaryButton onClick={submitRaiseRequest}>Submit</PrimaryButton>}
                          <OutlineButton onClick={resetRaiseRequest}>Reset</OutlineButton>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  )
}

export default ChatPopup
