import { React, useState, useMemo, useContext } from 'react'
import { Link, useSearchParams, Routes, Route, useParams, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from '@apollo/client'
import moment from "moment";
import { INVESTOR_FUND } from '../../../Queries/Pe'
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import ExcelPopup from '../../../Components/ExcelPopup'
import DataTable from '../../DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, getInvestorFundsUrl } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import { routeNames } from '../../../routeSegments';
import { UserContext } from '../../../Contexts/UserContext';
import useModule from '../../../hooks/useModule';
import axios from 'axios';
import Constants from '../../../Constants';
import { useEffect } from 'react';
import OverflowToolTip from '../../shared/OverflowToolTip';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';


const InvestorFunds = ({investorName}) => {

    const [fundingData, setFundingData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { getToken, isStudent, isDirectoryUser ,isTrialExport} = useContext(UserContext);
    const { investorId } = useParams();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const location = useLocation();
    const { primaryMasterId } = useModule();
    const { investorsFundsDataFound, setInvestorsFundsDataFound } =
    useContext(DataFoundContext);


   /**
    * The function `getFundsData` makes a POST request to a specified URL with certain parameters, sets
    * the response data as the funding data, and handles any errors that occur.
    */
    const getFundsData = () => {
        setIsLoading(true);
        axios.post(getInvestorFundsUrl, {
            "category": "investments",
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            console.log(!response.data?.data);
            if (response.status === 200 && response.data?.data) {
                setInvestorsFundsDataFound(true);
                setIsLoading(false);
                setFundingData(response.data);
            } else if (!response.data?.data || response?.data?.status === false){
                setInvestorsFundsDataFound(false);
            }
        }).catch(error => {
            setInvestorsFundsDataFound(false);
            setFundingData(null);
            setIsLoading(false);
        })
    }

  /* The `customStringSortMemo` constant is a memoized function that is used for sorting the data in
  the data table in the `InvestorFunds` component. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

   /* The `dealDateSort` constant is a memoized function that is used for sorting the data in the
   "Date" column of the data table in the `InvestorFunds` component. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

   /**
    * The function "addCommasToNumber" takes a number as input and returns a string representation of
    * the number with commas added for thousands separators.
    * @param number - The `number` parameter is the number that you want to add commas to.
    * @returns a string representation of the number with commas added for every three digits.
    */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

      
   /* The `columns` constant is an array of objects that define the columns for the data table in the
   `InvestorFunds` component. Each object represents a column and contains properties such as
   `Header` (the column header text), `accessor` (the key to access the data for that column in each
   row), `className` (the CSS class for the column), and `Cell` (a function that returns the content
   for each cell in the column). */
    const columns = useMemo(() => [
        {
            Header: "Fund Name",
            accessor: "fundName",
            className: "w-[13%] clickable lg:relative text-left sticky left-0  bg-white",
            headerspanClassName: "",
            headerClassName: "text-left lg:relative sticky left-0   w-[3rem] bg-[#EDEDED] ",
            Cell: (cell) => {
                return <OverflowToolTip component={  <Link className='hover:text-[#BC8B20]' state={{ prevPath: location.state?.prevPath ?? "" }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.pe_investors_funds_detail_page(cell.value.id, cell.value.dealId) : routeNames.vc_investors_funds_detail_page(cell.value.id, cell.value.dealId)}>
                    {cell?.value?.name ? cell?.value?.name : ''}
                </Link>}/>

            },
            sortType: customStringSortMemo,

        },
        {
            id: 'date',
            Header: "Date",
            accessor: "date",
            className: "w-[10%]",
            Cell: (cell) => {
                return <Link className='w-[5rem] flex' state={{ prevPath: location.state?.prevPath ?? "" }}
                    to={primaryMasterId === Constants.primaryMasterIds.peId ? routeNames.pe_investors_funds_detail_page(cell.value.id, cell.value.dealId) : routeNames.vc_investors_funds_detail_page(cell.value.id, cell.value.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Target Size (US$ M)",
            accessor: "targetSizeInUS",
            className: "text-right pr-[2.5rem] md:pr-[2.5rem] lg:pr-[2.75rem] w-[1%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Amount Raised (US$ M)",
            accessor: "amountRaisedInUS",
            className: "text-right lg:pr-[2.7rem] pr-[2.5rem]  md:pr-[3rem] w-[1%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },

        },
        {
            Header: "Status",
            accessor: "status",
            className: "w-[14%] whitespace-nowrap"
        },
        {
            Header: "Capital Source",
            accessor: "captitalSource",
            className: "w-[13%]",
            headerClassName: "10%",
        },

    ], [])


   /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
   the data table. It iterates over the `fundingData` array and creates a new array of objects,
   where each object represents a row in the data table. Each object contains properties for the
   fund name, date, target size, amount raised, status, and capital source. */
    const rowData = useMemo(() => {
        let rows = [];

        fundingData?.data?.forEach(invFund => {
            rows.push({
                fundName: { name: invFund?.fund_name, dealId: invFund?.fund_deal_id, id: investorId },
                date: { date: invFund?.deal_date, id: investorId, dealId: invFund?.fund_deal_id },
                targetSizeInUS: invFund?.target_size == 0 ? "" : invFund?.target_size,
                amountRaisedInUS: invFund?.amount_raised == 0 ? "" : invFund?.amount_raised,
                status: invFund?.status,
                captitalSource: invFund?.capital_source == 0 ? "" : invFund?.capital_source,
            })
        });
        setshowXlDownload(rows.length != 0)
        return rows;

    }, [fundingData])
   /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sorting options for
   the data table. It specifies that the data should be initially sorted by the `date` column in
   descending order (`desc: true`). The empty dependency array (`[]`) ensures that the sorting
   options are only calculated once and not re-evaluated on subsequent renders. */
    const initialSort = useMemo(() => [
        {
            id: 'date',
            desc: true
        }
    ], []);


    useEffect(() => {
        getFundsData();
        return () => {
            setInvestorsFundsDataFound(false)
        }
    }, [investorId])

    const pathSeg = location.pathname.split('/');

    const category = pathSeg.includes('exit')
        ? "exits"
        : pathSeg.includes('ipo')
            ? "ipos"
            : pathSeg.includes('angel')
                ? "angel"
                : pathSeg.includes('incubation')
                    ? "incubation"
                    : "investments";


    return (
        <> 
            {investorsFundsDataFound ? <div className=" px-4 bg-[#EDEDED]">
                <div className=" mt-4 bg-white shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] overflow-hidden sm:rounded-lg">
                    <div className="pt-4  px-5 border-b border-b-[#F1F1F1]">
                        <div className="flex justify-between mb-2">
                            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold mb-3">Funds</h3>
                            {!isDirectoryUser ? (isStudent == 'false' && isTrialExport !='false'  && showXlDownload) && <ExcelDownloader
                                url={exportExcel}
                                fileName={Constants.exportNames.portfolio_companies_funds(investorName)}
                                data={{
                                    "type":primaryMasterId,
                                    "export_from": "detail",
                                    "module_type":"investors",
                                    "category": category,
                                    "section":"funds",
                                   "investor_id": [investorId]
                                  }} /> : ""}
                        </div>
                    </div>


                    <div className="space-y-6">
                        <DataTable showLoader={isLoading} columns={columns} data={rowData} sortBy={initialSort} />
                    </div>

                </div>

            </div> : ""}
        </>
    )
}

export default InvestorFunds