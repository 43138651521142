import { React, useState, useMemo, useContext } from 'react'
import Table from './Table'
import { TableHeaderContext } from '../../Contexts/TableHeaderContext'

/**
 * The TableComponent function renders a table component with specified headers, ordering, and
 * pagination.
 * @param props - The `props` parameter is an object that contains any additional properties passed to
 * the `TableComponent` component. These properties can be accessed using dot notation, for example
 * `props.menu` would give you the value of the `menu` property.
 * @returns Table component being rendered with the props "order", "orderBy", "menu", "columns", and
 * "pagination".
 */
const TableComponent = (props) => {
    const { cfsTableHeader, orderBy, cfsOrderingColumn}= useContext(TableHeaderContext);
    console.log(cfsOrderingColumn,'cfs ordering column');
    const [pagination, setPagination] = useState()
    const paginationData = useMemo(() => pagination, pagination);   
    console.log(paginationData,"paginatorInfo")       

    const columns = cfsTableHeader

    return (
      
        <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            <div className="App">
                <Table order="asc" orderBy={cfsOrderingColumn=='S' ? 'SCompanyName' :cfsOrderingColumn} menu={props?.menu} columns={columns} pagination = {paginationData} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent