import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import companyLogo1 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo2 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo3 from "../../../images/logo2/png/logo2.png";
import { ADVISOR_COMPANY_TOACQUIRER } from "../../../Queries/Pe";
import moment from "moment";
import ExcelPopup from "../../../Components/ExcelPopup";
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";

/**
 * The `TransactionAdvisorTo_Acquirer` function is a React component that displays a table of
 * transactions to acquirers.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TransactionAdvisorTo_Acquirer` component. These properties can be accessed using dot notation, such
 * as `props.propertyName`.
 * @returns The `TransactionAdvisorTo_Acquirer` component is returning a JSX element.
 */
const TransactionAdvisorTo_Acquirer = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  let advisorType = "L";
  const{primaryMasterId} = useModule();
  const {isTrialExport,isStudent} = useContext(UserContext)



  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  /* The `customStringSortMemo` constant is a memoized function that is used for custom sorting in a
  DataTable component. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a memoized function that is used for custom sorting in a DataTable
component. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);

/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added as thousands separators.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
  /* The `columns` constant is an array of objects that define the columns for a DataTable component.
  Each object represents a column and contains properties such as `id`, `Header`, `accessor`,
  `className`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: 'acquirerName',
      Header: "Acquirer Name",
      accessor: "acquirerName",
      className:"w-[22%]",
      // headerClassName:"w-[10%]",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center">
          {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }

        <OverflowToolTip component={
        <p
        >
          {value.name ?? "--"}
        </p> } />
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
        Header: "Company Name",
        accessor: "companyName",
        className:"w-[20%]",
        Cell: ({ value }) => {
          return <OverflowToolTip component={<Link className='hover:text-[#BC8B20]'
            state={{ cameFrom: location.state?.prevPath }}
            to={reMatch!=null?routeNames.reCompanyFundById(value?.id)
              :"" }>
            {value.name ?? "--"}
          </Link>} />
        },
        sortType: customStringSortMemo,
      },
      
    {
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className:"w-[12%]",
      
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        to={reMatch!=null?routeNames.reCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
          :""}>
            {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
        </Link>
    
    },
    sortType:dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className:"pl-[8rem] lg:pl-[110px] w-[50%]",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
      // headerspanClassName:"ml-auto"
    },
   
   
  ], []);
  
 /* The `rowData` constant is a memoized function that transforms the `props.toAquirer` data into a
 format that can be used by the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toAquirer?.forEach(companies => {
      rows.push({
        acquirerName: { name: companies?.acquirer_name, id: companies?.acquirer_id, logo_path:companies?.logo_path},
        dealDate: {date:companies?.deal_date, dealId: companies?.deal_id, id: companies?.company_id},
        dealAmountInUS: companies?.deal_amount,
        companyName: { name: companies?.company, id: companies?.company_id },
      })
    });
    setshowXlDownload(rows.length!==0)
    return rows;
  }, [props.toAquirer])
  const { pathname, state } = useLocation();

   /* The `initialSort` constant is a memoized value that is used to set the initial sorting of the
   data in the DataTable component. It is an array that contains an object with two properties: `id`
   and `desc`. */
    const initialSort = useMemo(() => [
      {
          id:"dealDate",
          desc: true
      }
  ], []); 
  return (
    <div className="px-4 bg-[#EDEDED] w-[100%]">
      <div className="bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className=" pt-4 px-5 border-b border-b-[#F1F1F1]  ">
          <div className="flex justify-between mb-3">
            <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:leading-[22px] font-semibold lg:text-[17px]">
              To Acquirers
            </h3>
            {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload)&&<ExcelDownloader
                            url={reExportExcel}
                            fileName={Constants.exportNames.to_acquirers(props.advisorName)}
                            data={{
                              
                              "advisor_type":"T",
                              "section": "to_acquirers",
                              "advisor_id": advisorProfileId
                           }} />}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px]">
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>       
      </div>
    </div>
  );
};

export default TransactionAdvisorTo_Acquirer;
