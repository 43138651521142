import React from 'react'
import FAQSearch from '../../Components/FAQ/FAQSearch'
import Layout from '../../Components/Layout';
import Menu from '../../Components/FAQ/Menu';
import ReFAQ from './RE'

/**
 * The ReFaq function returns a JSX element that renders a layout with a search component, a menu
 * component, and a ReFAQ component.
 * @returns a JSX element, which is a div containing several components: Layout, FAQSearch, Menu, and
 * ReFAQ.
 */
function ReFaq() {
  return (
    <div>
      <Layout>
        <FAQSearch  menu="RE"/>
        <Menu active={2} menu="RE"/>
        <ReFAQ />
      </Layout>
    </div>
  )
}

export default ReFaq
