import React from 'react'
import Layout from '../Components/Layout'
import Card from '../Components/DealDetail/DealCard'
import RefineSearch from '../Components/RefineSearchComponent'
import CompanyDealDetail from '../Components/DealDetail/CompanyDealDetail'
import { COMPANY_PROFILE} from '../Queries/Pe'

/**
 * The DealPage component renders a layout with a primary and secondary column, displaying a company
 * deal detail and a refine search component respectively.
 * @returns The `DealPage` component is returning a JSX element.
 */
const DealPage = ({menu}) => {
        return (
        <div>
        <Layout>
        <div className="flex h-screen flex-col">
                <Card />
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <section
                        aria-labelledby="primary-heading"
                        className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                        <CompanyDealDetail COMPANY_PROFILE={COMPANY_PROFILE}  menu={menu} />
                        
                        {/* <Outlet /> */}
                    {/* Your content */}
                    </section>
                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch />
                        </div>
                    </aside>
                </div>
                    {/* <div className="fixed  right-1 bottom-0">          
                    <img className="float-right w-[4rem] h-[4rem] cursor-pointer" src={chatPopupIcon} /> 
                    </div>  */}
                    <br/>
            </div>
        </Layout>
    </div>
    )
}

export default DealPage
