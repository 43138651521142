import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl, filterSectorByIndutry } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import AcquirerTypeFilter from '../RefineSearchComponents/AcquirerTypeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import CompanyTypeFilter from '../RefineSearchComponents/CompanyTypeFilter';
import CountryFilter from '../RefineSearchComponents/CountryFilter';
import DealTypeFilter from '../RefineSearchComponents/DealTypeFilter';
import IndustryFilter from '../RefineSearchComponents/IndustryFilter';
import RegionFilter from '../RefineSearchComponents/RegionFilter';
import StateFilter from '../RefineSearchComponents/StateFilter';
import ValuationFilter from '../RefineSearchComponents/ValuationFilter';
import { TableDataContext } from '../../Contexts/TableDataContext';
import Constants from '../../Constants';
import SectorFilter from '../RefineSearchComponents/SectorFilter';
 
function MaCompaniesRefineSearch({ customPathname }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { setrefineSearchFilters, onErrorHandler, refineSearchFilters, MinAcquirerDealRange, MaxAcquirerDealRange,setMonthPickerValue, setSectors } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[customPathname]?.globalSearch?.length > 0 ? true : false
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [DealTypeOption, setDealTypeOption] = useState([])
    const [AcquirerCountryOptions, setAcquirerCountryOptions] = useState([])
    const [AcquirerRegionOptions, setAcquirerRegionOptions] = useState([])
    const [AcquirerStateOptions, setAcquirerStateOptions] = useState([])
    const [AcquirerCityOptions, setAcquirerCityOptions] = useState([])
    const [AcquirerTypeOptions, setAcquirerTypeOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [ValuationOptions, setValuationOptions] = useState([])


    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = SectorOptions.filter(option => option.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedAcquirerType = AcquirerTypeOptions?.filter(option => option.isChecked);
        const selectedAcquirerCountries = AcquirerCountryOptions?.filter(option => option.isChecked);
        const selectedAcquirerRegion = AcquirerRegionOptions?.filter(option => option.isChecked);
        const selectedAcquirerState = AcquirerStateOptions?.filter(option => option.isChecked);
        const selectedAcquirerCity = AcquirerCityOptions?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedDealTypes = DealTypeOption?.filter(option => option.isChecked);
        const selectedValuations = ValuationOptions?.filter(option => option.isChecked);

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                companyType: selectedCompanyType,
                countries: selectedCountries,
                region: selectedRegion,
                state: selectedState,
                city: selectedCity,
                acquirerType: selectedAcquirerType,
                acquirerCountries: selectedAcquirerCountries,
                acquirerRegion: selectedAcquirerRegion,
                acquirerState: selectedAcquirerState,
                acquirerCity: selectedAcquirerCity,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                dealType: selectedDealTypes,
                valuations: selectedValuations,
            },
        }))
    }

    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[]}
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
        setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onCountryChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerRegionOptions(response.data?.region)
                    setAcquirerStateOptions(response.data.state)
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setRegionOptions(response.data?.region)
                    setStateOptions(response.data.state)
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    const onRegionChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        let selectedCountries = [];

        if (type === "acquirer") {
            selectedCountries = AcquirerCountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
        } else {
            selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
        }
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerStateOptions(response.data.state)
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setStateOptions(response.data.state)
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    const onStateChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        let selectedCountries = [];
        let selectedRegion = [];

        if (type === "acquirer") {
            selectedCountries = AcquirerCountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
            selectedRegion = AcquirerRegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        } else {
            selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
            selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        }

        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    useEffect(() => {
        const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

        if (ids.length > 0) {
            axios.post(filterSectorByIndutry, {
                "industry": ids,
                category: "investments"
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                if (response.status == 200) {
                    setSectors(response.data?.data ?? [])
                } else {
                    setSectors([])
                }
            }).catch(onErrorHandler)
        } else {
            setSectors([])
        }

    }, [IndustriesOptions])


    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        const acquirerCountryIds = refineSearchFilters[customPathname]?.acquirerCountries?.map(value => value.id) ?? [];
        const acquirerRegionIds = refineSearchFilters[customPathname]?.acquirerRegion?.map(value => value.id) ?? [];
        const acquirerStateIds = refineSearchFilters[customPathname]?.acquirerState?.map(value => value.id) ?? [];
        const acquirerCityIds = refineSearchFilters[customPathname]?.acquirerCity?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))

                setAcquirerCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: acquirerCountryIds.includes(option.id) })))
                setAcquirerRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: acquirerRegionIds.includes(option.id) })))
                setAcquirerStateOptions(response.data.state?.map(option => ({ ...option, isChecked: acquirerStateIds.includes(option.id) })))
                setAcquirerCityOptions(response.data.city?.map(option => ({ ...option, isChecked: acquirerCityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])


    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                label="Target Industry"
                                open={select === 1} />
                            <SectorFilter
                                SectorOptions={SectorOptions}
                                setSectorOptions={setSectorOptions}
                                accordionClick={() => accordionClick(14)}
                                customPathname={customPathname}
                                label="Target Sector"
                                open={select === 14} />
                            <CompanyTypeFilter
                                CompanyTypeOptions={CompanyTypeOptions}
                                setCompanyTypeOptions={setCompanyTypeOptions}
                                customPathname={customPathname}
                                label="Target Company Type"
                                accordionClick={() => accordionClick(2)}
                                open={select === 2} />
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(3)}
                                onChange={onCountryChange}
                                label="Target Country"
                                open={select === 3} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                onChange={onRegionChange}
                                label="Target Region"
                                open={select === 4} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(5)}
                                label="Target State"
                                open={select === 5} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="Target City"
                                open={select === 6} />

                            <DealTypeFilter
                                DealTypeOption={DealTypeOption}
                                setDealTypeOption={setDealTypeOption}
                                accordionClick={() => accordionClick(13)}
                                customPathname={customPathname}
                                isMultiSelect={true}
                                showAll={false}
                                dataPath="ma"
                                open={select === 13}
                            />
                            <AcquirerTypeFilter
                                AcquirerTypeOptions={AcquirerTypeOptions}
                                setAcquirerTypeOptions={setAcquirerTypeOptions}
                                customPathname={customPathname}
                                label="Acquirer Company Type"
                                accordionClick={() => accordionClick(7)}
                                open={select === 7} />
                            <CountryFilter
                                CountryOptions={AcquirerCountryOptions}
                                setCountryOptions={setAcquirerCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(8)}
                                onChange={(options) => onCountryChange(options, 'acquirer')}
                                filterPathkey="acquirerCountries"
                                label='Acquirer Country'
                                open={select === 8} />
                            <RegionFilter
                                RegionOptions={AcquirerRegionOptions}
                                setRegionOptions={setAcquirerRegionOptions}
                                customPathname={customPathname}
                                filterPathkey="acquirerRegion"
                                label='Acquirer Region'
                                accordionClick={() => accordionClick(9)}
                                onChange={(options) => onRegionChange(options, 'acquirer')}
                                open={select === 9} />
                            <StateFilter
                                StateOptions={AcquirerStateOptions}
                                setStateOptions={setAcquirerStateOptions}
                                customPathname={customPathname}
                                onChange={(options) => onStateChange(options, 'acquirer')}
                                filterPathkey="acquirerState"
                                label='Acquirer State'
                                accordionClick={() => accordionClick(10)}
                                open={select === 10} />
                            <CityFilter
                                CityOptions={AcquirerCityOptions}
                                setCityOptions={setAcquirerCityOptions}
                                filterPathkey="acquirerCity"
                                label='Acquirer City'
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                open={select === 11} />
                            <AcquirerDealRangeFilter
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange} />
                            <ValuationFilter
                                ValuationOptions={ValuationOptions}
                                setValuationOptions={setValuationOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(12)}
                                dataPath="maCompanies"
                                open={select === 12}
                            />


                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default MaCompaniesRefineSearch