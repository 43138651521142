import { React, useState, useEffect, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext';
import HeaderButton from './HeaderButton';
import lock from "../../../images/lock-icon.png";
import { CurrencyContext } from '../../../Contexts/CurrencyContext'
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { Tooltip } from "antd";
import  CurrencyComponent from "../../Financial/CurrencyComponent";
import CurrencyTypeComponent from "../../Financial/CurrencyTypeComponent"
import LockIcon from "mdi-react/LockIcon";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

/* The above code is a React component written in JavaScript. It is a statement component for
displaying financial data. */
function PLStatement({ data = {}, isDetails, setIsDetail, companyName, openTab, showXlDownload, isExport, isRatio,companyLabel }) {
    const [selected, setSelected] = useState(1);
    const [alert, setalert] = useState({ message: "", show: false });
    const { openRequestPopup } = useContext(RaiseRequestContext);
    // standalone or consolidated


    const { currencyType, currency, setFinancialType } = useContext(CurrencyContext);
    const { companyId } = useParams();

    const { primaryMasterId } = useModule();
    const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)

    let companyProfileId = parseInt(companyId);

    /**
     * The function `onButtonSelect` updates the selected and financial type based on the selected
     * type.
     */
    const onButtonSelect = (selectedType) => {
        setSelected(selectedType)
        setFinancialType(selectedType === 1 ? 'consolidated' : 'standalone')
    }

    useEffect(() => {
        if (data?.consolidated?.financial_year?.length == 0) {
            onButtonSelect(2);
        } else if (data?.consolidated?.financial_year?.length != 0) {
            onButtonSelect(1);
        }
    }, [data])


    /* The above code is defining a `exportRequest` constant using the `useMemo` hook in React. The
    `exportRequest` value is calculated based on the values of `openTab`, `selected`, and
    `companyName`. */
    const exportRequest = useMemo(() => {
        if (openTab === 1) {
            return {
                "sub_section": ['plstandard'],
                "sub_section_type": selected === 1 ? 'PL-Consolidated' : 'PL-Standalone',
                "fileName": selected === 1 ? Constants.exportNames.plConsolidated(companyName) : Constants.exportNames.plStandalone(companyName)
            }
        } else if (openTab === 2) {
            return {
                "sub_section": ['balancesheet'],
                "sub_section_type": selected === 1 ? 'BS-Consolidated' : 'BS-Standalone',
                "fileName": selected === 1 ? Constants.exportNames.balanceSheetCon(companyName) : Constants.exportNames.balanceSheetStandalone(companyName)
            }
        } else {
            return {
                "sub_section": ['cashflow'],
                "sub_section_type": selected === 1 ? 'CF-Consolidated': 'CF-Standalone',
                "fileName": selected === 1 ? Constants.exportNames.cashflowCon(companyName) : Constants.exportNames.cashflowStandalone(companyName)
            }
        }
    }, [companyName, openTab, selected])

    const onBlurColumnClick = () =>{
        setalert({ show: true, message: Constants.cfsSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    return (
        <div>
            <div className='flex items-center justify-between px-3 md:px-5'>
                <div className="flex items-center space-x-2">
                <div className='flex items-center space-x-2 md:space-x-3 md:space-x-0'>
                    {data?.consolidated?.financial_year?.length != 0 && data?.consolidated?.financial_year != null
                        ? <div className=''>
                            <HeaderButton isSelected={selected === 1} onClick={(e) => onButtonSelect(1)}>
                                Consolidated
                            </HeaderButton>
                        </div>
                        : <></>}
                    {data?.standalone?.financial_year?.length != 0 && data?.standalone?.financial_year != null
                        ? <div className='py-4'>
                            <HeaderButton isSelected={selected === 2} onClick={(e) => onButtonSelect(2)}>
                                Standalone
                            </HeaderButton>
                        </div>
                        : <></>}
                </div>
                {(companyLabel !='Non-XBRL' && data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year?.length != 0) && data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year != null &&
                    <div className='rounded-full bg-purple-100 px-2.5 py-[0.1rem] text-[9px] leading-[12px] font-sans_book_body text-[#333333]'>{companyLabel}</div>
                }
                </div>
                
                {isStudent === 'false'  ?
                <div>
                    {data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year?.length != 0
                        && data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year != null
                        && (openTab === 1 || openTab === 2 || openTab === 3)
                        && showXlDownload === true && isExport === true
                        ? <div className="flex items-center md:justify-center justify-between">
                            <div className="flex items-center mr-4">
                            <CurrencyTypeComponent /><span className="md:mx-3 mx-1">in</span><CurrencyComponent />
                            </div>
                            {isStudent =='true' ? <></>:
                            <ExcelDownloader
                                url={exportExcel}
                                fileName={exportRequest.fileName}
                                data={{
                                    "export_from": "detail",
                                    "module_type": "investments", 
                                    "section": "financials",
                                    "type": primaryMasterId,
                                    "is_detailed": isDetails,
                                    "currency_cd": currencyType,
                                    "display_in": currency,
                                    "company_id": [companyProfileId],
                                    "sub_section": exportRequest.sub_section,
                                    "sub_section_type": exportRequest.sub_section_type,

                                }} />
                            }
                        </div>
                        : ""}
                </div>
                :<></>}
            </div>
            {
                data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year?.length != 0 && data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year != null
                    ? <div className='overflow-x-auto'>
                        <table className="w-full">
                            <thead className='text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] font-bold font-medium text-[#666666] py-4 px-0 text-left'>
                                <tr>
                                    <th width="10%" className='whitespace-nowrap px-5 py-3.5 text-left sticky left-0 bg-[#EDEDED]'>Financials</th>
                                    {
                                        data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year?.map((year,index) => {
                                            return <th width={`${data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year.length-1 === index ? '5':'5%'}`}className='whitespace-nowrap px-5 py-3.5 text-left '>{`FY ${year}`}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody className='text-[12pt] leading-[15pt] lg:text-[12px] lg:leading-[15px] text-[#666666] py-4 px-0 text-left'>
                                {
                                    data[`${selected === 1 ? "consolidated" : "standalone"}`]?.data?.map(plData => {
                                        // console.log(plData.display_label,"123456");
                                        return (
                                            plData.display_label ?
                                                <tr className='border-b font-sans_book_body text-[#333333] leading-[24px]  text-[14px]'>
                                                    
                                                    <td className={`${plData?.display_bold ? "font-bold" : ""} md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left sticky left-0  bg-white text-[#333333] w-[3rem]`}>
                                                    {isRatio ?
                                                    <Tooltip title={plData?.display_tooltip} placement="right" color="white" overlayInnerStyle={{color:'#333333',fontWeight:'500', whiteSpace:'nowrap', width:'fit-content'}} zIndex={99}>{plData?.display_label}</Tooltip>
                                                    : <>{plData?.display_label}</>}
                                                    </td>                                                    
                                                    {
                                                        plData?.financial_amount?.map(amount => {
                                                            if (isDirectoryUser) {
                                                            return (
                                                                <td
                                                                onClick={onBlurColumnClick}
                                                                className="relative whitespace-nowrap cursor-pointer"
                                                                >
                                                                <div className="blur-sm bg-[#DCDCDC] select-none h-full px-5 py-4 w-20">
                                                                    {Constants.restrictedPlaceholder}
                                                                </div>
                                                                </td>
                                                            );
                                                            } else
                                                            return <td className={`${plData?.display_bold ? "font-bold" : ""} whitespace-nowrap font-sans_book_body px-5 py-3 text-left pr-[2rem] w-[2rem]`}>
                                                                {amount === "0" || amount === "0.00" || amount === "0.00%" || amount === "0%" ? "" : amount}
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                                : ""
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : <div className='h-28 w-full'>
                        <div className="w-full h-full flex items-center justify-center">
                            {/* <img className='w-10 h-10' src={AlertGif} alt="" />     */}
                            <p className="px-5 text-[14px]">Data not found. Please <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup('financial')}>Click Here</button> to alert Venture Intelligence about this. Thanks</p>
                        </div>
                    </div>
            }
            {
                isDetails
                    ? <></>
                    : data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year?.length != 0 && data[`${selected === 1 ? "consolidated" : "standalone"}`]?.financial_year != null
                        ? <div className="flex">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsDetail(true)
                                }}
                                className=" py-1 px-2 border rounded-[5px] border-[#D2D2D2] inline-flex items-center float-right mx-3 my-3"
                            >
                                <img src={lock} alt='' />
                                <span className="pl-2 pr-1 text-[#333333] text-[13px]">
                                    Detailed
                                </span>
                            </button>
                        </div>
                        : <></>
            }
            {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </div >
    )
}

export default PLStatement