import React from 'react'
import moment from 'moment'
import "../DetailPage/profile.css";
import { Popover } from 'antd';



/**
 * The `DealInfoExit` component is a React component that displays deal information, including IPO
 * size, IPO price, IPO valuation, date, stake sold, exit status, news links, investor sale in IPO, and
 * price per share.
 * @returns The component is returning a JSX element that contains a table displaying deal information.
 * The table includes various data points such as IPO size, IPO price, IPO valuation, date, stake sold,
 * exit status, news link, investor sale in IPO, and price per share. The data is passed to the
 * component through the props object. If the data is available, it is displayed in the table.
 */
const DealInfoExit = (props) => {
    
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };
  
    return (
        <div className="mx-5 overflow-x-auto lg:overflow-hidden">
            <p
                className=" text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                Deal Info</p>
            <div
                className=" ">
                    {props?.data?
                <table
                    className=" w-full  ">
                <tr
                        className="space-x-6 border-b-[#E5E5E5] border-b ">
                        <td
                            className="py-1  lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                               IPO Size (US$ M)
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                   
                             {/* <ThousandSeperator  value=  */}
                                 {/* /> */}
                                 {props?.data[0]?.deal_amount_usd===0?"--":props?.data[0]?.deal_amount_usd?'$ '+props?.data[0]?.deal_amount_usd+' M':"--"}
                             </p>
                        </td>
                        <td
                            className="py-1 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                                IPO Price (INR)</p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                     
                                 {props?.data[0]?.deal_amount_inr?props?.data[0]?.deal_amount_inr:"--"}
                              </p>
                        </td>
                        <td
                            className="py-2 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                                IPO Valuation (US$ M)
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                
                               {props?.data[0]?.ipo_valuation?props?.data[0]?.ipo_valuation:"--"}
                            </p>
                        </td>  
                    </tr>
                   <tr
                        className="space-x-6 border-b-[#E5E5E5] border-b">
                        <td
                            className="py-1  lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                          Date
                                
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                 {props?.data[0]?.date?moment(props?.data[0]?.date).format("MMM YYYY"):"--"}
                            </p>
                        </td>
                     
                         <td
                            className="py-1 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                              Stake Sold
                            </p>
                            
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold">{props?.data[0]?.stake?props?.data[0]?.stake +"%":"--"}</p>
                        </td>  
                        <td
                            className="py-2 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                          Exit Status
                                
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                     
                                 {props?.data[0]?.exit_status?props?.data[0]?.exit_status:"--"}
                            </p>
                        </td>                   
                    </tr>
                      <tr
                        className="font-sans_book_body">
                        <td
                            className="py-1  lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                readonly>
                            News Link
                                
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold "
                                readonly>
                                 {props?.data[0]?.link ?
                                props?.data[0]?.link?.split(";")?.filter(link=>link?.trim().length!=0).length===0?"--":
                                props?.data[0]?.link?.split(";")?.filter(link=>link?.trim().length!=0)?.map((link,index)=>{
                                   return <Popover 
                                    placement="topLeft"
                                    className=' overflow text-[11pt]    leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline ' 
                                    content={
                                        <a
                                            className=" overflow text-[11pt]  hover:text-primaryColor   leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                            href={link} target="_blank" rel='noreferrer'
                                        >
                                            {link}
                                        </a>
                                    }>
                                        <a
                                            className=" overflow text-[11pt]  hover:text-primaryColor  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold hover:underline "
                                            href={link} target="_blank" rel='noreferrer'
                                        >
                                            Link {index+1}{props?.data[0]?.link?.split(";")?.filter(link=>link?.trim().length!=0).length>index+1?", ":""}
                                        </a>
                                    </Popover>
                                })
                             :"--"}
                            </p>
                        </td>
                         <td
                            className="py-2 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                            Investor Sale in IPO?
                                
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                  {props?.data[0]?.investor_sale?props?.data[0]?.investor_sale:"--"}
                            </p>
                        </td>
                         <td
                            className="py-2 pl-5 lg:leading-[21px] font-sans_book_body">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                readonly>
                            Price Per Share
                                
                            </p>
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                readonly>
                                  {props?.data[0]?.price_per_share? addCommasToNumber(props?.data[0]?.price_per_share):"--"}
                            </p>
                        </td>
                     
                                         
                    </tr>

                </table>
                 :
                    <div className="w-full bg-[#FFFFFF]">
                        <center><div role="status" className=" h-[8rem]">
                        <svg className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        </div></center>
                    </div>
                } 
            </div>
        </div>
    )
}

export default DealInfoExit
