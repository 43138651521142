import React from 'react'
import sorting from '../../../images/up-down-arrow.png';
/**
 * The above function is a React component that renders a table displaying information about a target
 * company and an acquirer company.
 * @returns The Target component is being returned. It renders a div with a table inside. The table has
 * a header and a body with multiple rows. Each row contains data about the target and acquirer
 * companies, such as their listed status, company group, industry, city and country, and website.
 */
const Target = (props) => {
    console.log(props, "xcgvbnm,")
    return (
        <div className=" bg-white ">
            <div className="px-4 ">
                <p
                    className="text-[15pt] leading-[19pt] lg:text-[17px] pb-3 text-[#333333] font-semibold lg:leading-[22px]">
                </p>
            </div>
            <div
                className="overflow-x-auto scrollbar mx-4">
                <table
                    className="w-full mx-auto">
                    <thead
                        className="bg-[#F3F3F3] lg:leading-[15px] lg:text-[13px] text-[12pt] leading-[15pt] text-[#666666] font-semibold text-left whitespace-nowrap">
                        <th
                            className="py-2 pr-3 text-left sm:pl-6 whitespace-nowrap">
                        </th>
                        <th className="whitespace-nowrap py-2 ">
                            {props?.data?.target_company ? props?.data?.target_company : "Target"}
                            <span className="font-medium">{props?.data?.target_company ? " (Target)" : ""}</span>
                        </th>
                        
                        <th className="whitespace-nowrap py-2 ">
                            {props?.data?.acquirer_name ? props?.data?.acquirer_name : "Acquirer"}
                            <span className="font-medium">{props?.data?.acquirer_name ? " (Acquirer)" : ""}</span>
                        </th>

                    </thead>
                    <tbody
                        className="text-[12pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] text-[#333333]  text-leftr">
                        <tr
                            className="border-b">
                            <td
                                className="whitespace-nowrap  py-4 pl-3 text-left">
                                Listed Status

                            </td>
                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left ">
                                {props?.data?.target_listed_status == 0 ? "--" : props?.data?.target_listed_status ? props?.data?.target_listed_status : "--"}

                            </td>

                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                                {props?.data?.acquirer_listed_status == 0 ? "--" : props?.data?.acquirer_listed_status ? props?.data?.acquirer_listed_status : "--"}

                            </td>
                        </tr>
                        <tr
                            className="border-b">
                            <td
                                className="whitespace-nowrap  py-4 pl-3 text-left">
                                Company Group
                            </td>
                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                                {props?.data?.target_company_group == 0 ? "--" : props?.data?.target_company_group ? props?.data?.target_company_group : "--"}

                            </td>

                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                                {props?.data?.acquirer_company_group == 0 ? "--" : props?.data?.acquirer_company_group ? props?.data?.acquirer_company_group : "--"}

                            </td>

                        </tr>
                        <tr
                            className="border-b">
                            <td
                                className="whitespace-nowrap  py-4 pl-3 text-left">
                                Industry
                            </td>
                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                                {props?.data?.target_industry == 0 ? "--" : props?.data?.target_industry ? props?.data?.target_industry : "--"}

                            </td>
                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                                {props?.data?.acquirer_industry == 0 ? "--" : props?.data?.acquirer_industry ? props?.data?.acquirer_industry : "--"}

                            </td>


                        </tr>
                        <tr
                            className="border-b">
                            <td
                                className="whitespace-nowrap  py-4 pl-3 text-left">
                                City, Country
                            </td>
                            <td
                                className="whitespace-nowrap  font-semibold py-4 text-left">
                                {/* {props?.data?.target_city + props?.data?.target_country =="" ? "":props?.data?.target_city + "," + " " + props?.data?.target_country == "" ? " " : props?.data?.target_city + "," + " " + props?.data?.target_country ? props?.data?.target_city + "," + " " + props?.data?.target_country: "--"} */}
                                {props?.data?.target_city + props?.data?.target_country == "" ? "--" : [props?.data?.target_city, props?.data?.target_country].filter(Boolean).join(", ")}                            
                                </td>
                            <td
                                className="whitespace-nowrap  font-semibold py-4 text-left">
                                {/* { props?.data?.acquirer_city+props?.data?.acquirer_country =="" ? "" :props?.data?.acquirer_city + "," + " " + props?.data?.acquirer_country == "" ? " " : props?.data?.acquirer_city + "," + " " + props?.data?.acquirer_country ? props?.data?.acquirer_city + "," + " " + props?.data?.acquirer_country : "--"} */}
                                {props?.data?.acquirer_city + props?.data?.acquirer_country == "" ? "--" : [props?.data?.acquirer_city, props?.data?.acquirer_country].filter(Boolean).join(", ")}                            
                            </td>

                        </tr>
                        <tr
                            className="">
                            <td
                                className="whitespace-nowrap  py-4 pl-3 text-left">
                                Website
                            </td>
                            <td
                                className="whitespace-nowrap font-semibold py-4 text-left">
                               {props?.data?.target_website ?
                                    <a target="_blank" href={props?.data?.target_website} > {props?.data?.target_website ? props?.data?.target_website : "--"} </a>
                                        : "--"
                               }

                            </td>
                            <td
                                className="whitespace-nowrap  font-semibold py-4 text-left">
                                {props?.data?.acquirer_website ? 
                                    <a target="_blank" href={props?.data?.acquirer_website} > {props?.data?.acquirer_website} </a> 
                                        : "--"
                                }
                            </td>


                        </tr>
                    </tbody>

                </table>


            </div>


        </div>
    )
}

export default Target