import React, { useState, useMemo , useContext} from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import ExcelPopup from '../../ExcelPopup'
// import { T_ADVISOR_COMPANY_TOINVESTORS } from "../../../Queries/Pe";
import moment from "moment";
import routePathSegments, { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";

const TransactionAdvisorTo_Investors = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { primaryMasterId } = useModule()

  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext);
  const {toInvestorsDataFound} = useContext(DataFoundContext);

  let advisorType = "T";
  // const handleFetchPortfoliotRequestCallback = (response, requestSuccess) => {
  //   try {
  //     setPortfolioData(response);
  //   } catch (e) {
  //     console.log("Error", e);
  //   }
  // };

  // const { data, loading, error } = useQuery(T_ADVISOR_COMPANY_TOINVESTORS, {
  //   variables: { CIAId: advisorIdPE, AdvisorType: advisorType },
  //   onCompleted: (response) =>
  //     handleFetchPortfoliotRequestCallback(response, true),
  //   onError: (response) => handleFetchPortfoliotRequestCallback(response),
  //   refetch: T_ADVISOR_COMPANY_TOINVESTORS,
  // });
  // console.log("portfolio1", T_ADVISOR_COMPANY_TOINVESTORS);

  // const PortfolioData = useMemo(() => portfolio, [portfolio]);
  // console.log("trancaction to investor", PortfolioData);

  // const indexOfLastPost = currentPage * dataPerPage;
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // const currentData = portfolio?.slice(
  //   indexOfFirstPost,
  //   indexOfLastPost
  // );
  // console.log(currentData, "currentData");
  // alert(currentData)
  const peMatch  =matchPath(`${routePathSegments.pe}/*`,location.pathname);


  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
 /**
  * The function `setPage` sets the current page to the specified page number.
  * @param pageNum - pageNum is a parameter that represents the page number that you want to set as the
  * current page.
  */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
 /* The `customStringSortMemo` constant is a memoized function that is used for sorting the data in a
 table column. It takes in two rows (`rowA` and `rowB`), the column ID, and a boolean flag (`desc`)
 indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
  }, []);

 /* The `dealDateSort` constant is a memoized function that is used for sorting the data in the "Deal
 Date" column of a table. It takes in two rows (`rowA` and `rowB`), the column ID, and a boolean
 flag (`desc`) indicating whether the sorting should be in descending order. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])

 /* The `investorSort` constant is a memoized function that is used for sorting the data in the
 "Investors" column of a table. It takes in two rows (`rowA` and `rowB`), the column ID, and a
 boolean flag (`desc`) indicating whether the sorting should be in descending order. */
  const investorSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';

      const valueA = rowA?.values[columnId].map(data => data.Investor_name).join("")
      const valueB = rowB?.values[columnId].map(data => data.Investor_name).join("")

      return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    }
  }, [])

 /**
  * The function "addCommasToNumber" takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number that you want to add commas to.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /* The above code is defining an array of columns for a table. Each column object in the array
  specifies the header, accessor (data key), and other properties for a column in the table. The
  properties include classNames for styling, Cell component for rendering the cell content, and
  sortType for defining the sorting behavior of the column. The code is using the useMemo hook to
  memoize the columns array, which can improve performance by preventing unnecessary re-renders. */
  const columns = useMemo(() => [
    {
      id: "investors",
      Header: "Investors",
      accessor: "investors",
      className: "clickable lg:relative text-left sticky left-0  bg-white",
      headerClassName: "w-[17%] lg:relative text-left sticky left-0   w-[3rem] bg-[#EDEDED] ",
      // Cell: ({ value }) => {
      //   return <OverflowToolTip component={
      //     value.map((inv, index) => (<Link className='hover:text-[#BC8B20]' state={{ cameFrom: location.state?.prevPath }} to={routeNames.investorById(inv.InvestorId)}>
      //       {`${inv.Investor_name}${index === value.length - 1 ? "" : ", "}`}
      //     </Link>))
      //   } />
      // },
      Cell: ({ value }) => {
        return <OverflowToolTip  className="w-[11rem] lg:w-auto max-w-[15rem] " component={
          value.map((inv, index) => (<Link className='text-start flex lg:inline hover:text-[#BC8B20]'
           state={{ cameFrom: location.state?.prevPath }} to={peMatch!=null?routeNames.peInvestorById(inv.InvestorId)
          :routeNames.vcInvestorById(inv.InvestorId)}>
        {`${inv?.Investor_name}${index == value.length - 1 ? "" : ", "}`}
        {/* {inv?.Investor_name} */}
    </Link>))
    }/>
  },
      sortType: investorSort, 
    },
    {
      id: 'dealDate',
      Header: "Deal Date",
      accessor: "dealDate",
      headerClassName: "w-[14%]",
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        to={peMatch != null ?routeNames.peCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
          :routeNames.vcCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
       }>
            {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
  
        </Link>
      },
      sortType: dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className: "px-0 text-right pr-[2.5rem] w-[10%]",
      headerClassName: "w-[15%]",
      headerspanClassName: "ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      headerClassName: "w-[8%]",
      Cell: ({ value }) => {
        return <OverflowToolTip className="w-[12rem] lg:w-auto" component={ <Link className='hover:text-[#BC8B20]'
        state={{ cameFrom: location.state?.prevPath }}
        to={peMatch!=null?routeNames.peCompanyFundById(value?.id)
        :routeNames.vcCompanyFundById(value?.id)}>
        {value.name ?? "--"}
      </Link>} />
      },
      sortType: customStringSortMemo,
    },
    {
      Header: "Post Money Valuation (INR Cr)",
      accessor: "postMoney",
      className: "px-0 text-right pr-[2.5rem] w-[15%]",
      headerClassName: "w-[5%]",
      headerspanClassName: "ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Revenue Multiple",
      accessor: "revenueMultiple",
      className: "px-0 text-right pr-[2.5rem] w-[12%]",
      headerClassName: "w-[4%]",
      headerspanClassName: "ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    }
  ], []);

 /* The `rowData` constant is using the `useMemo` hook to memoize the transformation of the
 `props.toInvestor` data into a format that can be used by the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];
    // JSON.parse
    props.toInvestor?.forEach(company => {
      rows.push({
        investors:company?.Investor,
        dealDate: { date: company?.Deal_Date, dealId: company?.Deal_Id, id: company?.CompanyId },
        dealAmountInUS: company?.Deal_Amount > 0 ? company?.Deal_Amount : "",
        companyName: { name: company?.Companyname, id: company?.CompanyId},
        postMoney: company?.Post_Money_Valuation > 0 ? company?.Post_Money_Valuation : "",
        revenueMultiple: company?.Revenue_Multiple > 0 ? company?.Revenue_Multiple : "",
      })
    });
    setshowXlDownload(rows.length != 0)
    return rows;
  }, [props.toInvestor])

  const { pathname, state } = useLocation();
  const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sorting options for
  the DataTable component. It specifies that the initial sorting should be done based on the
  'dealDate' column in descending order. The empty dependency array `[]` ensures that the sorting
  options are only calculated once and not re-evaluated on subsequent renders. */
  const initialSort = useMemo(() => [
    {
      id: 'dealDate',
      desc: true
    }
  ], []);
  
  return (
    <>
      {toInvestorsDataFound ? (
        <div className="px-4 bg-[#EDEDED] w-[100%]">
          <div className="  bg-white border  overflow-hidden sm:rounded-lg">
            <div className="px-5 pt-4 border-b border-b-[#F1F1F1]">
              <div className="flex justify-between">
                <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[18px] lg:leading-[22px] font-semibold mb-3">
                  To Investors
                </h3>
                {!isDirectoryUser ? (isStudent == 'false' && isTrialExport !='false' && showXlDownload) && <ExcelDownloader
                  url={exportExcel}
                  fileName={Constants.exportNames.to_investors(props.advisorName)}
                  data={{
                    "type": primaryMasterId,
                    "export_from": "detail",
                    "module_type": "advisors",
                    "advisor_type": "T",
                    "section": "to_investors",
                    "advisor_id": [advisorProfileId]
                  }} /> : ""}
              </div>
            </div>
            <div className="space-y-6">
              <div className="bg-white pb-2 rounded-[6px]">
                <DataTable columns={columns} data={rowData} sortBy={initialSort} />
              </div>
            </div>
          </div>
        </div>
      ) : ""}
    </>
  )
}

export default TransactionAdvisorTo_Investors;