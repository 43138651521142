import React from 'react'
import moment from 'moment'
import { newsLinkLimit } from "../../Config/config";
import "../DetailPage/profile.css";
import { Popover } from 'antd';

// import ThousandSeperator from '../../Utils/ThousandSeperator';


/* The above code is a React component called `DealInfoAngel`. It renders a table that displays deal
information. The component receives `props` as input, which contains the data to be displayed. */
const DealInfoAngel = (props) => {
    const newsLimit = newsLinkLimit
    const limit = (string = '', newsLimit = 0) => {
        return string.substring(0, newsLimit)
    }


    const newlink = props?.data?.link?.split(";")?.filter(link => link?.trim().length != 0);
    return (
        <div className="px-5 lg:overflow-x-hidden overflow-x-auto overflow-y-hidden">
           <div className='flex justify-between'>
                <p className=" text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">Deal Info</p>
                {props?.data?.deal_type &&
                <div className='rounded-full bg-purple-100 px-2.5 py-0.5 text-[10px] font-sans_book_body text-[#333333]'>{props?.data?.deal_type}</div>
                }
            </div>
            <div
                className=" ">
                {props?.data ?
                    <table
                        className=" w-full  ">
                        <tr
                            className="border-b-[#E5E5E5] border-b space-x-6">
                            <td
                                className="py-1  lg:leading-[21px] font-sans_book_body">
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                                    readonly>
                                    Date
                                </p>
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                    readonly>
                                    {props.data?.DealDate ? moment(props.data?.DealDate).format("MMM YYYY") : "--"}
                                </p>
                            </td>
                            <td
                                className="py-1 px-12 lg:px-5 lg:leading-[21px] font-sans_book_body">
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                    readonly>
                                    VC Funded</p>
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                    readonly>
                                    {props.data?.angel_vcFunded ? props.data?.angel_vcFunded : "--"}
                                </p>
                            </td>
                            <td
                                className="py-2 px-5 lg:leading-[21px] font-sans_book_body">
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                    readonly>
                                    Exited?
                                </p>
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                    readonly>
                                    {props.data?.excited ? props.data?.excited : "--"}
                                </p>
                            </td>
                        </tr>
                        <tr
                            className="font-sans_book_body">
                            <td
                                className="py-1  lg:leading-[21px] font-sans_book_body">
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                    readonly>
                                    Multiple Angel Rounds

                                </p>
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold "
                                    readonly>
                                    {props.data?.multiple_angel_rounds ? props.data?.multiple_angel_rounds : "--"}
                                </p>
                            </td>
                            <td
                                className="py-1 px-12 lg:px-5 lg:leading-[21px]">
                                <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                    readonly>
                                    News Link
                                </p>
                                {props?.data?.link == "--" || props?.data?.link == 0 ? " --" : newlink?.map((link, index) => {
                                    return <Popover
                                        placement="topLeft"
                                        className=' overflow text-[11pt]    leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline '
                                        content={
                                            <a
                                                className=" overflow text-[11pt] hover:text-primaryColor    leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                                href={link} target="_blank" rel='noreferrer'
                                            >
                                                {link == "--" ? "" : link}
                                            </a>
                                        }>
                                        {link == "--" ? "--" :
                                            <a
                                                className=" overflow text-[11pt] hover:text-primaryColor    leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                                href={link} target="_blank" rel='noreferrer'
                                            >
                                                Link {index + 1}{newlink.length > index + 1 ? ", " : " "}
                                            </a>

                                        }

                                    </Popover>
                                }) }

                            </td>
                        </tr>


                    </table>
                    :
                    <div className="w-full bg-[#FFFFFF]">
                        <center><div role="status" className=" h-[8rem]">
                            <svg className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                        </div></center>
                    </div>
                }
            </div>
        </div>
    )
}

export default DealInfoAngel
