import { React, useContext, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import YearFoundedFilter from "../../Components/RefineSearchComponents/YearFoundedFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../../Components/RefineSearchComponents/InvestorHeadquartersFilter";
import ExitStatusFilterSelect from "../../Components/RefineSearchComponents/ExitStatusFilterSelect";
import ReturnMultipleFilter from "../RefineSearchComponents/ReturnMultipleFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import ExitDealTypeFilter from "../RefineSearchComponents/ExitDealTypeFilter";
import Constants from "../../Constants";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation,useNavigate } from "react-router";
import { SelectTypeFilter } from '../RefineSearchComponents/TypeFilter'
import { useEffect } from "react";
import axios from "axios";
import { filterSectorByIndutry } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";

/**
 * The component renders a form for refining search filters.
 * @returns a JSX element.
 */
export default function ExitRefineSearch({ typeId, customPathname }) {
  const {
    
    setSectors,
    setrefineSearchFilters,
    onErrorHandler ,setMonthPickerValue ,refineSearchFilters} = useContext(PeFilterContext);
  const { setCurrentPage } = useContext(TableDataContext);
  const [select, setSelected] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { getToken } = useContext(UserContext);
  // const isMatch = useMatch({ path: `${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.exists}/*` })
  const location = useLocation();
  // const isPematch = useMatch({ path: `${routePathSegments.pe}/*` })

  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
  const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false


  //local updated options
  const [IndustriesOptions, setIndustriesOptions] = useState([])
  const [SectorOptions, setSectorOptions] = useState([])
  const [EndYearOptions, setEndYearOptions] = useState([]);
  const [StartYearOptions, setStartYearOptions] = useState([])
  const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
  const [HeadquartersOptions, setHeadquartersOptions] = useState([])
  const [FirmTypeOptions, setFirmTypeOptions] = useState([])
  const [ExitDealTypeOptions, setExitDealTypeOptions] = useState([])
  const [SwitchState, setSwitchState] = useState(true)
  const [TagsOptions, setTagsOptions] = useState([])
  const [TypeOptions, setTypeOptions] = useState([])
  const [ExitStatusOptions, setExitStatusOptions] = useState([])
  const [ReturnMultiple, setReturnMultiple] = useState({ from: "", to: "" })

  // useEffect(() => {
  //   setFirmType(firmTypeResponseData?.investments ?? [])
  // }, [firmTypeResponseData?.investments, setFirmType])

  // useEffect(() => {
  //   setExitStatus(exitStatusResponseData?.exits ?? [])
  // }, [exitStatusResponseData?.exits, setExitStatus])


  const applyFilter = () => {
    const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
    const selectedSectors = SectorOptions.filter(option => option.isChecked);
    const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
    const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
    const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
    const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
    const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
    const selectedExit = ExitDealTypeOptions?.filter(option => option.isChecked);
    const selectedType = TypeOptions?.filter(option => option.isChecked);
    const selectedExitStatus = ExitStatusOptions?.filter(option => option.isChecked);

    setrefineSearchFilters(prev => ({
      ...prev,
      [customPathname]: {
        ...prev[customPathname],
        industry: selectedIndustries,
        sector: selectedSectors,
        investorType: selectedInvestorType,
        investorHeadquarters: selectedHead,
        yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
        firmType: selectedFirm,
        operator: SwitchState ? 1 : 0,
        dealType: selectedExit,
        exitType: selectedType,
        exitStatus: selectedExitStatus,
        returnMultiple: ReturnMultiple?.from != "" && ReturnMultiple?.to != "" ? [{ id: 0, name: ReturnMultiple?.from }, { id: 1, name: ReturnMultiple?.to }] : [],
        tags: TagsOptions
      },
    }))
  }

  const resetFilter = () => {
    setrefineSearchFilters(prev => ({
      ...prev,
      [customPathname]: {}
    }))
  }

  // Submitting Refine Search Form 
  const refineSearchFilter = (event) => {
    setrefineSearchFilters(prev => ({
      ...prev,
      [customPathname]: {globalSearch:[],
      }
  }))
  if(isFromGlobalSearch() || isFromSearchedList()){
      setMonthPickerValue(Constants.initialMonthRange)
  }
    event.preventDefault();
    setCurrentPage(1)
    // if (customPathname !== location.pathname) {
    //   navigate(routeNames.pe_companies_exists_pe_vc_investments);
    // }
    applyFilter();
    if (location.pathname != customPathname) {
      navigate(customPathname);
    }
    setSelected(false)

  }

 /**
  * The `onResetClick` function resets the current page, navigates to a custom pathname if it is
  * different from the current location, and resets the filter.
  * @param event - The event parameter is an object that represents the event that triggered the
  * function. In this case, it is the click event that occurred when the reset button was clicked.
  */
  const onResetClick = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    // if (isMatch == null) {
    //   navigate(routeNames.pe_companies_exists_pe_vc_investments);
    // }
    if (location.pathname != customPathname) {
      navigate(customPathname)
    }
    resetFilter()
  }

  const accordionClick = (val) => {
    if (val === select) {
      setSelected(0);
    } else {
      setSelected(val);
    }
  };

  let exitDealTypeheading = "Deal Type";
  let exitTypeheading = "Type - Public Market (PE-VC)";
  let showTypeFilter = true;
  let showDealTypeFilter = true;

  if (typeId === Constants.primaryMasterSubIds.pe_vc_exits_id
    || typeId === Constants.primaryMasterSubIds.vc_exits_id) {
    exitDealTypeheading = "Deal Type - M&A (PE-VC)";
    showTypeFilter = true;
  } else {
    if (typeId === Constants.primaryMasterSubIds.pe_vc_pm_id || typeId === Constants.primaryMasterSubIds.vc_pm_id) {
      showTypeFilter = true;
      showDealTypeFilter = false;
      exitTypeheading = "Type";
    } else {
      showTypeFilter = false;
    }
  }

  useEffect(() => {
    const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    if (ids.length > 0) {
      axios.post(filterSectorByIndutry, {
        "industry": ids,
        category: "exits"
      }, {
        headers: {
          "Authorization": getToken()
        }
      }).then(response => {
        if (response.status === 200) {
          setSectors(response.data?.data ?? [])
        } else {
          setSectors([])
        }
      }).catch(onErrorHandler)
    } else {
      setSectors([])
    }

  }, [IndustriesOptions])


  return (
    <div className="h-screen">
      <div className={isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[100%]" : "flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
        <div className="flex justify-between px-3 items-center pt-0">
          <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>
          <Switch
            SwitchState={SwitchState}
            setSwitchState={setSwitchState}
            customPathname={customPathname} />
        </div>
        <div className="border-b-[3px] border-[#E8E6E1] bg-white">
          <div className="sticky top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
            <label className="  rounded ">
              <Tags
                TagsOptions={TagsOptions}
                customPathname={customPathname}
                setTagsOptions={setTagsOptions} />
            </label>
          </div>
        </div>
        <form onSubmit={refineSearchFilter} onReset={onResetClick}>
          <main className="bg-[#FAF5EA] w-full">
            <ul className="text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]">
              <IndustryFilter
                IndustriesOptions={IndustriesOptions}
                setIndustriesOptions={setIndustriesOptions}
                customPathname={customPathname}
                accordionClick={() => accordionClick(1)}
                open={select === 1} />
              <SectorFilter
                SectorOptions={SectorOptions}
                setSectorOptions={setSectorOptions}
                accordionClick={() => accordionClick(2)}
                customPathname={customPathname}
                open={select === 2} />
              {showDealTypeFilter ? <ExitDealTypeFilter
                ExitDealTypeOptions={ExitDealTypeOptions}
                setExitDealTypeOptions={setExitDealTypeOptions}
                customPathname={customPathname}
                accordionClick={() => accordionClick(7)}
                dataPath="others"
                open={select === 7}
                heading={exitDealTypeheading} />
                : null}
              {showTypeFilter
                ? <SelectTypeFilter
                  TypeOptions={TypeOptions}
                  setTypeOptions={setTypeOptions}
                  customPathname={customPathname}
                  heading={exitTypeheading}
                  accordionClick={() => accordionClick(27)}
                  open={select === 27} />
                : <></>}
              <InvestorTypeFilter
                InvestorTypeOptions={InvestorTypeOptions}
                setInvestorTypeOptions={setInvestorTypeOptions}
                customPathname={customPathname}
                accordionClick={() => accordionClick(14)}
                open={select === 14} />
              <InvestorHeadquartersFilter
                HeadquartersOptions={HeadquartersOptions}
                setHeadquartersOptions={setHeadquartersOptions}
                customPathname={customPathname}
                accordionClick={() => accordionClick(15)}
                open={select === 15} />
              <FirmTypeFilter
                FirmTypeOptions={FirmTypeOptions}
                setFirmTypeOptions={setFirmTypeOptions}
                customPathname={customPathname}
                accordionClick={() => accordionClick(16)}
                dataPath="investments"
                open={select === 16} />
              <ExitStatusFilterSelect
                ExitStatusOptions={ExitStatusOptions}
                setExitStatusOptions={setExitStatusOptions}
                customPathname={customPathname}
                dataPath="exits"
                accordionClick={() => accordionClick(6)}
                open={select === 6}
                defaultLabel="Select" />
              <ReturnMultipleFilter
                customPathname={customPathname}
                ReturnMultiple={ReturnMultiple}
                setReturnMultiple={setReturnMultiple} />
              <YearFoundedFilter
                EndYearOptions={EndYearOptions}
                customPathname={customPathname}
                setEndYearOptions={setEndYearOptions}
                StartYearOptions={StartYearOptions}
                setStartYearOptions={setStartYearOptions} />

            </ul>
          </main>
          <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
              Reset
            </button>
            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] rounded-[3px] text-[13px] font-sans_medium_body leading-[17px] ">
              Filter
            </button>
          </div>
          {/* reset button */}
        </form>
      </div>
    </div>
  );
}

