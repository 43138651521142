import React from 'react'
import Table from './Table'

/**
 * The TableComponent function renders a table component with specified columns and menu props.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu` and `props.sub_type_master_id`. In this case, the `menu` and `sub_type_master_id`
 * properties
 * @returns The TableComponent is returning a JSX element. It contains a div with the class name
 * "pr-[8px] pl-[11px]" and another div with the class name "App lg:relative". Inside the second div,
 * there is a Table component with the columns and menu props passed to it.
 */
const TableComponent = (props) => {

    const columns = [
        { accessor: 'Investor', label: 'Firm Name' },
        { accessor: 'fundName', label: 'Fund Name' },
        { accessor: 'stage', label: 'Type' },
        { accessor: 'size', label: 'Size' },
        { accessor: 'amount_raised', label: 'Amount raised (US$M) ' },
        { accessor: ' closeStatus', label: 'Status' },
        { accessor: 'source', label: 'Capital Source' },
        { accessor: 'fundingDate', label: 'Date' },
         { accessor: 'add', label: 'Add' },
    
 
      ]
     console.log(props.menu,'fund props menu');
 
    return (
      
        <div className="pr-[8px] pl-[11px]">
            <div className="App lg:relative">
                <Table  columns={columns} menu={props?.menu} sub_type_master_id={props?.sub_type_master_id}/>
            </div>
        </div>
    )
}

export default TableComponent