import React from 'react'
import Layout from '../../Components/InvestorLayout'
 import InvestorCompanyDetail from '../../Components/Investor/InvestorDetailPage/InverstorCompanyDetail'
 import RefineSearch from '../../Components/RefineSearchComponent'
 import InvestorCard from '../../Components/Investor/InvestorDetailPage/InvestorDetailCard'
 import ChatIcon from '../../images/Group 13/png/Group 13.png';
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_CARD_QUERY } from '../../Queries/Pe'
import InvestorExistRefineSearch from '../../Components/RefineSearch/InvestorExistRefineSearch'
import useModule from '../../hooks/useModule'
import Constants from '../../Constants'
import { routeNames } from '../../routeSegments'
import { useLocation } from 'react-router-dom'
/**
 * The InvestorExitDetailPage function is a React component that renders a page for displaying investor
 * exit details, including a primary column for displaying company details and a secondary column for
 * refining the search.
 * @returns The InvestorExitDetailPage component is being returned.
 */
function InvestorExitDetailPage({menu}) {
    const [showModal, setShowModal] = React.useState(false);
    const {primaryMasterId} = useModule();
    const {state} = useLocation();

    const customPathname = state?.prevPath != null
        ? state?.prevPath
        : primaryMasterId === Constants.primaryMasterIds.peId
            ? routeNames.pe_investors_pe_vc_investments
            : routeNames.vc_investors_vc_investments

        console.log('state?.prevPath',state?.prevPath,customPathname);

    return ( 
        <div>
            <Layout>
            <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    <InvestorCard page="exits"/>
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]">
                            {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                            <InvestorCompanyDetail page="exits" type="PE-VC Exits" menu={menu}/>
                            
                        {/* <Outlet /> */}
                        {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    <InvestorExistRefineSearch
                                        customPathname={customPathname} />
                                </RefineSearch>
                            </div>
                        </aside>
                    </div>
                    <br/>
                    
                </div>
            </Layout>
        </div>
    )
}

export default InvestorExitDetailPage