import { React, useContext, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

//files
import { UserContext } from "../../../Contexts/UserContext";
import { updateFinancial } from "../../../Config/config";
import useModule from "../../../hooks/useModule";

/**
 * The `InputComponent` is a reusable React component that renders an input field with a label and
 * various props for customization.
 * @returns The InputComponent is returning a JSX element, which consists of a div containing a label
 * and an input element. The label displays the value of the "label" prop, and the input element has
 * various props such as "name", "type", "disabled", "required", "onChange", "value", and "className"
 * that are passed down from the component's props.
 */
const InputComponent = ({ label, type, onChange, name, required, value, disabled }) => {
    return <div className="py-4 items-center px-4">
        <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'>
            {label}
        </label>
        <input
            name={name}
            type={type}
            disabled={disabled}
            required={required}
            onChange={onChange}
            value={value}
            className='border w-full py-1 px-2 mt-1 text-sm text-gray-500' />
    </div>;
}


/* The above code is a React component called "UpdateFinancial". It is a form that allows users to
update financial details for a company. The component includes input fields for the sender's email
address, recipient's email address, CC email address, and a message. When the user submits the form,
a POST request is sent to update the financial details using the axios library. If the request is
successful, a success message is displayed. The component also includes functionality to close the
form and reset the input fields. */
const UpdateFinancial = ({ setOpen, props, page }) => {
    const [toMailId, setToMailId] = useState("cfs@ventureintelligence.com")
    const [cc, setcc] = useState("")
    const [message, setSessage] = useState(' Please update latest financials for the company - ' + window.location.href + '')
    const { getToken, getUser } = useContext(UserContext);
    const [fromMailId, setFromMailId] = useState(getUser()?.email ?? "")
    const { primaryMasterId } = useModule();
    const { companyId } = useParams();
    let companyProfileId = parseInt(companyId);

    /**
     * The handleSubmit function prevents the default behavior of a form submission.
     */
    const handleSubmit = (e) => {
        e.preventDefault()
    }

    /**
     * The function `sendDetail` sends a POST request to update financial details and displays a
     * success message if the request is successful.
     */
    const sendDetail = () => {
        axios.post(updateFinancial, {
            "update_financial_id": page,
            "update_financial_sub_id": companyProfileId,
            "primary_type_master_id": primaryMasterId,
            "to": toMailId,
            "cc": cc,
            "message": message
        }, {
            headers: { "Authorization": getToken() }
        }).then(response => {
            if (response.status === 200) {
                console.log(response, "update financial") 
                toast.success("Your request has been sent successfully")
                setOpen(false)
            }
        }).catch(error => {

        })
    }

    /**
     * The function updates the state variable "fromMailId" with the value of the input field when it
     * changes.
     */
    const fromOnChange = (e) => {
        setFromMailId(e.target.value)
    }

    /**
     * The function `toOnChange` updates the `toMailId` state with the value of the target element.
     */
    const toOnChange = (e) => {
        setToMailId(e.target.value)
    }

    /**
     * The ccOnChange function updates the value of the cc state variable based on the value of the
     * input element.
     */
    const ccOnChange = (e) => {
        setcc(e.target.value)
    }

    /**
     * The function `messagehandleChange` updates the state variable `sessage` with the value of the
     * input field.
     */
    const messagehandleChange = (e) => {
        setSessage(e.target.value)
    }




    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="bg-white">
                            <div className="flex items-center  p-4">
                                <div className="w-full px-5 md:px-0 py-1 text-lg font-medium leading-6 text-gray-900 ">Update Financials</div>
                                <button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none pr-1"
                                    onClick={() => setOpen(false)}>
                                    <span className="sr-only">Close</span>
                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <InputComponent disabled={true} required value={fromMailId} label="From" name="fromaddress" onChange={fromOnChange} type="email" />
                                <InputComponent disabled={true} required onChange={toOnChange} value={toMailId} label="To" name="toAddress" type="email" />
                                <InputComponent value={cc} label="CC" name="cc" onChange={ccOnChange} type="text" />

                                <div className="py-4  p-4 items-center px-4 ">
                                    {/* <div className='flex justify-between '>
                                        <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'> Your Message</label>
                                        <span className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333]'>Words left: <span id="word_left">{Constants.emailMaxWordLength - count}</span></span>
                                    </div> */}
                                    <label className='text-[13pt] leading-[17pt] lg:text-[14px] lg:leading-[17px] text-[#333333] font-sans_semi_bold'> Message</label>
                                    <textarea name="text" id="link"
                                        className="border w-full py-1 px-2 mt-2 text-sm text-gray-500"
                                        placeholder="Enter your text here..."
                                        type='text' onChange={(e) => messagehandleChange(e)}
                                        value={message}
                                    >
                                    </textarea>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 space-y-3 md:space-y-0">
                                    <div>
                                        <button type="reset" className="mt-3 inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}>Close</button>
                                    </div>
                                    <div>
                                        <button type='submit' value="submit"
                                            onClick={sendDetail}
                                            className="inline-flex w-full justify-center rounded-md border border-transparent font-sans_bold_body font-medium text-white bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517] px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UpdateFinancial
