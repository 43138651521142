import React from 'react'

/**
 * The `MultiSelectEntity` function is a React component that renders a multi-select dropdown list of
 * entities with search functionality.
 */
function MultiSelectEntity(props) {

  const [primaryEntity, setPrimaryEntity] = React.useState(props.primaryEntity);
  const [searchText, setsearchText] = React.useState("");
  var handleEntityChange = props.handleEntityChange;

  const handleSearchTextChange = (e) => {
    setsearchText(e.target.value);
  }

  React.useEffect(() => {
    if (searchText !== "") {
      const filteredEntity = props.primaryEntity?.filter(entity => entity?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1);
      setPrimaryEntity(filteredEntity);
    } else {
      setPrimaryEntity(props.primaryEntity)
    }
  }, [props.primaryEntity, searchText])


  return (
    <>
      {props?.primaryEntity?.length > 0 && <input
        type="search"
        onChange={handleSearchTextChange}
        className="input w-[98%] border-b-2 border-black my-2 p-2 pt-0 focus:outline-none"
        placeholder="Search"
      />}
      {primaryEntity && primaryEntity.length > 0 && (props.showAll ?? true) ?
        <div
          className={`form-check font-sans_medium_body   cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
        >
          <input
            type="checkbox"
            className="form-check-input"
            name="allSelect"
            id="0"
            checked={!props?.primaryEntity?.some((entity) => entity?.isChecked !== true)}
            onChange={handleEntityChange}
          />
          <label htmlFor='0' className="form-check-label mx-2 mr-2 cursor-pointer text-[13px] text-[#333333] leading-[17px] font-sans_book_body">All</label>
        </div>
        : ""}
      {primaryEntity && primaryEntity.length > 0 ? (
        primaryEntity.map((entity, index) => (
          <div
            className={`form-check font-sans_medium_body cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
            key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              name={entity.name}
              id={entity.id}
              checked={entity?.isChecked || false}
              onChange={handleEntityChange}
            />
            <label htmlFor={entity.id} className="form-check-label mx-2 mr-2 cursor-pointer  text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{entity.name}</label>
          </div>
        ))
      ) :
        (
          <h1 className="text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{props?.field == 'sector' ? 'Choose Industry' : 'No results found!'}</h1>
        )}
    </>
  )
}

export default MultiSelectEntity
