import { useEffect, useContext } from 'react'
import './MonthPickerComponent.css'
import { PeFilterContext } from '../Contexts/PeFilterContext'
import { TableDataContext } from '../Contexts/TableDataContext'
import { TableHeaderContext } from '../Contexts/TableHeaderContext';
import MonthRangePicker from '../Components/shared/MonthRangePicker';
import moment from 'moment'

/**
 * The `MonthPickerComponent` is a component that renders a month range picker and handles the
 * selection of a month range.
 * @param props - props is an object that contains the properties passed to the MonthPickerComponent.
 */
const MonthPickerComponent = (props) => {
  
  const { monthPickerValue, setMonthPickerValue, setIsMonthPickerChanged, setShowMontnPicker,groupId } = useContext(PeFilterContext);
  const { setIsLoad, setIsDataLoad, setCurrentPage } = useContext(TableDataContext);
  const { setSelectedPage } = useContext(TableHeaderContext);
  const MonthPickerValue = (date) => {
    setIsLoad(1)
    setIsLoad(true)
    setIsDataLoad(true)
    setSelectedPage(1);
    setMonthPickerValue(date)
    if (props.onChange) {
      props.onChange(date)
    }
    setIsMonthPickerChanged(1)
    setShowMontnPicker(0)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (props?.date != null) {
      const date = [props?.date[0], props?.date[1]]
      setMonthPickerValue(date)
    }  
    if(groupId?.myList?.length > 0)  {
      setMonthPickerValue(['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")])
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="">
      {groupId?.myList?.length > 0 ? <></> :
      <MonthRangePicker value={monthPickerValue} onChange={MonthPickerValue} /> }
    </div>
  )
}

export default MonthPickerComponent
