import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl, filterSectorByIndutry } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import IndustryFilter from '../RefineSearchComponents/IndustryFilter';
import SectorFilter from '../RefineSearchComponents/SectorFilter';
import { TableDataContext } from '../../Contexts/TableDataContext';
import Constants from '../../Constants';

/**
 * The `MaAdvisorRefineSearch` function is a React component that renders a form for refining search
 * filters in a mergers and acquisitions advisor application.
 * @returns The function `MaAdvisorRefineSearch` returns a JSX element.
 */
function MaAdvisorRefineSearch({ customPathname, adviosrType }) {
    const location = useLocation();
    
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[customPathname]?.globalSearch?.length > 0 ? true : false
    const { setrefineSearchFilters, onErrorHandler, refineSearchFilters, setSectors, MinAcquirerDealRange, MaxAcquirerDealRange, setMonthPickerValue } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);

    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])


   /**
    * The function `applyFilter` updates the refine search filters based on the selected industries,
    * city, sectors, and deal size options.
    */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedSectors = SectorOptions.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;


        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                city: selectedCity,
                dealSize: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
            },
        }))
    }

  /**
   * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by setting
   * its value to an empty object.
   */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
   /**
    * The function `refineSearchFilter` updates search filters, resets month picker value, sets current
    * page to 1, applies filters, selects the first option, and navigates to a custom pathname if the
    * current pathname is different.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. In this case, it is used to prevent the default behavior of the event (e.g., preventing
    * a form submission) by calling the `preventDefault()` method on the event object.
    */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[]}
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
        setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

   /**
    * The function `onResetClick` resets the current page, filters, and navigates to a custom pathname
    * if it is not already the current location.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. In this case, it is likely a click event on a reset button.
    */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

   /**
    * The function `accordionClick` sets the selected value to either 0 or the provided value.
    * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It
    * is used to determine whether the accordion should be selected or deselected.
    */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    useEffect(() => {
        const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

        if (ids.length > 0) {
            const sectorsIds = SectorOptions.filter(sector => sector.isChecked)?.map(option => option.id)
            axios.post(filterSectorByIndutry, {
                "industry": ids,
                category: "investments"
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                if (response.status === 200) {
                    setSectors(response.data?.data?.map(option => ({ ...option, isChecked: sectorsIds.includes(option.id) })))
                } else {
                    setSectors([])
                }
            }).catch(onErrorHandler)
        } else {
            setSectors([])
        }

    }, [IndustriesOptions])


    useEffect(() => {

        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])


    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                label="Industry"
                                open={select === 1} />
                            <SectorFilter
                                SectorOptions={SectorOptions}
                                setSectorOptions={setSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(2)}
                                open={select === 2}
                            />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="City(of Target Company)"
                                open={select === 6} />
                            <AcquirerDealRangeFilter
                                dataPath="dealSize"
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange}
                                label="Deal Size ($M)"
                                setMaxDealOptions={setMaxDealRangeOptions} />
                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default MaAdvisorRefineSearch
