import React, { useContext } from 'react'
import { useState } from 'react'
import SavedFilterContent from '../../../../Components/SavedFilter'
import SavedFilterICON from '../../../../Components/SavedFilter/SavedFilterICON'
import xls from "../../../../images/xls/png/Group 47.png";
import FileIcon from "../../../../images/file.png";
import FileIconBlur from "../../../../images/fileblur.png";
import line from "../../../../images/line.png";
import MonthPickerComponent from '../../../../Utils/MonthPickerComponent';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { routeNames } from '../../../../routeSegments';
import { TableDataContext } from '../../../../Contexts/TableDataContext';
import { exportREInvestorExcel } from "../../../../Config/config";
import ExportPopup from "../../../../Components/ExportPopup/index";
import { investorInvestmentExportData,investorExitExportData,investorIpoExportData} from "../../../../Components/ExportPopup/ExportData"
import { PeFilterContext } from "../../../../Contexts/PeFilterContext";
import { UserContext } from '../../../../Contexts/UserContext';

/**
 * The `Tab` function is a React component that renders a tab with a label and an onClick event
 * handler.
 * @returns The Tab component is returning a div element containing a NavLink component.
 */
export const Tab = ({ label, to, onChange }) => {
    return <div className="lg:py-4  py-5 lg:pl-1 pl-2 ">
        <NavLink
            onClick={(e) => onChange(to)}
            className={({ isActive }) => `whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[14pt] leading-[17pt]  ${isActive ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]" : "font-sans_medium_body text-[#333333] opacity-[.8] hover:opacity-100"}`}
            data-toggle="tab"
            to={to}
            role="tablist">
            {label}
        </NavLink>
    </div>
}

/**
 * The Tabbar function is a React component that renders a tab bar with different tabs for PE-RE
 * investments, PE-RE exits via M&A, and PE-RE backed IPOs.
 * @returns The Tabbar component is being returned.
 */
function Tabbar({ primaryTypeMasterId, subTypeMasterId, typeId ,exportFileName,menu}) {
    const {save, setSave, showMonthPicker,refineSearchFilters ,groupId} = useContext(PeFilterContext);
    const [showExcelPopup, setshowExcelPopup] = useState(false)
    const { setCurrentPage } = useContext(TableDataContext);
    const location = useLocation();
    const {isTrialExport,isStudent} = useContext(UserContext)

   /**
    * The function `onTabChange` checks if the given URL matches the current page and sets the current
    * page to 1 if it doesn't.
    * @param to - The `to` parameter is the target URL that the user is navigating to.
    */
    const onTabChange = (to) => {
        const isCurrentUrl = matchPath(to, location.pathname)
        if (isCurrentUrl == null) {
            setCurrentPage(1)
        }
    }

    const savedList = () => {
        setSave(!save)
    }
    const {pathname} = useLocation()
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false


    return (
        <div className="pl-3 pr-4">
            <div className="scrollbar  rounded shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="px-5 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                    <div className='flex items-center space-x-2 overflow-x-auto overflow-y-hidden'>
                        <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_investors_re_investments} />
                        <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_investors_exists} />
                        <Tab onChange={onTabChange} label="PE-RE Backed IPOs" to={routeNames.re_investors_backed_ipos} />
                    </div>
                    {isFromGlobalSearch() ? <></> :
                    <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                        <label htmlFor="" className="text-[#666666] ">
                            <div className={showMonthPicker!=0 ?"":'flex'}>
                                <MonthPickerComponent />
                            </div>
                        </label>
                            <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>  
                            {isTrialExport == 'false' || isStudent == 'true' || groupId?.myList?.length >0? <></> : <>                      
                                <button>
                                    <img
                                        src={xls}
                                        className="h-[24px] mt-1 max-w-[21px]"
                                        alt=""
                                        onClick={() => setshowExcelPopup(!showExcelPopup)}
                                    />
                                    {showExcelPopup && (
                                        <div>
                                            <ExportPopup 
                                                onClose={() => setshowExcelPopup(false)}
                                                modules="re-investments"
                                                url={exportREInvestorExcel}
                                                fileName={exportFileName}
                                                exportOptions={menu === 're-investments' ? investorInvestmentExportData : menu === 're-exits' ? investorExitExportData : investorIpoExportData}
                                                type={  menu === 're-investemnts' ? "re-investments" : menu === 're-exits' ? "re-exits" : menu === 're-ipo' ? "re-ipo" : "re-investments" }
                                            />
                                            
                                        </div>
                                    )}
                                </button>
                            
                            <div>
                              <img alt='' className="h-[24px] max-w-[2px] mt-1 " src={line} />
                            </div>
                            </>}

                            {/* <SavedFilterICON
                                save={showFilterSave}
                                FileIcon={FileIcon}
                                FileIconBlur={FileIconBlur}
                                savedList={() => setshowFilterSave(prev => !prev)} /> */}
                             {groupId?.myList?.length >0 ?<></>:   <SavedFilterICON
                                save={save}
                                FileIcon={FileIcon}
                                FileIconBlur={FileIconBlur}
                                savedList={savedList} />
                            }
                        </div>
                    </div>
                    }
                </div>
                {isFromGlobalSearch() ? <></> :save && <SavedFilterContent primaryTypeMasterId={primaryTypeMasterId}
                    subTypeMasterId={subTypeMasterId}
                    typeId={typeId}
                    show={save}
                />}
            </div>
        </div>
    )
}

export default Tabbar