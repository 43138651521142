import React from 'react';
import { useMatch } from 'react-router-dom';
import routePathSegments, { routeNames } from '../../routeSegments'
import NavbarItems from './NavbarItems'
import NavbarWrapper from './NavbarWrapper'

/** This component renders a header for a `MA` section of a
website. It uses the `useMatch` hook from the `react-router-dom` library to determine if the current
URL matches certain paths. */
function MAHeader() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.ma}/${routePathSegments.company}/*` })
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.ma}/${routePathSegments.advisor}/*` })
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.ma}/${routePathSegments.transaction_advisor}/*` })
    const isAcquirerCompare = useMatch({path:`/${routePathSegments.compare}/${routePathSegments.acquirers}/*`})
    const isCompanyCompare = useMatch({path:`/${routePathSegments.compare}/${routePathSegments.company}/*`})
    const isAdvisorCompare = useMatch({path:`/${routePathSegments.compare}/${routePathSegments.advisor}/*`})
    const isTransactionAdvisorCompare = useMatch({path:`/${routePathSegments.compare}/${routePathSegments.transaction_advisor}/*`})
    const companyMatch = useMatch({ path: `/${routePathSegments.company}/*` })
    const isAcquirerMatch = useMatch({ path: `/${routePathSegments.ma}/${routePathSegments.acquirer}/*` })

    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.MA_company}
                isLinkActive={isCompanyMatch != null || isCompanyCompare!=null || companyMatch != null}
            />
            <NavbarItems
                title="Acquirers"
                to={routeNames.MA_acquirers}
                isLinkActive={isAcquirerCompare != null || isAcquirerMatch!=null}
            />
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.MA_advisors}
                isLinkActive={isAdvisorMatch != null || isAdvisorCompare != null}
            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.MA_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch != null || isTransactionAdvisorCompare != null}
            />
        </NavbarWrapper>
    )
}

export default MAHeader