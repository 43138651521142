import React from 'react'
import SearchGif from '../../images/search.gif'

/* The code is defining a functional component called `NoDataPlaceholder` in JavaScript React. */
function NoDataPlaceholder({titleMessage="Sorry! No Results Found", message = "We couldn't find what you are looking for" }) {
    return (
        <div className='flex flex-col items-center justify-center'>
            <img className='w-20 h-20' src={SearchGif} alt="" />
            <p className='text-[18px] font-sans_medium_body text-[#333333]'>
                {titleMessage}
            </p>
            <p className='text-[12px] font-sans_medium_body text-[#333333] opacity-[.4]'>
                {message}
            </p>
        </div>
    )
}

export default NoDataPlaceholder