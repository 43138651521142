import React, { useContext, useEffect, useState, Suspense } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import StageFilter from "../../Components/RefineSearchComponents/StageFilter";
import RoundFilter from "../../Components/RefineSearchComponents/RoundFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../../Components/RefineSearchComponents/InvestorHeadquartersFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import InvestorCityTag from '../RefineSearchComponents/InvestorCityTag';
// import InvestorDealFilter from '../RefineSearchComponents/InvestorDealFilter'
import axios from "axios";
import { UserContext } from "../../Contexts/UserContext";
import { filterSectorByIndutry } from "../../Config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
// import InvestorDealRangeFilter from "../RefineSearchComponents/InvestorDealRangeFilter";
const InvestorDealRangeFilter = React.lazy(() => import("../RefineSearchComponents/InvestorDealRangeFilter"));
const InvestorDealFilter = React.lazy(() => import('../RefineSearchComponents/InvestorDealFilter'));

/**
 * The InvestmentRefineSearch function is a React component that renders a form for refining search
 * filters .
 * @returns The function `InvestmentRefineSearch` returns a JSX element.
 */
export default function InvestmentRefineSearch({ customPathname }) {
    const {
        onErrorHandler, setrefineSearchFilters, setSectors ,setMonthPickerValue ,refineSearchFilters} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(0);
    const { getToken } = useContext(UserContext);
    const location = useLocation()
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false



    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [StageOptions, setStageOptions] = useState([])
    const [RoundOptions, setRoundOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [HeadquartersOptions, setHeadquartersOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [DealRange, setDealRange] = useState({ from: "", to: "" })
    const [SwitchState, setSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [MinDealOptions, setMinDealOptions] = useState([])
    const [MaxDealOptions, setMaxDealOptions] = useState([])

    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])

    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = SectorOptions.filter(option => option.isChecked);
        const selectedStages = StageOptions.filter(option => option.isChecked);
        const selectedRounds = RoundOptions.filter(option => option.isChecked);
        console.log(selectedRounds, 'rounds')
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);

        const invMin = MinDealOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const invMax = MaxDealOptions?.filter(option => option.isChecked)?.at(0) ?? null;

        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;


        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                stage: selectedStages,
                round: selectedRounds,
                city: selectedCity,
                investorType: selectedInvestorType,
                investorHeadquarters: selectedHead,
                firmType: selectedFirm,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                operator: SwitchState ? 1 : 0,
                tags: TagsOptions,
                dealInvestorRange: invMin != null && invMax != null ? [invMin, invMax] : []

            },
        }))
    }

    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    useEffect(() => {
        const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

        if (ids.length > 0) {
            axios.post(filterSectorByIndutry, {
                "industry": ids,
                category: "investments"
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                if (response.status === 200) {
                    setSectors(response.data?.data ?? [])
                } else {
                    setSectors([])
                }
            }).catch(onErrorHandler)
        } else {
            setSectors([])
        }

    }, [IndustriesOptions])

    return (
        <div className="h-screen">
            <div className={isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[70%]":"flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
                <div className="flex justify-between px-3 items-center pt-0">
                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>
                    <Switch
                        SwitchState={SwitchState}
                        setSwitchState={setSwitchState}
                        customPathname={customPathname} />
                </div>
                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                    <div className="sticky top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                        <label className=" flex  rounded ">
                            <Tags
                                TagsOptions={TagsOptions}
                                customPathname={customPathname}
                                setTagsOptions={setTagsOptions} />
                        </label>
                    </div>
                </div>
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                SectorOptions={SectorOptions}
                                setSectorOptions={setSectorOptions}
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <StageFilter
                                StageOptions={StageOptions}
                                customPathname={customPathname}
                                setStageOptions={setStageOptions}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3} />
                            <RoundFilter
                                RoundOptions={RoundOptions}
                                setRoundOptions={setRoundOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                open={select === 4} />
                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(14)}
                                open={select === 14} />
                            <InvestorHeadquartersFilter
                                HeadquartersOptions={HeadquartersOptions}
                                setHeadquartersOptions={setHeadquartersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                open={select === 15} />
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="investments"
                                open={select === 16} />
                            <Suspense fallback={<div>Loading...</div>}>
                                <InvestorDealRangeFilter
                                    customPathname={customPathname}
                                    MinDealOptions={MinDealRangeOptions}
                                    setMinDealOptions={setMinDealRangeOptions}
                                    MaxDealOptions={MaxDealRangeOptions}
                                    setMaxDealOptions={setMaxDealRangeOptions}
                                />
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <InvestorDealFilter
                                    customPathname={customPathname}
                                    MinDealOptions={MinDealOptions}
                                    setMinDealOptions={setMinDealOptions}
                                    MaxDealOptions={MaxDealOptions}
                                    setMaxDealOptions={setMaxDealOptions} />
                            </Suspense>
                            <InvestorCityTag
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(20)}
                                open={select === 20} />
                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    );
}
