import React, { useState, useMemo, useContext } from "react";
import { useParams, Link, useLocation, matchPath } from 'react-router-dom';
import moment from "moment"
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel} from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from '../../shared/OverflowToolTip';
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";


/**
 * The `AdvisorCompaniesExits` function is a React component that displays a table of company exits for
 * an advisor, including information such as company name, deal date, exit type, exit status, and
 * acquirer.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `AdvisorCompaniesExits` component. These properties can be accessed using dot notation, such as
 * `props.companyExits` or `props.advisorName`.
 * @returns The `AdvisorCompaniesExits` component is returning a JSX element, which represents the
 * structure and content of the component's rendered output.
 */
const AdvisorCompaniesExits = (props) => {
  
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)    
  const location = useLocation()
  const [showXlDownload, setshowXlDownload] = useState(false)
  const {isTrialExport,isStudent} = useContext(UserContext)
 
/* The `customStringSortMemo` variable is a memoized function that is used for sorting string values in
a table column. It is created using the `useMemo` hook, which memoizes the function so that it is
only re-computed when its dependencies change. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a memoized function that is used for sorting the "Deal Date" column
in the table. It is created using the `useMemo` hook, which memoizes the function so that it is only
re-computed when its dependencies change. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])

const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);

/* The `columns` variable is an array of objects that define the columns for the table in the
`AdvisorCompaniesExits` component. Each object represents a column and contains properties such as
`id`, `Header`, `accessor`, `className`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id:"companyName",
      Header: "Company Name",
      accessor: "companyName",
      headerClassName:"w-[23%]",
      className:"w-[24%]",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center"> 
            {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }
            <OverflowToolTip component={<Link className='hover:text-[#BC8B20]' state={{ cameFrom: location.state?.prevPath }} 
            to={reMatch!=null?routeNames.reCompanyById(value?.id )
             :""}>
              {value.name ?? "--"}
            </Link> } />
           </div>
      },
      sortType: customStringSortMemo,
    }, 
    {
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className:"w-[10%]",
     
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        to={reMatch!=null?routeNames.reCompanyExitsDealById(cell.value.id,cell.value.dealId)
          :""}>
            {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
        </Link>
    },
    sortType:dealDateSort
    },
    {
      Header: "Exit Type",
      accessor: "exitType",
      className:"w-[10%]",
      
     
    },
    {
      Header: "Exit Status",
      accessor: "exitStatus",
      className:"text-left",
      className:"w-[11%]",
    },
    {
      Header: "Acquirer",
      accessor: "acquirer",
      className:"w-[24%]",

     
    
 
      
    },
   
  ], []);

 /* The `rowData` constant is using the `useMemo` hook to memoize the creation of an array of objects.
 This array represents the data that will be displayed in the table rows of the
 `AdvisorCompaniesExits` component. */
  const rowData = useMemo(() => {
    const rows = [];
    {props.companyExits?.length>0 &&
      props.companyExits?.forEach(companies => {
      rows.push({
        companyName: { name: companies?.company, dealId: companies?.deal_id, id: companies?.company_id, logo_path:companies?.logo_path},
        dealDate: {date:companies?.deal_date, dealId: companies?.deal_id, id: companies?.company_id},
        exitType: companies?.exit_type,
        exitStatus: companies?.exit_status,
        acquirer:  companies?.acquirer_name,
      })
    });
  }
    setshowXlDownload(rows.length!==0)
    return rows;
  }, [props.companyExits])

  const initialSort = useMemo(() => [
    {
        id: 'dealDate',
        desc: true
    }
], []);
  return (
    <div className=" px-4 bg-[#EDEDED] w-[100%]">
      <div className="bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
          <div className="flex justify-between mb-3">
            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold ">
              To Companies - Exits
            </h3>
            {isTrialExport != 'false' && isStudent == 'false'  && showXlDownload&&<ExcelDownloader
                url={reExportExcel}
                fileName={Constants.exportNames.exit_investments(props.advisorName)}
                data={{
                  "advisor_type":"L",
                  "section": "exit_investments",
                  "advisor_id": advisorProfileId
               }
            } />}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px]">
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>       
      </div>
    </div>
  );
};

export default AdvisorCompaniesExits;
