import React,{useState,useContext, useEffect} from 'react';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi"
import Loader from '../../Components/Loader';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext'
import { FaqContext } from '../../Contexts/FaqContext';
import { UserContext } from '../../Contexts/UserContext';
import axios from 'axios';
import {faqLogApi,faqTrendTopics } from '../../Config/config';


/**
 * The MA function is a React component that displays a list of frequently asked questions (FAQs) and
 * allows users to expand and collapse each question to view its answer.
 * @returns The MA function returns a JSX element, which represents the UI component to be rendered on
 * the screen.
 */
function MA() {

  const { openRequestPopup } = useContext(RaiseRequestContext)
  const {selectedSearchResult,setSelectedSearchResult,isLoading,faqs, setIsLoading, setTrends} = useContext(FaqContext)
  const { getToken } = useContext(UserContext);


/**
 * The handleOpen function toggles the selectedSearchResult state between the faq_id and an empty
 * string, and creates a log if the selectedSearchResult is not equal to the faq_id.
 * @param faq_id - The `faq_id` parameter is the unique identifier of a frequently asked question
 * (FAQ). It is used to identify a specific FAQ when handling the opening and closing of the FAQ.
 */
  const handleOpen = (faq_id) => {
    if(selectedSearchResult != faq_id)
    {   
      createLog(faq_id)
    } 
    setSelectedSearchResult(selectedSearchResult == faq_id ? "": faq_id);
  }; 
  
/**
 * The function `searchTrend` makes a POST request to a specified endpoint with a specific data payload
 * and headers, and then logs the response data to the console. If the response is successful, it sets
 * the trends state with the received data and sets isLoading to false. If there is an error, it logs
 * an error message to the console.
 */
  const searchTrend =()=>{
    axios(`${faqTrendTopics}`, {
      method: "POST",
      data: {
        "db_type" : 'M&A'
      },
    headers: {
      "Content-Type": "application/json",
      "Authorization": getToken()
    },
    })
      .then((response) => {
        console.log(response.data,"faq trend")
        if (response.data.success == true) {
          setTrends(response.data.data ??[])
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log("API not working - faq trend ", error);
      });
  }
  /**
   * The function `createLog` sends a POST request to an API endpoint with the provided `faq_id` and
   * `db_type` parameters, along with the necessary headers, and logs the response data or any error
   * that occurs.
   * @param faq_id - The `faq_id` parameter is the ID of the frequently asked question (FAQ) that you
   * want to create a log for. It is used to identify the specific FAQ in the database.
   */
  const createLog =(faq_id)=>{
    axios(`${faqLogApi}`, {
      method: "POST",
      data: {
          "faq_id"  : faq_id,
          "db_type" : "M&A"
    },
    headers: {
      "Content-Type"  : "application/json",
      "Authorization" : getToken()
    },
    })
      .then((response) => {
        console.log(response.data,"faqlog")
      })
      .catch((error) => {
        console.log("API not working - faq log", error);
      });
  }

  useEffect(()=>{
    // setSelectedSearchResult(selectedSearchResult)
  },[faqs])

  return (
    <div>
      {/* <Menu active={1}/> */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-1 px-4 sm:py-6 sm:px-6 lg:px-8">
          <div className="max-w-[930px] mx-auto space-y-2 divide-y-2 divide-gray-200">
          <div
                              className="mt-6 font-sans_medium_body text-center text-[#333333] text-[16pt] leading-[19pt] tracking-[-0.34pt] lg:text-[20px] lg:text-[#333333] lg:leading-[24px] lg:tracking-[-0.43px] lg:mt-1  mb-8">
                              Frequently Asked Questions</div>
            <dl className="mt-6  divide-y-2 divide-gray-200">
                 {
                  isLoading ? 
                  <center className="mt-4" ><Loader /></center>
                  :
                 faqs.length <= 0 ? <center className="mt-4 font-sans_medium_bodylg:text-[15px] text-[15px] text-[#333333]" >No Data Found</center>
                 : faqs.length > 0 && faqs.map((key,value) => (
                <section className='scroll-mt-[18rem]' id={key.id}>
                  <Accordion className="scroll-mt-10 focus:border-none focus:outline-none" open={selectedSearchResult == value } icon={selectedSearchResult == value ? <HiOutlineMinusCircle className="h-5 w-5 text-[#F1CA75] " aria-hidden="true" /> : <HiOutlinePlusCircle className="h-5 w-5 text-[#F1CA75]" aria-hidden="true" />}>
                      <dt className="text-lg">
                        <AccordionHeader className="font-medium	text-left w-full flex justify-between items-start text-gray-400 border-b-0" onClick={() => handleOpen(value)} >
                          <span className="font-sans_medium_body text-[#333333]  font-SequelSans text-[15pt] leading-[18pt] tracking-[-0.32pt] lg:tracking-[-0.39px] lg:leading-[22px] lg:text-[16px]">{key.question}</span>
                        </AccordionHeader>
                      </dt>
                      <AccordionBody className="py-0 pr-12">
                        <p className="lg:px-0  font-sans_book_body  text-[14pt] leading-[17pt] tracking-[-0.3pt] lg:text-[#333333] lg:text-[16px] lg:tracking-[-0.34px] lg:leading-[19px]">{key.answer}</p>
                            <div className="aspect-w-16 aspect-h-9 lg:px-0">
                              <video width="320" className="faqvideo" controls style={{height:220}}>
                                <source className="video-link" src={key.url} />
                              </video>                              
                            </div>
                      </AccordionBody>
                    </Accordion>
                  </section>
                  )) 
                
                  }
            </dl>
          </div>
          <p className={`${faqs.length <= 0 ? '':'border-b-[1px] border-[#D3D3D3]'}   md:mx-[100px]`}></p>
          <div className=" md:flex  justify-center mt-9 mb-4">
             <p
              className="text-[#2B2A29] text-center text-[18px] tracking-[-0.39px] leading-[22px] font-sans_semi_bold  px-1">
              Still have questions?
            </p> 
            <p className='hidden md:flex text-center justify-center font-sans_book_body'>
             Please 
              <a className="text-[#BC8B20] text-[18px]  font-sans_book_body tracking-[-0.39px] leading-[22px]  px-1 cursor-pointer" onClick={() => openRequestPopup("faq")}>
              click here </a>
              to interact with our team.
            </p>
            <div className='block md:hidden' >
              <p className= ' flex text-center justify-center font-sans_book_body md:hidden'>
             Please
              <a className="text-[#BC8B20] text-[18px]  font-sans_book_body tracking-[-0.39px] leading-[22px]  px-1 cursor-pointer" onClick={() => openRequestPopup("faq")}>
              click here </a>
              </p>
              <p className='flex justify-center '>
              to interact with our team.
               </p>
            </div>
          </div>
        
        </div>
  
      </div>
    </div>
  )
}

export default MA



