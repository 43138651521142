import React from 'react';
import { useMatch } from 'react-router-dom';
import NavbarItems from './NavbarItems'
import NavbarWrapper from './NavbarWrapper'
import routePathSegments, { routeNames } from '../../routeSegments'

/** This component renders a header for a `RE` section of a
website. It uses the `useMatch` hook from the `react-router-dom` library to determine if the current
URL matches certain paths. */
function REHeader() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.company}/*` })
    const isInvestorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.investor}/*` })
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.advisor}/*` })
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.transaction_advisor}/*` })
    const companyMatch = useMatch({ path: `/${routePathSegments.company}/*` })

    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.RE_company}
                isLinkActive={isCompanyMatch != null || companyMatch != null}
            />
            <NavbarItems
                title="Investors"
                to={routeNames.RE_investor}
                isLinkActive={isInvestorMatch != null}

            />
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.RE_advisor}
                isLinkActive={isAdvisorMatch != null}

            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.RE_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch != null}

            />
        </NavbarWrapper>
    )
}

export default REHeader