import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';


/**
 * The `FirmTypeFilter` function is a React component that renders a dropdown filter for selecting a
 * firm type.
 * @returns a JSX element, which represents the UI component for filtering firm types.
 */
function FirmTypeFilter({ open, accordionClick, FirmTypeOptions, setFirmTypeOptions, customPathname, dataPath }) {
    const { firmTypeResponseData, refineSearchFilters } = useContext(PeFilterContext);//firmTypeResponseData?.investments

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.firmType?.map(value => value.id) ?? [];
        setFirmTypeOptions(firmTypeResponseData[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [customPathname, dataPath, firmTypeResponseData, refineSearchFilters, setFirmTypeOptions])


    const handleChange = (e) => {
        const { value } = e.target;
        setFirmTypeOptions(FirmTypeOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === parseInt(value) })))
    };

    const selectedFirmType = FirmTypeOptions?.filter(indutry => indutry.isChecked)?.at(0)

    let options = [<option key={"industry.id"}>All</option>]

    FirmTypeOptions?.forEach(industry => {
        options.push(<option key={industry.name} value={industry.id}>{industry.name}</option>)
    });

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedFirmType?.name ? "Firm Type - " + selectedFirmType?.name : "Firm Type"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="firmtype" value={selectedFirmType?.id ?? ""} defaultValue={selectedFirmType?.id} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {FirmTypeOptions && FirmTypeOptions.length > 0
                            ? (options)
                            : (<h1>No results found!</h1>)
                        }
                    </select>
                </div>

            </li>

        </div>
    )
}

export default FirmTypeFilter