import {React, useState, createContext} from 'react'

export const SearchMenuContext = createContext('');

/**
 * The SearchMenuProvider component is a context provider that allows its children components to access
 * and use the search menu data.
 * @returns The SearchMenuProvider component is being returned.
 */
const SearchMenuProvider = ({children}) => {
    const [SearchMenu, setSearchMenu] = useState({
        companyPlaceholder :"Search for companies",
        investorPlaceholder :"Search for investors",
        ladvisorPlaceholder :"Search for legal advisors",
        tadvisorPlaceholder :"Search for transaction advisors",
        cfscompanyPlaceholder:"Search for companies",
        acquirerPlaceHolder :"Search for acquirers",
        data:[]
    }
    );

    return (
        <SearchMenuContext.Provider value={SearchMenu}>
            {children}
        </SearchMenuContext.Provider>
    )
}

export default SearchMenuProvider
