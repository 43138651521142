import React, { useState, useEffect, useContext } from 'react';
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi"
import axios from "axios";
import { faqLogApi,faqTrendTopics } from '../../Config/config';
import { UserContext } from '../../Contexts/UserContext';
import Loader from '../../Components/Loader';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { RaiseRequestContext } from '../../Contexts/RaiseRequestContext'
import { FaqContext } from '../../Contexts/FaqContext';

/**
 * The PE function is a React component that displays a list of frequently asked questions and their
 * corresponding answers, along with the option to expand or collapse each question.
 * @param props - The `props` parameter is an object that contains any properties passed to the `PE`
 * component. These properties can be accessed using dot notation, such as `props.propertyName`.
 * @returns a JSX element, which represents the UI of the component.
 */
function PE(props) {

  const { getToken } = useContext(UserContext);
  // const [isOpen, setIsOpen] = useState("");
  const { openRequestPopup } = useContext(RaiseRequestContext)
  const { selectedSearchResult,setSelectedSearchResult, isLoading, faqs,  setIsLoading ,setTrends} = useContext(FaqContext)


  /**
   * The function `handleOpen` is used to handle the opening of a FAQ item and toggling its selection
   * status.
   * @param faq_id - The `faq_id` parameter is the unique identifier of a frequently asked question
   * (FAQ). It is used to identify a specific FAQ in the system.
   * @param isfrom - The "isfrom" parameter is used to determine where the function is being called
   * from. It could be used to track the source of the function call or to perform different actions
   * based on the source.
   */
  const handleOpen = (faq_id,isfrom) => {
    // setSelectedSearchResult(faq_id)
    if (selectedSearchResult != faq_id) {
      createLog(faq_id)
    }  
    setSelectedSearchResult(selectedSearchResult == faq_id ? null : faq_id)
 
  };

/**
 * The function `searchTrend` makes a POST request to a specified API endpoint, passing in a JSON
 * object as data and including authorization headers. It then logs the response data to the console
 * and, if the response is successful, sets the trends state variable with the received data and sets
 * the isLoading state variable to false. If there is an error, it logs an error message to the
 * console.
 */
  const searchTrend =()=>{
    axios(`${faqTrendTopics}`, {
      method: "POST",
      data: {
        "db_type" : 'PE'
      },
    headers: {
      "Content-Type": "application/json",
      "Authorization": getToken()
    },
    })
      .then((response) => {
        console.log(response.data,"faq trend")
        if (response.data.success == true) {
          setTrends(response.data.data ??[])
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log("API not working - faq trend ", error);
      });
  }


/**
 * The `createLog` function sends a POST request to an API endpoint with the provided `faq_id` and
 * `db_type` parameters, and then calls the `searchTrend` function.
 * @param faq_id - The `faq_id` parameter is the ID of the frequently asked question (FAQ) that you
 * want to create a log for.
 */
  const createLog = (faq_id) => {
    axios(`${faqLogApi}`, {
      method: "POST",
      data: {
        "faq_id": faq_id,
        "db_type": "PE"
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        console.log(response.data, "faqlog")
        searchTrend()

      })
      .catch((error) => {
        console.log("API not working - faq log", error);
      });
  }

  useEffect(() => {
    console.log(selectedSearchResult,'selectedSearchResult');
  }, [faqs])

  return (
    <div>
      {/* <Menu active={1}/> */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-1 px-4 sm:py-6 sm:px-6 lg:px-8">
          <div className="max-w-[930px] mx-auto space-y-2 divide-y-2 divide-gray-200">
            <div className="mt-6 font-sans_medium_body  text-center text-[#333333] text-[16pt] leading-[19pt] tracking-[-0.34pt] lg:text-[20px] lg:text-[#333333] lg:leading-[24px] lg:tracking-[-0.43px] lg:mt-1  mb-4">
              Frequently Asked Questions</div>
            <dl className="mt-6  divide-y-2 divide-gray-200">
              {
                isLoading ? <center className="mt-4" ><Loader /></center>
                : (!isLoading && faqs.length <= 0) ? <center className="mt-4 font-sans_medium_bodylg:text-[15px] text-[15px] text-[#333333]" >No Data Found</center>
                  : faqs.map((key, value) => (
                  <section id={key.id} className='scroll-mt-[18rem]' >
                    <Accordion className={`focus:border-none outline-none`} key={key.id} open={selectedSearchResult == key.id} icon={selectedSearchResult == key.id ? <HiOutlineMinusCircle className="h-5 w-5 text-[#F1CA75] " aria-hidden="true" /> : <HiOutlinePlusCircle className="h-5 w-5 text-[#F1CA75]" aria-hidden="true" />} >
                      <dt className="text-lg">
                        <AccordionHeader className="font-medium	text-left w-full flex justify-between items-start text-gray-400 border-b-0 " onClick={() => handleOpen(key.id,'from origin')} >
                          <span className="font-sans_medium_body text-[#333333]  font-SequelSans text-[15pt] leading-[18pt] tracking-[-0.32pt] lg:tracking-[-0.39px] lg:leading-[22px] lg:text-[16px]">{key.question}</span>
                        </AccordionHeader>
                      </dt>
                      <AccordionBody className="pr-12 py-0">
                        <p className="lg:px-0 font-sans_book_body  text-[14pt] leading-[17pt] tracking-[-0.3pt] lg:text-[#333333] lg:text-[16px] lg:tracking-[-0.34px] lg:leading-[19px]">{key.answer}</p>
                        <div className="aspect-w-16 aspect-h-9 lg:px-0">
                          <video width="320" className="faqvideo" controls style={{ height: 220 }}>
                            <source className="video-link" src={key.url} />
                          </video>
                        </div>
                      </AccordionBody>
                    </Accordion>
                  </section>
                  ))
                  

              }
            </dl>
          </div>
          <p className={`${faqs.length == 0 ? 'border-nonwe' : 'border-b-[1px] border-[#D3D3D3]'}   md:mx-[100px]`}></p>
          <div className=" md:flex  justify-center mt-9 mb-4">
              <p
              className="text-[#2B2A29] text-center text-[18px] tracking-[-0.39px] leading-[22px] font-sans_semi_bold  px-1">
              Still have questions?
            </p> 
            <p className='hidden md:flex text-center justify-center font-sans_book_body'>
             Please 
              <a className="text-[#BC8B20] text-[18px]  font-sans_book_body tracking-[-0.39px] leading-[22px]  px-1 cursor-pointer" onClick={() => openRequestPopup("faq")}>
              click here </a>
              to interact with our team.
            </p>
            <div className='block md:hidden' >
              <p className= ' flex text-center justify-center font-sans_book_body md:hidden'>
             Please
              <a className="text-[#BC8B20] text-[18px]  font-sans_book_body tracking-[-0.39px] leading-[22px]  px-1 cursor-pointer" onClick={() => openRequestPopup("faq")}>
              click here </a>
              </p>
              <p className='flex justify-center '>
              to interact with our team.
               </p>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default PE



