import React, { useEffect, useContext} from "react";
import { matchPath, useLocation} from "react-router-dom";
import { TableDataContext } from '../../Contexts/TableDataContext'
import { routeNames } from "../../routeSegments";
import { Tab } from "./Investor/components/Tabbar";

/**
 * The MainMenu function is a component in JavaScript that renders a menu with different tabs based on
 * the props passed to it.
 * @param props - The props parameter is an object that contains the properties passed to the MainMenu
 * component.
 * @returns The function `MainMenu` returns JSX elements.
 */
function MainMenu(props) {
  const { setActiveTabDeal, setCurrentPage, setActiveTabs } = useContext(TableDataContext);
  const [openTab, setOpenTab] = React.useState(1);  
  const location = useLocation();

  if (props.active == 1) {
    setActiveTabDeal('re-investments')
    setActiveTabs('re-investments')
  }
  else if (props.active == 2) {
    setActiveTabDeal('re-exits')
    setActiveTabs('re-exits')

  }
  else if (props.active == 3) {
    setActiveTabDeal('other-ma')
    setActiveTabs('other-ma')
  }
  else if (props.active == 4) {
    setActiveTabDeal('re-ipo')
    setActiveTabs('re-ipo')
  }
  else {

  }

  useEffect(() => {
    setOpenTab(props.active)
  }, [openTab])


/**
 * The function `onTabChange` checks if the given URL matches the current page's URL and sets the
 * current page to 1 if they don't match.
 * @param to - The `to` parameter is the target URL that the user is navigating to.
 */
  const onTabChange = (to) => {
    const isCurrentUrl = matchPath(to, location.pathname)
    if (isCurrentUrl == null) {
      setCurrentPage(1)
    }
  }


  return (
    <>

      <div className="overflow-x-auto overflow-y-hidden">
        <div className="scrollbar">
          <div
            className="px-2 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between">
            {
              props?.menu == "l-advisor" ? (
                <div className='flex items-center space-x-2'>
                  <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_advisors_re_investments} />
                  <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_advisors_re_exits} />
                  <Tab onChange={onTabChange} label="PE-RE Other M&A" to={routeNames.re_advisors_other_ma} />
                </div>
              )
                :
                props?.menu == "t-advisor" ? (
                  <div className='flex items-center space-x-2'>
                    <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_transaction_advisors_re_investments} />
                    <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_transaction_advisors_re_exits} />
                    <Tab onChange={onTabChange} label="PE-RE Other M&A" to={routeNames.re_transaction_advisors_other_ma} />
                  </div>
                )

                  :

                  props?.menu == "re" ? (
                    <div className='flex items-center space-x-2'>
                      <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_companies_re_investments} />
                      <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_companies_re_exits} />
                      <Tab onChange={onTabChange} label="Other M&A" to={routeNames.re_companies_other_ma} />
                      <Tab onChange={onTabChange} label="PE-RE Backed IPOs" to={routeNames.re_companies_re_ipo} />
                    </div>
                  )

                    : ""

            }
            
          </div>          
        </div>
      </div>
    </>
  );
}

export default MainMenu;
