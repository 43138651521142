import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Constants from '../../Constants'
import '../../../src/index.css';
import CompanyLogo from '../../Components/CompanyLogo';
import RandomLogo from '../../Components/RandomLogo'
import { IoLinkSharp } from 'react-icons/io5'
import { Divider } from 'antd';
import useModule from '../../hooks/useModule';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

/**
 * The `CountLink` function is a React component that displays a count with a label and an optional
 * link, and allows the user to click on it to perform an action.
 * @returns The CountLink component is being returned.
 */
const CountLink = ({ count, onClick, countLabel, showFound = true,isTotalCount }) => {
    const {setrefineSearchFilters} = useContext(PeFilterContext)
    const location = useLocation()

    return (<span className='flex items-center' >        
            <div onClick={(e) => {
                if (count === 0) {
                    e.preventDefault();
                    e.stopPropagation();
                } else {
                    setrefineSearchFilters(prev => ({...prev,[location.pathname]: {}}));
                    onClick();
                }
            }}
                className={`font-sans_book_body text-[12px] text-[#666666] whitespace-nowrap ${isTotalCount ? 'cursor-default ':'cursor-pointer '} ${showFound === true ? "ml-3" : ""}`}>
                <div className="">
                    {showFound === true ? <><span>Found</span>{" "}</> : null}
                    <span className="text-[#333333]">
                        <b>{count}</b>
                    </span>{" "}
                    <span>
                        {countLabel}
                    </span>
                </div>

            </div>         
        {count === 0 ? null : !isTotalCount && <IoLinkSharp className='ml-1' size={14} />}
    </span>)
}

function SearchColumn({ title, leading, searchResult, count, countLabel, onClick, location, showCountLabel, column, type, labelState, onLabelClick,prevPath }) {

    const [dataList, setDataList] = useState([]);
    const {isPE, isVC, isRE, isMA, isCFS,isPeFunds,isReFunds ,isPeLimitedpartners} = useModule()
    const pathname = useLocation()

    // console.log(prevPath,'global prevPath');

    /* The above code is a React useEffect hook that is triggered whenever the `searchResult` variable
    changes. It retrieves the `matches` property from the `searchResult` object and slices it to get
    the first `Constants.searchMaxRowCount` elements. If `searchResult` or `searchResult.matches` is
    null or undefined, it sets `initialData` to an empty array. Finally, it updates the `dataList`
    state with the `initialData` array. */
    useEffect(() => {
        const initialData = searchResult?.matches?.slice(0, Constants.searchMaxRowCount) ?? [];
        setDataList([...initialData]);
    }, [searchResult])

   /**
    * The `showMore` function appends additional items from `searchResult.matches` to the `dataList`
    * array.
    */
    const showMore = () => {
        setDataList(prev => ([...prev, ...searchResult?.matches?.slice(prev.length, (prev.length + Constants.searchMaxRowCount))]));
    }

    // console.log(isReFunds,'isReFunds');
    const IsFundDetail = pathname.pathname.match("fund")

    return (
        <div className='w-[250%] lg:w-[100%]'>
            {count >0 &&
            <div className="flex items-center border-b-[1px] border-b-[#dfe1e6] py-5">
                <div className="p-2 inline-flex items-center justify-center rounded-md text-white  w-7 h-7 lg:w-8 lg:h-8 custom-icon">
                    {leading}
                </div>
                <div className="ml-3 flex items-center">
                    <p className="font-sans_semi_bold lg:text-[16px] text-[14px] text-[#333333] whitespace-nowrap">
                        {title}
                    </p>

                    { isPeFunds || isReFunds  || isPeLimitedpartners || IsFundDetail?
                        <CountLink count={count} countLabel={countLabel} isTotalCount={false} onClick={()=>{onLabelClick();onClick()}}/> 
                    :
                    isMA || isCFS ?
                    <CountLink count={count} countLabel={countLabel} isTotalCount={false} onClick={()=>{onLabelClick();onClick()}}/> 
                        :                
                    (!isPeFunds && !isReFunds && !IsFundDetail) || !isPeLimitedpartners || isPE || isVC || isRE ?
                    <CountLink count={count} countLabel={countLabel} isTotalCount={true} /> 
                    :
                    <CountLink count={count} countLabel={countLabel} isTotalCount={false} onClick={()=>{onLabelClick();onClick()}}/> 
                    
                    }
                    {isRE && 
                        title === "Company"
                        ? <>
                            {searchResult?.re_inv_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_inv_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.re_exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                            {searchResult?.other_ma_count >0 && <><Divider type="vertical" /> <CountLink count={searchResult?.other_ma_count} countLabel={"Other M&A"} onClick={()=>{onLabelClick('otherMa');onClick()}} showFound={false}/></>}
                            {searchResult?.re_ipo_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.re_ipo_count} countLabel={"IPOs"} onClick={()=>{onLabelClick('ipo');onClick()}} showFound={false}/></>}
                        </>
                        :title === "Investor" ?
                            <>
                            {searchResult?.re_inv_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_inv_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.re_exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                            {searchResult?.re_ipo_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.re_ipo_count} countLabel={"IPOs"} onClick={()=>{onLabelClick('ipo');onClick()}} showFound={false}/></>}
                            </>
                        :title === "Legal Advisors" ?
                        <>
                            {searchResult?.re_inv_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_inv_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.re_exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                            {searchResult?.other_ma_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.other_ma_count} countLabel={"Other M&A"} onClick={()=>{onLabelClick('otherMa');onClick()}} showFound={false}/></>}
                        </>
                        :title === "Transaction Advisor" ?
                        <>
                            {searchResult?.re_inv_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_inv_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.re_exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.re_exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                            {searchResult?.other_ma_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.other_ma_count} countLabel={"Other M&A"} onClick={()=>{onLabelClick('otherMa');onClick()}} showFound={false}/></>}
                        </>
                            : null
                    
                    }

                    { !isRE &&
                        title === "Company"
                            ? <>
                                {searchResult?.pevc_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.pevc_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                                {searchResult?.exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                                {searchResult?.ipo_count >0 && <><Divider type="vertical" /> <CountLink count={searchResult?.ipo_count} countLabel={"IPOs"} onClick={()=>{onLabelClick('ipo');onClick()}} showFound={false}/></>}
                                {searchResult?.angel_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.angel_count} countLabel={"Angels"} onClick={()=>{onLabelClick('angel');onClick()}} showFound={false}/></>}
                                {searchResult?.incubation_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.incubation_count} countLabel={"Incubation"} onClick={()=>{onLabelClick('incubation');onClick()}} showFound={false}/></>}
                            </>
                        :title === "Investor" ?
                            <>
                                {searchResult?.pevc_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.pevc_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                                {searchResult?.exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                                {searchResult?.ipo_count >0 && <><Divider type="vertical" /> <CountLink count={searchResult?.ipo_count} countLabel={"IPOs"} onClick={()=>{onLabelClick('ipo');onClick()}} showFound={false}/></>}
                                {searchResult?.angel_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.angel_count} countLabel={"Angels"} onClick={()=>{onLabelClick('angel');onClick()}} showFound={false}/></>}
                            </>
                        :title === "Limited Partners" ?
                        <>
                        {searchResult?.pevc_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.pevc_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                        {searchResult?.exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}
                          </>    
                        :title === "Legal Advisors" ?
                        <>
                            {searchResult?.pevc_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.pevc_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}                            
                        </>
                        :title === "Transaction Advisor" ?
                        <>
                            {searchResult?.pevc_count >0 && <><Divider type="vertical" /><CountLink count={searchResult?.pevc_count} countLabel={"Investments"} onClick={()=>{onLabelClick('investments');onClick()}} showFound={false}/></>}
                            {searchResult?.exit_count >0  && <><Divider type="vertical" /> <CountLink count={searchResult?.exit_count} countLabel={"Exits"} onClick={()=>{onLabelClick('exits');onClick()}} showFound={false}/></>}                            
                        </>
                            : null
                    }
                </div>
            </div>}
            <div className='flex flex-col justify-between rounded-lg transition duration-150 ease-in-out overflow-y-auto mt-2'>
                <ul>
                    {dataList?.map(data => (
                        <div className='flex items-center w-full border-b-0 pt-4 pb-0 gap-2 md:gap-0'>
                            <div className="md:w-[5%] w-[6%]">
                                {data?.logo_path === "default.png" || data?.logo_path === "/default.png" || !data?.logo_path ?
                                    <RandomLogo name={data?.name} />
                                    :
                                    <CompanyLogo id={data?.id} name={data.name} page="company page" logo={data?.logo_path} />
                                }
                            </div>
                            {console.log(data,'data?.deal_category?.at(0)')}
                            <div className='md:w-[95%] w-[140%] gap-2 md:gap-0'>
                                <Link className={`w-full flex items-center text-left ${type === 'cfs' || title === 'Funds'? 'gap-4' : 'gap-2'}`} onClick={() => onClick(title === 'Funds' ? column.columnLink(data.firm_id,data?.id):column.columnLink(data.id,data?.deal_category?.at(0)))} state={{ fromPath: prevPath ?? "" }} to={title === 'Funds' ? column.columnLink(data.firm_id,data?.id):column.columnLink(data.id,data?.deal_category?.at(0))} >
                                    <div className={`${type === "cfs" ? "lg:w-[35%] w-[45%]" : title === 'Transaction Advisor' || title === 'Legal Advisors' ? "lg:w-[30%] w-[40%]" :title === 'Funds' ? "lg:w-[35%] w-[40%]": "lg:w-[20%] w-[30%]"} float-left font-sans_book_body flex-grow  mark text-[13px] lg:text-[15px] custom-style break-words`}>
                                        <span dangerouslySetInnerHTML={{ __html: data.name }} />
                                    </div>
                                    <div className={`${type === "cfs" ? "lg:w-[65%] w-[75%]" : title === 'Funds' ? "md:w-[30%] w-[45%]": "md:w-[60%] w-[45%]"} float-left font-sans_book_body  text-gray-900 lg:text-[14px] text-[12px] custom-style break-words elipsesTooltip`}>
                                    {title === 'Funds' ? 
                                        <span dangerouslySetInnerHTML={{ __html: data.firm_name }} className='hover:text-[#BC8B20]'/>
                                    :
                                        <span dangerouslySetInnerHTML={{ __html: data.business_desc }}/>
                                    }
                                    </div>
                                    {type !== "cfs" &&
                                        <div className={`${title === 'Transaction Advisor' || title === 'Legal Advisors' ? 'w-[50%] md:w-[20%]':title === 'Funds' ? "w-[45%] md:w-[45%]":'w-[30%] md:w-[20%]'} text-right float-left font-sans_book_body  text-gray-900 lg:text-[14px] text-[12px]  custom-style break-words`}>
                                            {data.addl_text &&
                                                <span dangerouslySetInnerHTML={{ __html: '(' + data.addl_text + ')' }} />
                                            }
                                            {title === 'Funds' && 
                                            <span dangerouslySetInnerHTML={{ __html: data.more_info }}/>
                                            }
                                        </div>
                                    }

                                </Link>
                            </div>
                        </div>
                    ))
                    }
                </ul>
            </div>
            <div>
                {count > dataList.length ?
                    <button className={"mt-2 whitespace-nowrap text-[#98630A]  lg:text-[16px] text-[14px]  lg:mt-4 more-match focus:outline-none"}
                        onClick={() => showMore()}
                    >
                        View More ...
                    </button>
                    : ''}
            </div>
        </div>
    )
}

export default SearchColumn