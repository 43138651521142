import React from "react";
import { Popover } from "antd";

/**
 * The `DealInfo` function is a React component that renders a table displaying information about a
 * fund, including its type, target size, amount raised, date, fund manager, fund status, capital
 * source, and source links.
 * @param props - The `props` parameter is an object that contains data passed to the `DealInfo`
 * component. It is used to access the data needed to render the component.
 * @returns The `DealInfo` component is returning a JSX element, which represents the structure and
 * content of the component's rendered output.
 */
const DealInfo = (props) => {

  const data =
    Object.keys(props?.data).length > 0 &&
    Object.entries(props?.data).map(([key, value]) => {
      return (
        value != "The fund detail has been viewed successfully" &&
        key != "status" &&
        value?.length > 0 &&
        value?.map((round, i) => {

          return Object.entries(round).map(([key1, value1]) => {

            return (
              <div className="">
                {value1?.selected == true ? (
                  <p
                    key={value1?.deal_id}
                    className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold flex  "
                    readonly
                  >
                    {value1?.source ?
                      value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).map((link, index) => {
                        return (
                          <div>
                            <div className="hidden lg:block">
                          <Popover
                            trigger="hover"
                            placement="topLeft"
                            className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                            content={
                              <a
                                className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] ursor-auto font-semibold hover:underline "
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link}
                              </a>
                            }
                          >
                            <a
                              className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.length > 0 ?
                                <span className={index>0 ? "ml-1":""}>
                                  Link {index + 1}
                                  {value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).length > index + 1
                                    ? ", "
                                    : ""}
                                </span>
                                : "--"
                              }
                            </a>
                          </Popover>
                          </div>
                            <a className="lg:hidden text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold "
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.length > 0 ?
                                <span className={index>0 ? "ml-1":""}>
                                  Link {index + 1}
                                  {value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).length > index + 1
                                    ? ", "
                                    : ""}
                                </span>
                                : "--"
                              }
                            </a>
                          </div>
                          
                        );
                      }) :"--"
                    }
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          });
        })
      );
    });
  return (
    <div className="px-5 ">
      <p className="  text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
        Fund Details
      </p>
      <div className="overflow-x-auto scrollbar">
        <table className=" w-full">
          <tr className="border-b-[#E5E5E5] border-b ">
            <td className="py-1 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Fund Type
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.fund_type ? value1?.fund_type : '--'}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-1 pl-12 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Target Size (US$ M)
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.target_size != '$ 0 M' ? value1?.target_size : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>

            <td className="py-2  pl-12 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Amount Raised
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {

                      return Object.entries(round).map(([key1, value1]) => {

                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.amount_raised !='$ 0 M' ? value1?.amount_raised : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>

          </tr>
          <tr className="border-b-[#E5E5E5] border-b ">
            <td className="py-1 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Date
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.date ? value1?.date : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-2  pl-12 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Fund Manager
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {

                      return Object.entries(round).map(([key1, value1]) => {

                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.fundManager ? value1?.fundManager : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-2 pl-12 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Fund Status
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.fund_status ? value1?.fund_status : "--"}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>

          </tr>
          <tr className="">

            <td className="py-1 lg:leading-[21px]">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Capital Source
              </p>
              {Object.keys(props?.data).length > 0 &&
                Object.entries(props?.data).map(([key, value]) => {
                  return (
                    value != "The fund detail has been viewed successfully" &&
                    key != "status" &&
                    value?.length > 0 &&
                    value?.map((round, i) => {
                      // console.log(key, "getroundhier");
                      // console.log(value, "getroundhiervalue");
                      return Object.entries(round).map(([key1, value1]) => {
                        // console.log(key1, "getroundhier1");
                        // console.log(value1, "value1");
                        return (
                          <div className="">
                            {value1?.selected == true ? (
                              <p
                                key={value1?.deal_id}
                                className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold  "
                                readonly
                              >
                                {value1?.capital_source == null
                                  ? "--"
                                  : value1?.capital_source}
                              </p>
                            ) : (
                              " "
                            )}
                          </div>
                        );
                      });
                    })
                  );
                })}
            </td>
            <td className="py-2 pl-12 lg:leading-[21px] font-sans_book_body">
              <p
                className="text-[11pt]  leading-[18pt] lg:text-[13px]  text-[#333333] whitespace-nowrap cursor-auto"
                readonly
              >
                Source
              </p>
              {data}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default DealInfo;
