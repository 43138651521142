import React, { useState,useContext } from "react";

import Layout from '../../Components/Layout'
import MainMenu from '../../Components/MainMenu'
// import Card from '../../Components/ListPageCard/Card'
import IncubationCard from "../../Components/ListPageCard/IncubationCard";
import Table from '../../Components/TableComponent'
// import { PE_TABLE_QUERY } from '../../Queries/Pe';

import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
// import ExcelPopup from "../../Components/ExcelPopup";
import line from "../../images/line.png";
import moment from "moment";

import RefineSearch from '../../Components/RefineSearchComponent'
import FilterTag from '../../Components/Tag'
// import ComingSoon from '../../Components/ComingSoon'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import ExportPopup from "../../Components/ExportPopup";
import { incubationExportData } from "../../Components/ExportPopup/ExportData";
import SavedFilterContent from "../../Components/SavedFilter";

import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import { exportPDF } from "../../Config/config";
import Constants from "../../Constants";
import IncubationRefineSearch from "../../Components/RefineSearch/IncubationRefineSearch";
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { UserContext } from "../../Contexts/UserContext";
import { Switch, Tooltip } from "antd";
import ExcelDownloader from "../../Components/shared/ExcelDownloader";

/**
 * The Incubation function is a JavaScript function that renders a page layout for displaying and
 * managing incubation data, including filters, search functionality, and a table for displaying the
 * data.
 * @returns The Incubation component is being returned.
 */
function Incubation() {

  // const [save, setSave] = useState(false);
  const {save, setSave,showMonthPicker,groupId} = useContext(PeFilterContext);
  const { isStudent,isTrialExport} = useContext(UserContext)
  const [showPopup, setShowPopup] = useState(false);
  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);
  const savedList = () => {
    setSave(!save)
  }
  const location = useLocation();
  const { pathname } = useLocation(); 
 
  const { refineSearchFilters, dealsView, setDealsView, switchValue, setSwitchValue } = useContext(PeFilterContext);
  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
  
  
  const dealsViewHandler = (checked) =>{
    setSwitchValue(checked);
    if(checked){ setDealsView(true) }else setDealsView(false);
  }

  return (
    <Layout>
      <div className="flex h-screen flex-col">
        {/* Top nav*/}
     {isFromGlobalSearch() ? "" :<div className="hidden lg:block"> <IncubationCard /> </div> }  
        {/* Bottom section */}
        <div className="flex min-h-0 flex-1">
          {/* Narrow sidebar*/}
          {/* Main area */}
          <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
            {/* Primary column */}
            <section
              className="flex h-full min-w-0 flex-1 overflow-y-auto flex-col lg:order-last">
              {/* Your content */}
              <div className="mr-16 flex justify-between items-center">
              <FilterTag />
              <Tooltip title={`${dealsView ? 'Toggle Companies View' : 'Toggle Deals View'}`}>
                <Switch onChange={dealsViewHandler} checked={switchValue} handlebg='black'/>
              </Tooltip>
              </div>
              <div className="pl-3 lg:pr-4 pr-4 md:pr-5">
                <div className="scrollbar  rounded shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                  <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                    {/* <div className='px-3'> */}
                    <MainMenu active={5} menu="incubation" />
                    {isFromGlobalSearch() || groupId?.myList?.length > 0 ? "" :   <div className={"md:flex justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? '':'flex')}>
                      <label htmlFor="" className="text-[#666666] ">
                      <div className={showMonthPicker!=0 ?"":'flex'}>
                          <MonthPickerComponent  />
                        </div>
                      </label>
                      <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                      {isStudent == 'true' || isFromGlobalSearch() ? <></>  : <>                   
                          <button>
                          {
                             isTrialExport=='false' ?
                                  <ExcelDownloader
                                  url={exportPDF}
                                  fileName={Constants.exportNames.pe_company_incubation}
                                  data={{"type" : "incubation"}}  
                                  children={<img
                                    src={xls}
                                    className="max-h-[24px] mt-1 max-w-[21px]"
                                    alt=""
                                  />}                   
                                />
                                :
                            <img
                              src={xls}
                              className="h-[24px] mt-1 max-w-[21px]"
                              alt=""
                              onClick={() => setShowPopup(!showPopup)}
                            />
                          }
                             {showPopup &&<ExportPopup onClose={()=>setShowPopup(false)} 
                             modules="company"
                             url={exportPDF}
                             module_type="pe"
                             fileName={Constants.exportNames.pe_company_incubation}
                             exportOptions={incubationExportData} type="incubation"/>}
                          </button>
                        <a>
                          <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                        </a>
                        </> 
                      }
                        <SavedFilterICON  
                          save={save} 
                          FileIcon={FileIcon} 
                          FileIconBlur={FileIconBlur}  
                          savedList={savedList}  /> 
                      </div>
                    </div> }
                  </div>
                  {save ? <SavedFilterContent  primaryTypeMasterId={Constants.primaryMasterIds.peId}
                      subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_incubation_id}
                      typeId={Constants.moduleIds.companiesId}
                      show={save} /> : ''}
                </div>
              </div>
              <div className='overflow-y-auto'>
                <Table menu="incubation" sub_type_master_id={Constants.subCategoryIds.pe_incubation_id}/>
              </div>
              {/* </div> */}
            </section>
            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
              <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearch>
                  <IncubationRefineSearch customPathname={location.pathname}/>
                </RefineSearch>
              </div>
            </aside>
          </main>
        </div>
        <br />


      </div>
    </Layout>
  )
}

export default Incubation
