import React, {  useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';

import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorBackedIpoRefineSearch from '../../../Components/RefineSearch/InvestorBackedIpoRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
// import chatPopupIcon from '../../../images/chatPopupIcon.png'
// import ChatPopup from '../../../Components/ChatPopup'
import Constants from '../../../Constants'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";


/**
 * The function `BackedIpo` is a React component that renders a layout with a main section and a
 * sidebar, and includes various components such as a card, a filter tag, a main menu, a table, and a
 * refine search component.
 * @returns a JSX element, specifically a layout component with nested divs and other components such
 * as Card, FilterTag, MainMenu, Table, RefineSearch, and InvestorBackedIpoRefineSearch.
 */
function BackedIpo() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();

    const {refineSearchFilters} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className='flex h-screen flex-col'>
            {isFromGlobalSearch() ? ""
               :<div className="hidden lg:block"> <Card card2={window.location.pathname ? (window.location.pathname == '/pe/investors/backed-ipos/pe-vc-investments' ? 'PE-VC Backed IPOs' : '') : ''} />
    </div> } <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <main className={isFromGlobalSearch() ?"mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
                        <section

                            className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                        >
                            <FilterTag />
                            <MainMenu active={3} menu="backedipo" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_backed_ipos_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_ipo}/>
                            <div className='overflow-y-auto'>
                                <Table menu="backedipo" sub_type_master_id={Constants.subCategoryIds.pe_investor_backed_ipos_id}/>

                            </div>


                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>



                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    <InvestorBackedIpoRefineSearch customPathname={location.pathname}/>
                                </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>
                
            </div>

      
        </Layout >
    )
}

export default BackedIpo
