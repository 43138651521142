import React from 'react'
import FAQSearch from '../../Components/FAQ/FAQSearch'
import Layout from '../../Components/Layout';
import Menu from '../../Components/FAQ/Menu';
import MaFAQ from './MA'

/**
 * The function MaFaq returns a JSX element that renders a layout with a search component, a menu
 * component, and a component for frequently asked questions related to mergers and acquisitions.
 * @returns The function `MaFaq` is returning a JSX element, which is a div containing several
 * components: `Layout`, `FAQSearch`, `Menu`, and `MaFAQ`.
 */
function MaFaq() {
  return (
    <div>
      <Layout>
        <FAQSearch  menu="M&A" />
        <Menu active={3} menu="M&A"/>
        <MaFAQ />
      </Layout>
    </div>
  )
}

export default MaFaq
