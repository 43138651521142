import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `ExitStatusFilterSelect` function is a React component that renders a dropdown filter for selecting a
 * exit status.
 * @returns a JSX element
 */
function ExitStatusFilterSelect({ open, accordionClick, defaultLabel = "All", ExitStatusOptions, setExitStatusOptions, customPathname, dataPath }) {
    const { exitStatusResponseData, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.exitStatus?.map(value => value.id) ?? [];
        setExitStatusOptions(exitStatusResponseData[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(parseInt(industry.id)) })))
    }, [customPathname, dataPath, exitStatusResponseData, refineSearchFilters, setExitStatusOptions])

    const handleExitStatusChange = ((e) => {
        const { value } = e.target;
        const currentStatus = ExitStatusOptions.map((status) =>
            ({ ...status, isChecked: status.id === parseInt(value) })
        );
        setExitStatusOptions(currentStatus);
    });

    const selectedStatus = ExitStatusOptions?.filter(indutry => indutry.isChecked)?.at(0)

    let options = [<option key={"status.id"}>{defaultLabel}</option>]

    ExitStatusOptions?.forEach(status => {
        options.push(<option key={status.id} value={status.id}>{status.name}</option>)
    });

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedStatus?.name ? "Exit Status - " + selectedStatus?.name : "Exit Status"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selectedStatus?.id ?? ""} onChange={handleExitStatusChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {ExitStatusOptions && ExitStatusOptions.length > 0
                            ? (options)
                            : (<h1>No results found!</h1>)
                        }
                    </select>
                </div>
            </li>
        </div>
    )
}

export default ExitStatusFilterSelect


