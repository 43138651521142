import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import ExcelPopup from "../../../Components/ExcelPopup";
import { ADVISOR_COMPANY_TOINVESTORS } from "../../../Queries/Pe";
import moment from "moment";
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import routePathSegments from "../../../routeSegments";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel } from "../../../Config/config";
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";


/**
 * The `TransactionAdvisorTo_Investors` function is a React component that displays a table of
 * transactions to investors, with columns for investors, company name, deal date, and deal amount.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TransactionAdvisorTo_Investors` component. These properties can be accessed using dot notation,
 * such as `props.propertyName`.
 * @returns The function `TransactionAdvisorTo_Investors` returns a JSX element, which represents the
 * UI component to be rendered on the screen.
 */
const TransactionAdvisorTo_Investors = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  let advisorType = "L";
  const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
  const location = useLocation();  
  const {primaryMasterId} = useModule();
  const {isTrialExport,isStudent} = useContext(UserContext)


  const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);

  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
   * current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  /* The `customStringSortMemo` constant is a memoized function that is used as a custom sort function
  for a specific column in a data table. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const  row1 = rowA?.values[columnId].map(inv=>inv.Investor).join(" ");
      const  row2 = rowA?.values[columnId].map(inv=>inv.Investor).join(" ");
        const defaultVal = desc ? '--' : 'A';
        return (row1 ?? defaultVal).localeCompare(row2 ?? defaultVal);
    }
}, []);
/* The `customNameSortMemo` constant is a memoized function that is used as a custom sort function for
a specific column in a data table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
const customNameSortMemo = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
  }
}, []);

/* The `dealDateSort` constant is a memoized function that is used as a custom sort function for the
"Deal Date" column in a data table. It compares the deal dates of two rows (`rowA` and `rowB`) and
returns a negative value if `rowA` should be sorted before `rowB`, a positive value if `rowA` should
be sorted after `rowB`, or zero if the two rows have the same deal date. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
/* The `decimalNumberSort` constant is a memoized function that is used as a custom sort function for a
specific column in a data table. */
const decimalNumberSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
    const rowAValue = Number(rowA.values[columnId])
    const rowBValue = Number(rowB.values[columnId])
    if (rowAValue > rowBValue) return 1; 
    if (rowBValue > rowAValue) return -1;
     return 0;
  }
}, [])
/* The `investorSort` constant is a memoized function that is used as a custom sort function for the
"Investors" column in a data table. It compares the investor names of two rows (`rowA` and `rowB`)
and returns a negative value if `rowA` should be sorted before `rowB`, a positive value if `rowA`
should be sorted after `rowB`, or zero if the two rows have the same investor name. */
const investorSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';

      const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
      const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

      return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
  }
}, [])

/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added as thousands separators.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
 /* The `columns` constant is an array of objects that define the columns for the data table in the
 `TransactionAdvisorTo_Investors` component. Each object represents a column and contains properties
 such as `Header` (the column header text), `accessor` (the key to access the data for that column),
 `className` (the CSS class for the column), and `Cell` (a custom component to render the cell
 content). */
  const columns = useMemo(() => [
    {
      id:"investors",
      Header: "Investors",
      accessor: "investors",
      className: " w-[28%]",
      // headerClassName:"w-[14%]",
      Cell: ({ value }) => {
        return <OverflowToolTip className="max-w-[20rem]" component={
          value.map((inv, index) => (<Link className='hover:text-[#BC8B20]'
           state={{ cameFrom: location.state?.prevPath }} to={reMatch!=null?routeNames.reInvestorById(inv.investor_id)
          :""}>
        {`${inv?.investor_name}${index === value.length - 1 ? "" : ", "}`}
        {/* {inv?.Investor_name} */}
    </Link>))
    }/>
      },
      sortType: investorSort,
    },
    {      
        Header: "Company Name",
        accessor: "companyName",
        className: "w-[17%]",

        Cell: ({ value }) => {
          return <OverflowToolTip component={ <Link className='hover:text-[#BC8B20]'
          state={{ cameFrom: location.state?.prevPath }}
          to={reMatch!=null?routeNames.reCompanyFundById(value?.id)
          :""}>
          {value.name ?? "--"}
        </Link>} />
          
        },
        sortType: customNameSortMemo,
      },
    { 
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className: "w-[12%]",
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        to={reMatch!=null?routeNames.reCompanyInvestmentDealById(cell.value.id,cell?.value?.dealId)
            :""}>
            {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
        </Link>
    },
    sortType:dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className: "pl-[8rem] lg:pl-[110px] w-[50%]",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
       

      // headerspanClassName:"ml-auto"
    },
   
   
  ], []);

  /* The `rowData` constant is a memoized function that transforms the `props.toInvestor` data into a
  format that can be used by the data table component. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toInvestor?.forEach(company => {
      rows.push({
        investors:company?.investors, 
        dealDate: {date:company?.deal_date, dealId: company?.deal_id, id: company?.company_id},
        dealAmountInUS: company?.deal_amount,
        companyName:{ name: company?.company_name, id: company?.company_id},
      })
    });
    setshowXlDownload(rows.length!==0)

    return rows;
  }, [props.toInvestor])

  const { pathname, state } = useLocation();

  const initialSort = useMemo(() => [
    {
        id:"dealDate",
        desc: true
    }
], []);
  return (
    <>
      <div className=" px-4 bg-[#EDEDED] w-[100%]">
        <div className="bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
            <div className="flex justify-between mb-2">
              <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold mb-2">
                To Investors
              </h3>
              {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload)&&<ExcelDownloader
                            url={reExportExcel}
                            fileName={Constants.exportNames.to_investors(props.advisorName)}
                            data={{
                              
                              "advisor_type":"T",
                              "section": "to_investors",
                              "advisor_id": advisorProfileId
                           }} />}
            </div>
          </div>
          <div className="space-y-6">
            <div className="bg-white pb-2 rounded-[6px]">
              <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
            </div>
          </div>         
        </div>
      </div>
    </>
  );
};

export default TransactionAdvisorTo_Investors;
