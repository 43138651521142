import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { exportInvestorExcel, exportPDF } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableDataContext } from '../../Contexts/TableDataContext';
import Loader from '../Loader';
import { data as exportOptions } from './ExportData';
import Constants from '../../Constants';
import { UserContext } from '../../Contexts/UserContext';


const useExportRequest = ({ type, modules, module, url, filterData, module_type, category }) => {
    const { tableCompanyId, tableInvestorId, tableAdvisorId, tableTransactionAdvisorId, tableLimitedPartnersId } = useContext(TableDataContext);
    console.log(tableInvestorId, "advisor")
    const { monthPickerValue } = useContext(PeFilterContext);
    const [isExporting, setisExporting] = useState(false);
    const { getToken, logout } = useContext(UserContext);
   /* The above code is defining a state variable called `requestBodyForExport` using the `useState`
   hook. The initial value of `requestBodyForExport` is an object with various properties such as
   "Export_from", "with_deals", "category", "type", "module_type", "with_agg_hide",
   "profile_columns", "inv_deal_columns", "company_id", and "date_range". These properties are used
   to configure the request body for an export operation. The values of some properties are
   determined by variables or constants such as `type`, `module_type`, and `table */
    const [requestBodyForExport, setRequestBodyForExport] = useState(
        {
            "Export_from": "list",
            "with_deals": false,//selectedFilterName1 == "Company Profile + PE-VC Investments" ? true : false,
            "with_transaction": false,
            "category": "investments",
            "type": type,
            "module_type": module_type,
            "with_agg_hide": 0,
            "profile_columns": ["ALL"], // selectedFilterNameProfile,
            "inv_deal_columns": ["ALL"],//["Company", "CIN", "Company Type", "Industry", "Sector", "Amount(US$M)", "Amount(INR Cr)", "Round", "Stage"],
            "company_id": tableCompanyId,
            "date_range": {
                "from_date": monthPickerValue[0],
                "to_date": monthPickerValue[1]
            }
        }
    )


    useEffect(() => {
        const selectedInitial = filterData?.initial?.find(filter => filter?.isChecked);
        const selected = buildDealColumn(selectedInitial, filterData)


        let dealColumns = {};
        let transactionCategory = [];
        let category = filterData?.category[selectedInitial?.category]

       /* The above code is using the `reduce` method to group elements in the `selected` array based
       on their `category` property. It creates an object called `groupedColumns` where each key
       represents a unique category and the value is an array of products that belong to that
       category. If a category already exists in the `groupedColumns` object, the product is added
       to the existing array. If the category does not exist, a new array is created for that
       category. */
        const groupedColumns = selected.reduce((group, product) => {
            const { category } = product;
            group[category] = group[category] ?? [];
            group[category].push(product);
            return group;
        }, {});
        console.log("groupedColumnsgroupedColumns", groupedColumns);
        Object.keys(groupedColumns)?.forEach(key => {
            const cKey = filterData?.categoryKeys[key];
            transactionCategory?.push(filterData?.category[key])
            dealColumns = { ...dealColumns, [cKey]: groupedColumns[key]?.map(filter => filter?.value) }
        })

        if (modules === "fund") {
            setRequestBodyForExport({
                "category": category,

                "module_type": module_type,

                "date_range": {
                    "from_date": monthPickerValue[0],
                    "to_date": monthPickerValue[1]
                }
            })
        }
        else if (modules === "lp_advisor") {
            setRequestBodyForExport({
                "category": category,
                "module_type": module_type,
                "type": type,
                lp_id: tableLimitedPartnersId,
                "date_range": {
                    "from_date": monthPickerValue[0],
                    "to_date": monthPickerValue[1]
                }
            })
        }
        else if (modules === "re-funds") {
            setRequestBodyForExport({
               
                "module_type": module_type,
               
                investor_id: tableInvestorId,
                "date_range": {
                    "from_date": monthPickerValue[0],
                    "to_date": monthPickerValue[1]
                }
            })

        }


    }, [modules, module, module_type, tableInvestorId, tableAdvisorId, monthPickerValue, tableTransactionAdvisorId, tableLimitedPartnersId, type, filterData, tableCompanyId])

 /* The above code is defining a function called `downloadExcel` that is used to download an Excel
 file. It uses the `useCallback` hook to memoize the function and optimize performance. */
    const downloadExcel = useCallback( async ({ fileName }) => {
        setisExporting(true);
        const result = await axios(`${url}`, {
            method: "POST",
            data: JSON.stringify(requestBodyForExport),
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken(),

            },
            responseType: 'blob',
        })
            .then((response) => {

                setisExporting(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                console.log(response.headers['content-type'], "headers");
                console.log(response.headers, "content")
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                return true
            })

            .catch(async (error) => {
                console.log("API not working", error);
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                } else if (error?.response?.status == 400) {
                    const dataAsText = await error?.response?.data?.text()
                    const parsedData = JSON.parse(dataAsText)
                    return parsedData?.message
                }
            });
            return result
    }, [requestBodyForExport, url])
    return { requestBodyForExport, isExporting, downloadExcel }
}

/**
 * The function `buildDealColumn` recursively builds an array of filters based on a given filter option
 * and filter data.
 * @param filterOption - The `filterOption` parameter is an object that contains information about the
 * current filter option. It may have the following properties:
 * @param filterData - The `filterData` parameter is an object that contains filter options and their
 * corresponding data. It is structured like this:
 * @returns The function `buildDealColumn` returns an array of filtered data.
 */
const buildDealColumn = (filterOption, filterData) => {
    let data = [];
    filterData[filterOption?.filterKey]?.filter(filter => filter.isChecked && parseInt(filter.id) !== 100)
        ?.forEach(filter => {
            if (filter.hasMore) {
                const nestedData = buildDealColumn(filter, filterData);
                data = [...data.concat(nestedData)];
            } else {
                data = [...data.concat([filter])];
            }
        })
    if (data.length === 0) {
        filterData[filterOption?.filterKey]?.filter(filter => parseInt(filter.id) !== 100)
            ?.forEach(filter => {
                if (filter.hasMore) {
                    const nestedData = buildDealColumn(filter, filterData);
                    data = [...data.concat(nestedData)];
                } else {
                    data = [...data.concat([filter])];
                }
            })
    }
    return data;
}

/**
 * The ExportPopup function is a React component that renders a modal popup for exporting data in a
 * specific format.
 * @returns The ExportPopup component is being returned.
 */
function ExportPopup({ onClose, fileName = "file", exportOptions, onChange, type, modules, module, url, module_type, title = "Select option for pdf file export", isAlertOnly = false, message = "© TSJ Media Pvt. Ltd. This data is meant for the internal and non-commercial use of the purchaser and cannot be resold, rented, licensed or otherwise transmitted without the prior permission of TSJ Media. Any unauthorized redistribution will constitute a violation of copyright law." }) {
    const [filterData, setfilterData] = useState(exportOptions);
    const [selectedLeft, setselectedLeft] = useState(filterData.initial?.at(0));
    const [filterLevel, setfilterLevel] = useState([]);
    const [showAnimation, setshowAnimation] = useState(false);
    const [showRightAnimation, setshowRightAnimation] = useState(false);
    const [animationCount, setanimationCount] = useState(0);
    const [showNote, setShowNote] = useState(false);

    const { tableCompanyId, setTableCompanyId, rows, tableInvestorId, setTableInvestorId, tableLimitedPartnersId } = useContext(TableDataContext);
    console.log(tableInvestorId, "rows")
    // const [alert, setAlert] = useState({ show: false, message: message || Constants.excelPopupMessage })

    const { monthPickerValue } = useContext(PeFilterContext);
    const { isExporting, downloadExcel } = useExportRequest({ type, modules, module, url, filterData, module_type })
    const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })


    const changeChildFilterStatus = (filterData, option, isChecked) => {
        let modifiedFilterData = { ...filterData };
        let temp = filterData[option.filterKey]?.map(filter => {
            if (filter.hasMore) {
                modifiedFilterData = changeChildFilterStatus(modifiedFilterData, filter, isChecked)
                return { ...filter, isChecked: isChecked }
            } else {
                return { ...filter, isChecked: isChecked }
            }
        })
        modifiedFilterData = { ...modifiedFilterData, [option.filterKey]: [...temp] }
        return modifiedFilterData;
    }






    useEffect(() => {
        setshowAnimation(true)
        setshowRightAnimation(true);
    }, [animationCount])


    const onExportClick = async () => {
        const selectedInitial = filterData?.initial?.find(filter => filter?.isChecked);
        // const selected = buildDealColumn(selectedInitial, filterData)


        // let dealColumns = {};
        // let transactionCategory = [];
        // let category = filterData?.category[selectedInitial?.category]

        // const groupedColumns = selected.reduce((group, product) => {
        //     const { category } = product;
        //     group[category] = group[category] ?? [];
        //     group[category].push(product);
        //     return group;
        // }, {});
        // console.log("groupedColumnsgroupedColumns", groupedColumns);
        // Object.keys(groupedColumns)?.forEach(key => {
        //     const cKey = filterData?.categoryKeys[key];
        //     transactionCategory?.push(filterData?.category[key])
        //     dealColumns = { ...dealColumns, [cKey]: groupedColumns[key]?.map(filter => filter?.value) }
        // })
        // setwithDetails(selectedInitial?.withDetails ?? false);
        // setwithTransaction(selectedInitial?.isTransaction ?? false);

        const result = await downloadExcel({ fileName: fileName })
        if (result === true) {
            onClose()
        } else {
            setReachedLimitPopup({ show: true, message: result })
        }

        // downloadExcel({
        //     ...requestBodyForExport,
        //     // category: category,

        //     // // with_deals: selectedInitial?.hasMore ?? false,
        //     // with_deals: selectedInitial?.withDetails ?? false,
        //     // with_transaction: selectedInitial?.isTransaction ?? false,
        //     // transaction_category: transactionCategory,
        //     // type: selectedInitial?.isTransaction === true ? undefined : requestBodyForExport?.type,
        //     // exit_deal_columns: [],
        //     // inv_deal_columns: [],
        //     // ipo_deal_columns: ['ALL'],
        //     // angel_deal_columns: ['ALL'],
        //     // incb_deal_columns: ['ALL'],
        //     // ...dealColumns
        // });
    }


    const menuRef = useRef();

    useEffect(() => {
        filterLevel?.forEach((filterLev, index) => {
            if (index !== 0) {

                const checked = filterData[filterLev.filterKey].filter(filter => filter.isChecked);

                let temp = filterData[filterLev.parent]?.map(filter => {
                    return { ...filter, isChecked: filter.filterKey === filterLev.filterKey ? checked.length === 0 ? false : true : filter.isChecked }
                })


                let isAllSelected = temp.filter(fill => !fill?.isChecked);

                if (isAllSelected?.length === 1) {
                    temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
                }

                setfilterData(prev => ({ ...prev, [`${filterLev.parent}`]: [...temp] }))

            }

        })

        document.addEventListener("mousedown", (event) => {
            if (!menuRef.current.contains(event.target)) {
                setShowNote(false)
                // setShowModal(false)

            }
        })
    }, [filterLevel])

    if (reachedLimitPopup.show) {
        return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                <div className='w-full flex flex-col '>
                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button onClick={onClose} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>
    }


    return (


        <div ref={menuRef} className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div onClick={() => setShowNote((showNote) => !showNote)} className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                {/* Header */}
                <div className='w-full flex flex-col '>

                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        {/* <h3 className="text-lg font-sans_medium_body font-medium leading-6 text-gray-900" id="modal-title">{title}</h3> */}
                        <div className='w-full'>
                            <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Export</h1>
                            <h1 className='text-left text-sm leading-5 text-gray-500'>How do you prefer to export?</h1>
                        </div>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{message}</p>
                        </div>
                    </div>


                </div>



                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button type='submit' value="submit" onClick={isExporting ? null : onExportClick} className='inline-flex w-full justify-center rounded-md border border-transparent font-sans_bold_body font-medium text-white bg-[#BC8B20] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#a37517] ml-3 sm:w-auto sm:text-sm'>
                        {isExporting
                            ? <span className='flex items-center'>
                                <span role="status">
                                    <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </span>
                                Exporting
                            </span>
                            : "Export"}
                    </button>
                    <button onClick={onClose} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>


    )
}



export default ExportPopup