import moment from "moment"

export const LEVEL_1 = `{
  "measures": [
    "DealFlat.AmountM"
  ],
  "timeDimensions": [
    {
      "dimension": "DealFlat.date",
      "granularity": "year",
      "dateRange": [
        "2018-05-25",
        "2023-05-25"
      ]
    }
  ],
  "order": {},
  "dimensions": []
}
`;

/**
 * The function `getMasterDataQuery` returns a query object that retrieves the count of DateMasterCombo
 * records and sorts them in descending order.
 */
export const getMasterDataQuery = () => {

  let periods = [
    "DateMasterCombo.cyqy",
    "DateMasterCombo.cyqyDesc",
    "DateMasterCombo.cyhy",
    "DateMasterCombo.cyhyDesc",
    "DateMasterCombo.cymy",
    "DateMasterCombo.cymyDesc",
    "DateMasterCombo.fy",
    "DateMasterCombo.fyDesc",
    "DateMasterCombo.fyqy",
    "DateMasterCombo.fyqyDesc",
    "DateMasterCombo.fyhy",
    "DateMasterCombo.fyhyDesc",
    "DateMasterCombo.fymy",
    "DateMasterCombo.fymyDesc"
  ]


  return {
    "measures": [
      "DateMasterCombo.count"
    ],
    "timeDimensions": [],
    "order": {
      "DateMasterCombo.count": "desc"
    },
    "filters": [],
    "dimensions": periods
  }
}

export const initialDateRange = [
  moment().endOf("month").subtract(5, "year").add(1, "month").startOf('month').format("YYYY-MM-DD"),
  moment().endOf("month").format("YYYY-MM-DD"),
];

/**
 * The `peFilterQuery` function generates a query object based on the provided data to filter and
 * retrieve specific data from a database.
 * @param data - The `data` parameter is an object that contains various filters and options for the
 * query. 
 * @returns The function `peFilterQuery` returns an object with various properties based on the input
 * data. The properties include `dimensions`, `filters`, `order`, `segments`, `measures`, `limit`,
 * `timeDimensions`, and `granularity`.
 */
export const peFilterQuery = (data) => {
  console.log("peFilterQuery",data);
  const peDimensions = {
    industry: "DealFlat.industry",
    stage: "DealFlat.stage",
    rounds: "DealFlat.round_type",
    investorType: "DealFlat.investor_type",
    location: "DealFlat.region",
    tags: "Pecompanies.tags",
    dealRange: "PERange_master.id",
  }



  let pePeriods = {
    yearly: "DateMaster.cy",
    quarterly: "DateMaster.cyqy",
    halfYearly: "DateMaster.cyhy",
    monthly: "DateMaster.cymy",
  }

  // let pePeriodsDesc = {
  //   yearly: "DateMaster.cyDesc",
  //   quarterly: "DateMaster.cyqyDesc",
  //   halfYearly: "DateMaster.cyhy",
  //   monthly: "DateMaster.cymy",
  // }

  if (data?.period === "FY") {
    pePeriods = {
      yearly: "DateMaster.fy",
      quarterly: "DateMaster.fyqy",
      halfYearly: "DateMaster.fyhy",
      monthly: "DateMaster.fymy",
    }
  }

  let dimensions = []
  let filters = []
  let availableFiltersKeys = []
  let order = []

  availableFiltersKeys = Object.keys(data)
    ?.filter(key => key === data.filterViewOption && data[key]?.length !== 0)
console.log("availableFiltersKeys",availableFiltersKeys);
  dimensions = availableFiltersKeys?.map(key => peDimensions[key])

  // filters = availableFiltersKeys?.map(key => {
  //   return {
  //     "member": peDimensions[key],
  //     "operator": "equals",
  //     "values": data[key]?.map(data => availableFiltersKeys.includes('dealRange') ? data.id : data.name)
  //   };
  // })

  
/**
 * we add all selected filters in filter query
 */
  Object.keys(peDimensions)?.forEach(key => {
    if (data[key] && data[key]?.length !== 0) {
      filters.push({
        "member": peDimensions[key],
        "operator": "equals",
        "values": data[key]?.map(data =>key == 'dealRange'? data.id : data.name)
      })
    }
  })
 

  let timeGranularity = "year"
  let dateRange = data?.dateRange != null ? data?.dateRange : initialDateRange;


  if (data.viewBy === "periodWise" || data.viewBy == null) {

    let period = pePeriods[data?.viewOption] ?? pePeriods.yearly
    let periodTypeKey = data?.period === "FY" ? "DateMaster.fy" : "DateMaster.cy"

    if (data?.viewOption === "yearly" || data?.viewOption == null) {
      dimensions = [
        period,
        ...dimensions,
      ]
      if (availableFiltersKeys.includes('dealRange')) {
        order = [
          [
            period,
            "desc"
          ],
          [
            peDimensions.dealRange,
            "asc"
          ],
        ]
      } else {
        order = [
          [
            period,
            "desc"
          ],
        ]
      }

    } else {
      dimensions = [
        periodTypeKey,
        period,
        ...dimensions,
      ]
      order = [
        [
          period,
          "desc"
        ],
        [
          periodTypeKey,
          "desc"
        ]
      ]
    }


    // if (data?.viewOption === "quarterly") {
    //   dateRange = [
    //     moment(dateRange?.at(1)).subtract(5, "quarters").format("YYYY-MM-DD"),
    //     dateRange?.at(1)
    //   ]
    // } else if (data?.viewOption === "monthly") {
    //   dateRange = [
    //     moment(dateRange?.at(1)).subtract(5, "months").format("YYYY-MM-DD"),
    //     dateRange?.at(1)
    //   ]
    // } else if (data?.viewOption === "halfYearly") {
    //   dateRange = [
    //     moment(dateRange?.at(1)).subtract(30, "months").format("YYYY-MM-DD"),
    //     dateRange?.at(1)
    //   ]
    // }else if (data?.viewOption === "yearly") {
    //   dateRange = [
    //     moment(dateRange?.at(1)).subtract('5',"year"),
    //     dateRange?.at(1)
    //   ]
    // }

    var segments = [];
    segments.push(
      'DealFlat.standard',
      'DealFlat.industry_seg',
      'Pecompanies.standard',
    );
    
    filters.push({
      "member": "DealFlat.date",
      "operator": "inDateRange",
      "values": dateRange
    })
    filters.map((item, i) => {
      if(item['member'] == 'DealFlat.stage' || item['memer'] == 'PERange_master.id'){
        segments.push('DealFlat.stage_seg');
      }
      if(item['member'] == 'DealFlat.investor_type'){
        segments.push('DealFlat.investortype_seg', 'DealFlat.stage_seg');
      }
      if(item['member'] == 'DealFlat.region'){
        segments.push('DealFlat.investortype_seg', 'DealFlat.stage_seg', 'DealFlat.region_seg');
      }
      if(item['member'] == 'DealFlat.round_type'){
        segments.push('DealFlat.round_seg', 'DealFlat.stage_seg');
      }
    });
    segments = segments.filter(onlyUnique);
    return {
      "dimensions": dimensions,
      "timeDimensions": [],
      "order": order,
      "segments": segments,
      "measures": [
        "DealFlat.deal_id",
        "DealFlat.AmountM"
      ],
      "filters": filters,
      "limit": data?.viewOption == null ? 5 : 5000
    }
  }


  return {
    "measures": [
      "DealFlat.deal_id",
      "DealFlat.AmountM"
    ],
    "timeDimensions": [
      {
        "dimension": "DealFlat.date",
        "granularity": timeGranularity,
        "dateRange": dateRange
      }
    ],
    "segments": [
      "DateMaster.date_seg"
    ],
    "order": [
      [
        "DealFlat.date",
        "asc"
      ]
    ],
    "dimensions": dimensions,
    "filters": filters,
  }
}

/**
 * The `maFilterQuery` function generates a query object for filtering and querying data based on
 * various dimensions and filters.
 * @param data - The `data` parameter is an object that contains various filters and options for the
 * query. 
 */
export const maFilterQuery = (data) => {
  const maDimensions = {
    industry: "MAIndustry.industry",
    dealType: "MaInvestmentsFlat.deal_type",
    location: "MARegion.region",
    dealRange: "MARange_master.id",
  }

  let maPeriods = {
    yearly: "MADateMaster.cy",
    quarterly: "MADateMaster.cyqy",
    halfYearly: "MADateMaster.cyhy",
    monthly: "MADateMaster.cymy",
  }

  if (data?.period === "FY") {
    maPeriods = {
      yearly: "MADateMaster.fy",
      quarterly: "MADateMaster.fyqy",
      halfYearly: "MADateMaster.fyhy",
      monthly: "MADateMaster.fymy",
    }
  }

  let dimensions = []
  let filters = []
  let order = []

  const availableFiltersKeys = Object.keys(data)
    ?.filter(key => key === data.filterViewOption && data[key]?.length !== 0)


  dimensions = availableFiltersKeys?.map(key => maDimensions[key])
  // filters = availableFiltersKeys?.map(key => {
  //   return {
  //     "member": maDimensions[key],
  //     "operator": "equals",
  //     "values": data[key]?.map(data => availableFiltersKeys.includes('dealRange') ? data.id : data.name)
  //   };
  // })

  Object.keys(maDimensions)?.forEach(key => {
    if (data[key] && data[key]?.length !== 0) {
      filters.push({
        "member": maDimensions[key],
        "operator": "equals",
        "values": data[key]?.map(data =>key == 'dealRange'? data.id : data.name)
      })
    }
  })

  let dateRange = data?.dateRange != null ? data?.dateRange : initialDateRange;


  let period = maPeriods[data?.viewOption] ?? maPeriods.yearly
  let periodTypeKey = data?.period === "FY" ? "MADateMaster.fy" : "MADateMaster.cy"

  if (data?.viewOption === "yearly" || data?.viewOption == null) {
    dimensions = [
      period,
      ...dimensions,
    ]
    if (availableFiltersKeys.includes('dealRange')) {
      order = [
        [
          period,
          "desc"
        ],
        [
          maDimensions.dealRange,
          "asc"
        ],
      ]
    } else {
      order = [
        [
          period,
          "desc"
        ],
      ]
    }
  } else {
    dimensions = [
      periodTypeKey,
      period,
      ...dimensions,
    ]
    order = [
      [
        period,
        "desc"
      ],
      [
        periodTypeKey,
        "desc"
      ]
    ]
  }

  var segments = [];   

  filters.push({
    "member": "MaInvestmentsFlat.dealDate",
    "operator": "inDateRange",
    "values": dateRange
  })
  filters.map((item, i) => {
    console.log(item['member'],'items')
   
    if(item['member'] == 'MaInvestmentsFlat.deal_type') {
      segments.push('MaInvestmentsFlat.dealtype_seg');
    }
    else if(item['member'] == 'MaInvestmentsFlat.dealDate'){
      segments=segments;
    }
    else {
      segments.push('MaInvestmentsFlat.standard');
    }
  })
  segments = segments.filter(onlyUnique);

  return {
    "measures": [
      "MaInvestmentsFlat.ma_dealCount",
      "MaInvestmentsFlat.ma_dealAmount",
    ],
    "timeDimensions": [],
    "segments": segments,
    "order":order,
    "dimensions": dimensions,
    "filters": filters,
    "limit": data?.viewOption == null ? 5 : 5000,
  }
}


/**
 * The `reFilterQuery` function generates a query object for filtering and ordering data based on the
 * provided input.
 * @param data - The `data` parameter is an object that contains various properties used to construct a
 * filter query. 
 * @returns The function `reFilterQuery` returns an object.
 */
export const reFilterQuery = (data) => {
  const maDimensions = {
    sector: "REStage.REType",
    location: "RERegion.region",
    dealRange: "RERange_master.id",
  }

  let maPeriods = {
    yearly: "REDateMaster.cy",
    quarterly: "REDateMaster.cyqy",
    halfYearly: "REDateMaster.cyhy",
    monthly: "REDateMaster.cymy",
  }

  if (data?.period === "FY") {
    maPeriods = {
      yearly: "REDateMaster.fy",
      quarterly: "REDateMaster.fyqy",
      halfYearly: "REDateMaster.fyhy",
      monthly: "REDateMaster.fymy",
    }
  }

  let dimensions = []
  let filters = []
  let order = []

  const availableFiltersKeys = Object.keys(data)
    ?.filter(key => key === data.filterViewOption && data[key]?.length !== 0)


  dimensions = availableFiltersKeys?.map(key => maDimensions[key])
  // filters = availableFiltersKeys?.map(key => {
  //   return {
  //     "member": maDimensions[key],
  //     "operator": "equals",
  //     "values": data[key]?.map(data => availableFiltersKeys.includes('dealRange') ? data.id : data.name)
  //   };
  // })
  Object.keys(maDimensions)?.forEach(key => {
    if (data[key] && data[key]?.length !== 0) {
      filters.push({
        "member": maDimensions[key],
        "operator": "equals",
        "values": data[key]?.map(data =>key == 'dealRange'? data.id : data.name)
      })
    }
  })

  let dateRange = data?.dateRange != null ? data?.dateRange : initialDateRange;


  let period = maPeriods[data?.viewOption] ?? maPeriods.yearly
  let periodTypeKey = data?.period === "FY" ? "REDateMaster.fy" : "REDateMaster.cy"

  if (data?.viewOption === "yearly" || data?.viewOption == null) {
    dimensions = [
      period,
      ...dimensions,
    ]
    if (availableFiltersKeys.includes('dealRange')) {
      order = [
        [
          period,
          "desc"
        ],
        [
          maDimensions.dealRange,
          "asc"
        ],
      ]
    } else {
      order = [
        [
          period,
          "desc"
        ],
      ]
    }
  } else {
    dimensions = [
      periodTypeKey,
      period,
      ...dimensions,
    ]
    order = [
      [
        period,
        "desc"
      ],
      [
        periodTypeKey,
        "desc"
      ]
    ]
  }

  filters.push({
    "member": "REinvestments.dates",
    "operator": "inDateRange",
    "values": dateRange
  })
  if(data.filterViewOption == 'sector'){
    filters.push({
      "member": "REinvestments.industryId",
      "operator": "equals",
      "values": [
        "15"
      ],
    })
  }

  return {
    "dimensions": dimensions,
    "timeDimensions": [],
    "order": order,
    "measures": [
      "REinvestments.count",
      "REinvestments.amount"
    ],
    "segments": [
      "REinvestments.restage_seg",
      "REinvestments.standard",
      "REinvestments.reindustry_seg"
    ],
    "filters": filters,
    "limit": data?.viewOption == null ? 5 : 5000
  }

}

/**
 * The function `getFilterQuery` returns a specific filter query based on the product value in the
 * input data.
 * @param data - The `data` parameter is an object that contains information about the product.
 * @returns The function `getFilterQuery` returns the result of calling one of three different filter
 * query functions (`peFilterQuery`, `maFilterQuery`, or `reFilterQuery`) based on the value of the
 * `data.product` parameter.
 */
export const getFilterQuery = (data) => {
  if (data.product === "PE" || data.product == null) {
    return peFilterQuery(data)
  } else if (data.product === "MA") {
    return maFilterQuery(data)
  } else {
    return reFilterQuery(data)
  }

}

/**
 * The `advanceSearchQuery` function generates a search query object based on the provided operator and
 * id parameters.
 * @param operator - The operator parameter is used to determine the type of filtering operation to be
 * performed. 
 * @param id - The `id` parameter is the value that you want to filter on. It represents the country ID
 * that you want to include or exclude in the search query.
 * @returns The function `advanceSearchQuery` returns an object with properties such as "measures",
 * "timeDimensions", "order", "dimensions", and "filters". The specific values of these properties
 * depend on the input parameters `operator` and `id`.
 */
export const advanceSearchQuery = (operator, id) => {
  console.log(operator, id, "requestCUbe");
  return {
    "measures": [
      "CfsFlat.count"
    ],
    "timeDimensions": [],
    "order": {
      "CfsFlat.count": "desc"
    },
    "dimensions": [
      "CfsFlat.country",
      "CfsFlat.company",
      "CfsFlat.industry",
      "CfsFlat.sector"
    ],
    "filters": [
      {
        "member": "CfsFlat.country_id",
        "operator":
          operator == "Includes any" ? "contains"
            : operator == "Includes all" ? "contains"
              : operator == "does not include" ? "notContains"
                : operator == "Equals" ? "equals"
                  : "contains",
        "values": id
      }
    ]
  }
}

/**
 * The function onlyUnique returns true if the value is unique in the array, otherwise it returns
 * false.
 * @param value - The current element being processed in the array.
 * @param index - The index parameter represents the current index of the element being processed in
 * the array.
 * @param array - The array parameter is the array that the function is being called on. It represents
 * the array of values that we want to filter for unique values.
 * @returns The function `onlyUnique` returns `true` if the current value is the first occurrence in
 * the array, and `false` otherwise.
 */
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}