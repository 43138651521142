import moment from "moment";

/* The above code is defining a JavaScript object called "Constants". This object contains various
constants used in the application, such as the number of rows per page, popup messages, subscription
messages, email addresses, maximum word lengths, search parameters, local storage keys, export
names, and module IDs. These constants are used throughout the application for various purposes. */
const Constants = {
    noOfRowPerPage: 10,
    excelPopupMessage: "© TSJ Media Pvt. Ltd. This data is meant for the internal and non-commercial use of the purchaser and cannot be resold, rented, licensed or otherwise transmitted without the prior permission of TSJ Media. Any unauthorized redistribution will constitute a violation of copyright law.",
    peSubscriptionMsg: "You are not listed as a PE database subscriber. Please contact us for subscription information",
    maSubscriptionMsg: "You are not listed as a MA database subscriber. Please contact us for subscription information",
    cfsSubscriptionMsg: "You are not listed as a CFS database subscriber. Please contact us for subscription information",
    subscribeProductMsg: "You are not listed as a database subscriber. Please contact us for subscription information.",
    reachSalesTeamMsg: "You do not have access to PE database. Kindly reach out to our sales team",
    youDontHavePermissionMsg: "You do not have permission to access PE Investor Profile. Kindly reach out to our sales team to upgrade the subscription",
    ventureeMail: "research@ventureintelligence.com",
    maximumFiveTermsMsg:"A maximum of five terms can be selected at once",
    emailMaxWordLength: 200,
    searchMaxRowCount: 3,
    restrictedPlaceholder: "XXX",
    defaultSaveFilterLabel: 'Save refine search request',
    initialMonthRange: [
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
    ],

    sessionTerminatedStatus: 440,

    globalSearchMaxCount:250,

    //Primary master ids
    primaryMasterIds: {
        peId: 1,
        vcId: 2,
        reId: 3,
        mANDaId: 4,
        cfsId: 5,
    },

    primaryMasterSubIds: {
        pe_vc_investments_id: 1,
        pe_vc_exits_id: 2,
        pe_vc_ma_id: 3,
        pe_vc_pm_id: 4,
        pe_vc_backed_ipos_id: 5,
        pe_vc_angel_invesments_id: 6,
        pe_vc_incubation_id: 7,
        pe_vc_advisors_id: 8,
        pe_vc_advisors_exits_id: 9,
        pe_vc_advisors_ma_id: 10,
        pe_vc_advisors_pm_id: 11,
        pe_vc_limited_partners_id: 12,
        pe_vc_exits_limited_partners_id: 13,
        pe_vc_ma_limited_partners_id: 14,
        pe_vc_pm_limited_partners_id: 15,
        pe_vc_funds_id: 16,
        vc_investments_id: 17,
        vc_exits_id: 18,
        vc_ma_id: 19,
        vc_pm_id: 20,
        vc_backed_ipos_id: 21,
        vc_angel_invesments_id: 22,
        vc_incubation_id: 23,
        vc_advisors_id: 24,
        vc_advisors_exits_id: 25,
        vc_advisors_ma_id: 26,
        vc_advisors_pm_id: 27,
        vc_limited_partners_id: 28,
        vc_exits_limited_partners_id: 29,
        vc_ma_limited_partners_id: 30,
        vc_pm_limited_partners_id: 31,
        vc_funds_id: 32,
        re_investments_id: 33,
        re_exits_id: 34,
        re_others_ma_id: 35,
        re_backed_ipos_id: 36,
        re_investor_investment_id: 37,
        re_investor_exits_id: 38,
        re_investor_ipo_id: 39,
        re_advisor_investment_id: 40,
        re_advisor_exits_id: 41,
        re_advisor_otherma_id: 42,
        re_transaction_investment_id: 43,
        re_transaction_exits_id: 44,
        re_transaction_otherma_id: 45,
        ma_companies_id: 46,
        ma_acquirer_id: 47,
        ma_l_advisor: 48,
        ma_t_advisor: 49,
        re_investor_funds_id:50

    },
    subCategoryIds: {
        pe_investments_id: 1,
        pe_exits_id: 2,
        pe_exitsma_id: 3,
        pe_exitspm_id: 4,
        pe_backed_ipos_id: 5,
        pe_angel_invesments_id: 6,
        pe_incubation_id: 7,
        pe_investor_investments_id:8,
        pe_investor_exits_id:9,
        pe_investor_exitsma_id:10,
        pe_investor_exitspm_id:11,
        pe_investor_backed_ipos_id:12,
        pe_investor_angel_invesments_id:13,
        pe_investor_incubation_id:14,
        pe_lp_investments_id: 15,
        pe_lp_exits_id: 16,
        pe_lp_exitsma_id: 17,
        pe_lp_exitspm_id: 18,
        pe_funds_id:19,
        pe_ladvisor_investments_id: 20,
        pe_ladvisor_exits_id:21,
        pe_ladvisor_exitsma_id: 22,
        pe_ladvisor_exitspm_id: 23,
        pe_tadvisor_investments_id: 20,
        pe_tadvisor_exits_id:21,
        pe_tadvisor_exitsma_id: 22,
        pe_tadvisor_exitspm_id: 23,
        vc_investments_id: 24,
        vc_exits_id: 25,
        vc_exitsma_id: 26,
        vc_exitspm_id: 27,
        vc_backed_ipos_id: 28,
        vc_angel_invesments_id: 29,
        vc_incubation_id: 30,
        vc_investor_investments_id:31,
        vc_investor_exits_id:32,
        vc_investor_exitsma_id:33,
        vc_investor_exitspm_id:34,
        vc_investor_backed_ipos_id:35,
        vc_investor_angel_invesments_id:36,
        vc_investor_incubation_id:37,
        vc_ladvisor_investments_id: 38,
        vc_ladvisor_exits_id: 39,
        vc_ladvisor_exitsma_id: 40,
        vc_ladvisor_exitspm_id: 41,
        vc_tadvisor_investments_id: 42,
        vc_tadvisor_exits_id: 43,
        vc_tadvisor_exitsma_id: 44,
        vc_tadvisor_exitspm_id: 45,
        re_investments_id: 46,
        re_exitsma_id: 47,
        re_others_ma_id: 48,
        re_backed_ipos_id: 49,
        re_investor_investment_id: 50,
        re_investor_exits_id: 51,
        re_investor_ipo_id: 52,
        re_investor_funds_id:53,
        re_advisor_investment_id: 54,
        re_advisor_exits_id: 55,
        re_advisor_otherma_id: 56,
        re_tadvisor_investment_id: 57,
        re_tadvisor_exits_id: 58,
        re_tadvisor_otherma_id: 59,
        ma_companies_id: 60,
        ma_acquirer_id: 61,
        ma_l_advisor_id: 62,
        ma_t_advisor_id: 63,
        cfs_id:64

    },
    limitedPartnerId: 5,
    fundsId: 7,


    moduleIds: {
        companiesId: 1,
        investorsId: 2,
        l_advisor_id: 3,
        t_advisor_id: 4,
        limitedPartnerId: 5,
        acquirer_id: 6,
        fundsId: 7,
        incubators_id: 8,

    },

    searchParamKey:{
        notificationType:"notificationType"
    },

    localStorageKeys: {
        loggedInEmail: "LOGGED_IN_EMAIL",
        accessToken: "access_token",
        userId: "user_id",
        allowedModules: "allowed_modules",
        loginExpireAt: "expires_at",
        studentLogin:"studentLogin",
        referer:"Referer",
        isExport:'isExport'
    },

    exportNames: {
        //pe
        pe_company_investment: "PEInv_deals",
        pe_company_exit: "PEExits_deals",
        pe_company_ipo: "PE-backed-IPOs",
        pe_company_angel: "Angel_deals",
        pe_company_incubation: "Inc_deals",

        pe_investor_investment: "PE-Inv-Investors",
        pe_investor_exit: "PE-Exits-Investors",
        pe_investor_exit_ma: "PE-Exits-M&A-Investors",
        pe_investor_exit_pm: "PE-PMS-Investors",
        pe_investor_ipo: "PE-backed-IPOInvestors",
        pe_investor_angel: "Angel_Investors",
        pe_investor_incubation: "Incubator_Accelerator",

        pe_limited_partner_investment: "PE-Inv-Limited Partners",
        pe_limited_partner_exit: "PE-Exits-Limited Partners",
        pe_limited_partner_exit_ma: "PE-Exits-M&A-Limited Partners",
        pe_limited_partner_exit_pm: "PE-PMS-Limited Partners",

        pe_funds: "PE-Funds",

        pe_l_advisor_investment: "PE-Advisors-Legal",
        pe_l_advisor_exit: "PE-Exits-Advisors-Legal",
        pe_l_advisor_exit_pm: "PE-PMS-Advisors-Legal",
        pe_l_advisor_exit_ma: "PE-Exits-M&A-Advisors-Legal",

        pe_t_advisor_investment: "PE-Advisors-Transaction",
        pe_t_advisor_exit: "PE-Exits-Advisors-Transaction",
        pe_t_advisor_exit_pm: "PE-PMS-Advisors-Transaction",
        pe_t_advisor_exit_ma: "PE-Exits-M&A-Advisors-Transaction",


        //vc
        vc_company_investment: "VCInv_deals",
        vc_company_exit: "VCExits_deals",
        vc_company_ipo: "VC-backed-IPOs",
        vc_company_angel: "Angel_deals",
        vc_company_incubation: "Inc_deals",

        vc_investor_investment: "VC-Inv-Investors",
        vc_investor_exit: "VC-Exits-Investors",
        vc_investor_exit_ma: "VC-Exits-M&A-Investors",
        vc_investor_exit_pm: "VC-PMS-Investors",
        vc_investor_ipo: "VC-backed-IPOInvestors",
        vc_investor_angel: "Angel_Investors",
        vc_investor_incubation: "Incubator_Accelerator",
        vc_limited_partner_investment: "VC-Inv-Limited Partners",
        vc_limited_partner_exit: "VC-Exits-Limited Partners",
        vc_limited_partner_exit_ma: "VC-Exits-M&A-Limited Partners",
        vc_limited_partner_exit_pm: "VC-PMS-Limited Partners",

        vc_funds: "VC-Funds",

        vc_l_advisor_investment: "VC-Advisors-Legal",
        vc_l_advisor_exit: "VC-Exits-Advisors-Legal",
        vc_l_advisor_exit_pm: "VC-PMS-Advisors-Legal",
        vc_l_advisor_exit_ma: "VC-Exits-M&A-Advisors-Legal",

        vc_t_advisor_investment: "VC-Advisors-Transaction",
        vc_t_advisor_exit: "VC-Exits-Advisors-Transaction",
        vc_t_advisor_exit_pm: "VC-PMS-Advisors-Transaction",
        vc_t_advisor_exit_ma: "VC-Exits-M&A-Advisors-Transaction",
        //cfs
        cfs_list: "cfsexport",
        //ma
        ma_list: "MA-Companies",
        ma_acquirers: "MA-Acquirers",
        ma_l_advisor: "MA-Advisor-Legal",
        ma_t_advisor: "MA-Advisor_Transaction",
        //re

        re_company_investment: "REInv_deals",
        re_company_exit: "REExits_deals",
        re_company_ipo: "RE_Ipoexits",
        re_company_otherma: "REmerger_acq_data",

        re_investor_investments: "PE-RE-Investments",
        re_investor_exits: "PE-RE-Exits-Via-M&A",
        re_investor_ipos: "PE-RE-Backed-IPOs",

        re_advisor_investments: "PE-RE-Investments",
        re_advisor_exits: "PE-RE-Exits-Via-M&A",
        re_advisor_ipos: "PE-RE-Backed-IPOs",


        re_funds: "RE-Funds",

        funding_investment: (name, module) => `${name}-${module}Inv_deals`,
        funding_exit: (name, module) => `${name}-${module}Exits_deals`,
        funding_ipo: (name, module) => `${name}-${module}IPOs_deals`,
        funding_angel: (name) => `${name}-Angel_deals`,
        funding_incubation: (name) => `${name}-Inc_deals`,
        mergeAndAcquisition: (name) => `${name}-M&A`,
        plStatement: (name) => `${name}`,
        balanceSheet: (name) => `${name}`,
        cashflow: (name) => `${name}`,
        plStandalone: (name) => `${name}_PL_Stand`,
        plConsolidated: (name) => `${name}_PL_Cons`,
        balanceSheetStandalone: (name) => `${name}_BS_Stand`,
        balanceSheetCon: (name) => `${name}_BS_Cons`,
        cashflowStandalone: (name) => `${name}_CF_Stand`,
        cashflowCon: (name) => `${name}_CF_Cons`,
        ratios: (name) => `${name}`,
        captable: (name) => `${name}_Cap Table`,
        similarCompanies: (name) => `${name}_competitors`,

        portfolio_companies_investments: (name) => `${name}-Inv Portfolio`,
        portfolio_companies_exit: (name) => `${name}-Exit Portfolio`,
        portfolio_companies_ipo: (name) => `${name}-IPOs Portfolio`,
        portfolio_companies_funds: (name) => `${name}-funds`,
        portfolio_companies_coInvestor: (name) => `${name}-co-investors`,

        to_companies_investments: (name) => `${name}-Inv`,
        to_companies_exit: (name) => `${name}-Exits`,
        to_investors: (name) => `${name}-To Investors`,
        to_acuirers: (name) => `${name}-To Acquirers`,

        limitedPartnerInvestments: (name) => `${name}-PEInv_deals`,
        limitedPartnerExits: (name) => `${name}-PEExits_deals`,
        limitedPartnerAngel: (name) => `${name}-Angel_deals`,
        limitedPartnerIncubation: (name) => `${name}-Inc_deals`,


        funding_re_investment: (name, module) => `${name}-${module}-RE_Inv_deals`,
        funding_re_exit: (name, module) => `${name}-${module}-RE_Exits_deals`,
        funding_re_otherma: (name, module) => `${name}-${module}-RE_OtherMA_deals`,
        funding_re_ipo: (name, module) => `${name}-${module}-RE_Backed_Ipos`,


        company_investments: (name) => `${name}-Inv`,
        exit_investments: (name) => `${name}-Exits`,
        to_investors: (name) => `${name}-To Investors`,
        to_acquirers: (name) => `${name}-To Acquirers`,

        acquisitions: (name) => `${name}-Acquisitions`,

        to_companies: (name) => `${name}-To Companies`,

        reinvestor_investment:(name)=>`${name}-exit`

    }
}

export default Constants;