import React from 'react'
import MALayout from '../../../Components/M&ALayout'
import AdvisorDetailCard from '../../../Components/MA/DetailCard/AdvisorDetailCard'
import AdvisorDetail from '../../../Components/MaAdvisors/AdvisorsDetail'
import RefineSearch from '../../../Components/RE/RefineSearchComponent'
import MaAdvisorRefineSearch from '../../../Components/RefineSearch/MaAdvisorRefineSearch'
import { routeNames } from '../../../routeSegments'

/**
 * The DetailPage function is a React component that renders a layout with a primary and secondary
 * column, displaying an AdvisorDetailCard component in the primary column and a RefineSearch component
 * in the secondary column.
 * @returns The DetailPage component is being returned.
 */
function DetailPage({ advisorType }) {
    return (
            <MALayout>
                <div className="flex h-screen flex-col">
                    <AdvisorDetailCard advisorType={advisorType}/>
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                            <AdvisorDetail advisorType={advisorType} />

                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                            <RefineSearch>
                                <MaAdvisorRefineSearch adviosrType={advisorType} customPathname={advisorType === "L" ? routeNames.MA_advisors : routeNames.MA_transaction_advisor} />
                            </RefineSearch>
                            </div>
                        </aside>
                    </div>
                    <br />

                </div>
            </MALayout>
    )
}

export default DetailPage
