import { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react'
import axios from "axios";
import { NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import { sortRows, filterRows } from '../helpers'
import Pagination from '../Pagination'
// import { rowsPerPageData } from '../../Config/config'
import { rowsPerPageData, getAdvisorsApi, getPEGlobalSearchCompaniesApi} from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
// import addNoted from "../../images/AtomFavourite_1/png/AtomFavourite.png";
// import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
// import AddNote from "../../Pages/addNote";
import '../TableView.css'
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import { TableDataContext } from '../../Contexts/TableDataContext'
// import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import CompanyLogo from '../../Components/CompanyLogo';
import RandomLogo from '../RandomLogo';
import { routeNames } from '../../routeSegments';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import OverflowToolTip from '../shared/OverflowToolTip';
import AddNotePopover from '../shared/AddNotePopover';
import { getSection } from '../../Utils/utils';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';


 
const Table = ({ columns, menu, advisor_type,sub_type_master_id}) => {

  // console.log('now-menu', menu);
  const { monthPickerValue,  isMonthPickerChanged, setIsMonthPickerChanged, setIsTagClosed, refineSearchFilters ,groupId,setGroupId} = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, setTableAdvisorId, setIsLoad,  setIsDataLoad, currentPage, setCurrentPage,setCurrentFilter, setAllDealIds } = useContext(TableDataContext);

  // const [activePage, setActivePage] = useState(currentPage)
  const [filters, 
    // setFilters
  ] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'advisor_name_sort' })
  const [
    // showId, 
    setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)


  /**
   * The function `findActivePage` sets the current page, renders a table with specified values, and
   * sets a flag to indicate that data is being loaded.
   * @param page - The "page" parameter represents the current page number. It is used to update the
   * current page, render the table with the specified page, monthPickerValue, sort.orderBy, and
   * sort.order, and set the "isLoad" state to true.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
    // setIsDataLoad(false)
  }
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false


 /* The above code is a JavaScript function that is used to render a table of advisors based on certain
 filters and sorting options. */
  const tableRender = useCallback((page, month, column, order) => {
    setIsMonthPickerChanged(0)
    setIsTagClosed(0)
    // axios(`${getAdvisorsApi}`, {
     
      
    
        const category = menu == "l-pe" || menu == "l-vc" || menu == "t-pe" || menu == "t-vc" ? "investments"
          : "investments"
        // "type": menu == "l-pe" ? "pe_vc_investor" : menu == "vcinvestor" ? "vc_investor"
        //   : menu == "pevcExit" ? "pe_vc_exit" : menu == "maPeExit" ? "pe_ma_exit" : menu == "ma_vc" ? "vc_ma_exit" : menu == "public_pevc" ? "pe_pm_exit" : menu == "public_vc" ? "vc_pm_exit"
        //     : menu == "backedipo" ? "pe_vc_ipo" : menu == "vcbackedipo" ? "vc_ipo"

        //       : "",
        // "type": menu == "pe_vc_advisor" ? "pe_vc_advisor" : menu == "vc_advisor" ? "vc_advisor" : menu == "pe_vc_ma_advisor" || menu == "pe_vc_exit" ? "pe_vc_ma_advisor" : menu == "vc_ma_advisor" || menu == "vc_exit" ? "vc_ma_advisor" : menu == "pe_vc_pm_advisor" ? "pe_vc_pm_advisor" : menu == "vc_pm_advisor" ? "vc_pm_advisor"
        
        const dealCategory =  menu == "vc_advisor" ||  menu =="pe_vc_advisor"
        ? "PEVC"
        :  "EXIT"
          
  
           
  
      const sortBy = dealCategory == "PEVC"
        ? "advisor_sort"
        
              : "advisor_sort"
  
              axios(isFromGlobalSearch()? getPEGlobalSearchCompaniesApi:`${getAdvisorsApi}`, {
                method: "POST",
                signal: abortRef.current.signal,
                data:isFromGlobalSearch()
                ? {
                  "searchTerm": refineSearchFilters[location.pathname]?.globalSearch[0]?.searchTerm,
                  "type": refineSearchFilters[location.pathname]?.globalSearch[0]?.type,
                  "dealCategory": dealCategory,
                  "type_id": 3,
                  "page": page,
                  "sortBy": sortBy,
                  "sortDirection": order
                }:{
                "category": category,
        "type": menu == "pe_vc_advisor" ? "pe_vc_advisor" : menu == "vc_advisor" ? "vc_advisor" : menu == "pe_vc_ma_advisor" ?"pe_vc_ma_advisor": menu == "pe_vc_exit" ? "pe_exit_advisor" : menu == "vc_ma_advisor"?"vc_ma_advisor": menu == "vc_exit" ? "vc_exit_advisor" : menu == "pe_vc_pm_advisor" ? "pe_vc_pm_advisor" : menu == "vc_pm_advisor" ? "vc_pm_advisor"
          : "",
        "advisor_type": advisor_type,
        "date_range": {
          "from_date": moment(month[0]).format("YYYY-MM-DD"),
          "to_date": moment(month[1]).format("YYYY-MM-DD")
        },
        "order_by": {
          "column": column,
          "direction": order
        },
        "page": page,
        "industry": refineSearchFilters[location.pathname]?.industry?.map(value => value.id), //Industries //[1],
        "sector": refineSearchFilters[location.pathname]?.sector?.map(value => value.id), //Industries //[1],
        "stage": refineSearchFilters[location.pathname]?.stage?.map(value => value.id),
        "round": refineSearchFilters[location.pathname]?.round?.map(value => value.id),
        "year_founded": refineSearchFilters[location.pathname]?.yearFounded?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.yearFounded[0].name,
          "to": refineSearchFilters[location.pathname]?.yearFounded[1].name
        },
        AdvisorIds: refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        "syndication": refineSearchFilters[location.pathname]?.syndication?.at(0)?.value ? [refineSearchFilters[location.pathname]?.syndication?.at(0)?.value] : [],
        "deals_involving": refineSearchFilters[location.pathname]?.dealsInvolving?.map(deal => deal.value),
        "country": refineSearchFilters[location.pathname]?.countries?.map(country => country.id),
        "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(inv => inv.id),
        "region": refineSearchFilters[location.pathname]?.region?.map(region => region.id),
        "state": refineSearchFilters[location.pathname]?.state?.map(state => state.id),
        "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "firm_type": refineSearchFilters[location.pathname]?.firmType?.map(firm => firm.id),
        "followOnFundStatus": refineSearchFilters[location.pathname]?.followexitStatus?.map(status => status.id),
        "deal_investor_range": (refineSearchFilters[location.pathname]?.dealInvestorRange?.length ?? 0) != 0 ? {
          "from": refineSearchFilters[location.pathname]?.dealInvestorRange?.at(0)?.name,
          "to": refineSearchFilters[location.pathname]?.dealInvestorRange?.at(1)?.name,
        } : null,
        "exited": refineSearchFilters[location.pathname]?.exited?.map(status => status.id),
        "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
          "to": refineSearchFilters[location.pathname]?.dealRange[1].name
        },
        exit_status: refineSearchFilters[location.pathname]?.exitStatus?.map(status => status.id),
        tag: refineSearchFilters[location.pathname]?.tags?.length != 0 ? {
          operator: refineSearchFilters[location.pathname]?.operator,
          data: refineSearchFilters[location.pathname]?.tags?.map(tag => tag.name)
        } : null,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }
      },
     
      headers: {

        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          console.log(response?.data, "axios api response")
          setCurrentFilter(response.config.data)
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.advisors)
          setData(response?.data)
          setTableAdvisorId([])
          setTableAdvisorId(response?.data?.all_advisor_ids)
          setAllDealIds(response?.data?.all_deal_ids)
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus) {
          logout()
        }
        console.log("API not working", error);
      });
  }, [isMonthPickerChanged, refineSearchFilters]);
  // console.log(rows,"axios api response")
  // console.log(data,"jsonn")
 

  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId)
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue, refineSearchFilters,groupId,reload]);


  // const addNote = (id) => {
  //   setShowNote(!showNote)
  //   setShowId(id)
  // }
  // const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  // console.log(filteredRows, "filteredrows");
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = sortedRows
  // console.log(calculatedRows, "paginationadvisor")
  const count = filteredRows.length
  // console.log(count, "advisorcount");

  // if(isMonthPickerChanged == 1 || isTagClosed == 1){
  //   console.log(monthPickerValue,"monthPickerValue")
  //   tableRender(1,monthPickerValue, advisororderingColumn,orderByAdvisor)
  //   setActivePage(1) 
  //  }

  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   * @param accessor - The `accessor` parameter is a value that represents the property or key of the
   * object that you want to sort by. It is used to access the specific property or key in the sorting
   * logic.
   * @param order - The `order` parameter is used to determine the sorting order. It can have two
   * possible values: 'asc' for ascending order and 'desc' for descending order.
   */
  const handleSort = (accessor, order) => {
    setRows([])
    setIsLoad(true);
    setCurrentPage(currentPage)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order == 'asc' && prevSort.orderBy == accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order == 'asc' && sort.orderBy == accessor ? 'desc' : 'asc',groupId)
  }

  var location = useLocation();

  // useEffect(() => {
  //   // storing input name
  //   localStorage.setItem("currenturl",window.location.pathname );
  // }, []);

 /**
  * The function `addCommasToNumber` takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number to which commas need to be added.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(()=>{
    // console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])


  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor == sort.orderBy) {
                          if (sort.order == 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={ i==0 ? "md:pl-5 md:pr-5 pl-5 pr-[8rem] lg:pr-[13rem] lg:relative text-left bg-[#EBEBEB] sticky left-0 pl-5":"px-5 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className='flex whitespace-nowrap'><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows.length > 0 ?
                        rows?.map((row, i) => { 
                          console.log(row, 'rows');
                          return (
                            <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="w-[20%] px-5 py-3 text-left sticky lg:relative left-0  text-[#333333]  bg-white  font-sans_book_body items-center justify-center card-animate leading-[1.8rem] lg:leading-[2rem]"  style={{whiteSpace: "initial"}}>
                                {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                  <RandomLogo name={row?.advisor_name} />
                                  :
                                  <CompanyLogo name={row.advisor_name} id={row?.advisor_id} page="company page" logo={row?.logo_path} />

                                }
                                <OverflowToolTip className="w-[14rem] lg:w-auto lg:max-w-full" component={<NavLink className='text-start flex lg:inline hover:text-[#BC8B20] max-w-[20rem]' state={{ prevPath: location.pathname }}
                                  to={{
                                    pathname: menu == "vc_advisor" || menu == "vc_ma_advisor" || menu == "vc_pm_advisor" || menu == "vc_exit"
                                      ? routeNames.vcAdvisorById(row.advisor_id)
                                      : routeNames.peAdvisorById(row.advisor_id),
                                    search: getSection(menu == "vc_advisor" || menu == "pe_vc_advisor"
                                      ? Constants.primaryMasterSubIds.pe_vc_advisors_id
                                      : menu == "vc_ma_advisor" || menu == "pe_vc_ma_advisor"
                                        ? Constants.primaryMasterSubIds.pe_vc_advisors_ma_id
                                        : menu == "vc_pm_advisor" || menu == "pe_vc_pm_advisor"
                                          ? Constants.primaryMasterSubIds.pe_vc_advisors_pm_id
                                          : Constants.primaryMasterSubIds.pe_vc_advisors_exits_id)
                                  }} >
                                 {row.advisor_name ? row.advisor_name : "--"}
                                </NavLink>} />
                              </td>
                              <td className="w-[5%] px-5 py-3 text-right pr-[2.8rem] md:pr-[3rem] font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                              <td className="w-[4%] px-5 py-3 text-right pr-[2.8rem] md:pr-[3rem] font-sans_book_body " > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                              <td className="w-[2%] px-5 py-3 text-right pr-[2.8rem] md:pr-[3rem] font-sans_book_body" > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                              <td className="w-[2%] px-5 py-3 text-right pr-[2.8rem] md:pr-[3rem] font-sans_book_body"  ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                              <td className="w-[1%] px-[10px] py-3 font-sans_book_body " >
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.l_advisor_id} setReload={setReload}/>
                                 :
                                <AddNotePopover className="mx-3" key={row?.advisor_id} companyId={row?.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.l_advisor_id} sub_type_master_id={sub_type_master_id}/>
                              }
                              </td>
                            </tr> 
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_advisors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_advisors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}

      {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
    </>
  )
}

export default Table
