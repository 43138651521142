import React, { useState } from 'react';
import Layout from '../../Components/Layout'
import Notes from '../../Components/Notes/Notes'

/**
 * The NotePage function returns a JSX element that renders a layout with a Notes component.
 * @returns The NotePage component is returning a JSX element.
 */
function NotePage() { 
   return (
<div className=''>
<Layout>
  <p className="mt-[3rem]">  </p>
   <Notes />
</Layout>

</div>

  )
}

export default NotePage
