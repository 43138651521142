import { Badge } from 'antd'
import React from 'react'

/**
 * The `FilterWrapper` function is a React component that renders a button with a label, count, and an
 * arrow icon that can be rotated based on the `open` prop.
 * @returns a button element with a label and an icon. The appearance of the button depends on the
 * values of the `open`, `count`, and `isSelect` variables. If `open` is true, the button displays a
 * downward arrow icon. If `open` is false, the button displays a count badge (if `count` is greater
 * than 0 and `isSelect`
 */
function FilterWrapper({ label, open, count, isSelect, onClick , disabled}) {
    return (
        <button
            type="button"
            className="w-full text-left"
            disabled={disabled}
            onClick={onClick}>
            <div className="flex items-center justify-between">
                <span className="">{label}</span>
                {open ? (

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>

                ) : (
                    <div className='flex items-center justify-center py-1.5'>
                        {(count > 0 && isSelect === false) && < Badge className='mr-2' count={count} showZero={false} color="#BC8B20" />}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </button>
    )
}

export default FilterWrapper