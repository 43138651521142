import React, { useContext, useMemo, useState } from 'react';
import ExcelDownloader from '../shared/ExcelDownloader';
import { maAdvisorDetailExcelApi } from '../../Config/config';
import Constants from '../../Constants';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import OverflowToolTip from '../shared/OverflowToolTip';
import routePathSegments, { routeNames } from '../../routeSegments';
import moment from 'moment';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import DataTable from '../DataTables/DataTable';
import { UserContext } from '../../Contexts/UserContext';

/**
 * The `AdvisorToCompanies` function is a React component that renders a table of companies associated
 * with a specific advisor, including details such as company name, deal date, deal amount, acquirer,
 * enterprise value, and revenue multiple.
 * @returns The `AdvisorToCompanies` component is returning a JSX element, which represents the
 * structure and content of the component's rendered output.
 */
const AdvisorToCompanies = ({ companies, advisorName, isLoading, advisorType }) => {
    const { advisorsId } = useParams();
    const location = useLocation();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const {isTrialExport,isStudent} = useContext(UserContext)


 /* The `customStringSortMemo` variable is a memoized function that is used for sorting string values
 in a table column. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);
/* The `dealDateSort` variable is a memoized function that is used for sorting the "Deal Date" column
in the table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */

    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

   /* The `investorSort` variable is a memoized function that is used for sorting the "Acquirer" column
   in the table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

   /**
    * The function "addCommasToNumber" takes a number as input and returns a string representation of
    * the number with commas added for thousands separators.
    * @param number - The `number` parameter is the number that you want to add commas to.
    * @returns a string representation of the number with commas added for every three digits.
    */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };
      

   /* The above code is defining an array of columns for a table. Each column has a Header (column
   name), accessor (data key), className (CSS class for styling), and a Cell component (rendering
   logic for each cell in the column). The columns are used in a table component to display data. */
    const columns = useMemo(() => [
        {
            Header: "Company Name",
            accessor: "companyName",
            headerClassName:"w-[25%]",
            className:"w-[25%]",
            Cell: ({ value }) => {
                return <div className='leading-[2rem] font-sans_book_body items-center justify-center' style={{whiteSpace:"break-spaces"}} >
                    {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
                        <RandomLogo name={value.name} page="company page" />
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.logo_path} />
                    }

                    <OverflowToolTip className=" w-[10rem] lg:max-w-[19rem] 2xl:max-w-[22rem]" component={
                        <Link className='flex text-start lg:inline leading-[1.1rem] hover:text-[#BC8B20]'
                            state={{ cameFrom: location.state?.prevPath }}
                            to={routeNames.maCompanyById(value?.id)}>
                            {value.name ?? "--"}
                        </Link>
                    } />
                </div>

            },
            sortType: customStringSortMemo,
        },
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            className:"w-[15%]",

            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }}
                    to={routeNames.maCompanyInvestmentDealById(cell.value.id, cell?.value?.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Deal Amount (US$ M)",
            accessor: "dealAmountInUS",
            className: "px-0 text-right pr-[2.5rem] sm:w-[10%] 2xl:w-[0%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Acquirer",
            accessor: "acquirer",
            headerClassName:"sm:w-[12%] 2xl:w-[20%]",
            className:"sm:w-[12%] 2xl:w-[20%]",
            headerspanClassName: "sm:pl-[1.5rem] 2xl:pl-[6rem]",

            Cell: ({ value }) => {
                return <div className='sm:pl-[1.5rem] 2xl:pl-[6rem]'style={{whiteSpace:'break-spaces'}}><OverflowToolTip className=" w-[10rem] lg:max-w-[13rem] 2xl:max-w-[22rem]" component={
                    <Link className='hover:text-[#BC8B20]'
                        state={{ cameFrom: location.state?.prevPath }}
                        to={routeNames.maAcquiresById(value?.id)}>
                        {value.name ?? "--"}
                    </Link>
                } /></div>
            },
            sortType: customStringSortMemo
        },
        {
            Header: "Enterprise Value",
            accessor: "enterpriseValue",
            className: "px-0 text-right pr-[2.7rem] w-[15%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Revenue Multiple",
            accessor: "revenueMultiple",
            className: "px-0 text-right pr-[2.7rem] w-[12%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        }
    ], []);

  /* The `rowData` variable is a memoized function that is used to transform the `companies` data into
  a format that can be used by the DataTable component. */
    const rowData = useMemo(() => { 
        const rows = [];
     
        companies?.forEach((company, i) => {
            rows.push({
                companyName: { name: company?.company_name, dealId: company?.deal_id, id: company?.company_id, logo_path: company?.icon_path },
                dealDate: { date: company?.deal_date, dealId: company?.deal_id, id: company?.company_id },
                dealAmountInUS: company?.deal_amount > 0 ? company?.deal_amount:"",
                acquirer: { name: company?.acquirer, id: company?.acquirer_id },
                enterpriseValue: company?.enterprise_value > 0 ? company?.enterprise_value : "",
                revenueMultiple: company?.revenue_value > 0 ? company?.revenue_value : "",
            })
        });
        setshowXlDownload(rows.length !== 0)
        return rows;
    }, [companies])

    /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sorting options
    for the table. It specifies that the table should be initially sorted by the "dealDate" column
    in descending order. This means that the rows in the table will be sorted based on the
    "dealDate" values, with the most recent dates appearing first. The empty dependency array `[]`
    ensures that the sorting options are only calculated once and not re-evaluated on subsequent
    renders. */
    const initialSort = useMemo(() => [
        {
            id: "dealDate",
            desc: true
        }
    ], []);

    return (
        <div className="px-4 bg-[#EDEDED]">
            <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                    <div className="flex justify-between mb-2">
                        <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">To Companies</p>
                        {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload) && <ExcelDownloader
                            url={maAdvisorDetailExcelApi}
                            fileName={Constants.exportNames.to_companies(advisorName)}
                            data={{
                                "section": "to_companies",
                                "advisor_id": advisorsId,
                                "advisor_type": advisorType
                            }} />}
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white pb-2 rounded-[6px]">
                        <DataTable
                            columns={columns}
                            data={rowData}
                            sortBy={initialSort}
                            showLoader={isLoading}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdvisorToCompanies