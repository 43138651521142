import { React, useState, useMemo, useContext,useEffect ,useRef} from "react";

/** The above component is used to
display a confirmation popup with customizable options such as the message, button names, title, and
icon. The popup can be shown or hidden based on the `show` prop, and the `onChange` prop is used to
handle the user's response (0 for cancel, 1 for confirm). The component is implemented in two
different styles, one for mobile screens and one for larger screens. */
function ConfirmPopup({
    show,
    message,
    onChange,
    closeBtnName = "No, Cancel",
    activeBtnName = "Yes, I'm Sure",
    isAlertOnly = false,
    title = "Alert !",
    icon
})


 {
    return (
        !show
            ? <></>
            : <div  className="relative z-[100] font-sans_book_body" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div onClick={() => onChange(1)} className="h-screen w-screen fixed inset-0 bg-black bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div   className="fixed inset-0 z-[100] overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">

                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mx-4 mx-0 sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    {icon || <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                                        </svg>
                                    </div>}
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        {/* <h3 className="text-lg font-sans_medium_body font-medium leading-6 text-gray-900" id="modal-title">{title}</h3> */}
                                        <div className="mt-2">
                                            <p className="text-start text-sm text-gray-500">{message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse px-6">
                                {!isAlertOnly && <button onClick={() => onChange(1)} 
                               
                                type="button" className="whitespace-nowrap  mt-3 inline-flex w-full justify-center font-sans_bold_body rounded-md text-white px-4 py-2 text-base font-medium shadow-sm  focus:outline-none focus:ring-offset-2 sm:mt-0 ml-3 sm:w-auto text-sm bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517]">{activeBtnName}</button>}
                                <button onClick={() => onChange(0)} type="button" className="whitespace-nowrap  mt-3 inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-offset-2 sm:mt-0 ml-3 sm:w-auto text-sm">{closeBtnName}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    return (
        !show
            ? <></>
            : <div id="popup-modal" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 md:inset-0 h-modal md:h-full">
                <div className="relative w-full max-w-md h-full md:h-auto">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button onClick={() => onChange(1)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="popup-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-6 text-center">
                            <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                            <button onClick={() => onChange(0)} data-modal-toggle="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                {activeBtnName}
                            </button>
                            <button onClick={() => onChange(1)} data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{closeBtnName}</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ConfirmPopup