import { React, useState, createContext } from 'react'

export const TableHeaderContext = createContext('');

const TableHeaderProvider = ({ children }) => {
    const [selectedPage, setSelectedPage] = useState(1)

    const [orderBy, setOrderBy] = useState("desc")
    const [OrderByVCTableHeader, setOrderByVCTableHeader] = useState("date")
    // const [orderBya, setOrderBya] = useState("desc")
    
    
    const [OrderByPEVCExitMATableHeader, setOrderByPEVCMAExitTableHeader] = useState("date")

        
    const [OrderByPEVCExitPMTableHeader, setOrderByPEVCPMExitTableHeader] = useState("desc")
    const [OrderByVCExitMATableHeader, setOrderByVCExitMATableHeader] = useState("desc")
    const [OrderByVCExitPMTableHeader, setOrderByVCExitPMTableHeader] = useState("desc")
    const [OrderByPEVCBackedIposTableHeader, setOrderByPEVCBackedIposTableHeader] = useState("desc")
    const [OrderByVCBackedIposTableHeader, setOrderByVCBackedIposTableHeader] = useState("desc")
    const [OrderByAngelInvestmentsTableHeader, setOrderByAngelInvestmentsTableHeader] = useState("desc")
    const [OrderByIncubationTableHeader, setOrderByIncubationTableHeader] = useState("desc")
    const [orderByLP, setOrderByLP] = useState("asc")

    const [orderByInvestor, setOrderByInvestor] = useState("asc")

    const [OrderByInvestorVCTableHeader, setOrderByInvestorVCTableHeader] = useState("asc")
    // const [orderByInvestorExits, setOrderByInvestorExits] = useState("asc")
    const [OrderByInvestorPEVCExitMATableHeader, setOrderByInvestorPEVCMAExitTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorPEVCExitPMTableHeader, setOrderByInvestorPEVCPMExitTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorVCExitMATableHeader, setOrderByInvestorVCExitMATableHeader] = useState("asc")
    const [OrderByInvestorVCExitPMTableHeader, setOrderByInvestorVCExitPMTableHeader] = useState("asc")
    const [OrderByInvestorPEVCBackedIposTableHeader, setOrderByInvestorPEVCBackedIposTableHeader] = useState("asc")
    const [OrderByInvestorVCBackedIposTableHeader, setOrderByInvestorVCBackedIposTableHeader] = useState("asc")
    const [OrderByInvestorAngelInvestmentsTableHeader, setOrderByInvestorAngelInvestmentsTableHeader] = useState("last_investment_made_sort")
    const [OrderByInvestorIncubationTableHeader, setOrderByInvestorIncubationTableHeader] = useState("asc")

    const [orderByAdvisor, setOrderByAdvisor] = useState("asc")
    const [orderByTAdvisor, setOrderByTAdvisor] = useState("asc")
    const [orderingColumn, setOrderingColumn] = useState("date")
    const [reOrderingColumn, setreOrderingColumn] = useState("deal_date")
    const [reIpoOrderingColumn, setIporeOrderingColumn] = useState("ipo_date")
    const [exitOrderingColumn, setexitOrderingColumn] = useState("date")
    
    const [orderBya, setOrderBya] = useState("date")
    const [investororderingColumn, setInvestorOrderingColumn] = useState("investor_sort")
    
    const [advisororderingColumn, setAdvisorOrderingColumn] = useState("asc")
    const [maAdvisororderingColumn, setmaAdvisorOrderingColumn] = useState("latest_deal_date_sort")
    const [limitedPartnerderingColumn, setLimitedPartnerderingColumn] = useState("lp_name_sort")
    
    const [incubatorOrderingColumn, setIncubatorOrderingColumn] = useState("Incubator_sort")
    
    const [orderByInvestorExits, setOrderByInvestorExits] = useState("last_investment_made_sort")
    // const [orderByInvestorExits, setOrderByInvestorExits] = useState("last_investment_made_sort")
    
    
    const [cfsOrderingColumn, setCfsOrderingColumn] = useState("SCompanyName")
    const [maOrderingColumn, setmaOrderingColumn] = useState("deal_date")
    const [maAcquirerOrderingColumn,setMaAcquirerOrderingColumn] = useState("acquirer_name")

    const [PEVCTableHeader, setPEVCTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'total_funding', label: 'Total Funding (US$ M)' },
            { accessor: 'latest_round', label: 'Round' },
            { accessor: 'date', label: 'Deal Date' },
            // { accessor: 'latest_deal_type', label: 'Deal Type' },
            { accessor: 'latest_deal_amount', label: 'Deal Amount (US$ M)' },
            { accessor: 'city_sort', label: 'City' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCDealsTableHeader, setPEVCDealsTableHeader] = useState([
        {accessor: 'company_name', label: 'Company'},
        {accessor: 'sector', label:'Sector'},
        {accessor: 'investor_name_sort', label: 'Investor'},
        {accessor: 'date', label: 'Deal Date'},
        {accessor: 'exit_status', label: 'Exit Status'},
        { accessor: 'deal_amount', label: 'Deal Amount (US$ M)' },
        { accessor: 'add', label: 'Add' },
    ])

    const [VCTableHeader, setVCTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'total_funding', label: 'Total Funding (US$ M)' },
            { accessor: 'latest_round', label: 'Round' },
            { accessor: 'date', label: 'Deal Date' },
            // { accessor: 'latest_deal_type', label: 'Deal Type' },
            { accessor: 'latest_deal_amount', label: 'Deal Amount (US$ M)' },
            { accessor: 'city_sort', label: 'City' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitTableHeader, setPEVCExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitMATableHeader, setPEVCMAExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCExitPMTableHeader, setPEVCPMExitTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCExitMATableHeader, setVCExitMATableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCExitPMTableHeader, setVCExitPMTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'exit_status', label: 'Exit Status' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'amount_realized', label: 'Amount Realized (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PEVCBackedIposTableHeader, setPEVCBackedIposTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [VCBackedIposTableHeader, setVCBackedIposTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Company' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AngelInvestmentsTableHeader, setAngelInvestmentsTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Investee' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [IncubationTableHeader, setIncubationTableHeader] = useState(
        [
            { accessor: 'company_name_sort', label: 'Incubatee' },
            { accessor: 'sector_business', label: 'Sector' },
            { accessor: 'incubator_sort', label: 'Incubator' },
            { accessor: 'date', label: 'Date' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    // Investor Section

    const [InvestorPEVCTableHeader, setInvestorPEVCTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio_companies', label: 'Portfolio' },
            { accessor: 'top_portfolio_companies_sort', label: 'Top portfolio cos based on investment size' },
            { accessor: 'funds_count', label: 'No. of Funds' },
            { accessor: 'total_invested', label: 'Total invested (US$ M)' },
            { accessor: 'add', label: 'Add' }

        ]
    );
    const [InvestorVCTableHeader, setInvestorVCTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio_companies', label: 'Portfolio' },
            { accessor: 'top_portfolio_companies_sort', label: 'Top portfolio cos based on investment size' },
            { accessor: 'funds_count', label: 'No. of Funds' },
            { accessor: 'total_invested', label: 'Total invested (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitTableHeader, setInvestorPEVCExitTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitMATableHeader, setInvestorPEVCExitMATableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCExitPMTableHeader, setInvestorPEVCExitPMTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorVCExitMATableHeader, setInvestorVCExitMATableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [InvestorVCExitPMTableHeader, setInvestorVCExitPMTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'existed_companies', label: 'Exited Cos (Partial/Full)' },
            { accessor: 'top_existed_companies_sort', label: 'Top exits based on transaction size' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorPEVCBackedIposTableHeader, setInvestorPEVCBackedIposTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'ipos', label: 'IPOs' },
            { accessor: 'latest_ipo', label: 'IPO' },
            { accessor: 'latest_ipo_size', label: 'IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorVCBackedIpos, setInvestorVCBackedIpos] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'ipos', label: 'IPOs' },
            { accessor: 'latest_ipo', label: 'IPO' },
            { accessor: 'latest_ipo_size', label: ' IPO Size (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [InvestorAngelInvestmentsTableHeader, setInvestorAngelInvestmentsTableHeader] = useState(
        [
            { accessor: 'investor_sort', label: 'Investor' },
            { accessor: 'portfolio', label: 'Portfolio' },
            { accessor: 'latest_investments_sort', label: 'Investments' },
            { accessor: 'last_invested_in_sort', label: 'Last Invested in' },
            { accessor: 'last_investment_made_sort', label: 'Last Investment Made' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [InvestorIncubationTableHeader, setInvestorIncubationTableHeader] = useState(
        [
            { accessor: 'Incubator_sort', label: 'Incubator' },
            { accessor: 'Incubatees', label: 'Incubatees' },
            { accessor: 'latest_incubated', label: 'Recently Incubated/Graduated/Assisted' },
            { accessor: 'add', label: 'Add' }
        ]
    );
    const [AdvisorPEVCTableHeader, setAdvisorPEVCTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );
    const [AdvisorVCTableHeader, setAdvisorVCTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorPEVCExitMATableHeader, setAdvisorPEVCMAExitTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorPEVCExitPMTableHeader, setAdvisorPEVCPMExitTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorVCExitMATableHeader, setAdvisorVCExitMATableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [AdvisorVCExitPMTableHeader, setAdvisorVCExitPMTableHeader] = useState(
        [
            { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [InvestorLimitedPartner, setInvestorLimitedPartner] = useState(
        [
            { accessor: 'lp_name_sort', label: 'Institution Name' },
            { accessor: 'TypeOfInstitution', label: 'Type' },
            { accessor: 'investor_names_sort', label: 'Limited Partners in' },
            { accessor: 'add', label: 'Add' },

        ]
    );

    const [NewInvestor, setNewInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'add', label: 'Add' },

        ]
    );

    const [ActiveInvestor, setActiveInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ]
    );

    const [AngelActiveInvestor, setAngelActiveInvestor] = useState(
        [
            { accessor: 'investor', label: 'Investor' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ]
    );

   const  IncubatorNewInvestor=[
            { accessor: 'investor', label: 'Incubator' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'add', label: 'Add' },

        ]

    const IncubatorActiveInvestor=[
            { accessor: 'investor', label: 'Incubator' },
            { accessor: 'deals', label: 'No. of deals' },
            { accessor: 'cos', label: 'No. of Cos' },
            // { accessor: 'newPCos', label: "New Portfolio Cos" },
            // { accessor: 'add', label: 'Add' },
        ];

    const [cfsTableHeader, setCfsTableHeader] = useState(
        [
            { accessor: 'SCompanyName', label: 'Company' },
            { accessor: 'OptnlIncome', label: 'Revenue' },
            { accessor: 'EBITDA', label: 'EBITDA' },
            { accessor: 'PAT', label: 'PAT' },
            { accessor: 'fy', label: 'Detailed' },
            { accessor: 'add', label: 'Add' },
        ]
    );

    const [PERETableHeadder,setPERETableHeadder]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'investor_sort ', label: 'Investor' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'amount_m', label: 'Deal Amount (US$ M)' },
      { accessor: 'add', label: 'Add' },

    ])
    const [PEReExitTableHeader,setPEReExitTableHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'investor_sort', label: 'Investor' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'deal_amount', label: 'Deal Amount (US$ M)' },
      { accessor: 'add', label: 'Add' },

    ])
    const [PEREBackedIposHeader,setPEREBackedIposHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
    //   { accessor: 'investor_sort', label: 'Investor' },
      { accessor: 'sector', label: 'Sector' },
      { accessor: 'ipo_date', label: 'Deal Date' },
      { accessor: ' ipo_size_m ', label: 'Deal Size (US$ M)' },
      { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'add', label: 'Add' },

    ])
    const [OtherMATableHeader,setOtherMATableHeader]=useState([
        { accessor: 'company', label: 'Company'},
      { accessor: 'city', label: 'City'},
      { accessor: 'acquirer_name', label: 'Acquirer' },
      { accessor: 'deal_date', label: 'Deal Date' },
      { accessor: 'amount', label: 'Deal Amount (US$ M)' },

    //   { accessor: 'exit_status', label: 'Exit Status' },
      { accessor: 'add', label: 'Add' },

    ])

    const [PEREAdvisorTableHeadder,setPEREAdvisorTableHeadder]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
        { accessor: 'to_company_investments', label: 'To Company (Investments)' },
        { accessor: 'to_company_exits', label: 'To Company (Exits)' },
        { accessor: 'to_investors', label: 'To Investors' },
        { accessor: 'to_acquirers', label: 'To Acquirers' },
        { accessor: 'add', label: 'Add' },

    ])

    

    const [PEReAdvisorExitTableHeader,setPEReAdvisorExitTableHeader]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
        { accessor: 'to_company_investments', label: 'To Company (Investments)' },
        { accessor: 'to_company_exits', label: 'To Company (Exits)' },
        { accessor: 'to_investors', label: 'To Investors' },
        { accessor: 'to_acquirers', label: 'To Acquirers' },
        { accessor: 'add', label: 'Add' },
    ])

    const [AdvisorOtherMATableHeader,setAdvisorOtherMATableHeader]=useState([
        { accessor: 'advisor_name_sort', label: 'Advisor' },
            { accessor: 'to_company_investments', label: 'To Company (Investments)' },
            { accessor: 'to_company_exits', label: 'To Company (Exits)' },
            { accessor: 'to_investors', label: 'To Investors' },
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'add', label: 'Add' },

    ])
    const [maTableHeader, setMaTableHeader] = useState(
        [  
        { accessor: 'target_company', label: 'Target'},          
        { accessor: 'target_sector', label: 'Sector'},
        { accessor: 'acquirer_name', label: 'Acquirer'},
        { accessor: 'deal_date', label: 'Deal Date'},
        { accessor: 'deal_amount', label: 'Deal Amount (US$ M)' },
        { accessor: 'add', label: 'Add' }
        ]
    );
    const [maAdvisorTableHeader, setMaAdvisorTableHeader] = useState(
        [  
            { accessor: 'advisor_name_sort', label: 'Advisors'},
            { accessor: 'to_company_investments', label: 'To Company (Investments)'},
            { accessor: 'to_acquirers', label: 'To Acquirers' },
            { accessor: 'latest_transaction_sort', label: 'Transaction'},
            { accessor: 'latest_deal_date_sort', label: 'Transaction Deal Date'},
            { accessor: 'add', label: 'Add' }
        ]
    );

    const [maAcquirerTableHeader, setMaAcquirerTableHeader] = useState(
        [
            { accessor: 'acquirer_name', label: 'Acquirer' },
            { accessor: 'acquisitions', label: 'Acquisitions' },
            { accessor: 'top_acquistion_names', label: 'Top Acquisitions based on amount' },
            { accessor: 'last_acquired', label: 'Last Acquired' },
            { accessor: 'total_acquistions', label: 'Total Acquisitions (US$ M)' },
            { accessor: 'add', label: 'Add' }
        ]

    )


    const headerMenu = {
        PEVCTableHeader, PEVCDealsTableHeader, VCTableHeader, PEVCExitTableHeader, PEVCExitMATableHeader, PEVCExitPMTableHeader, VCExitMATableHeader, VCExitPMTableHeader, PEVCBackedIposTableHeader, VCBackedIposTableHeader, AngelInvestmentsTableHeader, IncubationTableHeader,
        InvestorPEVCTableHeader, InvestorVCTableHeader, InvestorPEVCExitTableHeader, InvestorPEVCExitMATableHeader, InvestorPEVCExitPMTableHeader, InvestorVCExitMATableHeader, InvestorVCExitPMTableHeader, InvestorPEVCBackedIposTableHeader, InvestorVCBackedIpos, InvestorAngelInvestmentsTableHeader, InvestorIncubationTableHeader, InvestorLimitedPartner,
        AdvisorPEVCTableHeader, AdvisorVCTableHeader, AdvisorPEVCExitMATableHeader, AdvisorPEVCExitPMTableHeader, AdvisorVCExitMATableHeader, AdvisorVCExitPMTableHeader,
        selectedPage, setSelectedPage, orderBy, setOrderBy, orderingColumn, setOrderingColumn, investororderingColumn, setInvestorOrderingColumn, advisororderingColumn, setAdvisorOrderingColumn, incubatorOrderingColumn, setIncubatorOrderingColumn, cfsOrderingColumn, setCfsOrderingColumn,
        orderByInvestor, setOrderByInvestor, orderByAdvisor, setOrderByAdvisor, orderByTAdvisor, setOrderByTAdvisor, orderBya, setOrderBya,
        OrderByVCTableHeader, OrderByPEVCExitMATableHeader,
        OrderByPEVCExitPMTableHeader, OrderByVCExitMATableHeader, OrderByVCExitPMTableHeader, OrderByPEVCBackedIposTableHeader,
        OrderByVCBackedIposTableHeader, OrderByAngelInvestmentsTableHeader, OrderByIncubationTableHeader,
        limitedPartnerderingColumn, setLimitedPartnerderingColumn, orderByLP, setOrderByLP,
        OrderByInvestorVCTableHeader, orderByInvestorExits, OrderByInvestorPEVCExitMATableHeader,
        OrderByInvestorPEVCExitPMTableHeader, OrderByInvestorVCExitMATableHeader,
        OrderByInvestorVCExitPMTableHeader, OrderByInvestorPEVCBackedIposTableHeader,
        OrderByInvestorVCBackedIposTableHeader, OrderByInvestorAngelInvestmentsTableHeader,
        OrderByInvestorIncubationTableHeader, cfsTableHeader, NewInvestor, setNewInvestor, ActiveInvestor, setActiveInvestor,AngelActiveInvestor, setAngelActiveInvestor,
        IncubatorNewInvestor,IncubatorActiveInvestor,
        OtherMATableHeader,setOtherMATableHeader,PEREBackedIposHeader,setPEREBackedIposHeader,
        PEReExitTableHeader,setPEReExitTableHeader,PERETableHeadder,setPERETableHeadder,PEREAdvisorTableHeadder,setPEREAdvisorTableHeadder,
        PEReAdvisorExitTableHeader,setPEReAdvisorExitTableHeader,AdvisorOtherMATableHeader,setAdvisorOtherMATableHeader,
        maTableHeader, setMaTableHeader,maOrderingColumn, setmaOrderingColumn,maAdvisorTableHeader, setMaAdvisorTableHeader,
        maAcquirerTableHeader, setMaAcquirerTableHeader,maAcquirerOrderingColumn,setMaAcquirerOrderingColumn,reOrderingColumn, setreOrderingColumn,reIpoOrderingColumn, setIporeOrderingColumn,exitOrderingColumn, setexitOrderingColumn,maAdvisororderingColumn, setmaAdvisorOrderingColumn
    }

    return (
        <TableHeaderContext.Provider value={headerMenu}>
            {children}
        </TableHeaderContext.Provider>
    )
}

export default TableHeaderProvider
