import { React, useState, useMemo, useEffect, useContext } from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom';
import DetailInformation from '../DetailPage/CompanyDetailInformation';
import DealInfo from './DealInfo'
import DealInfoIncubation from './DealInfoIncubation';
import DealInfoAngel from './DealInfoAngel';
import InvestorInfoAngel from './InvestorInfoAngel';
import InvestorInfo from './InvestorInfo'
import InvestorInfoExits from "./InvestorInfoExits"
import AdvisorInvestmentsInfo from './AdvisorInforInvestments';
import AdvisorInfoExits from "./AdvisorInfoExits"
import ValuationInvestmentsInfo from './ValuationInfoInvestments'
import ValuationInfoExits from "./ValuationInfoExits"
import DealInfoExit from './DealInfoExit';
import BackedIpoDeal from "./BackedIpoDeal"
import BackedIpoValuation from "./BackedIpoValuationInfo"
import BackedIpoInvestor from "./BackedIpoInvestorInfo"
import BackedIpoAdvisor from "./BackedIpoAdvisorInfo"
import BackedIpoInvestmentDetail from "./BackedIpoInvestment"
import Filings from '../DealDetail/Filings'
import ShareHolding from '../DealDetail/ShareHolding'
import ShareHoldingExits from "./ShareHoldingExits"
import {
    dealPageAngel,
    getRoundHierarchy, dealPageProfile, dealPageInvestment
} from "./../../Config/config";
import routePathSegments, { routeNames } from '../../routeSegments';
import { TableDataContext } from '../../Contexts/TableDataContext'
import { UserContext } from '../../Contexts/UserContext';
import useModule from '../../hooks/useModule';
import { Tooltip } from 'antd';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

/** The above code is a React component called `CompanyDealDetail`. It is responsible for fetching and
displaying data related to a specific deal of a company. */
const CompanyDealDetail = ({  menu, category }) => {

    const { setActiveTabDeal, setDealIdTab, tableCompanyId, dealIds } = useContext(TableDataContext);
    const [companyProfile, setCompanyProfile] = useState({});
    const [companyProfileTab, setCompanyProfileTab] = useState({});
    const [companyDealDetailTab, setCompanyDealDetailTab] = useState();
    const [companyExitDealDetailTab, setCompanyExitDealDetailTab] = useState()
    const [companyDealDetailShareholding, setCompanyDealDetailShareholding] = useState({})
    const [companyDealDetailAngelInvestment, setCompanyDealDetailAngelInvestment] = useState({})
    const [companyDealDetailIncubation, setCompanyDealDetailIncubation] = useState({})
    const [
        isActive, 
        setIsActive] = useState(1);
    const [
        companyName,
         setCompanyName] = useState();
    const [roundsDetail, setRoundsDetail] = useState([]);
    const [selectedRoundType, setSelectedRoundType] = useState("");
    const { companyId, dealId } = useParams();
    const [currentDealId, setCurrentDealId] = useState(dealId);
    const [cardData, setCardData] = useState({})
    const [dealInfoDetail, setDealInfoDetail] = useState()
    const [invstorInfoDetail, setInvestorInfoDetail] = useState()
    const [
        // isProfileLoading, 
        setIsProfileLoading] = useState(true);
    const { getToken } = useContext(UserContext);
    const { primaryMasterId } = useModule();

    const location = useLocation();
    let companyProfileId = parseInt(companyId)
    const { dealsView } = useContext(PeFilterContext);


    const fetchProfileData = (activeTabDeal, currentDealId) => {
        setCardData({})
        axios(`${dealPageProfile}`, {
            method: "POST",
            data: {
                "type": activeTabDeal,
                "company_id": companyProfileId,
                "deal_id": currentDealId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "dealCarddata ");
                    setCardData(response.data)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }

    const fetchTableData = (activeTabDeal, currentDealId) => {
        axios(`${dealPageInvestment}`, {
            method: "POST",
            data: {
                "type": activeTabDeal,
                "company_id": companyProfileId,
                "deal_id": currentDealId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "dealCarddata ");
                    setCompanyDealDetailTab(response.data)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }


    const angelDealDetail = (activeTabDeal, dealId) => {

        axios.post(dealPageAngel, {
            "type": activeTabDeal,
            "company_id": companyProfileId,
            "deal_id": dealId

        }, {
            headers: { "Authorization": getToken() }
        }).then(response => {
            if (response.status == 200) {
                setDealInfoDetail(response.data?.deal_info)
                setInvestorInfoDetail(response.data?.investor_info)
                setIsProfileLoading(false);
            }
        }).catch(error => {

        })
    }


    // const handleFetchDealDetailAngelInvestmentCallback = (response, requestSuccess) => {
    //     setCompanyDealDetailShareholding({})
    //     try {
    //         setCompanyDealDetailAngelInvestment(response);
    //     } catch (e) {
    //     }
    // };

    const companyProfileData = useMemo(() => companyProfile, [companyProfile]);


    const companyProfileTabDataDetail = useMemo(() => companyDealDetailTab, [companyDealDetailTab]);
    const companyExitDealDetail = useMemo(() => companyExitDealDetailTab, [companyExitDealDetailTab])
    console.log(companyExitDealDetail, "exits");
    const companyProfileShareholding = useMemo(() => companyDealDetailShareholding, [companyDealDetailShareholding]);



    const getCompanyName = () => {
        setCompanyName(companyProfileData?.companyname)
    };

    useEffect(() => {
        getCompanyName();
        getRoundHierarchyData(companyProfileId, currentDealId);
    }, []);



    const getRoundHierarchyData = (companyProfileId, dealId) => {
        setCardData({})
        setSelectedRoundType( )
        axios(`${getRoundHierarchy}`, {
            method: "POST",
            data: {
                "category": "investments",
                "company_id": companyProfileId,
                "deal_id": dealId,
                type:primaryMasterId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "getRoundHierarchy");
                    setRoundsDetail(response?.data)
                    setSelectedRoundType(response?.data?.active_tab)
                    setActiveTabDeal(response?.data?.active_tab)
                    setDealIdTab(dealId)
                    fetchProfileData(response?.data?.active_tab, dealId)
                    fetchTableData(response?.data?.active_tab, dealId)
                    angelDealDetail(response?.data?.active_tab, dealId)

                    
                }
            })
            .catch((error) => {
                console.log("API not working - getRoundHierarchy", error);
            });
    };





    const getURL = localStorage.getItem("currenturl");
    const breadcrumb = (getURL == '/companies/pe-vc-investments') ? 'PE-VC Investments'
        : (getURL == '/companies/vc-investments') ? 'VC Investments'
            : (getURL == '/companies/exists/pe-vc-investments') ? 'PE-VC Exits'
                : (getURL == '/companies/exists/ma/pe-vc-investments') ? 'via M&A (PE-VC)'
                    : (getURL == '/companies/exists/pm/pe-vc-investments') ? 'via Public Market (PE-VC)'
                        : (getURL == '/companies/exists/pm/pe-vc-investments') ? 'M&A (VC)'
                            : (getURL == '/companies/exists/pm/vc-investments') ? 'Public Market (VC)'
                                : (getURL == '/companies/backed-ipos/pe-vc-investments') ? 'PE-VC Backed IPOs'
                                    : (getURL == '/companies/backed-ipos/vc-investments') ? 'VC Backed IPOs'
                                        : (getURL == '/companies/angel-invesments') ? 'Angel Investments'
                                            : (getURL == '/companies/incubation') ? 'Incubation'
                                                : ''




    //     {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
    //         // console.log(`${key}: ${value}`);
    //    })}
    let currentBreadCrumbLabel = ""    
    const pevcInvestmentMatch = matchPath(`${routeNames.peCompanyInvestmentDealById(companyId, dealId)}`, location.pathname)
    const pevcExitsMatch = matchPath(`${routeNames.peCompanyExitsDealById(companyId, dealId)}`, location.pathname)
    const pevcIPOMatch = matchPath(`${routeNames.peCompanyBackedIPOSDealById(companyId, dealId)}`, location.pathname)
    const pevcAngelMatch = matchPath(`${routeNames.peCompanyAngelDealById(companyId, dealId)}`, location.pathname)
    const pevcIncubationMatch = matchPath(`${routeNames.peCompanyIncubationDealById(companyId, dealId)}`, location.pathname)

    const vcInvestmentMatch = matchPath(`${routeNames.vcCompanyInvestmentDealById(companyId, dealId)}`, location.pathname)
    const vcExitsMatch = matchPath(`${routeNames.vcCompanyExitsDealById(companyId, dealId)}`, location.pathname)
    const vcIPOMatch = matchPath(`${routeNames.vcCompanyBackedIPOSDealById(companyId, dealId)}`, location.pathname)
    const vcAngelMatch = matchPath(`${routeNames.vcCompanyAngelDealById(companyId, dealId)}`, location.pathname)
    const vcIncubationMatch = matchPath(`${routeNames.vcCompanyIncubationDealById(companyId, dealId)}`, location.pathname)

    let urlLink;
    
    if(menu = 'pe'){
        if (pevcInvestmentMatch) {
            currentBreadCrumbLabel = "PE-VC Investments";
            urlLink = routeNames.peCompanyInvestmentDealById
        }else if (pevcExitsMatch){
            currentBreadCrumbLabel = 'Exits'
            urlLink = routeNames.peCompanyExitsDealById
        }else if(pevcIPOMatch){
           currentBreadCrumbLabel = "PE-VC Backed IPOs";
           urlLink = routeNames.peCompanyBackedIPOSDealById
        }else if(pevcAngelMatch){
           currentBreadCrumbLabel = "Angel Investments";
           urlLink = routeNames.peCompanyIncubationDealById
        }else if(pevcIncubationMatch){
           currentBreadCrumbLabel = "Incubation";
           urlLink = routeNames.peCompanyIncubationDealById
        }else {
            currentBreadCrumbLabel = 'Deals'
            urlLink = routeNames.peCompanyInvestmentDealById
        }
    }else if(menu = 'vc'){
        if(vcInvestmentMatch){
            currentBreadCrumbLabel = "PE-VC Investments";
            urlLink = routeNames.vcCompanyInvestmentDealById
        }else if(vcExitsMatch){
            currentBreadCrumbLabel = "Exits"
            urlLink = routeNames.vcCompanyExitsDealById
        }else if(vcIPOMatch){
            currentBreadCrumbLabel = "PE-VC Backed IPOs";
            urlLink = routeNames.vcCompanyBackedIPOSDealById
        }else if(vcAngelMatch){
            currentBreadCrumbLabel = "Angel Investments";
            urlLink = routeNames.vcCompanyAngelDealById
        }else if(vcIncubationMatch){
            currentBreadCrumbLabel = "Incubation";
            urlLink = routeNames.vcCompanyIncubationDealById
        }else {
            currentBreadCrumbLabel = 'Deals'
            urlLink = routeNames.peCompanyInvestmentDealById
        }
    }    

    const vcMatch = matchPath(`${routePathSegments.vc}/*`, location.pathname)

    const exportFileName = useMemo(() => {
        const pathSegs = location.pathname.split('/')
        let exportFileName = "";
               
        if (pathSegs.includes(routePathSegments.exists)) {
            exportFileName = `${cardData?.companyname}- mandaexit`;
        } else if (pathSegs.includes(routePathSegments.incubation)) {
            exportFileName = `${cardData?.companyname}-inc-${companyDealDetailTab?.deal_info?.date_month}`;
        } else if (pathSegs.includes(routePathSegments.angel)) {
          
            exportFileName = `${cardData?.companyname}-ang-${companyDealDetailTab?.deal_info?.DealDate}`;
        }  else if (pathSegs.includes(routePathSegments.backed_ipos)) {
            exportFileName = `${cardData?.companyname}-ipo-${companyDealDetailTab?.deal_info?.date}`;
        }
        else if (pathSegs.includes(routePathSegments.pe_vc_investments) || pathSegs.includes(routePathSegments.vc_investments)) {
            exportFileName = `${cardData?.companyname}-${ companyDealDetailTab && (moment(companyDealDetailTab?.deal_info[0]?.date).format('MMM YYYY'))}`;
        }
        else {
            // console.log(companyDealDetailTab,'companyDealDetailTab');
            exportFileName = `${cardData?.companyname}-${companyDealDetailTab &&(companyDealDetailTab?.deal_info[0]?.date)}`;
        }
        return exportFileName;
    }, [companyDealDetailTab, location.pathname,cardData?.companyname]);

    // console.log(companyDealDetailTab,"info")

    console.log(dealIds, tableCompanyId);

    const currentIndex = dealIds?.indexOf(Number(dealId));

    console.log("curr", currentIndex);

    return (

        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 px-3 lg:px-2 border-b overflow-x-auto" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            {console.log(location.state?.prevPath,'location.state?.prevPath')}
                            <div className='flex items-center'>
                                <NavLink to={
                                    location.state?.prevPath && location.state?.prevPath !=''
                                        ? location.state?.prevPath
                                        : vcMatch ? routeNames.vc_companies_vc_investments
                                            : routeNames.pe_companies_pe_vc_investments} className="text-[11pt] ml-2 lg:text-[12px] font-sans_book_body text-[#666666]">
                                    Companies</NavLink>
                            </div>
                        </li>
                        <li className='flex items-center'>
                            <div className="flex items-center">
                                <span className=" text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                <div className='pt-[0.1rem] flex items-center whitespace-nowrap'>
                                    <NavLink state={{ prevPath: location.state?.prevPath }} to={
                                        vcMatch ? routeNames.vcCompanyFundById(companyId)
                                            : routeNames.peCompanyFundById(companyId)} className="mr-1 whitespace-nowrap text-[11pt] lg:text-[12px] text-[#666666]">
                                        {cardData?.companyname}</NavLink>
                                </div>
                                <span className="text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                <span className="text-[11pt] lg:text-[12px] text-[#666666] whitespace-nowrap font-semibold"
                                    aria-current="page">{currentBreadCrumbLabel}</span>
                            </div>
                        </li>
                    </ol>
                </nav>
                    {dealsView ? dealIds &&  
                        <>
                            <Tooltip title="Prev">
                                <NavLink
                                className="absolute top-2 right-8"
                                to={urlLink(tableCompanyId[currentIndex - 1], dealIds[currentIndex - 1])}
                                >
                                {tableCompanyId?.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Next">
                                <NavLink
                                className="absolute top-2 right-4"
                                to={urlLink(tableCompanyId[currentIndex + 1], dealIds[currentIndex + 1])}
                                >
                                {tableCompanyId?.length - 1 === currentIndex ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                )}
                                </NavLink>
                            </Tooltip>
                        </>
                        : ""}
                {/* <!-- BarRaiser--> */}
                <DetailInformation activeTab={selectedRoundType} menu="dealpage" data={cardData} id={companyId} currentDealId={currentDealId} page="dealPage" desc={companyProfileTabDataDetail} exportFileName={exportFileName} />


                <div className=" flex flex-row mt-[0.7rem] overflow-x-auto scrollbar space-x-6 bg-white px-6 border-b border-[#D7D7D7] cursor-pointer">

                    {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                        return (
                            value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                console.log(key, "getroundhier")
                                console.log(value, "getroundhiervalue");
                                return (
                                    Object.entries(round).map(([key1, value1]) => {
                                        console.log(key1, "getroundhier1")
                                        console.log(value1, "value1");
                                        return (
                                            <a className={"py-2 whitespace-nowrap text-[12pt] leading-[17pt] text-[#333333] lg:text-[14px] lg:leading-[17px] font-semibold" +
                                                (value1?.hash_current_deal ?
                                                    " border-b-[3px] border-b-[#F1CA75]   group-hover:text-[#F1CA75]"
                                                    :
                                                    " opacity-[0.4]"
                                                )
                                            }
                                                onClick={() => {
                                                    getRoundHierarchyData(companyProfileId, value1?.hash_current_deal_id)
                                                    setCurrentDealId(value1?.hash_current_deal_id);
                                                    setIsActive(i + 1);
                                                    setCompanyDealDetailTab("")
                                                }}
                                            >
                                                {key1}
                                            </a>
                                        )
                                    })
                                )
                            })
                        )
                    })}
                </div>

            </div>
            <div className="bg-[#EDEDED] h-full">
                <div className=" flex flex-row overflow-x-auto scrollbar bg-[#EDEDED] space-x-6 py-4 px-6 pt-4 cursor-pointer ">
                    {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                        return (
                            value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                console.log(round, "val?.hash_current_deal_id")
                                return (
                                    Object.entries(round).map(([key1, val]) => {
                                        console.log(val, "valll")
                                        return (
                                            val?.hash_current_deal &&
                                            Object.entries(val).map(([key2, vall]) => {
                                                return (
                                                    vall?.deal_date_parsed &&
                                                    <a className={
                                                        "inline-block whitespace-nowrap px-3 py-1 text-[14px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                                                        (vall?.selected ?
                                                            "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] pointer-events-none"
                                                            :
                                                            "text-[#BC8B20] border border-[#BC8B20]"
                                                        )
                                                    }
                                                        onClick={() => {
                                                            setCurrentDealId(vall?.deal_id);
                                                            getRoundHierarchyData(companyProfileId, vall?.deal_id)
                                                            setIsActive(i + 1);
                                                            setCompanyDealDetailTab("")
                                                        }}
                                                    >
                                                        {moment(vall?.deal_date_parsed).format("MMM YYYY")}
                                                    </a>
                                                )
                                            })


                                        )

                                    })

                                )
                            })
                        )
                    })
                    }
                
                </div>
                {/* {currentDeal == "" */}
                {selectedRoundType === "investments" ?
                    <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED]">
                        <div
                            className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                            <div
                                className="w-full lg:grid lg:grid-rows-8 gap-5">
                                <div
                                    className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <DealInfo data={companyProfileTabDataDetail?.deal_info} dealType={location.state?.dealType}/>
                                </div>
                                <div
                                    className="lg:hidden row-span-3 py-4 mt-5 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <ValuationInvestmentsInfo data={companyProfileTabDataDetail?.valuation_info} />
                                </div>

                                <div className="row-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <InvestorInfo data={companyProfileTabDataDetail?.investor_info} />
                                </div>
                                <div className=" mb-2 row-span-1 py-4 mt-5 lg:mt-0 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <AdvisorInvestmentsInfo data={companyProfileTabDataDetail?.advisor_info} menu={menu}/>
                                </div>
                                <Filings cname={cardData?.companyname} data={companyProfileTabDataDetail?.deal_info}/>
                                
                            </div>
                        </div>
                        <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                            <div className="lg:grid lg:grid-rows-9 gap-5">
                                <div
                                    className="hidden lg:grid py-4 row-span-5 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <ValuationInvestmentsInfo data={companyProfileTabDataDetail?.valuation_info} />
                                </div>
                                {companyProfileTabDataDetail?.shareholding_pattern &&
                                    companyProfileTabDataDetail?.shareholding_pattern?.length > 0 ?
                                    <div className="row-span-4 mb-3 pt-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                        <ShareHolding data={companyProfileTabDataDetail?.shareholding_pattern} />
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                    :
                    selectedRoundType === "incubations" ?
                        <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 bg-[#EDEDED]">
                            <div
                                className=" mt-3 mb-[1rem] lg:mt-0 rounded-[4px] lg:rounded lg:col-span-8 ">
                                <div
                                    className="w-full lg:grid lg:grid-rows-8 gap-5">
                                    <div
                                        className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                        <DealInfoIncubation data={dealInfoDetail} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        selectedRoundType === "angels" ?
                            <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED]">
                                <div
                                    className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                    <div
                                        className="w-full lg:grid lg:grid-rows-8 gap-5">
                                        <div
                                            className=" row-span-3 mb-4 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                            <DealInfoAngel data={dealInfoDetail} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                    <div className="lg:grid lg:grid-rows-9 gap-5">
                                        <div
                                            className="grid py-4 row-span-5 mb-3 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                            <InvestorInfoAngel data={invstorInfoDetail} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            selectedRoundType === "exits" ?
                                <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED]">
                                    <div
                                        className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                        <div
                                            className="w-full lg:grid lg:grid-rows-8 gap-5">
                                            <div
                                                className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <DealInfoExit data={companyProfileTabDataDetail?.deal_info} />
                                            </div>

                                            <div className="row-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <InvestorInfoExits data={companyProfileTabDataDetail?.investor_info} />
                                            </div>
                                            <div className="mb-2 row-span-1 py-4 mt-5 lg:mt-0 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <AdvisorInfoExits data={companyProfileTabDataDetail?.advisor_info} menu={menu}
                                                />
                                            </div>
                                       


                                        </div>
                                    </div>
                                    <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                        <div className="lg:grid lg:grid-rows-9 gap-5">
                                            <div
                                                className="hidden lg:grid py-4 row-span-5 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <ValuationInfoExits data={companyProfileTabDataDetail?.valuation_info}
                                                />
                                            </div>
                                            {companyProfileShareholding?.length > 0 &&
                                                <div className="row-span-4 mb-3 pt-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <ShareHoldingExits
                                                        data={companyProfileShareholding}
                                                    />
                                                </div>
                                            }
                                            {(companyProfileTabDataDetail?.investments_detail?.length > 0 && companyProfileTabDataDetail?.investments_detail !=null) &&
                                            <div className="row-span-4 mb-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <BackedIpoInvestmentDetail
                                                    data={companyProfileTabDataDetail}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                :
                                selectedRoundType === "ipos" ?
                                    <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED]">
                                        <div
                                            className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                            <div
                                                className="w-full lg:grid lg:grid-rows-8 gap-5">
                                                <div
                                                    className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <BackedIpoDeal
                                                        data={companyProfileTabDataDetail?.deal_info}
                                                    />
                                                </div>

                                                <div className="row-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <BackedIpoInvestor
                                                        data={companyProfileTabDataDetail?.investor_info} menu={menu}
                                                    />
                                                </div>
                                                <div className="mb-3 row-span-1 py-4 mt-5 lg:mt-0 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <BackedIpoAdvisor
                                                        data={companyProfileTabDataDetail?.advisor_info}
                                                        menu={menu}/>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                            <div className="lg:grid lg:grid-rows-9 gap-5">
                                                <div
                                                    className="hidden lg:grid py-4 row-span-5 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <BackedIpoValuation
                                                        data={companyProfileTabDataDetail?.valuation_info}
                                                    />
                                                </div>
                                            { companyProfileTabDataDetail?.investments_detail?.length >0 &&
                                                <div className="row-span-4 mb-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                    <BackedIpoInvestmentDetail
                                                        data={companyProfileTabDataDetail}
                                                    />
                                                </div>}
                                            </div>
                                        </div>
                                    </div>

                                    :
                                    ""
                }
            </div>
        </>
    )

}

export default CompanyDealDetail
