import React, { useState, useContext, useEffect } from "react";
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE/ReListCard/Card'
import Table from '../../Components/RE/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import Constants from '../../Constants'
import SavedFilterContent from "../../Components/SavedFilter";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import { useLocation } from "react-router";
import { reExportPDF } from "../../Config/config";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import ReExitRefineSearch from '../../Components/RefineSearch/ReExitRefineSearch'
import {reExitExportData ,Data} from "../../Components/ExportPopup/ExportData"
import ExportPopup from "../../Components/ExportPopup";
import FilterTag from "../../Components/Tag";
import { UserContext } from "../../Contexts/UserContext";
import ExcelDownloader from "../../Components/shared/ExcelDownloader";

/**
 * The `REExists` function is a React component that renders a page for displaying and filtering real
 * estate exit data.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `REExists` component. These properties can be accessed using dot notation, such as `props.active` or
 * `props.someProperty`.
 * @returns a JSX element.
 */
function REExists(props) {
    const [showPopup, setShowPopup] = useState(false);
    const {save, setSave,refineSearchFilters ,groupId} = useContext(PeFilterContext);
    const [openTab, setOpenTab] = React.useState(1);
    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])
    const {isStudent,isTrialExport} = useContext(UserContext)

    const location = useLocation();
    const { pathname } = useLocation(); 
 
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <>
            <ReLayout>
                <div className="flex min-h-screen h-screen flex-col">
                {isFromGlobalSearch() ? <></>:<Card />}
                    <div className="flex min-h-0 flex-1 overflow-hidden">                       
                        <main className={`min-w-0 flex-1 border-t border-gray-200 lg:flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                                <FilterTag />
                                <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                    <div className="scrollbar ">
                                        <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">

                                            <MainMenu active={2} menu="re" subMenu="re-exits" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                                subTypeMasterId={Constants.primaryMasterSubIds.re_exits_id}
                                                typeId={Constants.moduleIds.companiesId} />
                                            {isFromGlobalSearch() ? <></> :
                                            <div className="flex justify-between items-center px-5 lg:px-2 py-2">
                                                <label htmlFor="" className="text-[#666666] w-[45%] ">
                                                    <div className="flex">
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>
                                                <div className="flex items-center ">
                                                   {isStudent == 'true' || groupId?.myList?.length >0  ?  <></> : <>
                                                        <button className="px-3">
                                                                    {
                                                                    isTrialExport=='false' ?
                                                                        <ExcelDownloader
                                                                        url={reExportPDF}
                                                                        fileName={Constants.exportNames.re_company_exit}
                                                                        data={{"type" : "re-exits"}}  
                                                                        children={<img
                                                                            src={xls}
                                                                            className="max-h-[24px] mt-1 max-w-[21px]"
                                                                            alt=""
                                                                        />}                   
                                                                        />
                                                                        :

                                                                        <img
                                                                        src={xls}
                                                                        className="h-[24px] mt-1 w-[21px]"
                                                                        alt=""
                                                                        onClick={() => setShowPopup(!showPopup)}
                                                                    />
                                                                    }
                                                          
                                                            {showPopup && (
                                                                <ExportPopup
                                                                    modules="re-company"
                                                                    fileName={Constants.exportNames.re_company_exit}
                                                                    url={reExportPDF}
                                                                    onClose={() => setShowPopup(false)}
                                                                    exportOptions={reExitExportData }
                                                                    type="re-exits"
                                                                />

                                                            )}
                                                        </button>
                                                                                          
                                                          <a>
                                                        <img className="h-[24px] w-[2px] mt-1 " src={line} />
                                                    </a>
                                                
                                                    </> }
                                                    {groupId?.myList?.length >0 ? <></>:   <a className="pl-4 group cursor-pointer">
                                                    <div className="cursor-pointer" onClick={savedList}>
                                                        <img src={save ? FileIcon : FileIconBlur}
                                                            className="h-[24px]  w-[21px]"
                                                            alt="" />
                                                    </div>
                                                </a> 
                                                     }
                                                </div>
                                            </div>
                                            }
                                    </div>
                                    {isFromGlobalSearch() ? <></> :save ? <SavedFilterContent
                                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.re_exits_id}
                                        typeId={Constants.moduleIds.companiesId}
                                        show={save} /> : ''}
                                </div>
                            </div>
                            <div className='overflow-y-auto'>
                                <Table menu="re-exit" sub_type_master_id={Constants.subCategoryIds.re_exitsma_id}/>
                            </div>
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch >
                                    <ReExitRefineSearch customPathname={location.pathname}/>
                                </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>
                <br />

            </div>
            {/* </Layout> */}
        </ReLayout>
        </>
    )
}

export default REExists
