import React, { useState, useContext, useEffect } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";

/**
 * The ToggleSwitch function is a JavaScript component that renders a toggle switch with two labels and
 * allows the user to toggle between two states.
 * @returns The ToggleSwitch component is returning a JSX element.
 */
function ToggleSwitch({
  onToggle,
  onLabel,
  offLabel,
  SwitchState,
  setSwitchState,
  customPathname
}) {
  // const [isAnd, setIsAnd] = useState('and');
  /**
   * The function `handleButtonClick` sets the switch state based on the value of `isAnd` and calls the
   * `onToggle` function.
   * @param isAnd - The `isAnd` parameter is a string that represents the type of button click. It can
   * have two possible values: "and" or "or".
   */
  const handleButtonClick = (isAnd) => {
    setSwitchState(isAnd=='and' ? true :false );
    onToggle(isAnd);
  };
  const { refineSearchFilters } = useContext(PeFilterContext);

  /* The `useEffect` hook in the ToggleSwitch component is used to perform side effects in the
  component. In this case, it is used to set the initial state of the switch based on the values in
  the `refineSearchFilters` and `customPathname` variables. */
  useEffect(() => {
    const ope = refineSearchFilters[customPathname]?.operator == null ? true : refineSearchFilters[customPathname]?.operator === 0
    setSwitchState(ope)
  }, [customPathname, refineSearchFilters, setSwitchState])

  const buttonClassName = `flex items-center space-x-[6px] rounded py-0.5 px-[6px] text-[10px] `;
  const buttonClassNameActive = `light  text-[#FFFFFF] bg-[#BC8B20]`;
  const buttonClassNameInactive = `dark text-body-color`;

  return (
    <div>
      <label for="themeSwitcherOne" className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
        <span
          onClick={() => handleButtonClick("and")}
          className={`${buttonClassName}  ${SwitchState  ?  `${buttonClassNameActive}` : `${buttonClassNameInactive}` }`}>
          {onLabel}
        </span>

        <span
          onClick={() => handleButtonClick("or")}
          className={`${buttonClassName}  ${!SwitchState  ?  `${buttonClassNameActive}` : `${buttonClassNameInactive}` }`}>
            {offLabel}
        </span>
      </label>
    </div>

  );
}


export default ToggleSwitch;