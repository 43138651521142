import React, { useState, useContext } from 'react';
import pe_Icon from '../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import tfs_Icon from '../../../images/Shape_4/png/Shape.png';
import tfIn_Icon from '../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import companyIcon from '../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import TextCard from '../../ListPageCard/TextCard';
import { UserContext } from '../../../Contexts/UserContext';
import { useParams } from 'react-router-dom';
import useModule from '../../../hooks/useModule';
import { topCardUrl } from '../../../Config/config';
import axios from 'axios';
import { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import routePathSegments, { routeNames } from '../../../routeSegments';


/**
 * The `Card` component is a functional component in JavaScript that displays various details about a
 * company, such as acquisitions, acquired by, acquisition date, enterprise value, and EV/EBITDA.
 * @returns The Card component is returning a JSX element, which represents the structure and content
 * of the component's UI.
 */
const Card = () => {

  const [detailCard, setDetailCard] = useState();
  const { companyId } = useParams();
  let companyProfileId = parseInt(companyId)
  const { getToken } = useContext(UserContext);
  const [isLoading, setisLoading] = useState(false);
  const { primaryMasterId } = useModule()
  const location = useLocation();

  useEffect(() => {
    const abortController = new AbortController()
    setisLoading(true);
    axios.post(topCardUrl, {
      "company_id": companyProfileId,
      "type": primaryMasterId
    }, {
      signal: abortController.signal,
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      setisLoading(false);
      if (response.status === 200) {
        setDetailCard(response.data?.data)


      }
    }).catch(error => {
      setisLoading(false);
    })

    return () => abortController.abort();
  }, [companyProfileId, getToken, primaryMasterId])

  return (
    <div className="">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
        <div className="overflow-x-auto lg:grid grid-cols-5 gap-4  px-4 py-[0.7rem] flex xl:overflow-hidden scrollbar-remove">
          <TextCard
            image={pe_Icon}
            isLoading={isLoading}
            showCount={true}
            subtitle={detailCard?.acquisitions_count}
            title="Acquisitions"
          />
          <TextCard
            image={tfs_Icon}
            isLoading={false}
            showCount={false}
            
            subtitle={
              
              detailCard?.acquired_by.map((data ,index ) => {
                return (
                  <>

                    <NavLink to={
                      routeNames.maAcquiresById(data?.acquirer_id)
                    }
                      className="hover:text-[#BC8B20] ">
                      {/* {data.acquirer_name ? data.acquirer_name : "--"} */}
                      {`${data.acquirer_name}${index === detailCard?.acquired_by?.length -1 ?  " ":", "}`}

                    </NavLink>  
                  </>
                )

              }
              )
            }
       
            title="Acquired by"
          />
          <TextCard
            image={tfIn_Icon}
            isLoading={false}
            showCount={false}
            subtitle={detailCard?.acquisitions}
            title="Acquisition date"
          />
          <TextCard
            image={companyIcon}
            isLoading={false}
            showCount={false}
            subtitle={ detailCard?.enterprise_value && detailCard?.enterprise_value != 0 ? "₹ " + detailCard?.enterprise_value + " Cr" : "--"}
            title="Enterprise Value"
          // subtext=" (INR Cr)"

          />
          <TextCard
            image={companyIcon}
            isLoading={false}
            showCount={false}
            subtitle={((detailCard?.enterprise_value && detailCard?.enterprise_value !=0) || (detailCard?.ebitda && detailCard?.ebitda !=0)) ? ((detailCard?.enterprise_value != 0 ? detailCard?.enterprise_value : "--") + "/" + (detailCard?.ebitda != 0 ? detailCard?.ebitda : "--")) : "--"}
            title="EV/EBITDA"
          />
        </div>
        <div className='border-b-2 border-[#e5e5e5]'></div>
      </div >
    </div >
  )
}

export default Card;
