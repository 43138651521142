import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

const valuationOptions = {
    other: [
        { id: 1, name: 'Company Valuation', isChecked: false, value: "C" },
        { id: 2, name: 'Revenue Multiple', isChecked: false, value: "R" },
        { id: 3, name: 'EBITDA Multiple', isChecked: false, value: "E" },
        { id: 4, name: 'PAT Multiple', isChecked: false, value: "P" }
    ],
    maCompanies: [
        { id: 2, name: 'Revenue Multiple', isChecked: false, value: "R" },
        { id: 3, name: 'EBITDA Multiple', isChecked: false, value: "E" },
        { id: 4, name: 'PAT Multiple', isChecked: false, value: "P" }
    ]
}

/**
 * The `ValuationFilter` function is a React component that renders a filter for valuations with
 * options that can be selected or deselected.
 * @returns The `ValuationFilter` component is being returned.
 */
function ValuationFilter({ open, accordionClick, ValuationOptions, setValuationOptions, customPathname, dataPath = "other" }) {
    const { refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.valuations?.map(value => value.id) ?? [];
        setValuationOptions(valuationOptions[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [customPathname, refineSearchFilters, setValuationOptions, dataPath])

    const handleValuationsChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentValuation = ValuationOptions.map((valuation) =>
                ({ ...valuation, isChecked: checked })
            );
            setValuationOptions(currentValuation);
        } else {
            let currentValuation = ValuationOptions.map((valuation) =>
                valuation.id === parseInt(id) ? { ...valuation, isChecked: checked } : valuation
            );
            setValuationOptions(currentValuation);
        }
    });

    const len = ValuationOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={false}
                    label={"Valuations"}
                    onClick={accordionClick}
                    count={len}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleValuationsChange}
                        primaryEntity={ValuationOptions}
                    /> : <></>}

                </div>
            </li>
        </div>
    )
}

export default ValuationFilter