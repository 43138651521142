import React from 'react'
import FAQSearch from '../../Components/FAQ/FAQSearch'
import Layout from '../../Components/Layout';
import Menu from '../../Components/FAQ/Menu';
import CfsFAQ from './CFS'

/**
 * The CfsFaq function returns a JSX element that renders a layout with a search component, a menu
 * component, and a CfsFAQ component.
 * @returns a JSX element, which is a div containing several components. The components being returned
 * are Layout, FAQSearch, Menu, and CfsFAQ.
 */
function CfsFaq() {
  return (
    <div>
      <Layout>
        <FAQSearch menu="CFS" />
        <Menu active={5} menu="CFS"/>
        <CfsFAQ />
      </Layout>
    </div>
  )
}

export default CfsFaq
