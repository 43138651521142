import React, {  useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorAngelRefineSearch from '../../../Components/RefineSearch/InvestorAngelRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
import Constants from '../../../Constants'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";


/**
 * The function `AngelInvestment` is a React component that renders a layout for displaying angel
 * investments and provides filtering options.
 * @returns The function `AngelInvestment` returns JSX elements wrapped in a `Layout` component. The
 * returned JSX structure includes a `div` with class `h-screen flex flex-col` as the root element.
 * Inside this `div`, there are several nested elements including a `Card` component, a `main` element
 * with a `section` and `aside` element inside, and a `div`
 */
function AngelInvestment() {
    const location = useLocation();
    const {refineSearchFilters} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className='h-screen flex flex-col'>
               
               {isFromGlobalSearch() ? "":
              <div className="hidden lg:block">  <Card card1={window.location.pathname ? (window.location.pathname == '/pe/investors/angel-invesments' ? 'Angel Investors' : '') : ''} card2={window.location.pathname ? (window.location.pathname == '/pe/investors/angel-invesments' ? 'Angel Investments' : '') : ''} />

              </div> }
                {/* <Card card2={window.location.pathname ? (window.location.pathname == '/pe/investors/angel-invesments' ? 'Angel Investments' : '') : ''} /> */}

                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>

                        <section
                            className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                        >
                            <FilterTag />
                            <MainMenu active={4} menu="angel" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_angel_invesments_id}
                                typeId={Constants.moduleIds.investorsId} 
                                exportFileName={Constants.exportNames.pe_investor_angel}/>
                            <div className='overflow-y-auto'>
                                <Table menu="angel" sub_type_master_id={Constants.subCategoryIds.pe_investor_angel_invesments_id}/>
                            </div>

                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>


                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch ><InvestorAngelRefineSearch customPathname={location.pathname}/> </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>                
            </div>
        
        
        </Layout >
    )
}

export default AngelInvestment
