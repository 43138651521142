import { React, useState, useContext, useEffect ,useRef, useCallback} from "react";
// import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit, exportPdf } from "../../Config/config";
import {
  // useSearchParams,
  // Routes,
  // Route,
  useParams,
  NavLink,
} from "react-router-dom";
import axios from "axios";
// import OutsideClickHandler from "react-outside-click-handler";

import compare from "../../images/compare.png";
// import pdf from "../../images/pdf.png";
// import atom from "../../images/atom.png";
// import edit from "../../images/combined-shape.png";
// import KinaraLogo from "../../images/kinara-logo.png";
// import Warning from "../../images/warning-btn.png";
import FundDealInfo from "../../Components/InvestorFundDealDetails/FundDealInfo";
import FundMoreInfo from "../../Components/InvestorFundDealDetails/FundMoreInfo";
import addNoted from "../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from "../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png";
import xls from "../../images/download-file-icon.png";
import { CompareCompanyContext } from "../../Contexts/CompareCompanyContext";
import ComparePopUp from "../../Pages/ComparePopUp";
import { getFundsDetails } from "../../Config/config";
import AddNote from "../../Pages/addNote";
import AlertPopUp from "../../Components/DetailPage/AlertPopUp";
import PENotesPopup from "../../Components/NotesPopup/PENotesPopup";
import SendDealPopUp from "../../Components/SendDealPopUp";
import ExcelPopup from "../../Components/DetailPage/DetailExcelPopUp";
import { routeNames } from "../../routeSegments";
import Constants from "../../Constants";
import PdfDownloader from "../shared/PdfDownloader";
// import { Tooltip } from "antd";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import { UserContext } from "../../Contexts/UserContext";
import AddNotePopover from "../shared/AddNotePopover";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
// import ExcelPopup from "../../Components/ExcelPopup";

const CompanyDealDetail = (props) => {
  const {groupId ,setGroupId } = useContext(PeFilterContext);
  // console.log(props,"property")
  const { 
    // companyDetail, 
    setCompanyDetail } = useContext(CompareCompanyContext);

  const { investorfundId } = useParams();
  const { dealId } = useParams();
  const [currentId, setCurrentId] = useState(dealId);
  let investorProfileId = parseInt(investorfundId);

  const [note,
    //  setNote
    ] = useState(false);

  const [showNote, setShowNote] = useState(false);

  const [data, setData] = useState({});
  const [
    // fundDetail,
     setFundDetail] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeBtn, setActiveBtn] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [fundName, setFundName]   = useState()
  const [fundNameId, setFundNameId] = useState()
  // const charLength = descriptionCharLimit;
  var firmName;
  var fundId;
  const [active, setActive] = useState(1);
  const {getToken} = useContext(UserContext);

 /**
  * The handleClick function toggles the values of active and activeBtn variables.
  */
  const handleClick = () => {
    setActive(!active);
    setActiveBtn(!activeBtn);
  };

  const menuRef =useRef();

  useEffect(() => {
    getFundsDetail(investorProfileId, currentId);
  },[]) 
  
  // document.addEventListener("mousedown",(event)=>{
  //   if(!menuRef.current.contains(event.target))
  //   {
  //     setShowNote(false)
  //     // setShowModal(false)
    
  //   }        
  //       });
console.log(fundNameId,'fundNameId');

 /* The above code is defining a function called `getFundsDetail` using the `useCallback` hook. This
 function makes a POST request to a specified endpoint (`getFundsDetails`) with the provided
 `investorProfileId` and `dealId` as data. It also includes the necessary headers, such as the
 "Content-Type" and "Authorization" headers. */
  const getFundsDetail = useCallback((investorProfileId, dealId) => {
    // setSelectedRoundType("")
    axios(`${getFundsDetails}`, {
      method: "POST",
      data: {
        investor_id: investorProfileId, 
        deal_id: dealId,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response.status == "200") {
          console.log(response.data, "getFunds");
          setData(response?.data);
          setFundDetail(response?.data?.fundList);
          {Object.keys(response?.data).length > 0 &&
            Object.entries(response?.data).map(([key, value]) => {
                value !=
                  "The fund detail has been viewed successfully" &&
                key != "status" &&
                value?.length > 0 &&
                value?.map((round, i) => {
                   Object.entries(round).map(([key1, value1]) => {
                     console.log(value1, "value1");
                        {value1?.selected == true &&
                          setFundName(value1?.fund_name)  
                          setFundNameId(value1?.fund_name_id)
                        }
                  });
                })
            })}
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  },[])
  // console.log(fundDetail, "responsefund");
  return (
    <>
      <div className="bg-[#FFFFFF]">
        
        <div className="col-span-5 md:col-span-4 font-sans_book_body Sans] bg-[#FFFFFF]">
          {/* bread crumb */}
          <nav
            className=" flex p-1 lg:px-2 px-3 border-b "
            aria-label="Breadcrumb"
          >
            <ol role="list" className="flex items-center space-x-1">
              <li className="flex items-center">
                <div className="flex items-center">
                  <NavLink
                   onClick={()=>setGroupId({})}
                    to={props.menu==="pe"?routeNames.pe_investors_pe_vc_investments:routeNames.vc_investors_vc_investments}
                    className="ml-2  text-[12pt] lg:text-[12px] text-[#666666] font-sans_book_body leading-[14px]"
                  >
                    Investor
                  </NavLink>
                </div>
              </li>

              <li>
                <div className="flex items-center">
                  <span className=" text-[12px] text-[#666666] ">/</span>
                  <NavLink
                    to={props.menu==="pe"?routeNames.pe_investors_pe_vc_funds:routeNames.vc_investors_vc_funds}
                    className=" ml-1 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]"
                  >
                 Funds
                  </NavLink>
                </div>
              </li>
              <li>
                {data?
                <div className="flex items-center">
                  <span className=" text-[12px] text-[#666666] font-medium">
                    /
                  </span>
                  {Object.keys(data).length > 0 &&
                    Object.entries(data).map(([key, value]) => {
                      return (
                        value !=
                          "The fund detail has been viewed successfully" &&
                        key != "status" &&
                        value?.length > 0 &&
                        value?.map((round, i) => {
                          // console.log(key, "getroundhier");
                          // console.log(value, "getroundhiervalue");
                          return Object.entries(round).map(([key1, value1]) => {
                            // console.log(key1, "getroundhier1");
                            // console.log(value1, "value1"); 
                            return (
                              <div className="leading-[25px] mb-[0.2rem]">
                                {value1?.selected == true ? (                                  
                                  <NavLink
                                    key={value1?.deal_id}
                                    to=""
                                    className=" text-[12px] leading-[14px] font-sans_book_body text-[#666666] pl-1 font-semibold"
                                    aria-current="page"
                                  >
                                    {" "}
                                    {value1?.firm_name
                                      ? value1?.firm_name
                                      : "--"}
                                  </NavLink>
                                ) : (
                                  " "
                                )}
                              </div>
                            );
                          });
                        })
                      );
                    })}
                </div>
                :<>
            <center><div className="w-full bg-[#FFFFFF]">
                <div role="status" className="bg-white" >
                  <svg className="relative top-[0rem] inline mr-2 w-10 h-screen text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                </div>
              </div></center>
              </>
         }
              </li>
            </ol>
          </nav>
          {/* <!-- KinaraCapital--> */}
          <div className="px-5 border-b border-[#D7D7D7]">
            <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4">
              <div
                className=" md:col-span-6 col-span-5"
                title="Compare Company"
              >
                <button
                  className={
                    showModal
                      ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                      : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                  }
                  type="button"
                  onClick={() => {
                    setShowModal(!showModal);
                    setCompanyDetail([]);
                  }}
                >
                  <img
                    className={
                      showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                    }
                    src={compare}
                    alt=""
                  />
                  <span>Compare</span>
                </button>
              </div>
              <div className="flex md:col-span-2 col-span-5 space-x-3">
                <div>
                  <AlertPopUp
                    type_id={Constants.fundsId}
                    cid={investorProfileId}
                  />
                </div>
                <div>
                  {/* <OutsideClickHandler onOutsideClick={() => { setShowNote(false); }} > */}
                  <span ref={menuRef}>
                    <img
                      className=" cursor-pointer w-[24px] h-[22px]"
                      src={showNote ? addNotedActive : addNoted}
                      onClick={() => setShowNote(!showNote)}
                      alt=""
                    />
                    {showNote ? (
                      <AddNote
                        module="funds"
                        page="detailpage"
                        type_id={Constants.fundsId}
                        cname={fundName ?? firmName}
                        cid={fundNameId ?? fundId}
                        investor_id={investorProfileId}
                        deal_id={parseInt(currentId)}
                        sub_type_master_id={Constants.subCategoryIds.pe_funds_id}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                  {/* </OutsideClickHandler> */}
                </div>

                <div className="col-span-1">
                  <PENotesPopup
                    note={note}
                    cid={investorProfileId}
                    type_id={Constants.fundsId}
                  />
                </div>
                <div className="col-span-1">
                {fundName &&
                  <SendDealPopUp data={fundName ? fundName?.replaceAll(/\s/g, "") :firmName?.replaceAll(/\s/g, "")+" Fund"} />
                }
                </div>

                <div>
                {<PdfDownloader 
                    url={exportPdf}
                    // fileName={fundName?.replaceAll(/\s/g, "")}
                    fileName={ fundName ?fundName?.replaceAll(/\s/g, ""): firmName?.replaceAll(/\s/g, "")+"Fund"}
                    data={{
                      entity_id: investorProfileId,
                      type_id: Constants.moduleIds.fundsId,
                    }}
                  />
                }
                </div>
              </div>
            </div>
            <div className="  py-4 bg-[#FFFFFF]">
              <ul role="list">
                <li className="w-full flex "> 
                  <div className="  rounded-md bg-white ">
                  {Object.keys(data).length > 0 &&
                    Object.entries(data).map(([key, value]) => {
                      return (
                        value !=
                          "The fund detail has been viewed successfully" &&
                        key != "status" &&
                        value?.length > 0 &&
                        value?.map((round, i) => {
                          // console.log(key, "getroundhier");
                          // console.log(value, "getroundhiervalue");
                          return Object.entries(round).map(([key1, value1]) => {
                            // console.log(key1, "getroundhier1");
                             console.log(value1, "value1");
                            return (
                              <>
                                {value1?.selected == true ? (
                                  value1?.logo_path=="default.png" || value1.logo_path=="/default.png" || !value1.logo_path?
                                   <RandomLogo name={value1?.firm_name} page="detail page"/>
                                  :
                                   <CompanyLogo name={value1?.firm_name} id={value1?.firm_id}  page="detail page" logo={value1?.icon_path}/>
                                   
                                ) : (
                                  " "
                                )}
                              </>
                            );
                          });
                        })
                      );
                    })}
                     
                  </div>

                  <div className="ml-3 lg:px-3 flex flex-1 flex-col pl-3 lg:pl-0">
                    {Object.keys(data).length > 0 &&
                      Object.entries(data).map(([key, value]) => {
                        return (
                          value !=
                            "The fund detail has been viewed successfully" &&
                          key != "status" &&
                          value?.length > 0 &&
                          value?.map((round, i) => {
                            // console.log(key, "getroundhier");
                            // console.log(value, "getroundhiervalue");
                            return Object.entries(round).map(
                              ([key1, value1]) => {
                                // console.log(key1, "getroundhier1");
                                // console.log(value1, "value1");
                                firmName=value1?.firm_name
                                fundId = value1?.fund_name_id
                                return (
                                  <div>
                                    {value1?.selected == true ? (
                                      <a
                                        key={value1?.deal_id}
                                        className="text-[#333333] text-[22px] font-semibold leading-[24px]"
                                        href="#"
                                      >
                                        
                                        {value1?.firm_name
                                          ? value1?.firm_name
                                          : "--"}
                                      </a>
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                );
                              }
                            );
                          })
                        );
                      })}
                    {Object.keys(data).length > 0 &&
                      Object.entries(data).map(([key, value]) => {
                        return (
                          value !=
                            "The fund detail has been viewed successfully" &&
                          key != "status" &&
                          value?.length > 0 &&
                          value?.map((round, i) => {
                            // console.log(key, "getroundhier");
                            // console.log(value, "getroundhiervalue");
                            return Object.entries(round).map(
                              ([key1, value1]) => {
                                // console.log(key1, "getroundhier1");
                                // console.log(value1, "value1");
                                return (
                                  <div>
                                    {value1?.selected == true ? (
                                      <span
                                        key={value1?.deal_id}
                                        className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body "
                                      >
                                        {value1?.fund_name
                                          ? value1?.fund_name
                                          : ""}
                                      </span>
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                );
                              }
                            );
                          })
                        );
                      })}
                  </div>
                  
                  <div className="hidden lg:grid grid-cols-8  gap-2">
                    <div className="col-span-3 mt-2">
                      {/* <Tooltip title="Compare Company">
                        <button
                          className={
                            showModal
                              ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                              : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                          }
                          type="button"
                          onClick={() => {
                            setShowModal(!showModal);
                            setCompanyDetail([]);
                          }}
                        >
                          <img
                            className={
                              showModal
                                ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                : "w-[20px] h-[18px] pr-1 blur-btn"
                            }
                            src={compare}
                            alt=""
                          />
                          <span>Compare</span>
                        </button>
                      </Tooltip> */}
                    </div>

                    <div className="col-span-5 mt-[5px]">
                      <div className="grid grid-cols-5 gap-2 flex items-center">
                        <div className="col-span-1 ">
                          <AlertPopUp
                            cid={investorProfileId} 
                            type_id={Constants.fundsId} 
                            page="fundDetail"
                          />{" "}
                        </div>

                        <div className="col-span-1" ref={menuRef}>
                        <AddNotePopover
                                  title="Save List"
                                  companyId={fundNameId ?? fundId}
                                  companyName={fundName ?? firmName}
                                  type_id={Constants.moduleIds.fundsId} 
                                  investor_id={investorProfileId}
                                  deal_id={parseInt(currentId)}
                                  sub_type_master_id={Constants.subCategoryIds.pe_funds_id}
                                  IsDetailed={true}/>
                          {/* <Tooltip title="Save List">
                            <img
                              className="w-[22px] h-[22px] cursor-pointer"
                              src={showNote ? addNotedActive : addNoted}
                              onClick={() => setShowNote(!showNote)}
                              alt=""
                            />
                            </Tooltip>
                            {showNote ? (
                              <AddNote
                                module="funds"
                                page="detailpage"
                                type_id={Constants.fundsId}
                                cname={fundName}
                                cid={fundNameId}
                              />
                            ) : (
                              ""
                            )} */}
                        </div>
                        <div className="col-span-1 mt-1">
                          <PENotesPopup
                            note={note}
                            cid={investorProfileId}
                            type_id={Constants.fundsId}
                          />
                        </div>
                        <div className="col-span-1 mt-1">
                        {
                          <SendDealPopUp data={fundName ? fundName?.replaceAll(/\s/g, "") :firmName?.replaceAll(/\s/g, "")+" Fund"} />
                        }                       
                        </div>
                        <div className="col-span-1">
                          {props.menu == "dealpage" ? (
                            <img
                              title="Export"
                              src={xls}
                              className="h-[27px] w-[25px] cursor-pointer"
                              onClick={() => setShowPopup(!showPopup)}
                            />
                          ) : (
                            <PdfDownloader 
                              url={exportPdf}
                              fileName={ fundName ?fundName?.replaceAll(/\s/g, ""): firmName?.replaceAll(/\s/g, "")+"Fund"}
                              data={{
                                entity_id: investorProfileId,
                                type_id: Constants.moduleIds.fundsId,
                              }}
                            />
                          
                          )}
                          {showPopup && (
                            <div>
                              <ExcelPopup
                                menu="dealpage"
                                showPop={showPopup}
                                setShowPop={setShowPopup}
                              />{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- tags--> */}
          </div>
        </div>
      </div>
      <div className="bg-[#EDEDED]">
        <div className="flex flex-row space-x-3 mt-3 justify-start mx-5 overflow-x-auto scrollbar-remove">
          {Object.keys(data).length > 0 &&
            Object.entries(data).map(([key, value]) => {
              return (
                value != "The fund detail has been viewed successfully" &&
                key != "status" &&
                value?.length > 0 &&
                value?.map((round, i) => {
                  // console.log(key, "getroundhier");
                  // console.log(value, "getroundhiervalue");
                  return Object.entries(round).map(([key1, value1]) => {
                    // console.log(key1, "getroundhier1");
                    // console.log(value1, "value1");
                    return (
                      <a
                        className={
                          "inline-block px-3 py-1 text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] whitespace-nowrap " +
                          (value1?.selected
                            ? " text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] font-sans_book_body"
                            : "text-[#BC8B20] border border-[#BC8B20] font-sans_book_body")
                        }
                        onClick={() => {
                          getFundsDetail(investorProfileId, value1?.deal_id);
                          setCurrentId(value1?.deal_id);
                          setActive(i + 1);
                          handleClick();
                        }}
                      >
                        {key1}
                      </a>
                    );
                  });
                })
              );
            })}
        </div>
        <div className="w-full lg:grid lg:grid-cols-8 gap-6 p-4 pt-0 lg:pt-4">
          <div className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
            <div className="w-full lg:grid lg:grid-rows-8 gap-5">
              <div className="overflow-x-auto row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                <FundDealInfo data={data} />
              </div>
            </div>
          </div>
          <div className="w-full  bg-[#FFFFFF] lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
            <div className="lg:grid lg:grid-rows-9 gap-5 h-[13rem]">
              <div className="row-span-2 pt-4">
                <FundMoreInfo data={data} page="fundDetail"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? <ComparePopUp id={1} onClose={(e)=>setShowModal(false)}/> : null}
    </>
  );
};
export default CompanyDealDetail;
