import React,{useState} from "react";

/**
 * The ToggleSwitch component is a customizable switch that toggles between two options and triggers a
 * callback function when toggled.
 */
const ToggleSwitch =({ onToggle,onLabel,offLabel,  }) =>{

  const [value, setValue] = useState(false) 

  const buttonClassName = `flex items-center space-x-[6px] rounded py-1 px-[6px] text-[13px] `;
  const buttonClassNameActive = `light  text-[#FFFFFF] bg-[#BC8B20]`;
  const buttonClassNameInactive = `dark text-body-color`;

  return (
    <div>
      <label for="themeSwitcherOne" className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
        <span
          onClick={() => {onToggle("bar");setValue(false)}}
          className={`${buttonClassName}  ${value === false ? `${buttonClassNameActive}` : `${buttonClassNameInactive}`}`}>
          {onLabel}
        </span>

        <span
          onClick={() => {onToggle("pie");setValue(true)}}
          className={`${buttonClassName}  ${value === true ? `${buttonClassNameActive}` : `${buttonClassNameInactive}`}`}>
          {offLabel}
        </span>
      </label>
    </div>

  );
}


export default ToggleSwitch;