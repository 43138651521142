import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FundSecondType from '../../Components/RefineSearchComponents/FundSecondType'
import FilterWrapper from './FilterWrapper';

/**
 * The `FirmTypeFilter` function is a React component that renders a filter for selecting fund types,
 * with options that can be selected or deselected by the user.
 * @returns The `FirmTypeFilter` component returns JSX elements that represent a filter for selecting
 * fund types. It includes a heading, a list item with a filter wrapper, and a multi-select entity
 * component. The filter options are rendered conditionally based on the `open` prop.
 */
function FirmTypeFilter({ open, accordionClick, FundsStageOptions, setFundsStageOptions, customPathname }) {

    const { FundsStage, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to update the `FundsStageOptions` state based on the values in the
    `refineSearchFilters` and `FundsStage` contexts. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.fundStage?.map(value => value.id) ?? [];
        setFundsStageOptions(FundsStage?.map(industry => ({ ...industry, isChecked: industryIds.includes(parseInt(industry.id)) })))
    }, [FundsStage, customPathname, refineSearchFilters, setFundsStageOptions])

    /* The `handleFundTypeChange` function is a callback function that is triggered when the user
    selects or deselects a fund type option. It takes an event object `e` as a parameter, which
    represents the event that triggered the function (in this case, a checkbox change event). */
    const handleFundTypeChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentFundType = FundsStageOptions.map((fundtype) =>
                ({ ...fundtype, isChecked: checked })
            );
            setFundsStageOptions(currentFundType);
        } else {
            let currentFundType = FundsStageOptions.map((fundtype) =>
                fundtype.id === parseInt(id) ? { ...fundtype, isChecked: checked } : fundtype
            );
            setFundsStageOptions(currentFundType);
        }
    });

    const len = FundsStageOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <>
            <div>
                <span className="px-3 py-2 text-[#333333] text-[14px] leading-[17px] tracking-[-0.3px]">Type</span>
            </div>
            <div className="border-b-[2px] border-[#E8E6E1]">
                <div >
                    <li
                        className={
                            open
                                ? "bg-white relative px-3 py-1"
                                : "relative px-3 py-1"
                        }
                    >
                        <FilterWrapper
                            count={len}
                            isSelect={false}
                            label="select option"
                            onClick={accordionClick}
                            open={open}
                        />

                        <div
                            className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                            style={{ maxHeight: open ? "10rem" : "" }}
                        >

                            {open === true ? <MultiSelectEntity
                                handleEntityChange={handleFundTypeChange}
                                primaryEntity={FundsStageOptions}
                            /> : <></>}
                        </div>

                    </li>

                </div>

            </div>

            {/* <FundSecondType /> */}

        </>
    )
}

export default FirmTypeFilter