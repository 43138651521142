
import { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import CompanyLogo from '../CompanyLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { rowsPerPageData, maAdvisorListApi, getPEGlobalSearchCompaniesApi } from '../../Config/config'
import { TableDataContext } from '../../Contexts/TableDataContext'
import axios from "axios";
import CustomNavigation from '../../Utils/CustomNavigation';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';


/* The above code is a JavaScript React component that renders a table. It receives props such as
columns, menu, month, order, orderBy, and sub_type_master_id. */
const Table = ({ columns, menu, month, order, orderBy,sub_type_master_id }) => {

  const { rows, setRows, data, setData, isLoad, setIsLoad, isDataLoad, setIsDataLoad, totalNoOfCompanies, setTotalNoOfCompanies, setTableCompanyId,setAllDealIds, setTableAdvisorId, currentPage, setCurrentPage,setCurrentFilter } = useContext(TableDataContext);
  const { monthPickerValue, setMonthPickerValue, isMonthPickerChanged, setIsMonthPickerChanged, isTagClosed, setIsTagClosed, refineSearchFilters,groupId } = useContext(PeFilterContext);
  const [activePage, setActivePage] = useState(currentPage)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  var location = useLocation();
  const[reload, setReload] = useState(false)
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
  }

  console.log('refineSearchFiltersmaadvisor', refineSearchFilters[location.pathname]);
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  /* The above code is a JavaScript React component that defines a function called `tableRender`. This
  function is using the `useCallback` hook to memoize the function and optimize performance. */
  const tableRender = useCallback((page, month, column, order,groupId) => {
    console.log(page, month, column, order, 'ma usecallback month');

    setIsTagClosed(0)
    setIsMonthPickerChanged(0)
    axios(isFromGlobalSearch()? getPEGlobalSearchCompaniesApi:`${maAdvisorListApi}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:isFromGlobalSearch()?
      {
        "searchTerm": refineSearchFilters[location.pathname]?.globalSearch[0]?.searchTerm,
        "type": refineSearchFilters[location.pathname]?.globalSearch[0]?.type,
        "type_id": 3,
        "page": page,
        "sortBy": "advisor_sort",
        "sortDirection": order 
      }: {
        "date_range": { "from_date": moment(month[0]).format("YYYY-MM-DD"), "to_date": moment(month[1]).format("YYYY-MM-DD") },
        "page": page,
        "order_by": { "column": column, "direction": order },
        "advisor_type": "L",
        AdvisorIds: refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        "industry": refineSearchFilters[location.pathname]?.industry?.map(value => value.id),
        "sector": refineSearchFilters[location.pathname]?.sector?.map(value => value.id),
        "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "deal_range": refineSearchFilters[location.pathname]?.dealSize?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.dealSize[0].name,
          "to": refineSearchFilters[location.pathname]?.dealSize[1].name
        },
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
        }
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },

    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          // console.log(response,'cfs table response');       
          // setTotalNoOfCompanies(response?.data?.num_companies)
          setCurrentFilter(response.config.data)
          setIsLoad(false)
          setIsDataLoad(false)
          // setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.advisors)
          setData(response?.data)
          setTableAdvisorId(response?.data?.all_advisor_ids)
          setAllDealIds(response?.data?.all_deal_ids)
          console.log(response?.data?.all_advisor_ids, 'cfs table response');
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus) {
          logout()
        }
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue,groupId ,reload]);

  const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  // const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  // const calculatedRows = paginateRows(sortedRows, currentPage, rowsPerPage)
  // console.log(calculatedRows, "cfs calculated")

  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   */
  const handleSort = (accessor, order) => {
    setCurrentPage(currentPage)
    setRows([])
    setIsLoad(true)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }
  const count = filteredRows?.length
  // const totalPages = Math.ceil(count / rowsPerPage)

  var location = useLocation();

  /**
   * The function adds commas to a number to improve readability.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

    useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
          <div className={"overflow-x-auto scrollbar rounded-b-[6px] "+(rows?.length > 5 ? '':'pr-2')}>
            <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%"> 
                <thead className="bg-[#EBEBEB]  text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[16px] font-semibold  text-[#666666]">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (

                        <th className={ i==0 ? "md:pl-5 md:pr-5 pl-5 pr-[13rem] ":"px-4 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className="flex whitespace-nowrap ">
                            <OverflowToolTip className='hover:text-[#000000]' component={<a className='hover:text-[#000000] cursor-auto'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows?.length > 0 ?
                        rows?.map((row, i) => {
                          return (
                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="w-[10%] leading-[2rem] pl-4 pr-3 py-3 font-sans_book_body card-animate " style={{whiteSpace:"break-spaces"}} >
                                <div className='flex items-center space-x-2 2xl:space-x-0'>
                                  {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                    <RandomLogo name={row?.advisor_name} />
                                    :
                                    <CompanyLogo id={row?.advisor_id} name={row.advisor_name} page="company page" logo={row?.logo_path} />
                                  }
                                  <OverflowToolTip className=" w-[10rem]" component={
                                    <NavLink className=' flex text-start leading-[1.1rem] lg:inline lg:whitespace-nowrap hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={routeNames.maAdvisorsById(row.advisor_id)}
                                    >  {row?.advisor_name}
                                    </NavLink>} />
                                </div>
                              </td>

                              <td className="w-[9%] py-3 px-5 font-sans_book_body whitespace-nowrap text-right pr-12 lg:pr-[3.5%] 2xl:pr-[6.2%]">
                                {row.to_company_investments !=0  ? addCommasToNumber(row.to_company_investments) : "--"}
                              </td>
                              <td className=" w-[6%] py-3 px-5 font-sans_book_body whitespace-nowrap text-right pr-10 lg:pr-[4.8%] 2xl:pr-[4.8%]" >
                                {row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers): "--"}
                              </td>
                              <td className=" w-[12%] py-3 px-5 font-sans_book_body whitespace-nowrap" style={{whiteSpace:"break-spaces"}} >
                                <OverflowToolTip className="w-[10rem]" component={<NavLink className=' flex text-start lg:inline  lg:whitespace-nowrap hover:text-[#BC8B20]'
                                
                                  to={routeNames.maCompanyById(row.latest_transaction_id)} >

                                  {row.latest_transaction ? row.latest_transaction : "--"} </NavLink>
                                } />
                              </td>
                              <td className=" w-[16%] py-3 px-5 font-sans_book_body whitespace-nowrap">
                                {row.latest_transaction_deal_date  ? <Link className='hover:text-[#BC8B20]'
                                  to={{
                                    pathname: routeNames. maCompanyDealById(row?.latest_transaction_id, row?.latest_transaction_deal_date_id)
                                  }}>
                                  {row.latest_transaction_deal_date ? row?.latest_transaction_deal_date : "--"}
                                </Link> : "--"}

                              </td>
                              <td className={groupId?.myList?.length >0 ? "w-[1%] px-5 py-3 font-sans_book_body ":"w-[5%] px-5 py-3 font-sans_book_body " }>
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.l_advisor_id}  setReload={setReload}/>
                                 :<AddNotePopover className="" key={row?.advisor_id} companyId={row.advisor_id} companyName={row?.advisor_name} type_id={Constants.moduleIds.l_advisor_id} sub_type_master_id={sub_type_master_id}/>

                        }</td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>

              {!isLoad ?
                count > 0 ?
                  <></>
                  : 
                  <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
            </div>
        </div>
      </div>

      {data?.num_advisors && rows?.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_advisors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
    </>
  )
}

export default Table
