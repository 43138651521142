import React, { useState,useContext } from 'react'
import Card from '../../../Components/Advisor/AdvisorPageCard/Card'
import Layout from '../../../Components/TransactionLayout'
import MainMenu from '../../../Components/MainMenu'
import Table from '../../../Components/TransactionAdvisor/T_TableComponent'
import FilterTag from '../../../Components/Tag'
import RefineSearch from '../../../Components/RefineSearchComponent'
import AdvisorInvestmentsRefineSearch from '../../../Components/RefineSearch/AdvisorInvestmentsRefineSearch'
import MonthPickerComponent from "../../../Utils/MonthPickerComponent";
import xls from "../../../images/xls/png/Group 47.png";
import FileIcon from "../../../images/file.png";
import FileIconBlur from "../../../images/fileblur.png";
import line from "../../../images/line.png";
import moment from "moment";
import SavedFilterContent from "../../../Components/SavedFilter";
import SavedFilterICON from "../../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../../Components/ExportPopup";
import {  transactionAdvisorProfileExportData } from "../../../Components/ExportPopup/ExportData"
import { exportAdvisorExcel } from "../../../Config/config";
import Constants from '../../../Constants'
import { useLocation } from 'react-router-dom'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { UserContext } from '../../../Contexts/UserContext'

function TransactionAdvisor() {

  // const [save, setSave] = useState(false)
  const {save, setSave,showMonthPicker ,refineSearchFilters,groupId} = useContext(PeFilterContext);
  const [showPopup, setShowPopup] = useState(false);
  const { pathname } = useLocation(); 

  /* The code `const [date, setDate] = useState([
      moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD"),
    ]);` is initializing a state variable called `date` using the `useState` hook. The initial value
  of `date` is an array containing two elements. The first element is the start date of the current
  month, subtracted by 12 months, and formatted as "YYYY-MM-DD". The second element is the end date
  of the current month, formatted as "YYYY-MM-DD". The `setDate` function can be used to update the
  value of `date` later in the component. */
  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);

  /**
   * The function `savedList` toggles the value of the `save` variable.
   */
  const savedList = () => {
    setSave(!save)
  } 
  const { isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)

  const location = useLocation();
  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

  return (
    <>
      <Layout active={5}>
        <div className="flex h-screen flex-col">
          {/* Top nav*/}
        {isFromGlobalSearch () ? "" : <div className='hidden lg:block'><Card/>  </div>}  
          {/* Bottom section */}
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Narrow sidebar*/}
            {/* Main area */}
            <main className= {isFromGlobalSearch () ? " mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex " :"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
              {/* Primary column */}
              <section
                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]"
              >
                {/* Your content */}
                <FilterTag menu="tadvisor" />
                <div className="pl-3 pr-4">
                  <div className="scrollbar  rounded shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                      <MainMenu active={1} menu="tadvisor" />
                      { isFromGlobalSearch () || groupId?.myList?.length > 0 ? "" :
                        <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                        <label htmlFor="" className="text-[#666666]">
                          <div className={showMonthPicker!=0 ?"":'flex'}>
                            <MonthPickerComponent  />
                          </div>
                        </label>
                        <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>    
                        {isTrialExport =='false' || isDirectoryUser || isStudent === 'true'|| isFromGlobalSearch()   ? <></>  : <>                 
                            <button>
                              <img
                                src={xls}
                                className="h-[24px] mt-1 w-[21px]"
                                alt=""
                                onClick={() => setShowPopup(!showPopup)}
                              />
                              {showPopup && (
                                <ExportPopup
                                  modules="advisor"
                                  module="transaction-advisor"
                                  url={exportAdvisorExcel}
                                  fileName={Constants.exportNames.pe_t_advisor_investment}
                                  onClose={() => setShowPopup(false)}
                                  exportOptions={transactionAdvisorProfileExportData}
                                  type="pe_vc_investment" module_type="pe" advisor_type="T" />
                                // <div>
                                //   <ExcelPopup
                                //     menu="investments"
                                //     subMenu="pevc"
                                //     showPop={showPopup}
                                //     setShowPop={setShowPopup}
                                //   />{" "}
                                // </div>
                              )}
                            </button>  
                          <a>
                            <img className="h-[24px] w-[2px] mt-1 " src={line} />
                          </a>
                          </>
                          }
                          <SavedFilterICON
                            save={save}
                            FileIcon={FileIcon}
                            FileIconBlur={FileIconBlur}
                            savedList={savedList} />
                        </div>
                      </div>
                      }
                    
                    </div>
                    {/* {save ? <SavedFilterContent data={save} /> : ''} */}
                  </div>
                  {save ? <SavedFilterContent primaryTypeMasterId={Constants.primaryMasterIds.peId}
                    subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_advisors_id}
                    typeId={Constants.moduleIds.t_advisor_id}
                    show={save} /> : ''}
                </div>
                <div className='overflow-y-auto'>
                  <Table menu="pe_vc_advisor" advisor_type="T" sub_type_master_id={Constants.subCategoryIds.pe_tadvisor_investments_id}/>
                </div>
              </section>
              {/* Secondary column (hidden on smaller screens) */}
              <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                  {/* Your content */}
                  <RefineSearch> <AdvisorInvestmentsRefineSearch customPathname={location.pathname}/> </RefineSearch>
                </div>
              </aside>
            </main>
          </div>          
        </div>
      </Layout>
    </>
  )
}

export default TransactionAdvisor
