import React, { useState, useContext, useEffect } from "react";

import MonthPickerComponent from "../Utils/MonthPickerComponent";
import xls from "../images/xls/png/Group 47.png";
// import ExcelPopup from "../Components/ExcelPopup";
import line from "../images/line.png";
import moment from "moment";
import FileIcon from "../images/file.png";
import FileIconBlur from "../images/fileblur.png";
import Card from "../Components/ListPageCard/Card";
import Layout from "../Components/Layout";
import MainMenu from "../Components/MainMenu";
import Table from "../Components/TableComponent";
import FilterTag from "../Components/Tag";
import RefineSearch from "../Components/RefineSearchComponent";
// import chatPopupIcon from "../images/chatPopupIcon.png";
// import ChatPopup from "../Components/ChatPopup";
import '../index.css'
import SavedFilterContent from "../Components/SavedFilter";
import ExportPopup from "../Components/ExportPopup";
import { data } from "../Components/ExportPopup/ExportData"
import { exportPDF } from "../Config/config";
import InvestmentRefineSearch from "../Components/RefineSearch/InvestmentRefineSearch";
import Constants from "../Constants";
import { useLocation } from "react-router";
import { PeFilterContext } from "../Contexts/PeFilterContext";
// import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
// import MonthRangePicker from "../Components/shared/MonthRangePicker";
import { UserContext } from "../Contexts/UserContext";
import DirectoryDownloadAccess, { ExportDirectoryExcel } from "../Components/ExportPopup/DirectoryExport";
import { Switch, Tooltip } from "antd";
import ConfirmPopup from "../Utils/ConfirmPopup";
import ExcelDownloader from "../Components/shared/ExcelDownloader";

// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// import { notification } from "antd";


// import echo  from '../../src/Components/NotificationSettings/echo';

/**
 * The Home function is a JavaScript function that renders a page layout with a top navigation bar, a
 * main content area with a filter tag and a table, and a sidebar with refine search options.
 * @returns The Home component is being returned.
 */
function Home() {
  // const [showModal, setShowModal] = React.useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);
 const { refineSearchFilters } = useContext(PeFilterContext);
  // const [save, setSave] = useState(false);
  const { save, setSave, showMonthPicker, groupId, dealsView, setDealsView, switchValue, setSwitchValue} = useContext(PeFilterContext);

  // const [showNotification, setShowNotification] = useState(false)
  /**
   * The function `savedList` toggles the value of the `save` variable.
   */
  const savedList = () => {
    setSave(!save)
  }
  const { pathname } = useLocation(); 
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)
  const [alertMsg, setalert] = useState({ message: Constants.excelPopupMessage, show: true, icon:'' });

  // useEffect(()=>{},[isStudent])
  console.log(isDirectoryUser,'isStudent');

  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })    
  } 


  const dealsViewHandler = (checked) =>{
    setSwitchValue(checked);
    if(checked){ setDealsView(true) }else setDealsView(false);
  }

  return (
    <>
      <Layout menu="pe">
        <div className="flex min-h-screen h-screen flex-col">
          {/* Top nav*/}
         {isFromGlobalSearch() ? "" :<div className="hidden lg:block"> <Card/> </div>}
          {/* Bottom section */}
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Narrow sidebar*/}
            {/* Main area */}
            <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
              {/* Primary column */}
              <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                {/* Your content */}
                <div className="mr-16 flex justify-between items-center">
                <FilterTag />
                <Tooltip title={`${dealsView ? 'Toggle Companies View' : 'Toggle Deals View'}`}>
                <Switch onChange={dealsViewHandler} checked={switchValue}/>
                </Tooltip>
                </div>
                <div className="pl-3 pr-4 lg:pr-4 md:pr-5">                 
                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                      <MainMenu active={1} menu="pe" subMenu="investment" />
                 {isFromGlobalSearch() ||  groupId?.myList?.length > 0 ? "":
                 <div className={"justify-between items-center  px-3 lg:px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                 <label htmlFor="" className="text-[#666666] ">
                   <div className={showMonthPicker!=0 ?"":'flex'}>
                    
                     <MonthPickerComponent />
                     {/* <MonthRangePicker value={monthPickerValue} onChange={(value)=>setMonthPickerValue(value)}/> */}

                   </div>
                 </label>
                 
                 <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                 { isStudent == 'true' || isFromGlobalSearch() || groupId?.myList?.length > 0 ? <></>  : 
                 <>                  
                     <button>
                        {
                          isTrialExport =='false' ?
                          <ExcelDownloader
                          url={exportPDF}
                          fileName={Constants.exportNames.pe_company_investment}
                          data={{"type" : "pe_vc_investment"}}  
                          children={<img
                            src={xls}
                            className="max-h-[24px] mt-1 max-w-[21px]"
                            alt=""
                          />}                   
                        />
                        :

                       <img
                         src={xls}
                         className="max-h-[24px] mt-1 max-w-[21px]"
                         alt=""
                         onClick={() =>setShowPopup(!showPopup)}
                       />
                    }
                       {showPopup && (
                         <ExportPopup
                           modules="company"
                           fileName={Constants.exportNames.pe_company_investment}
                           url={exportPDF}
                           module_type="pe"
                           onClose={() => setShowPopup(false)}
                           exportOptions={data}
                           isExport={isTrialExport}
                           type="pe_vc_investment" />                         
                       )}                     
                     </button>
                     
                     <img
                     alt=""
                       className="h-[24px] w-[2px] mt-1 "
                       src={line}
                     /> 
                 </> 
                 }                        
                   <div className="group cursor-pointer">
                     <div className="cursor-pointer" onClick={savedList}>
                       <img src={save ? FileIcon : FileIconBlur}
                         className="max-h-[24px] mt-1 max-w-[21px]"
                         alt="" />
                     </div>
                   </div>                         
                 </div>

               </div>

                 }
                      
                    </div>
                    {save ? <SavedFilterContent
                      primaryTypeMasterId={Constants.primaryMasterIds.peId}
                      subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_investments_id}
                      typeId={Constants.moduleIds.companiesId}
                      show={save} /> : ''}                  
                </div>
                <div  id="overFlow" className="overflow-y-auto">
                  <Table menu="pe" sub_type_master_id={Constants.subCategoryIds.pe_investments_id}/>
                </div>
              </section>
              {/* Secondary column (hidden on smaller screens) */}
              <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                  {/* Your content */}
                  <RefineSearch><InvestmentRefineSearch customPathname={pathname} /></RefineSearch>
                </div>
              </aside>
            </main>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Home;



