import { React, useState, useEffect, useContext, useMemo } from "react";
import { useParams, useLocation, matchPath } from "react-router-dom";
import { exportExcel, getFinancialUrl } from "../../Config/config";
import axios from "axios";
import FilingsFinancialTable from '../CFS/DetailsPage/CFSFinancial/FilingsFinancialTable'
import FormMCSite from '../CFS/DetailsPage/CFSFinancial/FormMCSite'
import ExcelDownloader from "../shared/ExcelDownloader";
import routePathSegments from "../../routeSegments";
import { CurrencyContext } from '../../Contexts/CurrencyContext'
import { UserContext } from "../../Contexts/UserContext";
import PLStatement from "./FinancialComponents/PLStatement";
import Constants from "../../Constants";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import useModule from "../../hooks/useModule";
import { Tooltip } from "antd";
import UpdateFinancial from "./FinancialComponents/UpdateFinancial";
import Loader from "../Loader";
import downloadIcon from '../../images/Download 3.svg'
import updateFinacialIcon from '../../images/Update Financials 2.svg'


/**
 * This  component is renders financial data, including profit and loss statements, 
 * balance sheets, cash flow statements, and ratios.
 */
const FinancialTabs = ({  page, companyName, fcompanyName }) => {

  const location = useLocation();

  const cfsMatch = matchPath(`${routePathSegments.cfs}/*`, location.pathname);

  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { currencyType, currency, setIsDetailed} = useContext(CurrencyContext);
  const [financialData, setFinancialData] = useState();

  const [isDetail, setIsDetail] = useState(cfsMatch != null);

  useEffect(() => {
    setIsDetailed(isDetail)
  }, [isDetail])
  
  const { companyId } = useParams();
  const {isTrialExport, getToken, isStudent, isDirectoryUser } = useContext(UserContext);
  const [alert, setalert] = useState({ message: "", show: false });
  const [showUpdateFinancial, setShowUpdateFinancial] = useState(false);
  const { primaryMasterId } = useModule();
  const [isLoading, setIsLoading] = useState(true);
  let companyProfileId = parseInt(companyId);
  const [isData, setIsData] = useState(false);
  const [companyLabel, setCompanyLabel] = useState()
  const [isExport, setIsExport] = useState();
  const [isFiling,setIsFilling] = useState()


  useEffect(() => {
    setIsLoading(true)
    const abortController = new AbortController()
    axios.post(getFinancialUrl, {
      "type": primaryMasterId,
      "sub_section": [
        "plstandard",
        "balancesheet",
        "cashflow",
        "ratio"
      ],
      "is_detailed": isDetail,
      "currency_cd": currencyType,
      "display_in": currency,
      "company_id": [
        companyProfileId
      ]
    }, {
      signal: abortController.signal,
      headers: { "Authorization": getToken() }
    }).then(response => {
      setIsLoading(false);
      if (response.status == 200) {
        setFinancialData(response.data.data[0]);
        setCompanyLabel(response?.data?.companyLabel)
        isDataClick(openTab,response.data.data[0])
        setIsExport(response.data?.isExport);
        setIsFilling(response?.data?.isFilings)
        if (response.data.data.length != 0 && response.data.data != null) {
          setshowXlDownload(true);
        } else {
          setshowXlDownload(false);
        }
      }
    }).catch(error => {

    })

    return () => abortController.abort()
  }, [currencyType, currency, isDetail, primaryMasterId, companyProfileId, getToken])


  const isDataClick = (openTab, financialData) => {
    const isEmpty = (data) => {
  if (!data) {
    return true;
  }
  if (!data?.consolidated || !data?.standalone) {
    return true;
  }
  if (data.consolidated.length === 0 || data.standalone.length === 0) {
    return true;
  }
  if (
    !data.consolidated?.financial_year ||
    !data.standalone?.financial_year ||
    (data.consolidated.financial_year.length === 0 &&
    data.standalone.financial_year.length === 0)
  ) {
    return true;
  }
  return false;
};

  
    let result = "";
  
    if (openTab === 1) {
      result = isEmpty(financialData?.pl_standard) ? true : false;
    } else if (openTab === 2) {
      result = isEmpty(financialData?.balance_sheet) ? true : false;
    } else if (openTab === 3) {
      result = isEmpty(financialData?.cashflow) ? true : false;
    } else if (openTab === 4) {
      result = isEmpty(financialData?.ratio) ? true : false;
    } else {
      result = true
    }
    setIsData(result)
  }



 /**
  * The function `onDetailsClick` checks if a module called "cfs" is included in a list called
  * `allowedModulesList`, and if so, it sets the value of `isDetail` to the provided `value`. If "cfs"
  * is not included in `allowedModulesList`, it displays an alert message.
  * @param value - The value parameter is a variable that represents the value to be passed to the
  * setIsDetail function.
  */
  const onDetailsClick = (value) => {
    if (allowedModulesList.includes("cfs")) {
      setIsDetail(value);
    } else {
      setalert({ show: true, message: Constants.cfsSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }
  }

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })
  }

  const fileName = useMemo(() => {
    isDataClick(openTab,financialData)
    if (openTab === 1) {
      return Constants.exportNames.plStatement(companyName)
    } else if (openTab === 2) {
      return Constants.exportNames.balanceSheet(companyName)
    } else if (openTab === 3) {
      return Constants.exportNames.cashflow(companyName)
    } else if (openTab === 4) {
      return Constants.exportNames.ratios(companyName)
    } else {
      return Constants.exportNames.plStatement(companyName)
    }
  }, [openTab, companyName])

  return (
    <div>
      <div className="mx-4">
        <div className=" bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className={`py-3 ${showUpdateFinancial ? 'pr-1 pl-5':'px-5'}  border-b border-b-[#F1F1F1]`}>
            <div className="mt-3 lg:mt-0 flex md:flex justify-between space-y-2 md:space-y-0">
              <h3 className="text-[14pt] pr-2 md:pr-0 leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] lg:mt-3 flex items-end">
                Financials
              </h3>

              {openTab === 4 || openTab === 5 || openTab === 6 || !showXlDownload
                ? <div></div>
                  : isData ? <></> :
                showXlDownload && <span className="flex items-center md:justify-center justify-between md:space-x-4 space-x-2 md:pr-2">
                  {/* <div className="flex items-center mr-4">
                      <CurrencyTypeComponent /><span className="md:mx-3 mx-1">in</span><CurrencyComponent />
                  </div> */}
                  <div className="flex space-x-4">
                  {isStudent == 'false' && showXlDownload && isExport ? <ExcelDownloader
                    url={exportExcel}
                    fileName={fileName}
                    data={{
                      "export_from": "detail",
                      "module_type": "investments",
                      "section": "financials",
                      "type": primaryMasterId,
                      "is_detailed": isDetail,
                      "currency_cd": currencyType,
                      "display_in": currency,
                      "company_id": [companyProfileId],

                    }} >
                     <Tooltip title="Export">
                      <img src={downloadIcon} className="cursor-pointer h-[26px] w-[24px]" alt="" />
                    </Tooltip>  

                  </ExcelDownloader> : <></>}

                  {/* <Tooltip title="Update financials">
                  <img src={updateFinacialIcon} className="cursor-pointer  h-[26px] w-[24px]" alt="" onClick={(e) => setShowUpdateFinancial(true)}/>                  
                  </Tooltip> */}
                  {showUpdateFinancial ? <UpdateFinancial setOpen={setShowUpdateFinancial} page={page} /> : <></>}
                  </div>
                </span>
              }

            </div>
          </div>
          {
            isLoading
              ? <div className="w-full h-40 flex items-center justify-center">
                <Loader />
              </div>
              : <>
                {/* Fiancial  Header Section */}
                <div className="justify-between flex">
          <div className="flex flex-row overflow-x-auto space-x-9 scrollbar px-5 border-b border-b-[#F1F1F1] scrollbar-remove">
            <a
              className={
                "whitespace-nowrap  py-3 text-[12pt] leading-[17pt] lg:text-[14px] lg:leading-[15px]   block  " +
                (openTab === 1
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : "  text-[#2B2A29] ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
                // financialTable("plstandard", isDetail);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Profit & Loss Statement
            </a>

            <a
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px]   block lg:leading-[15px] ${isDirectoryUser ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 2
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
                // financialTable("balancesheet", isDetail);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
              disabled={isDirectoryUser}
            >
              {/* <Link to="/financial/balance-sheet"> */}
              Balance Sheet
              {/* </Link> */}
            </a>

            <button
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px]   block lg:leading-[15px] ${companyLabel !='XBRL' ? 'cursor-not-allowed':'cursor-pointer' || isDirectoryUser ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 3
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
              }}
              disabled={companyLabel !='XBRL' || isDirectoryUser}
              data-toggle="tab"
              href="#link3"
              role="tablist"
              
            >
              Cash Flow
            </button>

            <button
              className={
                `whitespace-nowrap  py-3 text-[12pt] leading-[17pt]  lg:text-[14px]   block lg:leading-[15px] ${isDirectoryUser ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 4
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(4);
                // financialTable("ratio", isDetail);

              }}
              data-toggle="tab"
              // href="#link4"
              role="tablist"
              disabled={isDirectoryUser}
            >
              Ratios
            </button>

            <button
              className={
                `whitespace-nowrap py-3 text-[12pt] leading-[17pt] lg:text-[14px]   block lg:leading-[15px] ${!isFiling || isDirectoryUser ? 'cursor-not-allowed':'cursor-pointer'}` +
                (openTab === 5
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(5);
              }}
              disabled={!isFiling || isDirectoryUser}
              data-toggle="tab"
              // href="#link5"
              role="tablist"
            >
              {/* <Link to="/financial/mca-site"> */}
              Filings
              {/* </Link> */}
            </button>


            <button
              className={
                `whitespace-nowrap py-3 text-[12pt] leading-[17pt] lg:text-[14px]   block lg:leading-[15px] ${isDirectoryUser ? 'cursor-not-allowed':'cursor-pointer'} ` +
                (openTab === 6
                  ? "text-[#2B2A29] border-b-[3px] border-[#F1CA75] font-semibold"
                  : " text-[#2B2A29]  ")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(6);
              }}
              data-toggle="tab"
              href="#link6"
              role="tablist"
              disabled={isDirectoryUser}
            >
              {/* <Link to="/financial/mca-site"> */}
              From MCA Site
              {/* </Link> */}
            </button>


          </div>

        </div>
                {/* <FinancialTableHeader></FinancialTableHeader> */}
                {/*  Fiancial Content Section   */}
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <PLStatement companyName={companyName} data={financialData?.pl_standard} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} showXlDownload={showXlDownload} isExport={isExport} companyLabel={companyLabel}/>
                </div>

                {/* Balance Sheet */}

                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <PLStatement companyName={companyName} data={financialData?.balance_sheet} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} showXlDownload={showXlDownload} isExport={isExport} companyLabel={companyLabel}/>
                </div>

                {/* Cash Flow */}
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <PLStatement companyName={companyName} data={financialData?.cashflow} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} showXlDownload={showXlDownload} isExport={isExport} companyLabel={companyLabel}/>
                </div>

                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <PLStatement companyName={companyName} data={financialData?.ratio} isDetails={isDetail} setIsDetail={onDetailsClick} openTab={openTab} isRatio={true} companyLabel={companyLabel}/>
                </div>
              </>
          }
          <div className={openTab === 5 ? "block" : "hidden"} id="link5">
            <FilingsFinancialTable companyName={fcompanyName} />
          </div>
          <div className={openTab === 6 ? "block" : "hidden"} id="link6">

            <FormMCSite />
          </div>
        </div>

      </div>
      {/* {open && <ChatPopup page="pe" component="financial" data={false} /> } */}
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
  );

};


export default FinancialTabs;
