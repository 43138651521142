import { Fragment, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { exportXLS } from "../../Config/config";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../images/search_close_button.png";
import { TableDataContext } from "../../Contexts/TableDataContext";
import xls from '../../images/download-file-icon.png'
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';

/** The `DetailExcelPopup` component is a React functional component that renders a button with an
image. When the button is clicked, it opens a dialog box with a download button. */
const DetailExcelPopup = (props) => {
  const { dealIdTab } = useContext(TableDataContext);
  const [open, setOpen] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState();
  const { companyId } = useParams();
  const { getToken, logout } = useContext(UserContext);
  const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })

  const onExportClick = async () => {
	const result = await downloadExcel()
	if (result === true) {
	} else {
		setReachedLimitPopup({ show: true, message: result })
	}	
	setOpen(false)
  }

  const downloadExcel = async() => {
    const result = await axios(`${exportXLS}`, {
      method: "POST",
      data: {
        "company_id": companyId,
        "deal_id": dealIdTab,
        "active_tab": props?.activeTab

      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken(),
      },
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log(response.headers['content-disposition'], "companyheader");
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${props.exportFileName ?? "file"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setOpen(false)
        return true
      })
      .catch(async (error) => {
        console.log("API not working - getRoundHierarchy", error);
        if (error?.response?.status == Constants.sessionTerminatedStatus) {
            logout()
        } else if (error?.response?.status == 400) {
            const dataAsText = await error?.response?.data?.text()
            const parsedData = JSON.parse(dataAsText)
            return parsedData?.message
        }
      });
      return result
  };

	if (reachedLimitPopup.show) {
		return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
			<div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
				<div className='w-full flex flex-col '>
					<div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
						<h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
						<div className="mt-4 ">
							<p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
						</div>
					</div>
				</div>
				<div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
					<button onClick={() => setReachedLimitPopup(prev => ({ ...prev, show: false }))} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
						Close
					</button>
				</div>
			</div>
		</div>
	}

  return (
    <div>
      <a className="">
        <button>
          <img
            src={xls}
            className="h-[24px] w-[21px]"
            alt=""
            onClick={() => setOpen(true)}
          />

        </button>
      </a>
      {open &&
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="items-center flex min-h-full  justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute top-0 right-0  pt-4 pr-4 ">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        <img src={closeIcon} className="h-8 w-8 " />
                      </button>
                    </div>
                    {/* {!show && <div> */}
                    <div className="text-left px-1 md:px-0 md:text-center font-sans_semi_bold text-[#333333] text-[15px] ">
                      Export
                    </div>

                    {/* </div>} */}


                    <div>

                      <div className="text-left  mt-2 font-[Squel Sans] text-[#333333] text-[13px] font-Medium ">
                        © TSJ Media Pvt. Ltd. This data is meant for the internal
                        and non-commercial use of the purchaser and cannot be
                        resold, rented, licensed or otherwise transmitted without
                        the prior permission of TSJ Media. Any unauthorized
                        redistribution will constitute a violation of copyright
                        law.
                      </div>
                    </div>
                    <div className="items-center justify-center sm:flex">
                      <button
                        type="button"
                        className={
                          "  mt-5 inline-flex w-full justify-center font-[Squel Sans]   border border-transparent px-4 py-1 text-base font-medium text-white bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517] shadow-sm sm:ml-3 sm:w-auto sm:text-sm  " +
                          (!selectedExportType && "cursor-pointer")
                        }
                        onClick={onExportClick}
                      >
                        Download
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }
    </div>
  );
};

export default DetailExcelPopup;
