import axios from 'axios';
import { React, useState, useContext } from 'react'
import Constants from '../../Constants';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import pdf from "../../images/pdf.png";
import { Spin, Tooltip } from 'antd';
import { UserContext } from '../../Contexts/UserContext';
import { LoadingOutlined } from '@ant-design/icons';
import useModule from '../../hooks/useModule';
import { toast } from 'react-toastify';

/** The `PdfDownloader` function is a React component that allows the user to download a PDF file. */
function PdfDownloader({ url, data, children, message, fileName = "file", type = "pdf",responseType="blob" }) {
    const [alert, setAlert] = useState({ show: false, message: message || Constants.excelPopupMessage })
    const { getToken, logout } = useContext(UserContext);
    const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })
    const [isDownloading, setIsDownloading] = useState(false);
    const {primaryMasterId} = useModule()

    const onChange = async (flag) => {
        if (flag === 1) {
            const result = await downloadExcel()
            if (result === true) {
            } else {
                setReachedLimitPopup({ show: true, message: result })
            }
        }
        setAlert(prev => ({ ...prev, show: false }))
    }

    const downloadExcel = async() => {
        setIsDownloading(true);
        const result = await axios.post(url, {...data,type:primaryMasterId},
            {
                responseType: responseType,
                headers: {
                    "Authorization": getToken()
                }
            }).then((response) => {
                setIsDownloading(false);
                if(response.status === 200 && response.data?.message){
                    toast.success(response.data?.message, { position: 'bottom-right' })
                }
                else if (response.status === 200) {
                    // console.log("haederhaederhaeder", response);
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${fileName}.${type}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                return true
            }).catch(async(error) => {
                setIsDownloading(false);
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                } else if (error?.response?.status == 400) {
                    if(responseType=="json"){
                        const dataAsText1 = await error?.response?.data?.message
                        return dataAsText1
                    }else{
                        const dataAsText = await error?.response?.data?.text()
                        const parsedData = JSON.parse(dataAsText)
                        return parsedData?.message
                    }
                    
                }
            })
            return result
    }

    if (reachedLimitPopup.show) {
        return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                <div className='w-full flex flex-col '>
                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button onClick={() => setReachedLimitPopup(prev => ({ ...prev, show: false }))} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <ConfirmPopup {...alert} onChange={onChange} icon={<></>} activeBtnName="Download" closeBtnName='Close' title='Select option for pdf file export' />
            {isDownloading
                ? <Spin indicator={<LoadingOutlined style={{ fontSize: 26, color: "#BC8B20", }} spin />} />
                : <Tooltip title="Export">
                    <span onClick={() => setAlert(prev => ({ ...prev, show: true }))}>
                        {children || <img src={pdf} className="cursor-pointer   h-[24px] w-[21px] " alt="" />}
                    </span>
                </Tooltip>}
        </div>

    )
}

export default PdfDownloader