import React, { useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';

import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorExistRefineSearch from '../../../Components/RefineSearch/InvestorExistRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
// import chatPopupIcon from '../../../images/chatPopupIcon.png'
// import ChatPopup from '../../../Components/ChatPopup'
import Constants from '../../../Constants'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";


/**
 * The MA_PE_VC function is a React component that renders a layout with a primary and secondary
 * column, and includes various components such as a card, filter tag, main menu, table, and refine
 * search.
 * @returns The function `MA_PE_VC` returns JSX elements that make up a component.
 */
function MA_PE_VC() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();

    const {refineSearchFilters} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className='h-screen flex flex-col'>
            {isFromGlobalSearch() ? ""
               :
            <div className="hidden lg:block">  <Card card2={window.location.pathname ?(window.location.pathname =='/pe/investors/exits/ma/pe-vc-investments'? 'PE-VC Exits':''):''}/>
            </div>
    }
               <div className='flex min-h-0 flex-1'>
                    <div className="h-full min-w-0 flex-1 border-t border-gray-200 lg:flex">
                        {/* Primary column */}
                        <main className={isFromGlobalSearch() ?"mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
                            <section
                                aria-labelledby="primary-heading"
                                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
                            >
                                <FilterTag menu = "investor" />
                                <MainMenu active={2} menu="maPeExit" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_ma_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_exit_ma}/>
                                <div className='overflow-y-auto'>
                                    <Table menu="maPeExit" sub_type_master_id={Constants.subCategoryIds.pe_investor_exitsma_id}/>

                                </div>
                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>
                        </main>
                    </div>
                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch><InvestorExistRefineSearch customPathname={location.pathname}/> </RefineSearch>
                        </div>
                    </aside>
                        
                </div>
            </div>
        </Layout>
    )
}

export default MA_PE_VC
