import React from 'react'
 import AdvisorCompanyDetail from '../../Components/REAdvisor/AdvisorDetailPage.js/AdvisorCompanyDetail'
 import RefineSearch from '../../Components/RefineSearchComponent'
 import Card from '../../Components/RE/ReListCard/AdvisorDetailcard'
import ReLayout from '../../Components/ReLayout'
import { useSearchParams } from 'react-router-dom'
import Constants from '../../Constants'
import ReAdvisorRefineSearch from '../../Components/RefineSearch/ReAdvisorRefineSearch'
import { routeNames } from '../../routeSegments'
import ReInvestorExitRefineSearch from '../../Components/RefineSearch/ReInvestorExitRefineSearch'
/**
 * The ReAdvisorDetail function is a React component that renders a layout with a primary column and a
 * secondary column, and includes a card and a company detail section.
 * @returns The ReAdvisorDetail component is returning JSX elements wrapped in a div. The JSX elements
 * include a ReLayout component, a Card component, and a section element. The section element contains
 * an AdvisorCompanyDetail component. There is also an aside element that contains a RefineSearch
 * component.
 */
const ReAdvisorDetail = () => {
    const[URLSearchParams, SetURLSearchParams] = useSearchParams({section:Constants.primaryMasterSubIds.re_advisor_investment_id})
    const section = parseInt(URLSearchParams.get('section'))
  return ( 
    <div>
    <ReLayout>
    <div className="flex h-screen flex-col">
            {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
            <Card />
            <div className="flex min-h-0 flex-1 overflow-hidden bg-[#EDEDED] ">
                {/* Primary column */}
                <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                    {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                    <AdvisorCompanyDetail  section={section}/>
                    
                {/* <Outlet /> */}
                {/* Your content */}
                </section>
                {/* Secondary column (hidden on smaller screens) */}
                <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                    <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                              <RefineSearch>
                                  {
                                      section === Constants.primaryMasterSubIds.re_advisor_investment_id
                                          ? <ReAdvisorRefineSearch customPathname={routeNames.re_advisors_re_investments} />
                                          : section === Constants.primaryMasterSubIds.re_advisor_exits_id
                                              ? <ReInvestorExitRefineSearch customPathname={routeNames.re_advisors_re_exits} />
                                              : section === Constants.primaryMasterSubIds.re_advisor_otherma_id
                                                  ? <ReInvestorExitRefineSearch  customPathname={routeNames.re_advisors_other_ma} />
                                                  : <ReAdvisorRefineSearch customPathname={routeNames.re_advisors_re_investments} />
                                  }
                              </RefineSearch>
                    </div>
                </aside>
            </div>
            <br/>
            
        </div>
    </ReLayout>
</div>
  )
}

export default ReAdvisorDetail
