import { Select } from 'antd';
import React, { useContext, useEffect } from 'react'
import { useMemo } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';

/**
 * The `InvestorHeadquartersFilter` function is a React component that renders a dropdown menu for selecting an
 * investor headquarters.
 * @returns a JSX element.
 */
function InvestorHeadquartersFilter({ open, accordionClick, HeadquartersOptions, setHeadquartersOptions, customPathname }) {
    const { InvestorHeadquarters, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.investorHeadquarters?.map(value => value.id) ?? [];
        setHeadquartersOptions(InvestorHeadquarters?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [InvestorHeadquarters, customPathname, refineSearchFilters, setHeadquartersOptions])

    const handleInvestorHeadquarterChange = (selectedValue, option) => {
        setHeadquartersOptions(HeadquartersOptions?.map(type => ({ ...type, isChecked: selectedValue === "all" ? false : type.id === selectedValue })))
    };

    const options = useMemo(() => {
        let temp = [{ value: "all", label: "All" }];
        HeadquartersOptions?.forEach(data => {
            temp.push({ value: data.id, label: data.name })
        });
        return temp ?? []
    }, [HeadquartersOptions])

    const selected = HeadquartersOptions?.filter(option => option.isChecked)?.at(0)



    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Investor Headquarters - " + selected?.name : "Investor Headquarters"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {/* <input
                        type="search"
                        onChange={() => { }}
                        className="input w-[98%] border-b-2 border-black my-2 focus:outline-none p-2 pt-0"
                        placeholder="Search"
                    /> */}
                    <div className='bg-white input-search pointer-events-auto border border-[#BC8B20]  rounded '>
                        <Select
                            className='w-full mt-2'
                            optionFilterProp='label'
                            showSearch
                            bordered={false}
                            defaultValue={{ value: "all", label: "All" }}
                            onChange={handleInvestorHeadquarterChange}
                            value={selected?.id ?? "all"}
                            // placeholder={<p className='text-black'>All</p>}
                            options={options}
                        />
                    </div>
                    {/* InvestorHeadquarters.map((investorHeadquarter, index) => (
                        <option key={investorHeadquarter.id} value={investorHeadquarter.id}>{investorHeadquarter.name}</option>
                        )) */}



                </div>

            </li>

        </div>
    )
}

export default InvestorHeadquartersFilter