import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import { routeNames } from '../../../../routeSegments';
import { FundingContext } from '../../../../Contexts/FundingDataProvider';
import OverflowToolTip from '../../../shared/OverflowToolTip';
import Constants from '../../../../Constants';
import Paginate from '../../../Paginate';
import ConfirmPopup from '../../../../Utils/ConfirmPopup';
import { useSortBy, useTable,usePagination } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';

/* The above code is a JavaScript React component that renders a table. It receives props such as
`menu`, `data`, and `companyFundingId`. */
const OtherMaTable = ({menu,data,companyFundingId}) => {
   
    const{setothermaData} = useContext(FundingContext);
    const location = useLocation();
    const [alert, setalert] = useState({ message: "", show: false });
    
    /**
     * The function `onConfirmation` sets the `show` property of the `alert` state to `false` and
     * clears the `message` property.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets the state of an alert to show a message with an "OK"
     * button.
     */
    const onBlurColumnClick = (cell) => {
      
        setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The above code is defining a custom sorting function using the `useMemo` hook in React. The
    function takes in two rows (`rowA` and `rowB`), a column ID, and a boolean value `desc`
    indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.acquirer ?? defaultVal).localeCompare(rowB?.values[columnId]?.acquirer ?? defaultVal);
        }
    }, []);
    /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
    hook in React. This sorting function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean flag `desc` indicating whether the sorting should be in descending order. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, []) 
    /* The above code is defining an array of columns for a table in a React component. Each column has
    properties such as id, Header (displayed column header), accessor (data accessor for the
    column), className (CSS class for the column), Cell (rendering component for the cell), and
    sortType (custom sort function for the column). */
    const columns = useMemo(() => {
        let blurColumns = true;

        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        // console.log("allowedModulesListallowedModulesList", allowedModulesList);
        if (allowedModulesList.includes("re")) {
            blurColumns = false;
        } else {
            blurColumns = true;
        }

        const addCommasToNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          };


        return [
            {
                id: "acquirer",
                Header: "Acquirer",
                accessor: "acquirer",
                className: "clickable w-[2%]",
                headerspanClassName: "",
                Cell: ({ value }) => {
                    return <><OverflowToolTip key={value} component={<Link 
                        onClick={(e) => {
                            if (menu === "re") {
                                if (!allowedModulesList.includes("re")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        rel="noopener noreferrer" 
                        state={{ prevPath: location.state?.prevPath ?? "" }} 
                        to={ routeNames. reCompanyOtherMaDealById(companyFundingId, value.id)
                              }>
                        {value.acquirer ?? " "}
                        {/* {data?.logo_path == "default.png" || data?.logo_path == "/default.png" || !data?.logo_path ?
                            <RandomLogo name={value.acquirer} />
                            :
                            <CompanyLogo name={value.acquirer}  page="company page" logo={data?.logo_path} />
                        } */}
                    </Link>} />
                    </>                  
                },
                sortType: customStringSortMemo,
            },
           
            {
                id:"dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                className:"w-[1%]",
                Cell: ({ value }) => {
                    return <Link 
                    onClick={(e) => {
                        if (menu === "re") {
                            if (!allowedModulesList.includes("re")) {
                                e.preventDefault();
                                e.stopPropagation();
                                onBlurColumnClick();
                            }
                        }
                    }}
                    state={{ prevPath: location.state?.prevPath ?? "" }} 
                    to={ routeNames. reCompanyOtherMaDealById(companyFundingId, value.id)}
                        >
                        {moment(value.date).format('MMM YYYY')}
                    </Link>
                },
                sortType:dealDateSort
            },
        
            ,
            {
                Header: "Deal Amount (US$ M)",
                accessor: "dealAmountInUS",
                className: "w-[7%] pl-[7.8rem] lg:pl-[7rem]",
                headerspanClassName: " ",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },



            },
           
        ];
    }, [companyFundingId,menu])

    /* The above code is a JavaScript React code snippet. It defines a function called `rowData` using
    the `useMemo` hook. */
    const rowData = useMemo(() => {
        let rows = [];
        const addCommasToNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          };
          
        console.log("datadata12333",data);

        data?.forEach(otherMa => {  
            rows.push({
                acquirer:otherMa?.Acquirer == Constants.restrictedPlaceholder ? otherMa?.Acquirer: { acquirer: otherMa?.Acquirer, id: otherMa.Deal_Id },
                dealDate: { date: otherMa.Deal_Date, id: otherMa.Deal_Id },
                dealAmountInUS: otherMa?.Deal_Amount==0?"":addCommasToNumber(otherMa?.Deal_Amount),
                

            })
        });

        setothermaData(rows.length === 0 ? null : rows);

        return rows;

    }, [data])
    /* The above code is using the `useMemo` hook in React to create an initial sort configuration. It
    is creating an array with a single object that has two properties: `id` and `desc`. The `id`
    property is set to 'dealDate' and the `desc` property is set to `true`. This configuration is
    used for sorting data, with the `dealDate` property being sorted in descending order. The empty
    array `[]` passed as the second argument to `useMemo` ensures that the initial sort
    configuration is only created once, when the component is first rendered. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

   /* The above code is using the `useTable` hook from the React-Table library to create a table
   instance. It is passing in an object with various configuration options, including the columns
   and data for the table, disabling the ability to remove sorting, and setting an initial state for
   the table with a specific page size, page index, and initial sorting. It is also using the
   `useSortBy` and `usePagination` hooks to enable sorting and pagination functionality for the
   table. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract specific properties and methods from
    the `tableInstance` object. These extracted properties and methods are then being used in a
    JavaScript React component. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;

    console.log("tableInstancetableInstance1", tableInstance);

    return (
        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                        { rows?.length > 0 ? 
                        <table className=" datatable w-[100%] " {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-sans_semi_bold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `mr-1 ${column.headerspanClassName}`,
                                                                    },

                                                                ])}
                                                            >{column.render('Header')}</span>
                                                            {/* <span className='mr-1'>{column.render('Header')}</span> */}
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {page?.length === 0
                                    ? <tr>
                                        <td colspan="8">
                                            <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                                No data found
                                            </div>
                                        </td>
                                    </tr>
                                    : page?.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        console.log(cell,"celll");
                                                        if (cell.column.blurColumns) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} />
                                                                </div>
                                                            </td>)
                                                        }

                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate whitespace-nowrap px-5 py-4   ${cell.column.className} `,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        :
                        <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                                No data found
                        </div>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </>
            </div>
        </div>
    )
}

export default OtherMaTable