import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import FundSector from '../RefineSearchComponents/FundSector';
import InvestorTypeFilter from '../RefineSearchComponents/InvestorTypeFilter';
import SubsectorFilter from '../RefineSearchComponents/SubSector';
import Constants from '../../Constants';

/**
 * The function `ReAdvisorRefineSearch` is a React component that renders a form for refining search
 * filters and applying them to a specific custom pathname.
 * @returns a JSX element, which represents the UI of the refine search component.
 */
function ReAdvisorRefineSearch({ customPathname, adviosrType }) {
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false

    const navigate = useNavigate();

    const { setrefineSearchFilters, onErrorHandler, refineSearchFilters, MinAcquirerDealRange, MaxAcquirerDealRange, setMonthPickerValue } = useContext(PeFilterContext);
    const { setSelectedPage } = useContext(TableHeaderContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);

    const [fundSectorOptions, setFundSectorOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [SubSectorOptions, setSubSectorOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])

  /**
   * The function `applyFilter` updates the refine search filters based on the selected options.
   */
    const applyFilter = () => {
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedFundSector = fundSectorOptions?.filter(option => option.isChecked);
        const selectedSubsector = SubSectorOptions?.filter(option => option.isChecked);
        const selectedCities = CityOptions?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        // console.log(selectedSubsector,'selectedSubsector');

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                fundSector: selectedFundSector,
                investorType: selectedInvestorType,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                subSector: selectedSubsector,
                city: selectedCities,
            },
        }))
    }

 /**
  * The `resetFilter` function resets a specific filter in the `refineSearchFilters` state object by
  * setting its value to an empty object.
  */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
 /**
  * The function `refineSearchFilter` updates search filters, resets certain values, applies filters,
  * and navigates to a new location if necessary.
  * @param event - The event parameter is an object that represents the event that triggered the
  * function. It contains information about the event, such as the type of event, the target element,
  * and any additional data associated with the event. In this case, it is used to prevent the default
  * behavior of the event, which is
  */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        setSelectedPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

   /**
    * The `onResetClick` function resets the selected page, resets the filter, and navigates to a
    * custom pathname if the current pathname is not already the custom pathname.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. In this case, it is likely a click event on a reset button.
    */
    const onResetClick = (event) => {
        event.preventDefault();
        setSelectedPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

   /**
    * The function `accordionClick` sets the selected value to either 0 or the provided value.
    * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It
    * is used to determine whether the accordion should be selected or deselected.
    */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    useEffect(() => {

        /* The line of code `const cityIds = refineSearchFilters[customPathname]?.city?.map(value =>
        value.id) ?? [];` is extracting the IDs of the selected cities from the
        `refineSearchFilters` object. */
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])




    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <FundSector
                                fundSectorOptions={fundSectorOptions}
                                setFundSectorOptions={setFundSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3}
                                label="Type"
                            />
                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1}
                            />
                            <AcquirerDealRangeFilter
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange} />
                            <SubsectorFilter
                                SubSectorOptions={SubSectorOptions}
                                setSubSectorOptions={setSubSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(2)}
                                open={select === 2}
                                dataPath="re-investments"
                            />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="City (of Target Company)"
                                open={select === 6} />
                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default ReAdvisorRefineSearch
