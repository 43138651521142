import React, { useEffect } from 'react'
import { useContext } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The RoundFilter function is a component in JavaScript that renders a filter for selecting rounds in
 * a search feature, with options that can be checked or unchecked.
 * @returns The RoundFilter component is returning a JSX element.
 */
function RoundFilter({ open, accordionClick, RoundOptions, setRoundOptions, customPathname }) {
    const { Rounds, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.round?.map(value => value.id) ?? [];
        setRoundOptions(Rounds?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [Rounds, customPathname, refineSearchFilters, setRoundOptions])

    const handleRoundChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentRounds = RoundOptions.map((round) =>
                ({ ...round, isChecked: checked })
            );
            setRoundOptions(currentRounds);
        } else {
            let currentRounds = RoundOptions.map((round) =>
                round.id === parseInt(id) ? { ...round, isChecked: checked } : round
            );
            console.log('rroound', currentRounds)
            setRoundOptions(currentRounds);
        }
    });

    const len = RoundOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Round"
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleRoundChange}
                        primaryEntity={RoundOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default RoundFilter