import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const { Option } = Select;

/**
 * The SelectComponent function is a React component that renders a search input field with options
 * fetched from an API and allows the user to select multiple values.
 * @returns The SelectComponent is being returned, which is a JSX element containing a Select component
 * from an external library.
 */
const SelectComponent = ({ child, selectedChild, setSelectedChild,  selected, setSelected }) => {

  const childIndex = child.uniqueId;

  console.log('from select child.values', child.values);

  // console.log('childIndex', childIndex);

  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('initial');

  console.log(child.values,"jkjlkjlki");
  useEffect(() => {
    fetchFunction()
  },[])

  useEffect(() => {
    fetchFunction()
  },[searchText])

/**
 * The fetchFunction makes a POST request to an API endpoint, passing a search key in the request body,
 * and sets the fetched options in the state.
 */
  const fetchFunction = () => {
    // Fetch options from the API
  /**
   * The function fetchOptions makes a POST request to an API endpoint, passing a search key in the
   * request body, and sets the fetched options in the state.
   */
    const fetchOptions = async () => {
      const endpoint = `https://api-v2dev.vionweb.com/api${child.filterData.apiEndPoint}`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_key: searchText,
        }),
      });
      const data = await response.json();
      // const fetchedOptions = data?.results?.cfs.slice(0, 10) || [];
      const fetchedOptions = (data?.results?.pe || data?.results) || [];
      setOptions(fetchedOptions);
    };

    if (searchText.length >= 1) {
      fetchOptions();
    } else {
      setOptions([]);
    }
  };

/**
 * The clearOptions function clears the options array and resets the searchText variable.
 */
  const clearOptions = () => {
    setOptions([])
    setSearchText("");
  }

/**
 * The handleSearch function sets the value of searchText to the provided value.
 * @param value - The value parameter represents the input value entered by the user in the search
 * field.
 */
  const handleSearch = (value) => {
    setSearchText(value);
  };


/**
 * The handleChange function updates the selected values for a child based on the selectedValues array
 * and updates the overall selected values.
 * @param selectedValues - The `selectedValues` parameter is an array that contains the values that
 * have been selected or changed by the user.
 */
  const handleChange = (selectedValues) => {
    setSelectedChild((prevSelectedChild) =>
      prevSelectedChild.map((prevChild, index) => {
        console.log(prevChild.id, child.id,index,childIndex,"index === childIndex");
        if (prevChild.id === child.id && child.uniqueId === childIndex ) {
        // if (prevChild.id === child.id) {
          return { ...prevChild, values: selectedValues.length==0?[""]:selectedValues };
        }
        return prevChild;
      })
    );
    setSelected(selectedValues)
  };

  
  return (
    <div className='multiSearch'>
    <Select
      key={childIndex}
      mode="multiple"
      filterOption={false}
      name={child.name}
      id={child.id}
      style={{
        width: '15rem',
        borderBottom: '1px solid #BC8B20',
        background: '#ffffff',
        height: '40px',
        maxHeight: '40px',
        overflowY: 'auto',
        minWidth: '10rem',
      }}
      className="text-[#333333] AdvanceFilterSearch"
      placeholder="Search..."
      bordered={false}
      onSearch={handleSearch}
      onClick={()=>setSearchText("initial")}
      onChange={handleChange}
      // value={child.values?.length>0?child.values?.filter(data=>data !== ""):undefined}
      value={child.values?.length>0?child.values?.filter(data=>data !== ""):undefined}
      onBlur={clearOptions}
      notFoundContent={
        searchText === '' || searchText.length < 1 ? null : (
          <p className="text-black">No results found</p>
        )
      }
    >
      {options.map((option) => (
        <Option key={option.id} value={option.name}>
          {option.name}
        </Option>
      ))}
    </Select>
    </div>
  );
};

export default SelectComponent;
