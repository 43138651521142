import React, { useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import Paginate from '../Paginate';
import Constants from '../../Constants';
import "./DataTable.css"
import Loader from '../Loader';
import LockIcon from "mdi-react/LockIcon";
import ConfirmPopup from "../../Utils/ConfirmPopup";
const defaultPropGetter = () => ({})

/**
 * The DataTable function is a JavaScript function that renders a table with sortable columns,
 * pagination, and optional loader and empty data placeholders.
 * @returns The DataTable component is returning a JSX element.
 */
function DataTable({
    columns,
    data,
    disableSortBy,
    minPageSize = Constants.noOfRowPerPage,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    sortBy = [],
    emptyDataPlaceholder,
    showLoader=false,
}) {

    const tableInstance = useTable({
        columns,
        data,
        disableSortRemove:true,
        initialState: { pageSize: minPageSize, pageIndex: 0, sortBy: sortBy },
        disableSortBy,
    },
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;

  const [alert, setalert] = useState({ message: "", show: false });

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" });
  };    

  const onBlurColumnClick = (cell) => {
    setalert({
      show: true,
      message: Constants.subscribeProductMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

    return (
        <>
            <div className='overflow-x-auto scrollbar'>
                {rows?.length > 0 ? 
                <table className=" datatable w-full" {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                        {
                            headerGroups.map(headerGroup => (

                                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (

                                            <th {...column.getHeaderProps([
                                                {
                                                      className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                      style: column.headerStyle,
                                                },
                                                column.getSortByToggleProps(),
                                                getColumnProps(column),
                                                getHeaderProps(column),
                                            ])} title="">
                                                <div className="flex">
                                                    <span 
                                                    {...column.getHeaderProps([
                                                        {
                                                              className: `mr-1 ${column.headerspanClassName}`,
                                                        },
                                                       
                                                    ])}
                                                    >{column.render('Header')}</span>
                                                    {disableSortBy === true ? "" : column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                            : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                        : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody {...getTableBodyProps()} className="text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                        {page.length == 0
                            ?emptyDataPlaceholder==null? <tr>
                                <td colspan="8">
                                <div className="h-[4rem] mx-auto text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                    No data found
                                </div>
                                </td>
                                </tr>:<></>
                            : page.map(row => {
                                prepareRow(row)
                                {console.log(row.length,"row")}
                                return (
                                  
                                    <tr {...row.getRowProps(getRowProps(row))} className="border-b"  {...row.length > 1 ? row.length.className="border-b" :""}
                                    >
                                        { 
                                            row.cells.map(cell => {
                                              if (
                                                cell.column.blurColumns ||
                                                cell.value ==
                                                  Constants.restrictedPlaceholder
                                              ) {
                                                return (
                                                  <td
                                                    onClick={onBlurColumnClick}
                                                    className=" relative whitespace-nowrap cursor-pointer"
                                                    {...cell.getCellProps()}
                                                  >
                                                    <div className="blur-sm bg-[#C9C9C9] flex justify-center select-none w-full h-full px-5 py-4">
                                                      {
                                                        Constants.restrictedPlaceholder
                                                      }
                                                    </div>
                                                    <div className="absolute h-full w-full flex items-center justify-center top-0 bottom-0">
                                                      <LockIcon size={16} />
                                                    </div>
                                                  </td>
                                                );
                                              }
                                                return (
                                                    <td {...cell.getCellProps([
                                                        {
                                                            
                                                            className:`data-td-animate lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                            

                                                            style: cell.column.style,
                                                        },
                                                        getColumnProps(cell.column),
                                                        getCellProps(cell),
                                                    ])}>
                                                        {
                                                            cell.value ? cell.render('Cell') : " "
                                                        }
                                                    </td>


                                                )
                                            })}
                                    </tr>
                                 
                                )
                            })}
                    </tbody>
                </table>
                :
                <div className="h-[3rem] mx-auto lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                    No data found
                </div>
                }
                {showLoader
                ? <div className='flex flex-col items-center justify-center h-40'>
                    <Loader />
                </div>
                :emptyDataPlaceholder !=null&&rows?.length<=0? emptyDataPlaceholder:<></>}
            </div>
            {rows?.length <= minPageSize
                ? <></>
                : <Paginate
                    currentPage={pageIndex + 1}
                    totalCount={rows?.length}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        gotoPage(page - 1);
                    }}
                />}
            {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </>
    )
}

export default DataTable