import React, { useState, useContext, useEffect, useRef } from "react";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import { SearchMenuContext } from "../../Contexts/SearchMenuContext";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import v2logo from "../../images/Bitmap/png/Bitmap.png";
import shape from "../../images/Shape_13/png/Shape.png";
import MobileMenu from "../../Components/MobileMenu";
import Search from "../../images/Shape_13/png/Shape.png";
import Profile from "../../Components/Profile";
import PopOverSearch from "../../Components/PopOverSearch";
import "../../Components/HeaderComponent.css";
import useModule from "../../hooks/useModule";
import BellNotification from "./BellNotification";
import { Modal, Tooltip } from "antd";
import QueryBuilderTabs from "../QueryBuilder/QueryBuilderTabs";
import advanceSearchIcon from '../../images/advanceSearch.svg'

/** The above  component  is responsible for rendering the
navigation bar of a web application. */
const NavbarWrapper = ({ children }) => {
  const navigate = useNavigate();

  const { searchText,setSearchText } = useContext(HeaderMenuContext);
  const PEHeaderSearchMenu = useContext(SearchMenuContext);
  // const [value, setValue] = useState("");
  // const [showText, setShowText] = useState(false);
  const FilteredData = [PEHeaderSearchMenu.data];
  const [popOver, setPopOver] = useState(false);
  const [popOverAdvance, setPopOverAdvance] = useState(false);
  const [currentTab, setcurrentTab] = useState();
  const [type, setType] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [searchLabel, setsearchLabel] = useState(
    "Search for companies / Investors / Legal Advisors/..."
  );
  // const [sound, setSound] = useState(
  //   "https://soundbible.com/mp3/glass_ping-Go445-1207030150.mp3"
  // );
  const location = useLocation();
  const { isCFS, isMA, isVC, isRE ,isDadhboard,isNotifications,isRequests,isAlerts} = useModule();

  // console.log(isDadhboard, "isDadhboard");
  // console.log(PEHeaderSearchMenu, "1234567890as");

  const searchMenuSelect = (item) => {
    // console.log(item, "1234567890as");
    navigate("/" + item.name.toLowerCase() + "/" + item.id);
  };
  // const onchangeSearchMenu = (e) => {
  //   setValue(e.target.value);
  //   setFilteredData([]);
  //   let resultArray = [];
  //   PEHeaderSearchMenu?.data.map((arr, i) => {
  //     if (arr?.label.toLowerCase().match(e.target.value)) {
  //       resultArray.push({ id: arr.id, name: arr.name, label: arr.label });
  //     }
  //   });
  //   console.log(resultArray, "qwertyuiop");
  //   setFilteredData(resultArray);
  // };

  // console.log("isCFS", isCFS);

  useEffect(() => {
    setSearchInput(searchText)
  }, [searchText]);

  useEffect(() => {
    if (location.pathname.match("limited-partners")) {
      setType("limited_partners");
      setsearchLabel("Type LP or Investor name..");
    } 
    else if (location.pathname.match("re-fund")) {
      setType("re_funds");
      setsearchLabel("Type Investor or Fund name..");
    }
    else if (location.pathname.match("funds") || location.pathname.match("fund")) {
      setType("funds");
      setsearchLabel("Type Investor or Fund name..");
    } else {
      if (isCFS) {
        setsearchLabel("Search for Companies - Enter Company name or CIN or Brand name");
        setType("cfs");
      } else if (isMA) {
        setsearchLabel("Search for Companies / Acquirers / Legal Advisors...");
        setType("ma");
      } else if (isVC) {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
        setType("vc_primary");
      } else if (isRE) {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
        setType("re");
      } else {
        setsearchLabel("Search for Companies / Investors / Legal Advisors/...");
        setType("pe_primary");
      }
       if (location.pathname.match("acquirer")) {
        setcurrentTab("acquirer");
      } else if (location.pathname.match("fund")) {
        setcurrentTab(isRE ? "re_fund":"fund");
      }else if (location.pathname.match("compan")) {
        setcurrentTab("company");
      } else if (location.pathname.match("investo")) {
        setcurrentTab("investor");
      } else if (location.pathname.match("transaction")) {
        setcurrentTab("t_advisor");
      } else if (location.pathname.match("advisor")) {
        setcurrentTab("l_advisor");
      }  
      else {
        setcurrentTab("company");
      }
    }
  }, [location.pathname]);
  const menuRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef?.current?.contains(event.target)) {
        // setShowText(false);
        // setShowModal(false)
      }
    });
  });
  const handleChange = (e) => {
    setSearchInput(e.target.value);
    setSearchText(e.target.value)
    // if(searchInput.length+1 > 2) {
    // setPopOver(true)
    // }
    // if(searchInput.length-1 ==0) {
    //   setPopOver(false)
    //   }
    if(e.target.value.length > 2) {
      setPopOver(true)
      }
     else if(e.target.value.length===0) {
        setPopOver(false)
      }
      else{
        setPopOver(false)
      }
  }
  const handlePaste =(e)=>{
    // setSearchInput(e.clipboardData.getData('Text'));
    // setSearchText(e.clipboardData.getData('Text'))
    const pastedValue= e.clipboardData.getData('Text')
    // console.log(pastedValue,'pastedValue');
    if(pastedValue.length+1 > 3) {
    setPopOver(true)
    }
    if(pastedValue.length-1 === 0) {
      setPopOver(false)
      }
  }

  const searchRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchInput?.length < 3 && searchRef.current && !searchRef.current.contains(event.target) && event.target?.id !== "globalSearchInput") {
        setSearchInput("")
        // onClose()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchInput?.length, searchRef, setSearchInput]);

  return (
    <>    
      <PopOverSearch
        show={popOver}
        searchInput={searchInput}
        handleChange={handleChange}
        setSearchInput ={(txt)=>{
          setSearchInput(txt);
          setSearchText(txt)
        }}
        onClose={() => setPopOver(false)}
        type={type}
        searchFrom={currentTab}
        searchLabel={searchLabel}
        handlePaste={handlePaste}
        setSearchText={setSearchText}
        setPopOver={setPopOver}
        
      /> 
      <Modal
       
        style={{ top: "48px" ,padding:'0px'}}
        className="advanceSearchScroll"
        destroyOnClose={true}
        width={"80vw"}                
        footer={null}        
        open={popOverAdvance}
        onOk={() => setPopOverAdvance(false)}
        onCancel={() => setPopOverAdvance(false)}
      >
        <div className="query-builder max-h-[80vh] overflow-y-auto relative">
      <QueryBuilderTabs setPopOverAdvance= {setPopOverAdvance}/>
      </div>
      </Modal>

       {/* <PopOverAdvanceSearch
        show={popOverAdvance}
        onClose={() => setPopOverAdvance(false)}
        setPopOverAdvance= {setPopOverAdvance}
      />     */}

      <div className=" lg:fixed lg:z-[100] w-full lg:left-0 relative bg-white">
        {/* <div className=" bg-white"> */}
          <div className=" w-full fixed z-[10]">
            <div className="relative z-10 flex-shrink-0  bg-white border-b border-gray-200  ">
              <div>
                <div className="flex  lg:px-0 justify-between items-center lg:pr-2">
                  <div className="flex">
                    {/* <SideBarApp />   */}
                    <div className="md:hidden ">
                      <span className="sr-only">Open sidebar</span>
                      <MobileMenu />
                    </div>
                    <div
                      className="bg-white rounded-full px-2 py-1 flex text-sm  "
                      id="user-menu-button"
                      aria-expanded="false"
                      aria-haspopup="true"
                    >
                      <img
                        className="h-9 w-21 rounded-full"
                        src={v2logo}
                        alt=""
                      />
                    </div>

                    <p className="border-r border-gray-200 h-[3rem]"></p>
                    {!isDadhboard &&
                    <div className="hidden md:flex pl-4 items-center space-x-2">
                      <div className={`${isCFS ? 'lg:w-[33rem] md:w-[20rem]':'lg:w-[28rem] md:w-[28.5rem]'} relative pl-[2.5rem] rounded-md  border border-gray-200 py-2`}>
                        <div className=" flex absolute z-10 inset-y-0 left-0 pl-3 items-center text-[#989898] pointer-events-none">
                          <img className="h-[17px] w-[17px]" src={Search} alt="search"/>
                        </div>
                        <input type="search"
                          value={searchInput}
                          ref={searchRef} 
                          id="globalSearchInput"
                          onChange={handleChange}
                          onPaste={handlePaste}
                          className="w-[96%] text-[15px] outline-none shadow-none bg-inherit text-[#333333] opacity-[.55] leading-[17px] tracking-[-0.28] font-sans_book_body"
                          placeholder={searchLabel}
                          autoComplete="off"
                        />
                        {/* {value.length >= 3 && (
                          <div className="lg:w-[24rem] md:w-[28.5rem] bg-white grid border border-[#CCCCCC] ">
                            {FilteredData.length > 0 ? (
                              FilteredData.map((item, i) => {
                                return (
                                  <div
                                    onClick={() => searchMenuSelect(item)}
                                    className=" px-4 py-2 font-sans_book_body justify-between flex hover:bg-[#F2F2F2] text-[#333333] tracking-[-0.28px] leading-[18px] text-[15px]"
                                  >
                                    <div
                                      style={{
                                        background: i ? "" : "[#F2F2F2]",
                                      }}
                                      key={item.id}
                                    >
                                      {item.label}
                                    </div>
                                    <div
                                      style={{
                                        background: i ? "" : "[#F2F2F2]",
                                      }}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="px-4 py-1 font-sans_book_body">
                                No Data Found
                              </p>
                            )}
                          </div>
                        )}                         */}
                      </div>
                      {isCFS || isRE || isMA || isNotifications || isRequests || isAlerts? <></> :
                      <Tooltip title="Advanced Filter">
                        <img src={advanceSearchIcon} alt="advancesearch" className="h-[30px] cursor-pointer" onClick={() => setPopOverAdvance(true)}/>
                      </Tooltip>}
                    </div>}
                  </div>

                  <div>
                    <div className=" md:flex items-center justify-between xl:pl-0">
                      <nav className="hidden md:flex space-x-10 px-4">
                        <section>
                          <div
                            className="hidden lg:flex flex-col items-stretch items-center  w-full "
                            x-data="{tab: 1}"
                          >
                            <div className="flex flex-row items-center gap-5 w-full">
                              {children}
                            </div>
                          </div>
                        </section>
                      </nav>
                      <div className="flex space-x-4 mr-3 items-center">
                        <p className="md:border-r border-gray-200 h-[3rem] "></p>                        
                        <div className="  md:hidden py-5">
                          <div className="">
                            <div className="">
                              <img
                                className=" text-gray-500 w-[1.5rem] float-right"
                                src={shape}
                                alt="shape"
                                onClick={() => setPopOver(!popOver)}
                              />                              
                            </div>
                          </div>
                        </div>
                        <BellNotification />                       
                        <div className="" ref={menuRef}>
                          <Profile />
                        </div>
                      </div>
                    </div>

                    {/* profile and notification end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default NavbarWrapper;
