import React from "react";
import TopSection from '../../../../Components/InvestorLimitedpartnerDetail/TopSection'
import Layout from '../../../../Components/ReLayout'
import RefineSearch from '../../../../Components/RefineSearchComponent'
import FundCompanyDetail from '../../../../Components/REInvestor/ReFundsDetail/FundCompanyDetails'
import InvestorFundRefineSearch from '../../../../Components/RefineSearch/InvestorFundRefineSearch'
import useModule from '../../../../hooks/useModule'
import { routeNames } from '../../../../routeSegments'

/**
 * The DealPage component renders a layout with a primary and secondary column, displaying a
 * FundCompanyDetail component in the primary column and a RefineSearch component in the secondary
 * column.
 * @returns The `DealPage` component is returning a JSX element, which represents the structure and
 * content of the page.
 */
const DealPage = ({menu}) => {
  
  const {isRE} = useModule()
  return ( 
    <div>
      <Layout>
        <div className="flex h-screen flex-col">
          {/* <Card />  */}
           <TopSection />
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Primary column */}
            <section
              className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
            >
              <FundCompanyDetail menu={menu}/>
               
            </section>

            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
              <div className="relative flex h-full flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearch>
                <InvestorFundRefineSearch customPathname={isRE ? routeNames.re_investors_funds: ""} />
                </RefineSearch>
              </div>
            </aside>
          </div>
        </div>
        <br />
        
      </Layout>
    </div>

  )
}

export default DealPage
