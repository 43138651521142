import axios from 'axios';
import {useLocation } from 'react-router-dom';
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { TableDataContext } from '../../Contexts/TableDataContext';
import moment from 'moment'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import { Modal } from 'antd';

/**
 * The `useExportRequest` function is a custom hook  that handles exporting data based on
 * various parameters and downloading it as an Excel file.
 * @returns The function `useExportRequest` returns an object with three properties:
 * `requestBodyForExport`, `isExporting`, and `downloadExcel`.
 */
const useExportRequest = ({ props, type, modules, module, url, filterData, module_type, advisor_type, category }) => {
    const location = useLocation();
    const { isDirectoryUser } = useContext(UserContext);
    const { tableCompanyId, tableInvestorId, tableAdvisorId, tableAcquirerId, allDealIds } = useContext(TableDataContext);
    const { monthPickerValue ,refineSearchFilters} = useContext(PeFilterContext);
    const [isExporting, setisExporting] = useState(false);
    const { getToken, logout } = useContext(UserContext);
    const [requestBodyForExport, setRequestBodyForExport] = useState(
        {
            "Export_from": "list",
            "with_deals": false,//selectedFilterName1 == "Company Profile + PE-VC Investments" ? true : false,
            "with_transaction": false,
            "category": "investments",
            "type": type,
            "module_type": module_type,
            "advisor_type": advisor_type,
            "with_agg_hide": 0,
            "exit_deal_columns":[],
            "profile_columns": ["ALL"], // selectedFilterNameProfile,
            "inv_deal_columns": ["ALL"],//["Company", "CIN", "Company Type", "Industry", "Sector", "Amount(US$M)", "Amount(INR Cr)", "Round", "Stage"],
            "company_id": tableCompanyId,
            "advisor_id": tableAdvisorId,
            "deal_ids":allDealIds,
            "date_range": {
                "from_date": monthPickerValue[0],
                "to_date": monthPickerValue[1]
            }
        }
    )


    useEffect(() => {
        const selectedInitial = filterData?.initial?.find(filter => filter?.isChecked);
        const selected = buildDealColumn(selectedInitial, filterData)


        let dealColumns = {};
        let transactionCategory = [];
        let category = filterData?.category[selectedInitial?.category]

        const groupedColumns = selected.reduce((group, product) => {
            const { category } = product;
            group[category] = group[category] ?? [];
            group[category].push(product);
            return group;
        }, {});
        console.log("groupedColumnsgroupedColumns", groupedColumns);
        Object.keys(groupedColumns)?.forEach(key => {
            const cKey = filterData?.categoryKeys[key];
            transactionCategory?.push(filterData?.category[key])
            dealColumns = { ...dealColumns, [cKey]: groupedColumns[key]?.map(filter => filter?.value) }
        })

        if (modules === "investor") {
            setRequestBodyForExport({
                "Export_from": "list",
                "with_investments": selectedInitial?.withDetails ?? false,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "category": category,
                "type": type,
                investor_id: tableInvestorId,
                "deal_ids":allDealIds,
                "module_type": module_type,
                "with_agg_hide": 0,
                "profile_columns": ["ALL"],
                "inv_deal_columns": ["ALL"],
                transaction_category: transactionCategory,
                exit_deal_columns: [],
                ipo_deal_columns: ['ALL'],
                angel_deal_columns: ['ALL'],
                incb_deal_columns: ['ALL'],
                ...dealColumns,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }
            })
        } else if (modules === "company") {
            setRequestBodyForExport({
                "Export_from": "list",
                "with_deals": selectedInitial?.withDetails ?? false,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "category": category,
                "type": type,
                company_id: tableCompanyId,
                "deal_ids":allDealIds,
                "module_type": module_type,
                "with_agg_hide": 0,
                "profile_columns": ["ALL"],
                "inv_deal_columns": ["ALL"],
                transaction_category: transactionCategory,
                exit_deal_columns: [],
                ipo_deal_columns: ['ALL'],
                angel_deal_columns: ['ALL'],
                incb_deal_columns: ['ALL'],
                ...dealColumns,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }
            })
        }
        else if (modules === "advisor") {
            setRequestBodyForExport({
                "Export_from": "list",
                "with_investments": selectedInitial?.withDetails ?? false,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "category": category,
                "module": module,
                "module_type": module_type,
                "advisor_type": advisor_type,
                "type": type,
                advisor_id: tableAdvisorId,
                "deal_ids":allDealIds,
                "with_agg_hide": 0,
                "profile_columns": ["ALL"],
                "inv_deal_columns": ["ALL"],
                transaction_category: transactionCategory,
                exit_deal_columns: [],
                ipo_deal_columns: ['ALL'],
                angel_deal_columns: ['ALL'],
                incb_deal_columns: ['ALL'],
                ...dealColumns,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }



            })

        }
        else if (modules === "ma-advisor") {
            setRequestBodyForExport({
                "Export_from": "list",

                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "module_type": module_type,
                "advisor_type": advisor_type,
                advisor_id: tableAdvisorId,
                "deal_ids":allDealIds,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }





            })

        }
        else if (modules === "ma-acquirer") {
            setRequestBodyForExport({
                "Export_from": "list",
                "module_type": module_type,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,

                acquirer_id: tableAcquirerId,
                "deal_ids":allDealIds,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }





            })

        }
        else if (modules === "re-investments") {
            setRequestBodyForExport({
                "Export_from": "list",
                "with_investments": selectedInitial?.withDetails ?? false,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "module_type": module_type,
                "category": category,
                "type": type,
                investor_id: tableInvestorId,
                "deal_ids":allDealIds,
                "with_agg_hide": 0,
                "profile_columns": ["ALL"],
                "inv_deal_columns": ["ALL"],
                transaction_category: transactionCategory,
                "exit_deal_columns": [],
                "ipo_deal_columns": ['ALL'],
                ...dealColumns,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }
            })
        }
        else if (modules === "re-company") {
            setRequestBodyForExport({
                "Export_from": "list",
                "with_deals": selectedInitial?.withDetails ?? false,
                "with_transaction": selectedInitial?.isTransaction ?? false,
                "is_refine": refineSearchFilters[location.pathname] !=undefined ? true : false,
                "category": category,
                "type": type,
                "module_type": module_type,
                company_id: tableCompanyId,
                "deal_ids":allDealIds,
                "with_agg_hide": 0,
                "profile_columns": ["ALL"],
                "inv_deal_columns": ["ALL"],
                transaction_category: transactionCategory,
                "exit_deal_columns": [],
                "ipo_deal_columns": ['ALL'],
                "other_ma_deal_columns": ['ALL'],
                ...dealColumns,
                "date_range": {
                    "from_date": moment(monthPickerValue[0]).format("YYYY-MM-DD"),
                    "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD"),
                }
            })
        }



    }, [modules, module, module_type, tableInvestorId, tableAdvisorId, advisor_type, type, filterData, tableCompanyId, category])

    const downloadExcel = useCallback(async ({ fileName = "file" }) => {
        setisExporting(true);
        const result = await axios(`${url}`, {
            method: "POST",
            data: JSON.stringify(requestBodyForExport),
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken(),
            },
            responseType: 'blob',
        })
            .then((response) => {

                setisExporting(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                return true
            })

            .catch(async (error) => {

                console.log("downloadExcel - error", error);
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                } else if (error?.response?.status == 400) {
                    const dataAsText = await error?.response?.data?.text()
                    const parsedData = JSON.parse(dataAsText)
                    return parsedData?.message
                }
            });
        console.log({ result });
        return result
    }, [requestBodyForExport, url])

    return { requestBodyForExport, isExporting, downloadExcel }
}

/**
 * The function `buildDealColumn` recursively builds a column of filters based on a given filter option
 * and filter data.
 * @returns The function `buildDealColumn` returns an array of filtered data.
 */
const buildDealColumn = (filterOption, filterData) => {
    let data = [];
    filterData[filterOption?.filterKey]?.filter(filter => filter.isChecked && parseInt(filter.id) !== 100)
        ?.forEach(filter => {
            if (filter.hasMore) {
                const nestedData = buildDealColumn(filter, filterData);
                data = [...data.concat(nestedData)];
            } else {
                data = [...data.concat([filter])];
            }
        })
    if (data.length === 0) {
        filterData[filterOption?.filterKey]?.filter(filter => parseInt(filter.id) !== 100)
            ?.forEach(filter => {
                if (filter.hasMore) {
                    const nestedData = buildDealColumn(filter, filterData);
                    data = [...data.concat(nestedData)];
                } else {
                    data = [...data.concat([filter])];
                }
            })
    }
    return data;
}

/** The above code is a  component that represents a popup for exporting data. It
contains various state variables and functions to handle the selection of export options and trigger
animations. It also uses a custom hook called `useExportRequest` to handle the actual export request
and downloading of the exported file. The component renders a UI with left and right filter options,
breadcrumbs, and an export button. */
function ExportPopup({ onClose, exportOptions, type, modules, module, url, module_type, fileName, category, advisor_type, props }) {
    const { isDirectoryUser } = useContext(UserContext);
    const [filterData, setfilterData] = useState(exportOptions);
    const [selectedLeft, setselectedLeft] = useState(filterData.initial?.at(0));
    const [filterLevel, setfilterLevel] = useState([]);
    const [showAnimation, setshowAnimation] = useState(false);
    const [showRightAnimation, setshowRightAnimation] = useState(false);
    const [animationCount, setanimationCount] = useState(0);
    const [open, setOpen] = useState(false)
    const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })

    const { isExporting, downloadExcel } = useExportRequest({ type, modules, module, url, filterData, advisor_type, module_type, category })



   /**
    * The function `onBreadCrumbClick` updates the selected option and filter level based on the
    * clicked breadcrumb, and triggers a right animation.
    * @param option - The "option" parameter represents the selected breadcrumb option that was
    * clicked. It could be a string or an object, depending on how the breadcrumb options are defined
    * in your code.
    * @param index - The index parameter represents the index of the breadcrumb option that was
    * clicked.
    */
    const onBreadCrumbClick = (option, index) => {
        let temp = [...filterLevel];
        console.log(index,'temp');
        setselectedLeft(option)
        temp.splice(index + 1 > 1 ? index + 1 : 0);
        setfilterLevel(temp);
        triggerRightAnimation();

    }

 /**
  * The function `selectLeftFilter` updates the selected left filter option, triggers a right
  * animation, and updates the filter level if there are multiple filter options.
  * @param option - The `option` parameter is the value selected for the left filter.
  */
    const selectLeftFilter = (option) => {
        setselectedLeft(option);
        triggerRightAnimation();
        if (filterLevel?.length > 1) {
            let temp = [...filterLevel];
            temp[temp.length - 1] = option
            setfilterLevel([...temp])

        }
    }

   /**
    * The function `selectRightFilter` updates the selected left option, triggers an animation, and
    * updates the filter level based on the selected option.
    * @param option - The `option` parameter is the value that is selected by the user. It represents
    * the option that the user wants to filter by.
    */
    const selectRightFilter = (option) => {
        setselectedLeft(option);
        triggerAnimation();

        if (filterLevel?.length > 1) {
            setfilterLevel(prev => ([...prev, option]))
        } else {
            setfilterLevel([selectedLeft, option])
        }
    }

   /**
    * The function `onInitialChange` updates the `filterData` state and selects a left filter option.
    * @param option - The `option` parameter is an object that contains information about the selected
    * option. It may have properties such as `id`, `parent`, and `isChecked`.
    */
    const onInitialChange = (e, option) => {
        const { id } = e.target;
        setfilterData(filterData => {
            let temp = exportOptions[`${option?.parent}`].map(filter => ({ ...filter, isChecked: filter.id === id }));
            console.log("filterDatafilterData", temp, option.id);
            return { ...exportOptions, [`${option?.parent}`]: [...temp] }
        })
        selectLeftFilter({ ...option, isChecked: true });
    }

/**
 * The function `changeChildFilterStatus` modifies a filter data object by updating the isChecked
 * property of a specific filter option and its child filters recursively.
 * @param filterData - An object that contains filter data. It may have multiple filter options, each
 * represented by a key-value pair. The value is an array of filters for that option.
 * @param option - An object that contains information about the filter option. It has the following
 * properties:
 * @param isChecked - A boolean value indicating whether the filter option should be checked or
 * unchecked.
 * @returns The function `changeChildFilterStatus` returns the modified `filterData` object with
 * updated `isChecked` values for the specified `option`.
 */
    const changeChildFilterStatus = (filterData, option, isChecked) => {
        let modifiedFilterData = { ...filterData };
        let temp = filterData[option.filterKey]?.map(filter => {
            if (filter.hasMore) {
                modifiedFilterData = changeChildFilterStatus(modifiedFilterData, filter, isChecked)
                return { ...filter, isChecked: isChecked }
            } else {
                return { ...filter, isChecked: isChecked }
            }
        })
        modifiedFilterData = { ...modifiedFilterData, [option.filterKey]: [...temp] }
        return modifiedFilterData;
    }

   /**
    * The `onCheckChange` function is used to handle the change event when a checkbox is checked or
    * unchecked, and it updates the filter data accordingly.
    * @param e - The `e` parameter is an event object that is passed when the checkbox is changed. It
    * contains information about the event, such as the target element and the checked state of the
    * checkbox.
    * @param option - The `option` parameter is an object that contains information about the selected
    * option. It may have the following properties such as `id`, `parent`, and `isChecked`.
    */
    const onCheckChange = (e, option) => {
        const { id, checked } = e.target;
        setfilterData(prevFilterData => {
            //Current data list
            let temp = prevFilterData[`${option?.parent}`]?.map(filter => {
                if (parseInt(id) === 100) {
                    return { ...filter, isChecked: !option.isChecked }
                }
                let temp = { ...filter, isChecked: parseInt(filter.id) === parseInt(id) ? !filter.isChecked : filter.isChecked };
                return temp;
            })

            let isAllSelected = temp.filter(fill => !fill?.isChecked);

            if (isAllSelected?.length === 1) {
                temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
            }

            let modifiedFilterData = { ...prevFilterData, [`${option?.parent}`]: [...temp] };

            //Change all its child fiters to checked or unchecked if parent is checked or unchecked
            if (option.hasMore) {
                modifiedFilterData = changeChildFilterStatus(modifiedFilterData, option, checked)
            } else if (parseInt(option.id) === 100) {
                modifiedFilterData[option.parent].forEach(filter => {
                    if (parseInt(filter.id) !== 100 && filter.hasMore) {
                        modifiedFilterData = changeChildFilterStatus(modifiedFilterData, filter, checked)
                    }
                })
            }


            //Parent data list of current data list
            filterLevel?.forEach((filterLev, index) => {
                if (index !== 0) {

                    const checked = modifiedFilterData[filterLev.filterKey].filter(filter => filter.isChecked);
                    console.log("filterLevfilterLev", filterLev);

                    let temp = modifiedFilterData[filterLev.parent]?.map(filter => {
                        return { ...filter, isChecked: filter.filterKey === filterLev.filterKey ? checked.length === 0 ? false : true : filter.isChecked }
                    })


                    let isAllSelected = temp.filter(fill => !fill?.isChecked);

                    if (isAllSelected?.length === 1) {
                        temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
                    }
                    modifiedFilterData = { ...modifiedFilterData, [`${filterLev.parent}`]: [...temp] }
                }
            })


            return { ...modifiedFilterData }
        })


    }


    const triggerAnimation = () => {
        setshowAnimation(false);
        setshowRightAnimation(false);
        setanimationCount(prev => prev + 1);
    }


    const triggerRightAnimation = () => {
        setshowRightAnimation(false);
        setanimationCount(prev => prev + 1);
    }


    useEffect(() => {
        setshowAnimation(true)
        setshowRightAnimation(true);

    }, [animationCount])


    const onExportClick = async () => {
        setOpen(true)
        // const selectedInitial = filterData?.initial?.find(filter => filter?.isChecked);        

        const result = await downloadExcel({ fileName: fileName })
        if (result === true) {
            onClose()
        } else {
            setReachedLimitPopup({ show: true, message: result })
        }

    }



    useEffect(() => {
        filterLevel?.forEach((filterLev, index) => {
            if (index !== 0) {

                const checked = filterData[filterLev.filterKey].filter(filter => filter.isChecked);

                let temp = filterData[filterLev.parent]?.map(filter => {
                    return { ...filter, isChecked: filter.filterKey === filterLev.filterKey ? checked.length === 0 ? false : true : filter.isChecked }
                })


                let isAllSelected = temp.filter(fill => !fill?.isChecked);

                if (isAllSelected?.length === 1) {
                    temp[0] = { ...temp[0], isChecked: parseInt(isAllSelected[0].id) === 100 }
                }

                setfilterData(prev => ({ ...prev, [`${filterLev.parent}`]: [...temp] }))

            }

        })
    }, [filterLevel])
    console.log('filterLevel-12', filterLevel);


    if (reachedLimitPopup.show) {
        return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                {/* Header */}
                <div className='w-full flex flex-col '>
                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button onClick={onClose} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>
    }

    return (

        <>

            {modules === "ma-advisors " || modules == "ma-acquirers" ?
                <div show={open} className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>


                    <div className='flex flex-col w-[95%] md:w-[36%] h-[38%] bg-white rounded-lg overflow-hidden'>
                        {/* Header */}
                        <div className='w-full flex flex-col  p-4'>
                            <div className='w-full'>
                                <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Export</h1>
                                <h1 className='text-left text-sm leading-5 text-gray-500'>How do you prefer to export?</h1>
                            </div>
                            <div className='flex w-full py-2 overflow-y-auto '>
                                {
                                    filterLevel?.map((level, index) => <div className='flex justify-center items-center'>
                                        <h6 onClick={() => index === filterLevel.length - 1 ? null : onBreadCrumbClick(level, index)} className={`${index === filterLevel.length - 1 ? "" : "whitespace-nowrap cursor-pointer hover:font-bold"} text-[12px] text-gray-900 text-left `}>{level?.name}</h6>
                                        {(index === filterLevel.length - 1) ? <></> : <svg className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>}
                                    </div>
                                    )
                                }
                            </div>


                        </div>

                        {/* Options Container */}
                        <div className='h-[40%] w-full flex flex-row overflow-auto border-gray-200 border'>

                            <div className={`${showAnimation ? "w-[120%] transition-all duration-150" : "w-0"} h-[50%]  text-left border-gray-200 border-r`}>
                                {
                                    filterData[`${selectedLeft?.parent}`]?.map(option => {
                                        if (option.parent === "initial") {
                                            return <InitialItems
                                                title={option.name}
                                                key={option.name}
                                                onClick={() => selectLeftFilter(option)}
                                                hasMore={option?.hasMore}
                                                checked={option?.isChecked}
                                                selected={option?.id === selectedLeft?.id}
                                                name={option.parent}
                                                id={option.id}
                                                onCheckChange={(e) => onInitialChange(e, option)}
                                            />
                                        }
                                        return <Items
                                            key={option.name}
                                            title={option?.name ?? ""}
                                            id={option?.id}
                                            checked={option?.isChecked}
                                            selected={option?.id === filterLevel?.at(filterLevel?.length - 1)?.id}
                                            onCheckChange={(e) => onCheckChange(e, option)}
                                            onClick={() => selectLeftFilter(option)}
                                            hasMore={option?.hasMore}

                                        />
                                    })
                                }
                            </div>

                        </div>

                        <div className='flex bg-gray-50 px-4 py-3 flex-row-reverse'>
                            <button type='submit' value="submit" onClose={() => setOpen(false)} onClick={isExporting ? null : onExportClick} className='inline-flex w-full justify-center rounded-md border border-transparent font-sans_bold_body font-medium text-white bg-[#BC8B20] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#a37517] ml-3 sm:w-auto text-sm'>
                                {isExporting
                                    ? <span className='flex items-center '>
                                        <span role="status">
                                            <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                        </span>
                                        Exporting
                                    </span>
                                    : "Export"}
                            </button>
                            <button onClick={onClose} className='items-center inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
                :
                <div show={open} className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>


                    <div className='flex flex-col w-[95%] md:w-[60%] h-3/5 bg-white rounded-lg overflow-hidden'>
                        {/* Header */}
                        <div className='w-full flex flex-col  p-4'>
                            <div className='w-full'>
                                {isDirectoryUser && modules === 'company' ? <h1 className='mt-4 mx-4 pt-4 text-left text-sm leading-5 text-gray-500'>{Constants.excelPopupMessage}</h1>: 
                                    <>
                                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Export</h1>
                                        <h1 className='text-left text-sm leading-5 text-gray-500'>How do you prefer to export?</h1>
                                    </>
                                }
                            </div>
                            <div className='flex w-full py-3 overflow-y-auto '>
                                {
                                    filterLevel?.map((level, index) => <div className='flex justify-center items-center'>
                                        <h6 onClick={() => index === filterLevel.length - 1 ? null : onBreadCrumbClick(level, index)} className={`${index === filterLevel.length - 1 ? "" : "whitespace-nowrap cursor-pointer hover:font-bold"} text-[12px] text-gray-900 text-left `}>{level?.name}</h6>
                                        {(index === filterLevel.length - 1) ? <></> : <svg className="w-5 h-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>}
                                    </div>
                                    )
                                }
                            </div>


                        </div>

                        {/* Options Container */}
                        {
                            isDirectoryUser && modules === 'company' ? (
                                <>
                                    <div className='h-full w-full flex flex-row overflow-auto border-gray-200 border'>

                                        <div className={'w-full h-full  text-left overflow-auto border-gray-200 border-r'}>
                                            {filterData[`${selectedLeft?.parent}`].slice(0,1)?.map(option => 
                                                    <InitialItems
                                                        title={option.name}
                                                        key={option.name}
                                                        onClick={() => selectLeftFilter(option)}
                                                        hasMore={option?.hasMore}
                                                        checked={option?.isChecked}
                                                        selected={option?.id === selectedLeft?.id}
                                                        name={option.parent}
                                                        id={option.id}
                                                        onCheckChange={(e) => onInitialChange(e, option)}
                                                    />
                                            )} 
                                        </div>        
                                    </div>
                                </>
                            )   :
                            <div className='h-full w-full flex flex-row overflow-auto border-gray-200 border'>

                            <div className={`${showAnimation ? "w-[120%] transition-all duration-150" : "w-0"} h-full  text-left overflow-auto border-gray-200 border-r`}>
                                
                                    {filterData[`${selectedLeft?.parent}`]?.map(option => {
                                        if (option.parent === "initial") {
                                            return <InitialItems
                                                title={option.name}
                                                key={option.name}
                                                onClick={() => selectLeftFilter(option)}
                                                hasMore={option?.hasMore}
                                                checked={option?.isChecked}
                                                selected={option?.id === selectedLeft?.id}
                                                name={option.parent}
                                                id={option.id}
                                                onCheckChange={(e) => onInitialChange(e, option)}
                                            />
                                        }
                                        return <Items
                                            key={option.name}
                                            title={option?.name ?? ""}
                                            id={option?.id}
                                            checked={option?.isChecked}
                                            selected={option?.id === filterLevel?.at(filterLevel?.length - 1)?.id}
                                            onCheckChange={(e) => onCheckChange(e, option)}
                                            onClick={() => selectLeftFilter(option)}
                                            hasMore={option?.hasMore}
                                            disabled={filterLevel?.at(0)?.isChecked === true ? false : true}
                                        />
                                    })}
                            </div>
                            <div className={`${showRightAnimation ? "w-full transition-all duration-150" : "w-0"} h-full overflow-auto`}>

                                {
                                    filterData[`${selectedLeft?.filterKey}`]?.map(option => {
                                        return <Items
                                            key={option.name}
                                            title={option?.name ?? ""}
                                            id={option?.id}
                                            disabled={selectedLeft?.parent === "initial" ? filterData?.initial?.find(filter => filter?.isChecked).filterKey !== option.parent : filterLevel?.at(0)?.isChecked === true ? false : true}
                                            checked={option?.isChecked}
                                            onCheckChange={(e) => onCheckChange(e, option)}
                                            onClick={() => selectRightFilter(option)}
                                            hasMore={option?.hasMore}
                                        />
                                    })
                                }
                            </div>
                        </div>}

                        <div className='flex bg-gray-50 px-4 py-3 flex-row-reverse'>
                            <button type='submit' value="submit" onClose={() => setOpen(false)} onClick={isExporting ? null : onExportClick} className='inline-flex w-full justify-center rounded-md border border-transparent font-sans_bold_body font-medium text-white bg-[#BC8B20] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#a37517] ml-3 sm:w-auto text-sm'>
                                {isExporting
                                    ? <span className='flex items-center '>
                                        <span role="status">
                                            <svg className="  mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                        </span>
                                        Exporting
                                    </span>
                                    : "Export"}
                            </button>
                            <button onClick={onClose} className='items-center inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>


    )
}

const Items = ({ title, hasMore, checked, onClick, selected, id, onCheckChange, disabled }) => {
    return <div
        className={` font-sans_medium_body justify-between cursor-default flex text-left  py-2 pr-2 pl-3 w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto ${selected ? "bg-gray-200" : "hover:bg-gray-50"}`}>
        <span className='flex text-[13px] items-center'>
            <input
                id={id}
                disabled={disabled}
                className='mr-2 cursor-pointer'
                checked={checked}
                onChange={onCheckChange}
                type="checkbox" />
            <label htmlFor={id} >{title}</label>
        </span>
        {hasMore === true ? <svg onClick={onClick} className="w-6 h-6 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg> : <></>}
    </div>
}


const InitialItems = ({ title, hasMore, checked, onClick, onCheckChange, name, id, selected }) => {
    return <div
        className={`transition ease-in-out delay-150 justify-between  flex text-left  py-2 pr-2 pl-3 w-full font-sans_medium_body font-medium text-gray-700 border-b border-gray-100 md:w-auto ${selected ? "bg-gray-200" : "hover:bg-gray-50"}`}>
        <span className='flex text-[13px] items-center'>
            <input
                id={id}
                className='mr-2'
                name={name}
                checked={checked}
                defaultChecked={checked}
                onChange={onCheckChange}
                type="radio" />
            <label key={id} htmlFor={id} >{title}</label>
        </span>
        {hasMore === true ? <svg onClick={onClick} className="w-6 h-6 cursor-pointer text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg> : <></>}
    </div>
}

export default ExportPopup