import React from 'react'
import { Link } from 'react-router-dom';

/**
 * The CustomNavigation function returns a Link component based on the provided props, with conditional
 * rendering based on the menu, dealId, and name props.
 * @returns a JSX element, specifically a `<Link>` component from React Router. The content of the link
 * is determined by the conditional logic in the function.
 */
function CustomNavigation(props) {
    return (
      props?.menu == 'angel' ?
      <Link to={"/"+props?.mainModule+"/"+props?.module+"/"+props?.menu+"/"+props?.entityId} className="hover:text-[#BC8B20] text-[#333333]">
        {props?.name?props?.name:"--"}
      </Link> :
      props?.dealId?
      <Link to={"/"+props?.mainModule+"/"+props?.module+"/"+props?.entityId+"/"+props?.dealId} className="hover:text-[#BC8B20] text-[#333333]">
        {props?.name?props?.name:"--"}
      </Link>
        :
      <Link to={"/"+props?.mainModule+"/"+props?.module+"/"+props?.entityId} className="hover:text-[#BC8B20] text-[#333333]">
        {props?.name?props?.name:"--"}
      </Link>
  )
}

export default CustomNavigation
