import React from "react";
import Loader from "../Loader";
import OverflowToolTip from "../shared/OverflowToolTip";

/**
 * The `AdvisorOverview` component renders a profile overview of an advisor, including their contact
 * information and other details, with an optional loading state.
 * @returns The `AdvisorOverview` component is returning a JSX structure that represents the advisor
 * details. It includes conditional rendering based on the `isLoading` prop. If `isLoading` is true, a
 * loader component is displayed. Otherwise, the advisor details are displayed in a table format.
 */
const AdvisorOverview = ({ advisorDetails, isLoading }) => {
  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED]">
        <div className="border ">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            {isLoading
              ? <div className="w-full h-40 flex items-center justify-center">
                <Loader />
              </div>
              : <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                  <div className="px-5 py-4 ">
                    <p className="  text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                      Profile
                    </p>
                    <div className="overflow-x-auto scrollbar mt-1">
                      <table className=" w-full bg-[#FFFFFF] font-sans_book_body ">
                        <tr className="table-row align-top">
                          <td className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Contact Person
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Designation
                            </p>

                          </td>
                          <td className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Email
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Telephone
                            </p>

                          </td>
                        </tr>
                        <tr className="border-b-[#E5E5E5] border-b space-x-6 font-sans_book_body table-row align-top">
                          <td className="lg:leading-[21px]">
                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.contact_person ? advisorDetails?.contact_person : "--"}
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">

                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.designation ? advisorDetails?.designation : "--"}
                            </p>

                          </td>
                          <td className=" lg:leading-[21px]">

                            <p className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.email ? advisorDetails?.email : "--"}
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">

                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.telephone ? advisorDetails?.telephone : "--"}
                            </p>

                          </td>
                        </tr>
                        <tr className="table-row align-top pt-2">
                          <td className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Website
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Address
                            </p>

                          </td>
                          <td className=" lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              City
                            </p>

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">
                            <p
                              className="text-[11pt]  leading-[18pt]  lg:text-[14px]  text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Country
                            </p>

                          </td>
                        </tr>
                        <tr className=" space-x-6 font-sans_book_body table-row align-top">
                          <td className="lg:leading-[21px]">
                            {advisorDetails?.website
                              ? <a href={advisorDetails?.website} target="_blank" className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap font-semibold " rel="noreferrer">
                                {advisorDetails?.website}
                              </a>
                              : "--"
                            }

                          </td>
                          <td className=" px-12 lg:px-5 lg:leading-[21px]">

                            <p className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                             <OverflowToolTip className="max-w-[18rem]" component={advisorDetails?.address ? advisorDetails?.address : "--"} />
                            </p>

                          </td>
                          <td className=" lg:leading-[21px]">

                            <p className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.city ? advisorDetails?.city : "--"}
                            </p>

                          </td>
                          <td className="px-12 lg:px-5 lg:leading-[21px]">

                            <p className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold ">
                              {advisorDetails?.country ? advisorDetails?.country : "--"}
                            </p>

                          </td>
                        </tr>



                      </table>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorOverview;