import React from 'react'

/**
 * The CAGRarrow function returns a div element containing an SVG arrow that rotates based on the arrow
 * prop and changes color based on the arrow prop.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `CAGRarrow` component. It can include the following properties:
 * @returns The CAGRarrow component is returning a div element containing an SVG icon. The SVG icon is
 * a downward or upward arrow, depending on the value of the "arrow" prop. The color of the arrow is
 * red if the "arrow" prop is 'down', and green if the "arrow" prop is not 'down'.
 */
const CAGRarrow = (props) => {
  return ( 
       <div className={props.arrow =='down' ? 'rotate-90': 'rotate-[270deg]'}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 49.997 50" fill={props.arrow=='down' ? 'red' :'green'}><path className="a" d="M-462.659,128.236a25.054,25.054,0,0,0-24.985,25.026,25.054,25.054,0,0,0,24.986,24.974,25.063,25.063,0,0,0,25.011-25A25.054,25.054,0,0,0-462.659,128.236Zm8.156,22.742c-.227-.236-.353-.372-.483-.5-1.525-1.536-3.059-3.063-4.573-4.61a2.257,2.257,0,0,1-.019-3.2,2.25,2.25,0,0,1,3.239.021q4.431,4.414,8.845,8.844a2.246,2.246,0,0,1,.025,3.362q-4.555,4.59-9.143,9.147a2.136,2.136,0,0,1-1.838.65,2.184,2.184,0,0,1-1.894-1.5,2.164,2.164,0,0,1,.561-2.391c1.6-1.615,3.208-3.215,4.813-4.822.128-.128.245-.266.442-.481h-12.526c-3.087,0-6.174,0-9.261-.007a2.094,2.094,0,0,1-2.037-1.363,2.169,2.169,0,0,1,.374-2.385,2.379,2.379,0,0,1,1.954-.769q10.379.016,20.758.006Z" transform="translate(487.644 -128.236)"/></svg>  
      </div> 
  )
}

export default CAGRarrow
