import React, { useContext, useMemo, useState } from 'react';
import ExcelDownloader from '../shared/ExcelDownloader';
import { AcquisitionExport } from '../../Config/config';
import Constants from '../../Constants';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import OverflowToolTip from '../shared/OverflowToolTip';
import routePathSegments, { routeNames } from '../../routeSegments';
import moment from 'moment';
import useModule from '../../hooks/useModule';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import DataTable from '../DataTables/DataTable';
import { UserContext } from '../../Contexts/UserContext';

/* The above code is a React component written in JavaScript. It is a component called "Acquisitions"
that displays a table of acquisitions data. */
const Acquistions = ({ acquirers, acquirerName, isLoading}) => {
    const { acquirerId } = useParams();
    const location = useLocation();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const { primaryMasterId } = useModule();
    const {isTrialExport,isStudent} = useContext(UserContext)

    /* The `customStringSortMemo` constant is a memoized function that is used for sorting strings in a
    specific column of a data table. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    /* The `dealDateSort` constant is a memoized function that is used for sorting the "Deal Date"
    column in a data table. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])
   

    /**
     * The function adds commas to a number to improve readability.
     * @returns The function `addCommasToNumber` returns a string with commas added to the number.
     */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

    /* The `const columns` is an array of objects that defines the columns for a data table in the
    Acquisitions component. Each object represents a column and contains properties such as `Header`
    (the column header text), `accessor` (the key to access the data for that column), and `Cell` (a
    custom component to render the cell content). */
    const columns = useMemo(() => [
        {
            Header: "Target",
            accessor: "target",
            Cell: ({ value }) => {
                return <div className='leading-[2rem] font-sans_book_body items-center justify-center'style={{whiteSpace:"break-spaces"}}>
                    {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
                        <RandomLogo name={value.name} page="company page" />
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.logo_path} />
                    }

                    <OverflowToolTip className=' w-[10rem] lg:max-w-[20rem] 2xl:max-w-[30rem]' component={
                        <Link className='flex text-start lg:inline leading-[1.1rem] hover:text-[#BC8B20]'
                            state={{ cameFrom: location.state?.prevPath }}
                            to={routeNames.maCompanyDealById(value?.id, value?.dealId)}>
                            {value.name ?? "--"}
                        </Link>
                    } />
                </div>

            },
            sortType: customStringSortMemo,
        },
      
        {
            Header: "Sector", 
            accessor: "sector",
            Cell:(cell)=>{
                return <span style={{whiteSpace:"break-spaces"}}>
                <OverflowToolTip className=' w-[10rem] lg:max-w-[20rem] 2xl:max-w-[30rem]' component={cell?.value} />
                </span>
            }
        },
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",

            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }}
                    to={routeNames.maCompanyDealById(cell.value.id, cell?.value?.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        
        { 
            Header: "Deal Amount (US$ M)",
            accessor: "dealAmount",
            className: "px-0 text-right pr-[2.5rem] w-[12%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Enterprise Value (INR Cr)",
            accessor: "enterpriseValue",
            className: "px-0 text-right pr-[2.5rem] w-[10%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        }

    ], []);

    /* The `rowData` constant is using the `useMemo` hook to memoize the creation of an array of
    objects. Each object represents a row of data for the DataTable component in the Acquisitions
    component. */
    const rowData = useMemo(() => {
        const rows = [];
        acquirers?.forEach((acquirer, i) => {
            rows.push({
                target         : { name: acquirer?.target, dealId: acquirer?.deal_id, id: acquirer?.company_id, logo_path: acquirer?.icon_path },
                dealDate       : { date: acquirer?.deal_date, dealId: acquirer?.deal_id, id: acquirer?.company_id },
                sector         : acquirer?.sector,
                dealAmount     : acquirer?.deal_amount> 0 ? acquirer?.deal_amount : "",
                enterpriseValue: acquirer?.enterprise_value > 0 ? acquirer?.enterprise_value : "",
            })
        });
        setshowXlDownload(rows.length !== 0)
        return rows;
    }, [acquirers])

    /* The `initialSort` constant is using the `useMemo` hook to memoize the creation of an array. This
    array is used to specify the initial sorting configuration for the data table in the
    Acquisitions component. */
    const initialSort = useMemo(() => [
        {
            id: "dealDate",
            desc: true
        }
    ], []);

    return (
        <div className="px-4 bg-[#EDEDED]">
            <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                    <div className="flex justify-between mb-2">
                        <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">Acquisitions</p>
                        {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload) && <ExcelDownloader
                            url={AcquisitionExport}
                            fileName={Constants.exportNames.acquisitions(acquirerName)}
                            data={{
                                "acquirer_id": [acquirerId],
                                "type": primaryMasterId,
                                "export_from": "detail"
                            }} />}
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white pb-2 rounded-[6px]">
                        <DataTable
                            columns={columns}
                            data={rowData}
                            sortBy={initialSort}
                            showLoader={isLoading}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};

export default Acquistions;