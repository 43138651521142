import React from 'react'
import Layout from '../Components/Layout'
import ReportsMain from '../Components/Reports/ReportsMain'
import useModule from '../hooks/useModule'
import SubscribeProductPage from '../Components/shared/SubscribeProductPage'
import routePathSegments, { routeNames } from '../routeSegments'


/**
 * The function `Reports` renders a component based on the conditions of the `allowedModulesList`
 * array.
 * @returns JSX elements.
 */
function Reports() {
    const {allowedModulesList} = useModule()
    return (
        <div>
            <Layout>
                {(allowedModulesList.includes('cfs') || allowedModulesList.includes('vc')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re')  ?
                <SubscribeProductPage to={allowedModulesList.includes('cfs') ? routeNames.cfs : routeNames.vc_companies_vc_investments} />
                :
                <div className="mt-[3.5rem]">
                 <ReportsMain/>
                </div>
                }
            </Layout>
        </div>
    )
}

export default Reports
