import React  from 'react'
import Layout from '../Components/Dashboard/Layout'
import Card from '../Components/Dashboard/Card'
import Table from '../Components/Dashboard/TableComponent' 
import { DashboardChart } from './Charts/dashboard/deals/DealsChart' 
/**
 * The Dashboard function returns a JSX element that represents a dashboard with a card, a chart, and a
 * table.
 * @returns a JSX element, which is a div with a className of 'bg-white'. Inside the div, there is a
 * Layout component, a Card component, a div with a className of 'mt-3 p-2 w-[100%]', a DashboardChart
 * component with a prop of dataColors, and a Table component.
 */
function Dashboard() {
 

    return (
        <div className='bg-white'>
            <Layout>
                <Card />
                <div className='mt-3 p-2 w-[100%]'>
                <DashboardChart  dataColors='["#474747", "#BC8B20"]'/>
                </div>
                <Table/>
            </Layout>
        </div>
    )
}

export default Dashboard
