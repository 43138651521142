import React from 'react'
import FAQSearch from '../../Components/FAQ/FAQSearch'
import Layout from '../../Components/Layout';
import Menu from '../../Components/FAQ/Menu';
import PeFAQ from './PE'

/**
 * The function PeFaq returns a JSX element that renders a layout with a search component, a menu
 * component, and a FAQ component specific to the PE category.
 * @returns a JSX element.
 */
function PeFaq() {

  return (   
    <div>
      <Layout>
        <FAQSearch  menu="PE"/>
        <Menu active={1} menu="PE"/>
        <PeFAQ />
      </Layout>
    </div>
     )
}

export default PeFaq
