import { React, useState, useMemo,  useContext } from 'react'
// import { useQuery } from '@apollo/client'
import {  useParams, Link, useLocation, matchPath } from 'react-router-dom';
// import xls from '../../../images/download-file-icon.png'
// import sorting from '../../../images/up-down-arrow.png'
// import ExcelPopup from "../../../Components/ExcelPopup";
// import { ADVISOR_COMPANY_INVESTMENTS } from "../../../Queries/Pe"
import moment from 'moment'
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
// import axios from 'axios'
import OverflowToolTip from '../../shared/OverflowToolTip';
import Constants from '../../../Constants';
import useModule from '../../../hooks/useModule';
import { UserContext } from '../../../Contexts/UserContext';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';

 
const TransactionAdvisorCompaniesInvestments = (props) => {

    const [portfolio, setPortfolioData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(20)
    // const [openTab, setOpenTab] = useState(1);
    // const [showPopup, setShowPopup] = useState(false);
    // const [searchParams] = useSearchParams();
    let advisorType = "T";
    const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)   
    const location = useLocation();
    const [showXlDownload, setshowXlDownload] = useState(false);  
    const {primaryMasterId} = useModule();   
    const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext);
    const {toCompaniesInvesmentsDataFound} = useContext(DataFoundContext); 
    // const handleFetchPortfoliotRequestCallback = (response, requestSuccess) => {
    //     try {
    //         setPortfolioData(response);
    //     } catch (e) {
    //         console.log('Error', e)
    //     }
    // };

    // const { data, loading, error } = useQuery(ADVISOR_COMPANY_INVESTMENTS, {
    //     variables: { CIAId: advisorIdPE, AdvisorType: advisorType },
    //     onCompleted: (response) => handleFetchPortfoliotRequestCallback(response, true),
    //     onError: (response) => handleFetchPortfoliotRequestCallback(response),
    //     refetch: ADVISOR_COMPANY_INVESTMENTS

    // })
    // console.log("portfolio1", ADVISOR_COMPANY_INVESTMENTS)

    // const PortfolioData = useMemo(() => portfolio, [portfolio]);
    // console.log("advisorinvestmentportfoliodata", PortfolioData)


//   useEffect(() =>{
//     axios(`${advisorExitTable}`, {
//       method: "POST",
//       data: {
//         "type": "investments",
//         "advisor_id": advisorIdPE,
//         "advisor_type": advisorType
//     },
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }) 
//       .then((response) => {
//         // if (response.data) {
//           setPortfolioData(response.data)
//           console.log(response.data,"sxgfvhg")
//         // }
//       })
//       .catch((error) => {
//         console.log("API not working - investment deal ", error);
//       });
  
//     },[])


    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(portfolio?.length / dataPerPage); i++) {
        pageNumbers.push(i);
    }
    console.log(pageNumbers, "pageNumbers")
   /**
    * The function `setPage` sets the current page to the specified page number.
    * @param pageNum - pageNum is a parameter that represents the page number that you want to set as
    * the current page.
    */
    const setPage = (pageNum) => {
        setCurrentPage(pageNum)

    }
    /* The above code is defining a custom sorting function called `customStringSortMemo` using the
    `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean value `desc` indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

   /* The above code is defining a function called `dealDateSort` using the `useMemo` hook in React.
   This function is used for sorting rows in a table based on a specific column called `columnId`.
   It compares the dates of two rows (`rowA` and `rowB`) using the Moment.js library to calculate
   the difference in milliseconds. The result of the comparison is returned, which will determine
   the order of the rows in the table. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])
   /* The above code is defining a function called `investorSort` using the `useMemo` hook in
   JavaScript. This function is used for sorting rows in a table based on the values in a specific
   column. */
    const investorSort = useMemo(() => {
        return (rowA,rowB, columnId,desc)=>{
            const defaultVal = desc ? '--' : 'A';
      
            const valueA = rowA?.values[columnId].map(data=>data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data=>data.investor_name).join("")
      
            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
      }, [])

      const peMatch  =matchPath(`${routePathSegments.pe}/*`,location.pathname);

     /**
      * The function "addCommasToNumber" takes a number as input and returns a string representation of
      * the number with commas added for thousands separators.
      * @param number - The `number` parameter is the number that you want to add commas to.
      * @returns a string representation of the number with commas added for every three digits.
      */
      const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };
    
    /* The above code is defining an array of columns for a table. Each column has a Header (column
    name), accessor (data key), and Cell (rendering component for each cell in the column). The code
    also includes sorting functions for some of the columns. The columns are used in a table
    component to display data. */
    const columns = useMemo(() => [
        {
            Header: "Company Name",
            accessor: "companyName",
            className: "clickable  lg:relative text-left sticky left-0  bg-white",
            headerClassName: " lg:relative text-left sticky left-0   w-[3rem] bg-[#EDEDED] ",
            Cell: ({ value }) => {
                return <div className=' leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center'>
                    {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                        <RandomLogo name={value.name} page="company page"/>
                        : 
                        <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
                    }
                    <OverflowToolTip className="w-[11rem] lg:w-auto"  component={
                    <Link rel="noopener noreferrer" className=' text-start lg:inline flex hover:text-[#BC8B20]'
                    state={{ cameFrom: location.state?.prevPath }}
                    to={peMatch!=null?routeNames.peCompanyFundById(value?.id) 
                    :routeNames.vcCompanyFundById(value?.id)}>
                    {value.name ?? "--"}
                </Link>
                 } /> 
                </div>
            },
            sortType: customStringSortMemo, 
        },
        {
            id:"dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
                to={peMatch!=null?routeNames.peCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
                  :routeNames.vcCompanyInvestmentDealById(cell.value.id,cell.value.dealId)}>
                    {cell?.value?.date !='' ? moment(cell?.value?.date).format('MMM YYYY') : "--"}
                </Link>
            },
            sortType:dealDateSort
        },
        {
            Header: "Deal Amount (US$ M)",
            accessor: "dealAmountInUS",
            className:"px-0 text-right pr-[2.25rem] w-[10%]",
            headerspanClassName:"ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Investors",
            accessor: "investors",
            Cell: (cell) => {
                  return<OverflowToolTip  className="w-[14rem] lg:w-auto max-w-[20rem]" component={
                    cell.value.map((inv, index) => (<Link className='hover:text-[#BC8B20]' 
                    state={{cameFrom:location.pathname}} to={peMatch!=null?routeNames.peInvestorById(inv.investor_id)
                   :routeNames.vcInvestorById(inv.investor_id)}>
                       {`${inv.investor_name}${index == cell.value.length - 1 ? "" : ", "}`}
                   </Link>))
              } />
            },
            sortType:investorSort
        },
        {
            Header: "Post Money Valuation (INR Cr)",
            accessor: "enterpriseValue",
            className:"px-0 text-right pr-[2.25rem] w-[10%]",
            headerspanClassName:"ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Revenue Multiple",
            accessor: "revenueMultiple",
            className:"px-0 text-right pr-[2.25rem] w-[11%]",
            headerspanClassName:"ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        }
    ], []);

   /* The above code is a JavaScript function that uses the `useMemo` hook to create a memoized version
   of the `rowData` variable. */
    const rowData = useMemo(() => {
        const rows = [];
        {props.companyInvestment?.length>0 &&
        props.companyInvestment?.forEach((company, i) => {
            // let investor = company?.investor;

            rows.push({
                companyName: { name: company?.company_name, id: company?.company_id ,logo_path:company?.logo_path },
                dealDate: {date:company?.dates, dealId: company?.deal_id, id: company?.company_id},
                dealAmountInUS: company?.amount >0 ? company?.amount:'',
                investors:company?.investor,
                enterpriseValue: company?.company_valuation > 0 ? company?.company_valuation :'',
                revenueMultiple: company?.revenue_multiple > 0 ? company?.revenue_multiple :'',
            })
        });
    }
        setshowXlDownload(rows.length!=0)
        return rows;
    }, [props.companyInvestment])
   /* The `initialSort` constant is used to specify the initial sorting configuration for the data
   table. It is an array that contains an object with two properties: `id` and `desc`. */
    const initialSort = useMemo(() => [
        {
            id:"dealDate",
            desc: true
        }
    ], []);
    const { pathname, state } = useLocation();

    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
    
    return (
        <>
            {toCompaniesInvesmentsDataFound ? (
                <div className=" px-4 bg-[#EDEDED]">
                    <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                        <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                            <div className="flex justify-between mb-2">
                                <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">To Companies - Investments</p>
                                {!isDirectoryUser ?(isStudent == 'false' && isTrialExport !='false' && showXlDownload)&&<ExcelDownloader
                                url={exportExcel}
                                fileName={Constants.exportNames.to_companies_investments(props.advisorName)}
                                data={{
                                    "type":primaryMasterId,
                                    "export_from": "detail",
                                    "module_type": "advisors",
                                    "advisor_type":"T",
                                    "section": "company_investments",
                                    "advisor_id": [advisorProfileId]
                                }} />: ""}
                            </div>
                        </div>
                        <div className="space-y-6">
                            <div className="bg-white pb-2 rounded-[6px]">
                                <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
                            </div>
                        </div>
                    
                    </div>
                </div>
            ) : ""}
        </>
    )
}


export default TransactionAdvisorCompaniesInvestments;