import { React, useContext, useEffect, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import YearFoundedFilter from "../../Components/RefineSearchComponents/YearFoundedFilter";
import CountryFilter from "../../Components/RefineSearchComponents/CountryFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import StateFilter from "../../Components/RefineSearchComponents/StateFilter";
import CityFilter from "../../Components/RefineSearchComponents/CityFilter";
import VcFundedFilter from "../RefineSearchComponents/VcFundedFilter";
import ExitedFilter from "../RefineSearchComponents/ExitedFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation,  useNavigate } from "react-router";
import axios from "axios";
import { filterLocationUrl, filterSectorByIndutry } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";

/**
 * The `AngelRefineSearch` function is a React component that renders a form for refining search
 * filters in an angel investment application.
 * @returns a JSX element, which represents the UI of the component.
 */
function AngelRefineSearch({ customPathname }) {
    const {
        setrefineSearchFilters,
        refineSearchFilters,
        setSectors,
        onErrorHandler,setMonthPickerValue } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(0);
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    // const isMatch = useMatch({ path: routeNames.pe_companies_angel_investments })
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false



    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [SwitchState, setSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [VCFundedOptions, setVCFundedOptions] = useState([])
    const [ExitedOptions, setExitedOptions] = useState([])

    useEffect(() => {
        const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

        if (ids.length > 0) {
            axios.post(filterSectorByIndutry, {
                "industry": ids,
                category: "investments"
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                if (response.status === 200) {
                    setSectors(response.data?.data ?? [])
                } else {
                    setSectors([])
                }
            }).catch(onErrorHandler)
        } else {
            setSectors([])
        }

    }, [IndustriesOptions])

 /**
  * The `onCountryChange` function takes in an array of options, filters the checked options, and makes
  * a POST request to a specified URL with the filtered options as parameters, then updates the region,
  * state, and city options based on the response data.
  * @param options - The `options` parameter is an array of objects. Each object represents an industry
  * and has the following properties:
  */
    const onCountryChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setRegionOptions(response.data?.region)
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

   /**
    * The `onRegionChange` function takes in options and filters them based on whether they are
    * checked, then makes a POST request to a specified URL with the selected countries and regions,
    * and updates the state and city options based on the response.
    * @param options - The `options` parameter is an array of objects. Each object represents an
    * industry and has the following properties:
    */
    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

  /**
   * The `onStateChange` function sends a POST request to a specified URL with selected country,
   * region, and state options, and updates the city options based on the response.
   * @param options - The `options` parameter is an array of objects that represent industries. Each
   * object has the following properties:
   */
    const onStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }


   /**
    * The function `applyFilter` updates the refine search filters based on the selected options.
    */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = SectorOptions.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedVc = VCFundedOptions?.filter(option => option.isChecked);
        const selectedExited = ExitedOptions?.filter(option => option.isChecked);

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                countries: selectedCountries,
                region: selectedRegion,
                state: selectedState,
                city: selectedCity,
                operator: SwitchState ? 1 : 0,
                tags: TagsOptions,
                vcFunded: selectedVc,
                exited: selectedExited,
            },
        }))
    }

   /**
    * The function `resetFilter` resets a specific filter in the `refineSearchFilters` state object by
    * setting its value to an empty object.
    */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }


    // Submitting Refine Search Form 
   /**
    * The function `refineSearchFilter` updates search filters, resets the month picker value, sets the
    * current page to 1, applies the filter, navigates to a custom pathname if necessary, and sets the
    * selected state to false.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. It contains information about the event, such as the target element, the type of event,
    * and any additional data associated with the event. In this case, it is used to prevent the
    * default behavior of the event, which is
    */
    const refineSearchFilter = (event) => {
         setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
      
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_angel_investments);
        // }
        applyFilter();
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        setSelected(false)

    }

  /**
   * The `onResetClick` function resets the current page, filters, and navigates to a custom pathname
   * if it is different from the current location.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is the click event that occurred when the user clicked on a reset
   * button or link.
   */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_angel_investments);
        // }
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

  /**
   * The function `accordionClick` sets the selected value to 0 if it is equal to `select`, otherwise
   * it sets it to the provided value.
   * @param val - The `val` parameter is the value that is passed to the `accordionClick` function. It
   * is used to determine which accordion item should be selected.
   */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };
    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])
    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <div className="flex justify-between px-3 items-center pt-0">
                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>
                    <Switch
                        SwitchState={SwitchState}
                        setSwitchState={setSwitchState}
                        customPathname={customPathname} />
                </div>
                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                    <div className="sticky top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                        <label className=" flex  rounded ">
                            <Tags
                                TagsOptions={TagsOptions}
                                customPathname={customPathname}
                                setTagsOptions={setTagsOptions} />
                        </label>
                    </div>
                </div>
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className=" space-y-1 text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] ">
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                SectorOptions={SectorOptions}
                                setSectorOptions={setSectorOptions}
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <YearFoundedFilter
                                EndYearOptions={EndYearOptions}
                                customPathname={customPathname}
                                setEndYearOptions={setEndYearOptions}
                                StartYearOptions={StartYearOptions}
                                setStartYearOptions={setStartYearOptions} />
                            <VcFundedFilter
                                VCFundedOptions={VCFundedOptions}
                                setVCFundedOptions={setVCFundedOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                open={select === 4}
                                heading="VC Funded" />
                            <ExitedFilter
                                ExitedOptions={ExitedOptions}
                                setExitedOptions={setExitedOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(5)}
                                open={select === 5} />
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(10)}
                                onChange={onCountryChange}
                                open={select === 10} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(12)}
                                open={select === 12} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default AngelRefineSearch